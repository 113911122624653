import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ResponsiveImage } from '@atoms/images'
import { Spacer } from '@atoms/layout'
import { Heading, Paragraph } from '@atoms/typography'
import { Button } from '@components/index'
import { ResponsivePXValue, PageWidth, ZeroSpace } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { PageBannerFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { scrollToLink } from '@utility/Scroll'

import { getPageSectionButtonColor } from './PageSectionGroup'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  ${ResponsivePXValue('max-height', { mobile: '260px', tablet: '260px', desktop: '216px' })}
  ${ResponsivePXValue('height', { mobile: '244px', tablet: '244px', desktop: '200px' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 100%;

  ${PageWidth}

  ${ResponsivePXValue('border-radius', '6px')}

  .text-spacing {
    ${ZeroSpace}
    ${ResponsivePXValue('margin-bottom', '16px')}
  }

  .banner-title {
    font-family: 'utopia';
    font-weight: 600;
    letter-spacing: 1px;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  height: 100%;
`

export interface PageBannerCompactProps {
  pageBanner: PageBannerFragment
}

export function PageBannerCompact({ pageBanner }: PageBannerCompactProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const navigate = useNavigate()
  const theme = useTheme()
  const config = useConfig()

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const linkUrl = pageBanner?.link?.link
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)
  const linkTitle = pageBanner?.link?.title
  const description = pageBanner?.description

  const titleColor = isMobile ? (pageBanner?.mobileTitleColor ? pageBanner?.mobileTitleColor : theme.colors.slates.bitter) : pageBanner?.titleColor
  const subTitleColor = isMobile ? (pageBanner?.mobileSubTitleColor ? pageBanner?.mobileSubTitleColor : theme.colors.slates.bitter) : pageBanner?.subTitleColor
  const descriptionColor = isMobile ? (pageBanner?.mobileDescriptionColor ? pageBanner?.mobileDescriptionColor : theme.colors.slates.bitter) : pageBanner?.descriptionColor
  const imageSource = isMobile ? pageBanner?.mobileImage : pageBanner?.image
  const objectFit = isMobile ? pageBanner?.mobileObjectFit : pageBanner?.objectFit

  const _handleLinkClick = (): void => {
    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container>
      <ContentContainer>
        <If condition={!!pageBanner.bannerTitle}>
          <Heading
            className='banner-title'
            variant='h2'
            align='center'
            color={titleColor}>
            {pageBanner.bannerTitle}
          </Heading>
        </If>
        <If condition={!!pageBanner.subTitle}>
          <Spacer universal='4px' />
          <Heading
            variant='h6'
            align='center'
            color={subTitleColor}>
            {pageBanner.subTitle}
          </Heading>
        </If>
        <If condition={!!description}>
          <Spacer universal='4px' />
          <Paragraph
            align='center'
            variant='p1'
            color={descriptionColor}>
            {description}
          </Paragraph>
        </If>
        <Spacer universal='16px' />
        <If condition={!!linkTitle}>
          <Spacer universal='16px' />
          <Button
            title={linkTitle}
            href={linkUrl}
            color={linkColor}
            variant='secondary'
            onClick={_handleLinkClick} />
        </If>
      </ContentContainer>
      <ImageContainer>
        <ResponsiveImage
          image={imageSource}
          objectFit={objectFit} />
      </ImageContainer>
    </Container>
  )
}
