export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Color: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Domain: { input: any; output: any; }
  Email: { input: any; output: any; }
  ExpiryMonth: { input: any; output: any; }
  ExpiryYear: { input: any; output: any; }
  Geometry: { input: any; output: any; }
  GeometryCollection: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWT: { input: any; output: any; }
  Password: { input: any; output: any; }
  Phone: { input: any; output: any; }
  Point: { input: any; output: any; }
  Time: { input: any; output: any; }
  URI: { input: any; output: any; }
};

/** Represents a AbTest in the system */
export type AbTest = {
  __typename: 'AbTest';
  /** The creation date of the AbTest */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['Date']['output']>;
  /** The AbTest's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['Date']['output']>;
  status: Maybe<AbTestStatusEnum>;
  /** The update date of the AbTest */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type AbTestActiveBucketFilters = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<AbTestStatusEnum>;
};

export type AbTestActiveBucketList = {
  __typename: 'AbTestActiveBucketList';
  list: Maybe<Scalars['JSON']['output']>;
};

export type AbTestActiveUserBucketFilters = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<AbTestStatusEnum>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum AbTestAndOrEnum {
  AND = 'AND',
  OR = 'OR'
}

/** Represents a AbTestBucket in the system */
export type AbTestBucket = {
  __typename: 'AbTestBucket';
  abTest: Maybe<AbTest>;
  abTestFilter: Maybe<AbTestFilter>;
  /** The creation date of the AbTestBucket */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The AbTestBucket's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the AbTestBucket */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** AbTestBucket filtering options */
export type AbTestBucketFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AbTestBucketList = {
  __typename: 'AbTestBucketList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AbTestBucket records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<AbTestBucket>>;
  /** Number of AbTestBucket records skipped */
  skip: Scalars['Int']['output'];
};

/** AbTestBucket ordering options */
export type AbTestBucketOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AbTestBucketOrderEnum>;
};

/** Fields available to order Properties */
export enum AbTestBucketOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a AbTestBucketUser in the system */
export type AbTestBucketUser = {
  __typename: 'AbTestBucketUser';
  abTestBucket: Maybe<AbTestBucket>;
  /** The AbTestBucketUser's ID */
  id: Scalars['ID']['output'];
  user: Maybe<User>;
};

/** AbTestBucketUser filtering options */
export type AbTestBucketUserFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AbTestBucketUserList = {
  __typename: 'AbTestBucketUserList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AbTestBucketUser records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<AbTestBucketUser>>;
  /** Number of AbTestBucketUser records skipped */
  skip: Scalars['Int']['output'];
};

/** AbTestBucketUser ordering options */
export type AbTestBucketUserOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AbTestBucketUserOrderEnum>;
};

/** Fields available to order Properties */
export enum AbTestBucketUserOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum AbTestClauseEnum {
  IN = 'IN'
}

/** Represents a AbTestFilter in the system */
export type AbTestFilter = {
  __typename: 'AbTestFilter';
  andOr: Maybe<AbTestAndOrEnum>;
  clause: Maybe<AbTestClauseEnum>;
  /** The creation date of the AbTestFilter */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  data: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  filterType: Maybe<AbTestFilterTypeEnum>;
  /** The AbTestFilter's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the AbTestFilter */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** AbTestFilter filtering options */
export type AbTestFilterFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AbTestFilterList = {
  __typename: 'AbTestFilterList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AbTestFilter records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<AbTestFilter>>;
  /** Number of AbTestFilter records skipped */
  skip: Scalars['Int']['output'];
};

/** AbTestFilter ordering options */
export type AbTestFilterOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AbTestFilterOrderEnum>;
};

/** Fields available to order Properties */
export enum AbTestFilterOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum AbTestFilterTypeEnum {
  SELECTABLE = 'SELECTABLE'
}

/** AbTest filtering options */
export type AbTestFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AbTestStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AbTestList = {
  __typename: 'AbTestList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AbTest records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<AbTest>>;
  /** Number of AbTest records skipped */
  skip: Scalars['Int']['output'];
};

/** AbTest ordering options */
export type AbTestOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AbTestOrderEnum>;
};

/** Fields available to order Properties */
export enum AbTestOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type AbTestResult = {
  __typename: 'AbTestResult';
  returnObj: Scalars['String']['output'];
};

export enum AbTestStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** In Active */
  INACTIVE = 'INACTIVE'
}

/** Represents a ActiveCampaignCustomField in the system */
export type ActiveCampaignCustomField = {
  __typename: 'ActiveCampaignCustomField';
  /** The creation date of the ActiveCampaignCustomField */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  /** The update date of the ActiveCampaignCustomField */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Fields to add a new ActiveCampaignCustomField */
export type ActiveCampaignCustomFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ActiveMenuCategories = {
  __typename: 'ActiveMenuCategories';
  menuCategories: Array<Maybe<MenuMealKitDishCategory>>;
};

export type ActiveMenuTags = {
  __typename: 'ActiveMenuTags';
  menuTags: Array<Maybe<MenuMealKitDishTag>>;
};

export enum AcuDiscountTypeEnum {
  /** Intercompany */
  NSDC000001 = 'NSDC000001',
  /** Promotional Coupon */
  NSDC000002 = 'NSDC000002',
  /** New Customer Coupon */
  NSDC000003 = 'NSDC000003',
  /** Reactivation Coupon */
  NSDC000004 = 'NSDC000004',
  /** Customer Service Coupon */
  NSDC000005 = 'NSDC000005',
  /** Free Shipping */
  NSDC000006 = 'NSDC000006',
  /** Staff */
  NSDC000007 = 'NSDC000007',
  /** Other */
  NSDC000008 = 'NSDC000008'
}

export type AcuInventoryItemDetail = {
  __typename: 'AcuInventoryItemDetail';
  acctCD: Maybe<Scalars['String']['output']>;
  acctName: Maybe<Scalars['String']['output']>;
  baseUnit: Maybe<Scalars['String']['output']>;
  nonStockDiscountSku: Maybe<Scalars['String']['output']>;
  nonStockSku: Maybe<Scalars['String']['output']>;
  purchaseUnit: Maybe<Scalars['String']['output']>;
  salesPrice: Maybe<Scalars['Float']['output']>;
  salesUnit: Maybe<Scalars['String']['output']>;
  sku: Maybe<Scalars['String']['output']>;
  skuName: Maybe<Scalars['String']['output']>;
  warehouse: Maybe<Scalars['String']['output']>;
};

export enum AcuSubContainerSyncStatusEnum {
  /** Not Synced */
  NOT_SYNCED = 'NOT_SYNCED',
  /** Synced */
  SYNCED = 'SYNCED',
  /** Syncing */
  SYNCING = 'SYNCING',
  /** Sync Failed */
  SYNC_FAILED = 'SYNC_FAILED',
  /** Sync Queued */
  SYNC_QUEUED = 'SYNC_QUEUED'
}

/** Represents a AcumaticaDiscountNonStockSku in the system */
export type AcumaticaDiscountNonStockSku = {
  __typename: 'AcumaticaDiscountNonStockSku';
  /** The creation date of the AcumaticaDiscountNonStockSku */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The AcumaticaDiscountNonStockSku's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the AcumaticaDiscountNonStockSku */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** AcumaticaDiscountNonStockSku filtering options */
export type AcumaticaDiscountNonStockSkuFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AcumaticaDiscountNonStockSkuList = {
  __typename: 'AcumaticaDiscountNonStockSkuList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AcumaticaDiscountNonStockSku records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<AcumaticaDiscountNonStockSku>;
  /** Number of AcumaticaDiscountNonStockSku records skipped */
  skip: Scalars['Int']['output'];
};

/** AcumaticaDiscountNonStockSku ordering options */
export type AcumaticaDiscountNonStockSkuOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AcumaticaDiscountNonStockSkuOrderEnum>;
};

/** Fields available to order Properties */
export enum AcumaticaDiscountNonStockSkuOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a AcumaticaProductTypeNonStockSku in the system */
export type AcumaticaProductTypeNonStockSku = {
  __typename: 'AcumaticaProductTypeNonStockSku';
  /** The creation date of the AcumaticaProductTypeNonStockSku */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The AcumaticaProductTypeNonStockSku's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the AcumaticaProductTypeNonStockSku */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** AcumaticaProductTypeNonStockSku filtering options */
export type AcumaticaProductTypeNonStockSkuFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AcumaticaProductTypeNonStockSkuList = {
  __typename: 'AcumaticaProductTypeNonStockSkuList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AcumaticaProductTypeNonStockSku records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<AcumaticaProductTypeNonStockSku>;
  /** Number of AcumaticaProductTypeNonStockSku records skipped */
  skip: Scalars['Int']['output'];
};

/** AcumaticaProductTypeNonStockSku ordering options */
export type AcumaticaProductTypeNonStockSkuOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AcumaticaProductTypeNonStockSkuOrderEnum>;
};

/** Fields available to order Properties */
export enum AcumaticaProductTypeNonStockSkuOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

/** Fields to add a new AbTestBucket */
export type AddAbTestBucketInput = {
  abTest?: InputMaybe<Scalars['ID']['input']>;
  abTestFilter?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new AbTestBucketUser */
export type AddAbTestBucketUserInput = {
  abTestBucket?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new AbTestFilter */
export type AddAbTestFilterInput = {
  andOr?: InputMaybe<AbTestAndOrEnum>;
  clause?: InputMaybe<AbTestClauseEnum>;
  data?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AbTestFilterTypeEnum>;
  name: Scalars['String']['input'];
};

/** Fields to add a new AbTest */
export type AddAbTestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<AbTestStatusEnum>;
};

/** Fields to add a new AcumaticaDiscountNonStockSku */
export type AddAcumaticaDiscountNonStockSkuInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new AcumaticaProductTypeNonStockSku */
export type AddAcumaticaProductTypeNonStockSkuInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type AddAddressIssueInput = {
  stub?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a all UserPreferencea in bulk */
export type AddAllUserPreferencesInput = {
  preferences?: InputMaybe<Array<InputMaybe<UserPreferenceBulkObject>>>;
  user: Scalars['ID']['input'];
};

/** Fields to add a new Allergen */
export type AddAllergenInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type AddAndValidateCreditCardInput = {
  cardNickname?: InputMaybe<Scalars['String']['input']>;
  cardNumber: Scalars['String']['input'];
  cvv: Scalars['String']['input'];
  expiryMonth: Scalars['ExpiryMonth']['input'];
  expiryYear: Scalars['ExpiryYear']['input'];
  failureRedirect?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nameOnCard: Scalars['String']['input'];
  provider?: InputMaybe<PaymentProviderEnum>;
  successRedirect?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['ID']['input'];
};

/** Fields to add a new Archive */
export type AddArchiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new BabyBoxCategory */
export type AddBabyBoxCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BabyBoxCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new Frozen */
export type AddBabyBoxInput = {
  allergens?: InputMaybe<Array<InputMaybe<BabyBoxAllergenEnum>>>;
  babyBoxCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  babyBoxTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize: BabyBoxPortionSizeEnum;
  price?: InputMaybe<Scalars['Float']['input']>;
  productImages?: InputMaybe<Array<AddProductImageInput>>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shouldDisplay?: InputMaybe<BabyBoxTubShouldDisplayEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle: Scalars['String']['input'];
};

/** Fields to add a new BabyBoxTag */
export type AddBabyBoxTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  tagType: BabyBoxTagTypeEnum;
  title: Scalars['String']['input'];
};

/** Fields to add a new BabyBoxTubDetail */
export type AddBabyBoxTubDetailInput = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  babyBox?: InputMaybe<Scalars['ID']['input']>;
  carbs?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  energyInKilojoules?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['String']['input']>;
  fibre?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  ingredientList?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  saturatedFat?: InputMaybe<Scalars['String']['input']>;
  sensoryPersonality?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new BabyBox */
export type AddBabyBoxTubInput = {
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  attributes?: InputMaybe<Scalars['String']['input']>;
  babyBoxCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryDescription?: InputMaybe<Scalars['String']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  coverImageId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  packageDetails?: InputMaybe<Scalars['String']['input']>;
  packageGoals?: InputMaybe<Scalars['String']['input']>;
  packageSize?: InputMaybe<Scalars['String']['input']>;
  packingVolume: Scalars['Float']['input'];
  recommendedServings?: InputMaybe<Scalars['String']['input']>;
  servingSuggestions?: InputMaybe<Scalars['String']['input']>;
  storageInstructions?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new BankBinNumber */
export type AddBankBinNumberInput = {
  bank: BankEnum;
  binNumber: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new Barcode */
export type AddBarcodeInput = {
  baseUnit?: InputMaybe<UnitTypeEnum>;
  product?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
  type?: InputMaybe<BarcodeTypeEnum>;
};

/** Fields to add a new Box */
export type AddBoxInput = {
  boxType?: InputMaybe<BoxTypeEnum>;
  capacity: Scalars['Float']['input'];
  containersNeedLooseItems?: InputMaybe<Scalars['Boolean']['input']>;
  depthInMillimeters: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  estimatedFullMassInGrams: Scalars['Float']['input'];
  hiegthInMillimeters: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  widthInMillimeters: Scalars['Float']['input'];
};

/** Fields to add a new BoxRestriction */
export type AddBoxRestrictionInput = {
  box: Scalars['ID']['input'];
  type: BoxRestrictionTypeEnum;
  value: Scalars['JSON']['input'];
};

/** Fields to add a new BoxSubContainer */
export type AddBoxSubContainerInput = {
  box: Scalars['ID']['input'];
  subContainer: Scalars['ID']['input'];
};

/** Fields to add a new Branch */
export type AddBranchInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Brand */
export type AddBrandInput = {
  about: Scalars['String']['input'];
  banner?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new CategoryGroup */
export type AddCategoryGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Category */
export type AddCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  group: Scalars['ID']['input'];
  icon?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new Chef */
export type AddChefInput = {
  biography?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new CommunicationTemplate */
export type AddCommunicationTemplateInput = {
  activationType: CommunicationActivationTypeEnum;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<UserGroupsEnum>;
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledDay?: InputMaybe<WeekDayEnum>;
  scheduledTime?: InputMaybe<Scalars['Time']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a new CourierBox */
export type AddCourierBoxInput = {
  box: Scalars['ID']['input'];
  courier: Scalars['ID']['input'];
  courierIdentifier: Scalars['String']['input'];
  distanceConverstion: Scalars['Float']['input'];
  massConverstion: Scalars['Float']['input'];
};

/** Fields to add a new CourierDeliveryDay */
export type AddCourierDeliveryDayInput = {
  courier: Scalars['ID']['input'];
  dayOfWeek: Scalars['Int']['input'];
};

/** Fields to add a new CourierDelivery */
export type AddCourierDeliveryInput = {
  courier: Scalars['ID']['input'];
  deliveryDate: Scalars['DateTime']['input'];
  estimatedCollectionTime?: InputMaybe<Scalars['DateTime']['input']>;
  warehouse: Scalars['ID']['input'];
};

/** Fields to add a new Courier */
export type AddCourierInput = {
  appendParcelIndexToBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  barcodeType: BarcodeType;
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  delayedIntegrationFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  identity: CourierIdentityEnum;
  maxVolume?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  volumeType?: InputMaybe<CourierVolumeType>;
};

/** Fields to add a new CourierWarehouseCityDeliveryDay */
export type AddCourierWarehouseCityDeliveryDayInput = {
  courierDeliveryDay: Scalars['ID']['input'];
  courierWarehouseCity: Scalars['ID']['input'];
};

/** Fields to add a new CourierWarehouseCity */
export type AddCourierWarehouseCityInput = {
  city: Scalars['ID']['input'];
  courierWarehouse: Scalars['ID']['input'];
};

/** Fields to add a new CourierWarehouse */
export type AddCourierWarehouseInput = {
  courier: Scalars['ID']['input'];
  courierReference?: InputMaybe<Scalars['String']['input']>;
  maxVolume?: InputMaybe<Scalars['Int']['input']>;
  minVolume?: InputMaybe<Scalars['Int']['input']>;
  volumeType?: InputMaybe<CourierVolumeType>;
  warehouse: Scalars['ID']['input'];
};

/** Fields to add a new DeletedUser */
export type AddDeletedUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new DeliveryItem */
export type AddDeliveryItemInput = {
  addressString: Scalars['String']['input'];
  center: CoordinateInput;
  courierDelivery: Scalars['ID']['input'];
  courierStatus?: InputMaybe<CourierItemStatusEnum>;
  order: Scalars['ID']['input'];
  orderAddress: Scalars['ID']['input'];
  placementStatus: PlacementStatusEnum;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['ID']['input'];
};

/** Fields to add a new DeliveryItemParcel */
export type AddDeliveryItemParcelInput = {
  box: Scalars['ID']['input'];
  deliveryItem: Scalars['ID']['input'];
};

/** Fields to add a new DeliverySchedule */
export type AddDeliveryScheduleInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scheduleCron: Scalars['String']['input'];
  targetDeliveryWeekDay: Scalars['Int']['input'];
};

/** Fields to add a new Device */
export type AddDeviceInput = {
  type: RegisteredDeviceTypeEnum;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new DiscountCampaign */
export type AddDiscountCampaignInput = {
  codePrefix: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['ID']['input']>;
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfCodes: Scalars['Int']['input'];
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueToUser: Scalars['Boolean']['input'];
};

/** Fields to add a new DiscountCode */
export type AddDiscountCodeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code: Scalars['String']['input'];
  discount: Scalars['ID']['input'];
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a new Discount */
export type AddDiscountInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  acuDiscountType: AcuDiscountTypeEnum;
  applicationEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  banner?: InputMaybe<DiscountBannerInput>;
  campaignName: Scalars['String']['input'];
  canStack?: InputMaybe<Scalars['Boolean']['input']>;
  canStackWith?: InputMaybe<Array<InputMaybe<CanStackWithInput>>>;
  cannotStackWith?: InputMaybe<Array<InputMaybe<CannotStackWithInput>>>;
  deliveryDays?: InputMaybe<Array<InputMaybe<WeekDayEnum>>>;
  discountType: DiscountTypeEnum;
  dropOffDays?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<DiscountFrequencyEnum>;
  internalDescription: Scalars['String']['input'];
  isAppOnly?: InputMaybe<Scalars['Boolean']['input']>;
  maxFrequencyAmount?: InputMaybe<Scalars['Int']['input']>;
  maxOrderCount?: InputMaybe<Scalars['Int']['input']>;
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  mealKitPortionSizes?: InputMaybe<Array<InputMaybe<PortionSizeEnum>>>;
  minOrderCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsDiscoveryHealthMeal?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfReapplications?: InputMaybe<Scalars['Int']['input']>;
  orderType?: InputMaybe<OrderTypeEnum>;
  productRanges?: InputMaybe<Array<InputMaybe<ProductRangeEnum>>>;
  redemptionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subvalidationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  termsAndConditions?: InputMaybe<DiscountTermsAndConditionsInput>;
  title: Scalars['String']['input'];
  validationApplication?: InputMaybe<DiscountApplicationTypeEnum>;
  validationId?: InputMaybe<Scalars['String']['input']>;
  validationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  validationType?: InputMaybe<DiscountValidationTypeEnum>;
  validationValue?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<DiscountValueInput>>;
};

/** Fields to add a new DiscountTag */
export type AddDiscountTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagLevel?: InputMaybe<DiscountTagLevelEnum>;
  title: Scalars['String']['input'];
};

export type AddDishToUserMenuInput = {
  dishId: Scalars['ID']['input'];
  portionSize: NumberOfPortionsEnum;
};

/** Fields to add a new Excel */
export type AddExcelInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  file: UploadFileInput;
  name: Scalars['String']['input'];
};

/** Fields to add a new FileCategory */
export type AddFileCategoryInput = {
  name: Scalars['String']['input'];
};

export type AddFriendReferenceInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new FrozenCategory */
export type AddFrozenCategoryInput = {
  background?: InputMaybe<CategoryBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<FrozenCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new FrozenInstruction */
export type AddFrozenInstructionInput = {
  description: Scalars['String']['input'];
  frozenMeal?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Fields to add a new FrozenMeal */
export type AddFrozenMealDishInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  frozenTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionalInfoInput>;
  packingVolume: Scalars['Float']['input'];
  servingSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  story?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new Frozen */
export type AddFrozenMealInput = {
  allergens?: InputMaybe<Array<InputMaybe<FrozenMealAllergenEnum>>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  nutrition?: InputMaybe<NutritionalInfoInput>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize: FrozenPortionSizeEnum;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle: Scalars['String']['input'];
};

/** Fields to add a new FrozenTag */
export type AddFrozenTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<FrozenTagTypeEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new Gift */
export type AddGiftInput = {
  alcoholic?: InputMaybe<GiftAlcoholStatusEnum>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  packingVolume: Scalars['Float']['input'];
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
};

/** Fields to add a new GuestUser */
export type AddGuestUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Image */
export type AddImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  file: UploadFileInput;
  hasTransparency?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  staticId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new Ingredient */
export type AddIngredientInput = {
  baseUnitType?: InputMaybe<UnitTypeEnum>;
  conversionFactor?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTitle: Scalars['String']['input'];
  ingredientType?: InputMaybe<IngredientTypeEnum>;
  name: Scalars['String']['input'];
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  productionUnitType?: InputMaybe<UnitTypeEnum>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  taxCategory?: InputMaybe<TaxCategoryTypeEnum>;
};

/** Fields to add a new Lead */
export type AddLeadInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  referredBy?: InputMaybe<Scalars['ID']['input']>;
  referredById?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LeadStatusEnum>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new LeadUpload */
export type AddLeadUploadInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  leadUploadFile?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Location */
export type AddLocationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  coordinates?: InputMaybe<CoordinateInput>;
  legacyAddress?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['ID']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new LoyaltyTier */
export type AddLoyaltyTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  orderCount: Scalars['Int']['input'];
  referrerPoints?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to add a new ManualEmail */
export type AddManualEmailInput = {
  emailType: ManualEmailEnum;
};

/** Fields to add a new MarketProductBrand */
export type AddMarketProductBrandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new MarketProductCategory */
export type AddMarketProductCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<MarketProductCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MarketProduct */
export type AddMarketProductInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  brand?: InputMaybe<Scalars['ID']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  isOccasionBox?: InputMaybe<Scalars['Boolean']['input']>;
  isWeekendBox?: InputMaybe<Scalars['Boolean']['input']>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketProductTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  packageIngredients?: InputMaybe<Array<Scalars['String']['input']>>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume: Scalars['Float']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageInstructions?: InputMaybe<Scalars['String']['input']>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subCategory?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
  warning?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new MarketProductSubCategory */
export type AddMarketProductSubCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketProductSubCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MarketProductTag */
export type AddMarketProductTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<MarketProductTagTypeEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MealKitCategory */
export type AddMealKitCategoryInput = {
  background?: InputMaybe<CategoryBackgroundEnum>;
  canSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  costClass?: InputMaybe<MealKitCostClass>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  maxMealCount?: InputMaybe<Scalars['Int']['input']>;
  minMealCount?: InputMaybe<Scalars['Int']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<MealKitCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MealKitDefaultPricing */
export type AddMealKitDefaultPricingInput = {
  categoryId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  servingSize: MealKitServingSizeEnum;
};

/** Fields to add a new MealKitDish */
export type AddMealKitDishInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  chefsTips?: InputMaybe<Array<Scalars['String']['input']>>;
  cookWithin?: InputMaybe<Scalars['Int']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromYourKitchen?: InputMaybe<Array<Scalars['String']['input']>>;
  mealKitCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  nutrition?: InputMaybe<NutritionalInfoInput>;
  nutritionPerServing?: InputMaybe<NutritionalInfoInput>;
  packingVolume: Scalars['Float']['input'];
  prepStation?: InputMaybe<Array<Scalars['String']['input']>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  upSellFrozenMealDishes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  upSellMarketProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
  upSellWines?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to add a new MealKit */
export type AddMealKitInput = {
  allergens?: InputMaybe<Array<InputMaybe<AllergenEnum>>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  chefsTips?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cookWithin: Scalars['Int']['input'];
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromYourKitchen?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  handsOnTime: Scalars['Int']['input'];
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  nutrition?: InputMaybe<NutritionalInfoInput>;
  overallTime: Scalars['Int']['input'];
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize: PortionSizeEnum;
  prepStation?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to add a new MealKitInstruction */
export type AddMealKitInstructionInput = {
  description: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  mealKit?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MealKitPlan */
export type AddMealKitPlanInput = {
  nothing?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a new MealKitSubscriptionFee */
export type AddMealKitSubscriptionFeeInput = {
  description: Scalars['String']['input'];
  isAvailable: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  packingVolume: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  shouldDisplay: Scalars['Boolean']['input'];
  status: ProductStatusEnum;
  volume: Scalars['Int']['input'];
};

/** Fields to add a new MealKitTag */
export type AddMealKitTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<MealKitTagTypeEnum>;
  title: Scalars['String']['input'];
};

/** Fields to add a new MenuCategory */
export type AddMenuCategoryInput = {
  category: Scalars['ID']['input'];
  dish: Scalars['ID']['input'];
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  menu: Scalars['ID']['input'];
  preference?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to add a new MenuDishWine */
export type AddMenuDishWineInput = {
  dish: Scalars['ID']['input'];
  menu: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  wine: Scalars['ID']['input'];
};

/** Fields to add a new Menu */
export type AddMenuInput = {
  billingDate: Scalars['DateTime']['input'];
  checkingDate: Scalars['DateTime']['input'];
  deliveryDates?: InputMaybe<Array<InputMaybe<DeliveryDateInput>>>;
  openingDate: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  weekIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new MenuWarehouse */
export type AddMenuWarehouseInput = {
  menu: Scalars['ID']['input'];
  newSignUpOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  reactivationOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  warehouse: Scalars['ID']['input'];
};

/** Fields to add a new MinimumOrderValue */
export type AddMinimumOrderValueInput = {
  areaType: CityDeliveryTypeEnum;
  deliveryFee?: InputMaybe<Scalars['String']['input']>;
  mov?: InputMaybe<Scalars['String']['input']>;
  orderType: OrderTypeEnum;
  productRange: ProductRangeEnum;
};

/** Fields to add a new OrderAddress */
export type AddOrderAddressInput = {
  addressDetails: Scalars['JSONObject']['input'];
  orderId: Scalars['ID']['input'];
};

/** Fields to add a new OrderBox */
export type AddOrderBoxInput = {
  box: Scalars['ID']['input'];
  order: Scalars['ID']['input'];
};

/** Fields to add a new OrderBoxItem */
export type AddOrderBoxItemInput = {
  orderBox: Scalars['ID']['input'];
  orderItem: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

/** Fields to add a new OrderHistory */
export type AddOrderHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Order */
export type AddOrderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderItem */
export type AddOrderItemInput = {
  discountAmount: Scalars['Float']['input'];
  isFromSubscription: Scalars['Boolean']['input'];
  order: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  product: Scalars['ID']['input'];
  productName?: InputMaybe<Scalars['String']['input']>;
  productSku?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

/** Fields to add a new OrderItemRating */
export type AddOrderItemRatingInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  orderItem?: InputMaybe<Scalars['ID']['input']>;
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type AddOrderRatingInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['ID']['input']>;
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to add a new OrderSubContainer */
export type AddOrderSubContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderTaxDiscount */
export type AddOrderTaxDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderTaxProduct */
export type AddOrderTaxProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderTaxProductTypeDiscount */
export type AddOrderTaxProductTypeDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderTaxProductType */
export type AddOrderTaxProductTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new OrderTaxReceipt */
export type AddOrderTaxReceiptInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new PackingVolumeDefault */
export type AddPackingVolumeDefaultInput = {
  defaultPackingVolume: Scalars['Float']['input'];
  productRange: ProductRangeEnum;
  variant?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageBanner */
export type AddPageBannerInput = {
  backgroundColor?: InputMaybe<PageBannerBackgroundColorEnum>;
  bannerTitle: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionColor?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  mobileDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  mobileImageId?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  mobileSubTitleColor?: InputMaybe<Scalars['String']['input']>;
  mobileTitleColor?: InputMaybe<Scalars['String']['input']>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageBannerSection: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleColor?: InputMaybe<Scalars['String']['input']>;
  titleColor?: InputMaybe<Scalars['String']['input']>;
  variation: PageBannerVariationEnum;
};

/** Fields to add a new PageBannerSection */
export type AddPageBannerSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  bannerHeight?: InputMaybe<Scalars['String']['input']>;
  bannerSectionHeight?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileBannerHeight?: InputMaybe<Scalars['String']['input']>;
  mobileBannerSectionHeight?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageCard */
export type AddPageCardInput = {
  cardTitle: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageCardSection?: InputMaybe<Scalars['ID']['input']>;
  pageTileSection?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new PageCardSection */
export type AddPageCardSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  cardHeight?: InputMaybe<Scalars['String']['input']>;
  cardSectionHeight?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileCardHeight?: InputMaybe<Scalars['String']['input']>;
  mobileCardSectionHeight?: InputMaybe<Scalars['String']['input']>;
  mobileShouldStack?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageDataSection */
export type AddPageDataSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  dataSectionFilterVariation?: InputMaybe<PageSectionDataFilterVariationEnum>;
  dataSectionVariation?: InputMaybe<PageSectionDataVariationEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  embeddedFormHeight?: InputMaybe<PageSectionFormFactorInput>;
  embeddedFormId?: InputMaybe<Scalars['String']['input']>;
  frozenCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  viewAllUrl?: InputMaybe<Scalars['String']['input']>;
  wineCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wineTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to add a new PageDetail */
export type AddPageDetailInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageDetailSection: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Fields to add a new PageDetailSection */
export type AddPageDetailSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageGridItem */
export type AddPageGridItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gridItemTitle: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageGridSection: Scalars['ID']['input'];
  titleColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageGridSection */
export type AddPageGridSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  columns: PageSectionFormFactorInput;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  gutterWidth?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  spacingType: PageGridSpacingEnum;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageMediaSection */
export type AddPageMediaSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageSize?: InputMaybe<ImageSizeEnum>;
  imageVariant?: InputMaybe<ImageVariantEnum>;
  links?: InputMaybe<PageSectionLinksInput>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  name: Scalars['String']['input'];
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  variation?: InputMaybe<PageSectionMediaVariationEnum>;
  variationBackground?: InputMaybe<PageSectionBackgroundEnum>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageModal */
export type AddPageModalInput = {
  actionLink?: InputMaybe<Scalars['String']['input']>;
  actionTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionColor?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  locationLink?: InputMaybe<Scalars['String']['input']>;
  mobileDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  mobileImageId?: InputMaybe<Scalars['String']['input']>;
  mobileSubTitleColor?: InputMaybe<Scalars['String']['input']>;
  mobileTitleColor?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleColor?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  titleColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageNotificationGroup */
export type AddPageNotificationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Fields to add a new PageNotification */
export type AddPageNotificationInput = {
  additionalText?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  canClose?: InputMaybe<Scalars['Boolean']['input']>;
  countDownBackground?: InputMaybe<Scalars['String']['input']>;
  ctaBackground?: InputMaybe<ButtonColorEnum>;
  ctaText?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  isPopup?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  pageNotificationGroup: Scalars['ID']['input'];
  shouldCountDownDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  shouldIconDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text: Scalars['String']['input'];
  textColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageSectionGroup */
export type AddPageSectionGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Fields to add a new PageTab */
export type AddPageTabInput = {
  header?: InputMaybe<Scalars['String']['input']>;
  pageTabSection: Scalars['ID']['input'];
};

/** Fields to add a new PageTabItem */
export type AddPageTabItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  pageTab: Scalars['ID']['input'];
  tabItemTitle: Scalars['String']['input'];
};

/** Fields to add a new PageTabSection */
export type AddPageTabSectionInput = {
  activeTab?: InputMaybe<Scalars['String']['input']>;
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageTextCard */
export type AddPageTextCardInput = {
  contentColumns?: InputMaybe<PageSectionFormFactorInput>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hideContentOverflow?: InputMaybe<Scalars['Boolean']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
  pageTextCardSection: Scalars['ID']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageTextCardSection */
export type AddPageTextCardSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageTextSection */
export type AddPageTextSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  contentColumns?: InputMaybe<PageSectionFormFactorInput>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  hideContentOverflow?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<PageSectionLinksInput>;
  name: Scalars['String']['input'];
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  textAlign?: InputMaybe<TextAlignEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new PageTileSection */
export type AddPageTileSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  name: Scalars['String']['input'];
  pageSectionGroup?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  tileVariation?: InputMaybe<PageTileVariationEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new Pdf */
export type AddPdfInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  file: UploadFileInput;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  productRange?: InputMaybe<ProductRangeEnum>;
};

/** Fields to add a new PreferenceCategory */
export type AddPreferenceCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Fields to add a new Preference */
export type AddPreferenceInput = {
  dataType?: InputMaybe<DataTypeEnum>;
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  options?: InputMaybe<Scalars['String']['input']>;
  preferenceCategory: Scalars['ID']['input'];
};

/** Fields to add a new ProcessGroupCourier */
export type AddProcessGroupCourierInput = {
  courier: Scalars['ID']['input'];
  priority: Scalars['Int']['input'];
  processGroup: Scalars['ID']['input'];
};

/** Fields to add a new ProcessGroup */
export type AddProcessGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderType: OrderTypeEnum;
  squencePosition: Scalars['Int']['input'];
};

/** Fields to add a new ProductAvailabilityEmail */
export type AddProductAvailabilityEmailInput = {
  area?: InputMaybe<AreaInput>;
  city: CityInput;
  email: Scalars['String']['input'];
};

/** Fields to add a new ProductImage */
export type AddProductImageInput = {
  image: Scalars['ID']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['ID']['input']>;
  productGroup?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new ProductIngredient */
export type AddProductIngredientInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  displayTitle?: InputMaybe<Scalars['String']['input']>;
  displayUnit?: InputMaybe<Scalars['String']['input']>;
  ingredient: Scalars['ID']['input'];
  parent: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  unitType?: InputMaybe<UnitTypeEnum>;
};

/** Fields to add a new ProductWarhouseCostHistory */
export type AddProductWarhouseCostHistoryInput = {
  costPerUnit: Scalars['Float']['input'];
  eventId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  productWarehouseId?: InputMaybe<Scalars['ID']['input']>;
  taxCategoryId: Scalars['ID']['input'];
  warehouseId: Scalars['ID']['input'];
};

/** Fields to add a new PublicHoliday */
export type AddPublicHolidayInput = {
  date: Scalars['Date']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new RefundHistory */
export type AddRefundHistoryInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  type: RefundHistoryType;
};

/** Fields to add a new Refund */
export type AddRefundInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueAmount: Scalars['Float']['input'];
  method: TransactionMethodEnum;
  payment: Scalars['ID']['input'];
  refundReportingReasons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skuReferences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user: Scalars['ID']['input'];
};

/** Fields to add a new RefundReason */
export type AddRefundReasonInput = {
  refund: Scalars['ID']['input'];
  refundReportingReason: Scalars['ID']['input'];
};

/** Fields to add a new RefundReportingReason */
export type AddRefundReportingReasonInput = {
  defaultRefundAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new RegisteredUser */
export type AddRegisteredUserInput = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  communicationConsent?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<TitleEnum>;
};

/** Fields to add a new Report */
export type AddReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Role */
export type AddRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Fields to add a new ScheduleCategoryExclusion */
export type AddScheduleCategoryExclusionInput = {
  deliveryScheduleId: Scalars['ID']['input'];
  productCategoryId: Scalars['ID']['input'];
};

/** Fields to add a new ScheduleCategoryInclusion */
export type AddScheduleCategoryInclusionInput = {
  deliveryScheduleId: Scalars['ID']['input'];
  productCategoryId: Scalars['ID']['input'];
};

/** Fields to add a new ServingSuggestion */
export type AddServingSuggestionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  frozenMealDish?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Fields to add a new Shipment */
export type AddShipmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new ShipmentItem */
export type AddShipmentItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new ShipperBox */
export type AddShipperBoxInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Shipper */
export type AddShipperInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new ShipperWarehouse */
export type AddShipperWarehouseInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  areaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  onDemandPreference: Scalars['Int']['input'];
  onDemandShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shipperId: Scalars['ID']['input'];
  shipperReference: Scalars['String']['input'];
  subscriptionPreference: Scalars['Int']['input'];
  subscriptionShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  warehouseId: Scalars['ID']['input'];
};

/** Fields to add a new ShippingConsignment */
export type AddShippingConsignmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new SmsTemplate */
export type AddSmsTemplateInput = {
  activationType: SMSActivationTypeEnum;
  group: UserGroupsEnum;
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scheduledDay?: InputMaybe<WeekDayEnum>;
  scheduledTime?: InputMaybe<Scalars['Time']['input']>;
};

/** Fields to add a new SubContainer */
export type AddSubContainerInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxVolume: Scalars['Float']['input'];
  minVolume: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productTypes?: InputMaybe<Scalars['JSON']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  storageArea?: InputMaybe<SubContainerStorageAreaStatusEnum>;
};

/** Fields to add a new SubContainerOrderItem */
export type AddSubContainerOrderItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new SubContainerRestriction */
export type AddSubContainerRestrictionInput = {
  subContainer: Scalars['ID']['input'];
  type: SubContainerRestrictionTypeEnum;
  value: Scalars['JSON']['input'];
};

/** Fields to add a new SubscriptionPriceHistory */
export type AddSubscriptionPriceHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new Supplier */
export type AddSupplierInput = {
  bannerImage?: InputMaybe<Scalars['ID']['input']>;
  bannerImageId?: InputMaybe<Scalars['String']['input']>;
  csrFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gallery?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  galleryIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  mobileNameColor?: InputMaybe<Scalars['String']['input']>;
  mobileShortDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameColor?: InputMaybe<Scalars['String']['input']>;
  productOfferings?: InputMaybe<ProductOfferingsEnum>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  supplierSeriesFeatured?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a new TaxClass */
export type AddTaxClassInput = {
  acumaticaReference: Scalars['String']['input'];
  name: Scalars['String']['input'];
  percentage: Scalars['Float']['input'];
};

/** Fields to add a new TaxReceiptEvent */
export type AddTaxReceiptEventInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new TransactionReport */
export type AddTransactionReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new UserAddress */
export type AddUserAddressInput = {
  alias: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  isSupported?: InputMaybe<Scalars['Boolean']['input']>;
  location: Scalars['ID']['input'];
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new UserCommunicationPlatformData */
export type AddUserCommunicationPlatformDataInput = {
  device?: InputMaybe<Scalars['ID']['input']>;
  platformId: Scalars['String']['input'];
};

/** Fields to add a new UserDevice */
export type AddUserDeviceInput = {
  active: Scalars['Boolean']['input'];
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** Fields to add a new UserDiscount */
export type AddUserDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to add a new UserHistory */
export type AddUserHistoryInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  admin?: InputMaybe<Scalars['ID']['input']>;
  event?: InputMaybe<UserHistoryEventEnum>;
  requiresAttention?: InputMaybe<Scalars['Boolean']['input']>;
  type: UserHistoryTypeEnum;
  user: Scalars['ID']['input'];
};

/** Fields to add a new UserPartnership */
export type AddUserPartnershipInput = {
  memberId: Scalars['String']['input'];
  memberToken: Scalars['String']['input'];
  memberVerified: Scalars['Boolean']['input'];
  partnershipPlatform: PartnershipPlatformEnum;
  user: Scalars['ID']['input'];
};

/** Fields to add a new UserPreference */
export type AddUserPreferenceInput = {
  preference: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
  userValue?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new UserRole */
export type AddUserRoleInput = {
  role: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};

/** Fields to add a new UserSubscription */
export type AddUserSubscriptionInput = {
  alcoholicGifts?: InputMaybe<GiftAlcoholStatusEnum>;
  category: Scalars['ID']['input'];
  deliveryOption: WeekDayEnum;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfMeals: Scalars['Int']['input'];
  numberOfPortions: NumberOfPortionsEnum;
  user: Scalars['ID']['input'];
};

export type AddUserToAbTestInput = {
  testId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Fields to add a new Vendor */
export type AddVendorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['String']['input'];
  vendorName: Scalars['String']['input'];
};

/** Fields to add a new Vineyard */
export type AddVineyardInput = {
  about: Scalars['String']['input'];
  banner?: InputMaybe<Scalars['ID']['input']>;
  bannerId?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new Warehouse */
export type AddWarehouseInput = {
  branch: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  location: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a new WarehouseLocation */
export type AddWarehouseLocationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  warehouseId: Scalars['ID']['input'];
};

/** Fields to add a new WineCampaign */
export type AddWineCampaignInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  vineyards?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Fields to add a new WineCategory */
export type AddWineCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<WineCategoryStatusEnum>;
  title: Scalars['String']['input'];
  variety: WineVarietyEnum;
};

/** Fields to add a new Wine */
export type AddWineInput = {
  alcoholContent?: InputMaybe<Scalars['Float']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Array<Scalars['String']['input']>>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  maker?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume: Scalars['Float']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  sugar?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  vineyard: Scalars['ID']['input'];
  vintage?: InputMaybe<Scalars['Int']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  wineCategory?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to add a new WineMaker */
export type AddWineMakerInput = {
  biography?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to add a new WineTag */
export type AddWineTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<WineTagTypeEnum>;
  title: Scalars['String']['input'];
};

/** Represents a AddressIssue in the system */
export type AddressIssue = {
  __typename: 'AddressIssue';
  cityId: Maybe<Scalars['ID']['output']>;
  cityName: Maybe<Scalars['String']['output']>;
  fullAddress: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  issue: AddressIssueEnum;
  lastOrderDate: Maybe<Scalars['Date']['output']>;
  userAddressId: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export enum AddressIssueEnum {
  /** Default City */
  DEFAULT_CITY = 'DEFAULT_CITY',
  /** No City */
  NO_CITY = 'NO_CITY',
  /** No Place */
  NO_PLACE = 'NO_PLACE',
  /** No Postal Code */
  NO_POSTAL_CODE = 'NO_POSTAL_CODE',
  /** No Suburb */
  NO_SUBURB = 'NO_SUBURB',
  /** No Warehouse */
  NO_WAREHOUSE = 'NO_WAREHOUSE',
  /** Support Issue */
  SUPPORT_ISSUE = 'SUPPORT_ISSUE'
}

export type AddressIssueFilters = {
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  hasOrdered?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  issues?: InputMaybe<Array<AddressIssueEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Properties list object */
export type AddressIssueList = {
  __typename: 'AddressIssueList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of AddressIssue records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<AddressIssue>>;
  /** Number of AddressIssue records skipped */
  skip: Scalars['Int']['output'];
};

/** AddressIssue ordering options */
export type AddressIssueOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AddressIssueOrderEnum>;
};

/** Fields available to order Properties */
export enum AddressIssueOrderEnum {
  CITY_NAME = 'CITY_NAME',
  ISSUE = 'ISSUE',
  IS_DEFAULT = 'IS_DEFAULT',
  LAST_ORDER_DATE = 'LAST_ORDER_DATE'
}

export enum AddressUnsupportedReasonEnum {
  /** City Not Supported */
  CITY_NOT_SUPPORTED = 'CITY_NOT_SUPPORTED',
  /** None */
  NONE = 'NONE',
  /** Polygon Not Supported */
  POLYGON_NOT_SUPPORTED = 'POLYGON_NOT_SUPPORTED'
}

/** Fields to sign up an existing User */
export type AdminSignUpInput = {
  email: Scalars['Email']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['Phone']['input']>;
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<TitleEnum>;
};

export type AllCodesArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  includeCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  onlyCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Allergen in the system */
export type Allergen = {
  __typename: 'Allergen';
  /** The creation date of the Allergen */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<Image>;
  /** The Allergen's ID */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  /** The update date of the Allergen */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum AllergenEnum {
  /** Gluten */
  GLUTEN = 'GLUTEN',
  /** Cows Milk */
  MILK = 'MILK',
  /** Vegetables */
  VEGETABLES = 'VEGETABLES',
  /** Wheat */
  WHEAT = 'WHEAT'
}

/** Allergen filtering options */
export type AllergenFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type AllergenList = {
  __typename: 'AllergenList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Allergen records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Allergen>>;
  /** Number of Allergen records skipped */
  skip: Scalars['Int']['output'];
};

/** Allergen ordering options */
export type AllergenOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AllergenOrderEnum>;
};

/** Fields available to order Properties */
export enum AllergenOrderEnum {
  CREATED_AT = 'CREATED_AT',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ApiScalarDataTypeEnum {
  /** Boolean */
  BOOLEAN = 'BOOLEAN',
  /** Color */
  COLOR = 'COLOR',
  /** Date */
  DATE = 'DATE',
  /** Date Time */
  DATE_TIME = 'DATE_TIME',
  /** Double */
  DOUBLE = 'DOUBLE',
  /** Email */
  EMAIL = 'EMAIL',
  /** Float */
  FLOAT = 'FLOAT',
  /** Hyper Text */
  HYPERTEXT = 'HYPERTEXT',
  /** Integer */
  INT = 'INT',
  /** Month */
  MONTH = 'MONTH',
  /** Phone */
  PHONE = 'PHONE',
  /** String */
  STRING = 'STRING',
  /** Text */
  TEXT = 'TEXT',
  /** Time */
  TIME = 'TIME',
  /** Link */
  URI = 'URI',
  /** Week */
  WEEK = 'WEEK',
  /** Year */
  YEAR = 'YEAR'
}

export type App = {
  __typename: 'App';
  defaultPortions: NumberOfPortionsEnum;
  deviceType: DeviceTypeEnum;
  hasFrozenBoughtBefore: Maybe<Scalars['Boolean']['output']>;
  hasInitiallySetTrackingData: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isNativeApplication: Scalars['Boolean']['output'];
  isOneSignalLoggedIn: Maybe<Scalars['Boolean']['output']>;
  trackingData: Maybe<LocalTrackingData>;
  urlDiscount: Maybe<Scalars['String']['output']>;
};

/** Represents a Archive in the system */
export type Archive = File & {
  __typename: 'Archive';
  categories: Maybe<Array<Maybe<FileCategory>>>;
  /** The creation date of the Archive */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  extension: Scalars['String']['output'];
  /** The Archive's ID */
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The update date of the Archive */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Int']['output'];
};

/** Represents a Area in the system */
export type Area = {
  __typename: 'Area';
  activeUserAddressCount: Scalars['Int']['output'];
  city: Maybe<City>;
  country: Country;
  /** The creation date of the Area */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Area's ID */
  id: Scalars['ID']['output'];
  locations: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  parentCity: Maybe<City>;
  parentRegion: Region;
  postalCode: Maybe<Scalars['String']['output']>;
  region: Region;
  /** The update date of the Area */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userAddressCount: Scalars['Int']['output'];
};

/** Area filtering options */
export type AreaFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cities?: InputMaybe<Array<Scalars['ID']['input']>>;
  countries?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  notCities?: InputMaybe<Array<Scalars['ID']['input']>>;
  notRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  parentCities?: InputMaybe<Array<Scalars['ID']['input']>>;
  parentRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AreaInput = {
  id: Scalars['ID']['input'];
};

/** Properties list object */
export type AreaList = {
  __typename: 'AreaList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Area records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Area>>;
  /** Number of Area records skipped */
  skip: Scalars['Int']['output'];
};

/** Area ordering options */
export type AreaOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<AreaOrderEnum>;
};

/** Fields available to order Properties */
export enum AreaOrderEnum {
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PARENT_CITIES = 'PARENT_CITIES',
  PARENT_REGIONS = 'PARENT_REGIONS',
  POSTAL_CODE = 'POSTAL_CODE',
  REGIONS = 'REGIONS',
  UPDATED_AT = 'UPDATED_AT'
}

export type AvailableDispatchDays = {
  __typename: 'AvailableDispatchDays';
  days: Array<Maybe<DeliveryDayOptionEnum>>;
};

/** Represents a Frozen in the system */
export type BabyBox = Product & {
  __typename: 'BabyBox';
  allergens: Array<Allergen>;
  availability: ProductAvailabilityEnum;
  babyBoxCategories: Array<BabyBoxCategory>;
  babyBoxTags: Array<BabyBoxTag>;
  babyBoxTubDetails: Maybe<Array<BabyBoxTubDetail>>;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingCode: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  portionSize: BabyBoxPortionSizeEnum;
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  shouldDisplay: Maybe<BabyBoxTubShouldDisplayEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  subTitle: Scalars['String']['output'];
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

export enum BabyBoxAllergenEnum {
  /** Gluten */
  GLUTEN = 'GLUTEN',
  /** Cows Milk */
  MILK = 'MILK',
  /** Vegetables */
  VEGETABLES = 'VEGETABLES',
  /** Wheat */
  WHEAT = 'WHEAT'
}

/** Represents a BabyBoxCategory in the system */
export type BabyBoxCategory = {
  __typename: 'BabyBoxCategory';
  /** The creation date of the BabyBoxCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  /** The BabyBoxCategory's ID */
  id: Scalars['ID']['output'];
  longDescription: Maybe<Scalars['String']['output']>;
  pricing: Maybe<Array<Maybe<BabyBoxCategoryPrice>>>;
  status: BabyBoxCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the BabyBoxCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** BabyBoxCategory filtering options */
export type BabyBoxCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<BabyBoxCategoryStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BabyBoxCategoryList = {
  __typename: 'BabyBoxCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BabyBoxCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BabyBoxCategory>>;
  /** Number of BabyBoxCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** BabyBoxCategory ordering options */
export type BabyBoxCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BabyBoxCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum BabyBoxCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export type BabyBoxCategoryPrice = {
  __typename: 'BabyBoxCategoryPrice';
  id: Scalars['String']['output'];
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: NumberOfPortionsEnum;
  price: Scalars['Float']['output'];
};

export enum BabyBoxCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** Frozen filtering options */
export type BabyBoxFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BabyBoxList = {
  __typename: 'BabyBoxList';
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list: Array<Maybe<BabyBox>>;
  skip: Scalars['Int']['output'];
};

/** Frozen ordering options */
export type BabyBoxOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenMealOrderEnum>;
};

/** Fields available to order Properties */
export enum BabyBoxOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BabyBoxPortionSizeEnum {
  /** Large */
  LARGE = 'LARGE',
  /** Single Tub */
  SINGLE_TUB = 'SINGLE_TUB',
  /** Small */
  SMALL = 'SMALL'
}

/** Represents a BabyBoxTag in the system */
export type BabyBoxTag = {
  __typename: 'BabyBoxTag';
  /** The creation date of the BabyBoxTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Scalars['Int']['output'];
  icon: Maybe<Image>;
  /** The BabyBoxTag's ID */
  id: Scalars['ID']['output'];
  tagType: BabyBoxTagTypeEnum;
  title: Scalars['String']['output'];
  /** The update date of the BabyBoxTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** BabyBoxTag filtering options */
export type BabyBoxTagFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<BabyBoxTagTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BabyBoxTagList = {
  __typename: 'BabyBoxTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BabyBoxTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BabyBoxTag>>;
  /** Number of BabyBoxTag records skipped */
  skip: Scalars['Int']['output'];
};

/** BabyBoxTag ordering options */
export type BabyBoxTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BabyBoxTagOrderEnum>;
};

/** Fields available to order Properties */
export enum BabyBoxTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Baby Box Tag Type Enum */
export enum BabyBoxTagTypeEnum {
  /** Preferences */
  PREFERENCE = 'PREFERENCE'
}

/** Represents a BabyBox in the system */
export type BabyBoxTub = ProductGroup & {
  __typename: 'BabyBoxTub';
  ageGroup: Maybe<Scalars['String']['output']>;
  allergens: Maybe<Array<Allergen>>;
  attributes: Maybe<Scalars['String']['output']>;
  babyBoxCategories: Maybe<Array<BabyBoxCategory>>;
  babyBoxTags: Maybe<Array<BabyBoxTag>>;
  cartItemIds: Array<Scalars['String']['output']>;
  categoryDescription: Maybe<Scalars['String']['output']>;
  configurationOptions: Maybe<Array<Maybe<Configuration>>>;
  coverImage: Image;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discoveryHealthyDiningItem: Maybe<Scalars['Boolean']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  groupProducts: Maybe<Array<Maybe<BabyBox>>>;
  id: Scalars['ID']['output'];
  isValid: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  packageDetails: Maybe<Scalars['String']['output']>;
  packageGoals: Maybe<Scalars['String']['output']>;
  packageSize: Maybe<Scalars['String']['output']>;
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  quantityInCart: Scalars['Int']['output'];
  recommendedServings: Maybe<Scalars['String']['output']>;
  related: Array<BabyBoxTub>;
  sentIngredients: Array<Scalars['String']['output']>;
  servingSuggestions: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  storageInstructions: Maybe<Scalars['String']['output']>;
  story: Maybe<Scalars['String']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  type: ProductGroupTypeEnum;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a BabyBoxTubDetail in the system */
export type BabyBoxTubDetail = {
  __typename: 'BabyBoxTubDetail';
  allergens: Maybe<Scalars['String']['output']>;
  carbs: Maybe<Scalars['String']['output']>;
  /** The creation date of the BabyBoxTubDetail */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  energyInKilojoules: Maybe<Scalars['String']['output']>;
  fat: Maybe<Scalars['String']['output']>;
  fibre: Maybe<Scalars['String']['output']>;
  /** The BabyBoxTubDetail's ID */
  id: Maybe<Scalars['ID']['output']>;
  image: Maybe<Image>;
  index: Maybe<Scalars['String']['output']>;
  ingredientList: Maybe<Scalars['String']['output']>;
  protein: Maybe<Scalars['String']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  saturatedFat: Maybe<Scalars['String']['output']>;
  sensoryPersonality: Maybe<Scalars['String']['output']>;
  size: Maybe<Scalars['Int']['output']>;
  sodium: Maybe<Scalars['String']['output']>;
  sugars: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the BabyBoxTubDetail */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** BabyBoxTubDetail filtering options */
export type BabyBoxTubDetailFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BabyBoxTubDetailList = {
  __typename: 'BabyBoxTubDetailList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BabyBoxTubDetail records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BabyBoxTubDetail>>;
  /** Number of BabyBoxTubDetail records skipped */
  skip: Scalars['Int']['output'];
};

/** BabyBoxTubDetail ordering options */
export type BabyBoxTubDetailOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BabyBoxTubDetailOrderEnum>;
};

/** Fields available to order Properties */
export enum BabyBoxTubDetailOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** BabyBox filtering options */
export type BabyBoxTubFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BabyBoxTubList = {
  __typename: 'BabyBoxTubList';
  /** The filtering aggregation for BabyBoxTub */
  aggregation: Maybe<ProductAggregation>;
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list: Array<Maybe<BabyBoxTub>>;
  skip: Scalars['Int']['output'];
};

/** BabyBox ordering options */
export type BabyBoxTubOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BabyBoxTubOrderEnum>;
};

/** Fields available to order Properties */
export enum BabyBoxTubOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  /** Name */
  NAME = 'NAME',
  /** Status */
  STATUS = 'STATUS',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export enum BabyBoxTubShouldDisplayEnum {
  /** Dont Display On Front End */
  FALSE = 'FALSE',
  /** Display On Front End */
  TRUE = 'TRUE'
}

export type BackgroundTask = {
  downloadType: Maybe<DownloadTypeEnum>;
  id: Maybe<Scalars['ID']['output']>;
  objectType: Scalars['String']['output'];
  reportType: Maybe<LiveReportTypeEnum>;
  updateType: BackgroundTaskUpdateEnum;
};

export type BackgroundTaskInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  objectType: Scalars['String']['input'];
  taskTypes?: InputMaybe<Array<BackgroundTaskTypeEnum>>;
};

export enum BackgroundTaskStatusEnum {
  /** Available */
  AVAILABLE = 'AVAILABLE',
  /** Errored */
  ERRORED = 'ERRORED',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Created */
  NOT_CREATED = 'NOT_CREATED',
  /** Outdated */
  OUTDATED = 'OUTDATED',
  /** Queued */
  QUEUED = 'QUEUED',
  /** Stalled */
  STALLED = 'STALLED',
  /** Uploading */
  UPLOADING = 'UPLOADING'
}

export enum BackgroundTaskTypeEnum {
  /** Download */
  DOWNLOAD = 'DOWNLOAD',
  /** Process */
  PROCESS = 'PROCESS',
  /** Report */
  REPORT = 'REPORT'
}

export enum BackgroundTaskUpdateEnum {
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Progress */
  PROGRESS = 'PROGRESS',
  /** Queued */
  QUEUED = 'QUEUED',
  /** Started */
  STARTED = 'STARTED',
  /** Uploading */
  UPLOADING = 'UPLOADING'
}

/** Represents a BankBinNumber in the system */
export type BankBinNumber = {
  __typename: 'BankBinNumber';
  bank: BankEnum;
  binNumber: Scalars['String']['output'];
  /** The creation date of the BankBinNumber */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The BankBinNumber's ID */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  /** The update date of the BankBinNumber */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** BankBinNumber filtering options */
export type BankBinNumberFilters = {
  bank?: InputMaybe<Array<InputMaybe<BankEnum>>>;
  binNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BankBinNumberList = {
  __typename: 'BankBinNumberList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BankBinNumber records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BankBinNumber>>;
  /** Number of BankBinNumber records skipped */
  skip: Scalars['Int']['output'];
};

/** BankBinNumber ordering options */
export type BankBinNumberOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BankBinNumberOrderEnum>;
};

/** Fields available to order Properties */
export enum BankBinNumberOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BankEnum {
  /** ABSA */
  ABSA = 'ABSA',
  /** Capitec */
  CAPITEC = 'CAPITEC',
  /** Discovery */
  DISCOVERY = 'DISCOVERY',
  /** Firstrand */
  FIRSTRAND = 'FIRSTRAND',
  /** Investec */
  INVESTEC = 'INVESTEC',
  /** Nedbank */
  NEDBANK = 'NEDBANK',
  /** Standard */
  STANDARD = 'STANDARD'
}

/** Represents a Barcode in the system */
export type Barcode = {
  __typename: 'Barcode';
  baseUnit: Maybe<UnitTypeEnum>;
  /** The creation date of the Barcode */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  format: Maybe<BarcodeFormatEnum>;
  /** The Barcode's ID */
  id: Scalars['ID']['output'];
  product: Maybe<Product>;
  text: Scalars['String']['output'];
  type: Maybe<BarcodeTypeEnum>;
  /** The update date of the Barcode */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Barcode filtering options */
export type BarcodeFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum BarcodeFormatEnum {
  /** EAN13 */
  EAN13 = 'EAN13',
  /** Other */
  OTHER = 'OTHER'
}

/** Properties list object */
export type BarcodeList = {
  __typename: 'BarcodeList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Barcode records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Barcode>>;
  /** Number of Barcode records skipped */
  skip: Scalars['Int']['output'];
};

/** Barcode ordering options */
export type BarcodeOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BarcodeOrderEnum>;
};

/** Fields available to order Properties */
export enum BarcodeOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Type of encoded bar code */
export enum BarcodeType {
  /** Barcode */
  BARCODE = 'BARCODE',
  /** QR Code */
  QR_CODE = 'QR_CODE'
}

/** Represents the type of barcode */
export enum BarcodeTypeEnum {
  /** Internal */
  INTERNAL = 'INTERNAL',
  /** Vendor */
  VENDOR = 'VENDOR'
}

/** Unit for product */
export enum BaseUnitEnum {
  /** Internal */
  INTERNAL = 'INTERNAL',
  /** Vendor */
  VENDOR = 'VENDOR'
}

/** Represents a Box in the system */
export type Box = {
  __typename: 'Box';
  acceptedSubContainers: Maybe<Array<BoxSubContainer>>;
  boxType: BoxTypeEnum;
  capacity: Scalars['Float']['output'];
  containersNeedLooseItems: Scalars['Boolean']['output'];
  courierBoxes: Maybe<Array<CourierBox>>;
  /** The creation date of the Box */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryItemParcels: Maybe<Array<DeliveryItemParcel>>;
  depthInMillimeters: Scalars['Float']['output'];
  description: Maybe<Scalars['String']['output']>;
  estimatedFullMassInGrams: Scalars['Float']['output'];
  hiegthInMillimeters: Scalars['Float']['output'];
  /** The Box's ID */
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  /** The update date of the Box */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  widthInMillimeters: Scalars['Float']['output'];
};

/** Box filtering options */
export type BoxFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  skus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BoxList = {
  __typename: 'BoxList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Box records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Box>>;
  /** Number of Box records skipped */
  skip: Scalars['Int']['output'];
};

/** Box ordering options */
export type BoxOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BoxOrderEnum>;
};

/** Fields available to order Properties */
export enum BoxOrderEnum {
  CREATED_AT = 'CREATED_AT',
  IS_ACTIVE = 'IS_ACTIVE',
  NAME = 'NAME',
  SKU = 'SKU',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a BoxRestriction in the system */
export type BoxRestriction = {
  __typename: 'BoxRestriction';
  /** The creation date of the BoxRestriction */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The BoxRestriction's ID */
  id: Scalars['ID']['output'];
  type: BoxRestrictionTypeEnum;
  /** The update date of the BoxRestriction */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['JSON']['output'];
};

/** BoxRestriction filtering options */
export type BoxRestrictionFilters = {
  boxId?: InputMaybe<Scalars['ID']['input']>;
  boxIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Array<InputMaybe<BoxRestrictionTypeEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BoxRestrictionList = {
  __typename: 'BoxRestrictionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BoxRestriction records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BoxRestriction>>;
  /** Number of BoxRestriction records skipped */
  skip: Scalars['Int']['output'];
};

/** BoxRestriction ordering options */
export type BoxRestrictionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BoxRestrictionOrderEnum>;
};

/** Fields available to order Properties */
export enum BoxRestrictionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  TYPE = 'TYPE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BoxRestrictionTypeEnum {
  /** Illegal Combination */
  ILLEGAL_COMBO = 'ILLEGAL_COMBO',
  /** Mixed Volume Limit */
  MIX_VOLUME_LIMIT = 'MIX_VOLUME_LIMIT',
  /** Product Restriction */
  PRODUCT_RESTRICTION = 'PRODUCT_RESTRICTION'
}

/** Represents a BoxSubContainer in the system */
export type BoxSubContainer = {
  __typename: 'BoxSubContainer';
  box: Box;
  /** The creation date of the BoxSubContainer */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The BoxSubContainer's ID */
  id: Scalars['ID']['output'];
  subContainer: SubContainer;
  /** The update date of the BoxSubContainer */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** BoxSubContainer filtering options */
export type BoxSubContainerFilters = {
  boxId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BoxSubContainerList = {
  __typename: 'BoxSubContainerList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of BoxSubContainer records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<BoxSubContainer>>;
  /** Number of BoxSubContainer records skipped */
  skip: Scalars['Int']['output'];
};

/** BoxSubContainer ordering options */
export type BoxSubContainerOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BoxSubContainerOrderEnum>;
};

/** Fields available to order Properties */
export enum BoxSubContainerOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BoxTypeEnum {
  /** Baby */
  BABY = 'BABY',
  /** Frozen */
  FROZEN = 'FROZEN',
  /** Gift */
  GIFT = 'GIFT',
  /** Market - Ambient */
  MARKET_AMBIENT = 'MARKET_AMBIENT',
  /** Market - Chilled */
  MARKET_CHILLED = 'MARKET_CHILLED',
  /** Market - Frozen */
  MARKET_FROZEN = 'MARKET_FROZEN',
  /** Market */
  MARKET_PRODUCT = 'MARKET_PRODUCT',
  /** Meal Kit */
  MEAL_KIT = 'MEAL_KIT',
  /** Wine */
  WINE = 'WINE'
}

/** Represents a Branch in the system */
export type Branch = {
  __typename: 'Branch';
  /** The creation date of the Branch */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The Branch's ID */
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the Branch */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Maybe<Array<Maybe<Warehouse>>>;
};

/** Branch filtering options */
export type BranchFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BranchList = {
  __typename: 'BranchList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Branch records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Branch>>;
  /** Number of Branch records skipped */
  skip: Scalars['Int']['output'];
};

/** Branch ordering options */
export type BranchOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BranchOrderEnum>;
};

/** Fields available to order Properties */
export enum BranchOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Brand in the system */
export type Brand = {
  __typename: 'Brand';
  about: Maybe<Scalars['String']['output']>;
  banner: Maybe<Image>;
  /** The creation date of the Brand */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Brand's ID */
  id: Scalars['ID']['output'];
  logo: Maybe<Image>;
  name: Scalars['String']['output'];
  /** The update date of the Brand */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Brand filtering options */
export type BrandFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type BrandList = {
  __typename: 'BrandList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Brand records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Brand>>;
  /** Number of Brand records skipped */
  skip: Scalars['Int']['output'];
};

/** Brand ordering options */
export type BrandOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<BrandOrderEnum>;
};

/** Fields available to order Properties */
export enum BrandOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BuildingTypeEnum {
  /** Apartment Complex */
  APARTMENT_COMPLEX = 'APARTMENT_COMPLEX',
  /** Free Standing House */
  HOUSE = 'HOUSE',
  /** Office Building / High Rise */
  OFFICE_BUILDING = 'OFFICE_BUILDING',
  /** Office Complex / Low Rise */
  OFFICE_COMPLEX = 'OFFICE_COMPLEX',
  /** Security Complex */
  SECURITY_COMPLEX = 'SECURITY_COMPLEX'
}

export enum ButtonColorEnum {
  /** Black */
  BLACK = 'BLACK',
  /** Blue */
  BLUE = 'BLUE',
  /** Green */
  GREEN = 'GREEN',
  /** Grey */
  GREY = 'GREY',
  /** Orange */
  ORANGE = 'ORANGE',
  /** Red */
  RED = 'RED',
  /** Transparent */
  TRANSPARENT = 'TRANSPARENT',
  /** Tundora */
  TUNDORA = 'TUNDORA',
  /** White */
  WHITE = 'WHITE'
}

export enum CacheControlScope {
  LOCATION = 'LOCATION',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type CacheKeyInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export enum CallStatusEnum {
  /** Called */
  CALLED = 'CALLED',
  /** None */
  NONE = 'NONE',
  /** No Answer */
  NO_ANSWER = 'NO_ANSWER',
  /** Order Edited */
  ORDER_EDITED = 'ORDER_EDITED',
  /** Requested Cancellation */
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION',
  /** Requested Rebill */
  REQUESTED_REBILL = 'REQUESTED_REBILL'
}

/** Represents a Campaign in the system */
export type Campaign = {
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  /** The Campaign's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageSections: Array<PageSection>;
  published: Scalars['Boolean']['output'];
  sections: Maybe<Array<Maybe<PageSection>>>;
  startDate: Scalars['DateTime']['output'];
  status: CampaignStatusEnum;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Campaign filtering options */
export type CampaignFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CampaignList = {
  __typename: 'CampaignList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Campaign records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Campaign>>;
  /** Number of Campaign records skipped */
  skip: Scalars['Int']['output'];
};

/** Campaign ordering options */
export type CampaignOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CampaignOrderEnum>;
};

/** Fields available to order Properties */
export enum CampaignOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum CampaignStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Invalid */
  INVALID = 'INVALID',
  /** Pending */
  PENDING = 'PENDING'
}

export type CanStackWith = {
  __typename: 'CanStackWith';
  id: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CanStackWithInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CannotStackWith = {
  __typename: 'CannotStackWith';
  id: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type CannotStackWithInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Cart in the system */
export type Cart = {
  __typename: 'Cart';
  additions: Array<CartCostItem>;
  allItems: Maybe<Array<Maybe<CartItem>>>;
  assignedUserPoints: Scalars['Float']['output'];
  availableUserPoints: Scalars['Float']['output'];
  /** Cart items in cart */
  cartItems: Maybe<Array<Maybe<CartItem>>>;
  cartType: OrderTypeEnum;
  checkoutStatus: UserCheckoutStatus;
  /** The creation date of the Cart */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  errors: Maybe<Array<Scalars['String']['output']>>;
  grandTotal: Scalars['Float']['output'];
  /** The Cart's ID */
  id: Scalars['ID']['output'];
  isValid: Maybe<Scalars['Boolean']['output']>;
  open: Scalars['Boolean']['output'];
  reductions: Array<CartCostItem>;
  status: CartStatusEnum;
  total: Scalars['Float']['output'];
  totalItems: Scalars['Int']['output'];
  totalNonSubscriptionItems: Scalars['Int']['output'];
  totalSubscriptionItems: Scalars['Int']['output'];
  trackingData: Maybe<Scalars['JSONObject']['output']>;
  /** The update date of the Cart */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  userHasChangedUserPoints: Maybe<Scalars['Boolean']['output']>;
};

export type CartAdditionItem = CartCostItem & {
  __typename: 'CartAdditionItem';
  additionType: CartAdditionTypeEnum;
  canBeRemoved: Scalars['Boolean']['output'];
  id: Maybe<Scalars['ID']['output']>;
  previousOrderId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum CartAdditionTypeEnum {
  /** Delivery */
  DELIVERY = 'DELIVERY',
  /** Previously Owed Amount */
  PREVIOUSLY_OWED_AMOUNT = 'PREVIOUSLY_OWED_AMOUNT'
}

export type CartCostDiscountValue = {
  __typename: 'CartCostDiscountValue';
  actualValue: Scalars['Float']['output'];
  hasReachedZero: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  productId: Maybe<Scalars['ID']['output']>;
  productRange: Maybe<Scalars['ID']['output']>;
  value: Scalars['Float']['output'];
  valueApplication: DiscountApplicationTypeEnum;
  valueType: DiscountValueTypeEnum;
};

export type CartCostItem = {
  canBeRemoved: Scalars['Boolean']['output'];
  id: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum CartCostItemTypeEnum {
  /** Addition */
  ADDITION = 'ADDITION',
  /** Reduction */
  REDUCTION = 'REDUCTION'
}

export type CartError = {
  __typename: 'CartError';
  description: Scalars['String']['output'];
  discountCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  itemProduct: Maybe<Product>;
  title: Scalars['String']['output'];
  type: CartErrorTypeEnum;
};

export enum CartErrorTypeEnum {
  /** Discount Error */
  DISCOUNT = 'DISCOUNT',
  /** Invalid */
  INVALID = 'INVALID',
  /** Missing Info */
  MISSING_INFO = 'MISSING_INFO',
  /** Not Available Geographically */
  NOT_AVAILABLE_GEOGRAPHICALLY = 'NOT_AVAILABLE_GEOGRAPHICALLY',
  /** Not Available on Delivery Day */
  NOT_AVAILABLE_ON_DELIVERY_DAY = 'NOT_AVAILABLE_ON_DELIVERY_DAY',
  /** Not enough Stock */
  NOT_ENOUGH_STOCK = 'NOT_ENOUGH_STOCK',
  /** Out of Stock */
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

/** Cart filtering options */
export type CartFilters = {
  cartType?: InputMaybe<Array<OrderTypeEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxValue?: InputMaybe<Scalars['Int']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minValue?: InputMaybe<Scalars['Int']['input']>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CartStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Details required to create or update a cart */
export type CartInput = {
  /** Customer selected address id */
  address: Scalars['ID']['input'];
  /** Customer credit card id */
  creditCard: Scalars['ID']['input'];
  /** User Id */
  user: Scalars['ID']['input'];
};

/** Represents a CartItem in the system */
export type CartItem = {
  __typename: 'CartItem';
  /** Customer Cart */
  cart: Cart;
  /** Product */
  cartItemProduct: Product;
  /** The creation date of the CartItem */
  createdAt: Scalars['DateTime']['output'];
  /** The CartItem's ID */
  id: Scalars['ID']['output'];
  isDisplayed: Scalars['Boolean']['output'];
  isFromSubscription: Scalars['Boolean']['output'];
  /** Price Amount */
  price: Scalars['Float']['output'];
  /** Quantity */
  quantity: Scalars['Int']['output'];
  stockStatus: CartItemStatusEnum;
  /** The update date of the CartItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Fields available to ''der Properties */
export enum CartItemOrderEnum {
  PRICE = 'PRICE',
  PRODUCT = 'PRODUCT'
}

export enum CartItemStatusEnum {
  /** In Stock */
  IN_STOCK = 'IN_STOCK',
  /** Not Available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** Not Enough Stock */
  NOT_ENOUGH_STOCK = 'NOT_ENOUGH_STOCK',
  /** Out of Stock */
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

/** Properties list object */
export type CartList = {
  __typename: 'CartList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Cart records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Cart>>;
  /** Number of Cart records skipped */
  skip: Scalars['Int']['output'];
};

/** Cart ordering options */
export type CartOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CartOrderEnum>;
};

/** Fields available to order Properties */
export enum CartOrderEnum {
  CART_TYPE = 'CART_TYPE',
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT',
  USER = 'USER',
  VALUE = 'VALUE'
}

export type CartProductCount = {
  __typename: 'CartProductCount';
  cart: Cart;
  count: Scalars['Int']['output'];
  itemProduct: Product;
};

export type CartReductionItem = CartCostItem & {
  __typename: 'CartReductionItem';
  canBeRemoved: Scalars['Boolean']['output'];
  discountCodeId: Maybe<Scalars['ID']['output']>;
  discountId: Maybe<Scalars['ID']['output']>;
  discountValues: Maybe<Array<Maybe<CartCostDiscountValue>>>;
  id: Maybe<Scalars['ID']['output']>;
  reductionType: CartReductionTypeEnum;
  title: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum CartReductionTypeEnum {
  /** Discount */
  DISCOUNT = 'DISCOUNT',
  /** User Points */
  USER_POINTS = 'USER_POINTS'
}

export enum CartStatusEnum {
  /** Abandoned */
  ABANDONED = 'ABANDONED',
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Converted to Order */
  CONVERTED_TO_ORDER = 'CONVERTED_TO_ORDER',
  /** Detached for Billing */
  DETACHED = 'DETACHED',
  /** Guest */
  GUEST = 'GUEST'
}

export type CartTracker = TrackingData & {
  __typename: 'CartTracker';
  admarulaUTMTracker: Maybe<Scalars['String']['output']>;
  facebookBrowserId: Maybe<Scalars['String']['output']>;
  facebookClickId: Maybe<Scalars['String']['output']>;
  googleClickId: Maybe<Scalars['String']['output']>;
  googleSessionId: Maybe<Scalars['Int']['output']>;
  instagramClickId: Maybe<Scalars['String']['output']>;
  referrerUrl: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
  tikTokClickId: Maybe<Scalars['String']['output']>;
};

/** User Preferences within a Cateogry */
export type CategorisedUserPreferences = {
  __typename: 'CategorisedUserPreferences';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  preferences: Maybe<Array<Maybe<UsersPreferencesOrDefaultsObject>>>;
};

/** List of Categories User Preferences */
export type CategorisedUserPreferencesList = {
  __typename: 'CategorisedUserPreferencesList';
  count: Maybe<Scalars['Int']['output']>;
  list: Maybe<Array<Maybe<CategorisedUserPreferences>>>;
};

/** Represents a Category in the system */
export type Category = {
  __typename: 'Category';
  ancestryDescription: Scalars['String']['output'];
  children: Maybe<Array<Maybe<Category>>>;
  /** The creation date of the Category */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<CategoryGroup>;
  icon: Maybe<Image>;
  /** The Category's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent: Maybe<Category>;
  slug: Scalars['String']['output'];
  /** The update date of the Category */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum CategoryBackgroundEnum {
  /** Blue */
  BLUE = 'BLUE',
  /** Dark Grey */
  DARK_GREY = 'DARK_GREY',
  /** Green */
  GREEN = 'GREEN',
  /** Light Grey */
  LIGHT_GREY = 'LIGHT_GREY',
  /** Orange */
  ORANGE = 'ORANGE',
  /** Raspberry */
  RASPBERRY = 'RASPBERRY',
  /** Red */
  RED = 'RED',
  /** Yellow */
  YELLOW = 'YELLOW'
}

/** Category filtering options */
export type CategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a CategoryGroup in the system */
export type CategoryGroup = {
  __typename: 'CategoryGroup';
  /** The creation date of the CategoryGroup */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The CategoryGroup's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the CategoryGroup */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** CategoryGroup filtering options */
export type CategoryGroupFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CategoryGroupList = {
  __typename: 'CategoryGroupList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CategoryGroup records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CategoryGroup>>;
  /** Number of CategoryGroup records skipped */
  skip: Scalars['Int']['output'];
};

/** CategoryGroup ordering options */
export type CategoryGroupOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CategoryGroupOrderEnum>;
};

/** Fields available to order Properties */
export enum CategoryGroupOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type CategoryList = {
  __typename: 'CategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Category records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Category>>;
  /** Number of Category records skipped */
  skip: Scalars['Int']['output'];
};

/** Category ordering options */
export type CategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum CategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type Checkout = {
  __typename: 'Checkout';
  errors: Array<Maybe<CartError>>;
  order: Maybe<Order>;
};

export enum CheckoutStepEnum {
  ABOUT = 'ABOUT',
  ACTIVATE_SUBSCRIPTION = 'ACTIVATE_SUBSCRIPTION',
  CART_ERRORS = 'CART_ERRORS',
  CART_SUMMARY = 'CART_SUMMARY',
  CONFIGURE_PLAN = 'CONFIGURE_PLAN',
  DELIVERY = 'DELIVERY',
  DELIVERY_DAY = 'DELIVERY_DAY',
  IDENTIFY_USER = 'IDENTIFY_USER',
  NOTHING = 'NOTHING',
  PAYMENT = 'PAYMENT',
  REACTIVATE = 'REACTIVATE',
  SAVE_MENU = 'SAVE_MENU',
  THANK_YOU = 'THANK_YOU',
  VALIDATION = 'VALIDATION',
  VERIFY_NUMBER = 'VERIFY_NUMBER'
}

export enum CheckoutTypeEnum {
  ON_DEMAND = 'ON_DEMAND',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

/** Represents a Chef in the system */
export type Chef = {
  __typename: 'Chef';
  biography: Maybe<Scalars['String']['output']>;
  /** The creation date of the Chef */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  /** The Chef's ID */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profileImage: Maybe<Image>;
  /** The update date of the Chef */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Chef filtering options */
export type ChefFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ChefList = {
  __typename: 'ChefList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Chef records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Chef>>;
  /** Number of Chef records skipped */
  skip: Scalars['Int']['output'];
};

/** Chef ordering options */
export type ChefOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ChefOrderEnum>;
};

/** Fields available to order Properties */
export enum ChefOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Chronological Period Type */
export enum ChronologicalPeriodTypeEnum {
  /** Daily */
  DAILY = 'DAILY',
  /** Monthly */
  MONTHLY = 'MONTHLY',
  /** Weekly */
  WEEKLY = 'WEEKLY',
  /** Yearly */
  YEARLY = 'YEARLY'
}

/** Chronological Unit Type */
export enum ChronologicalUnitTypeEnum {
  /** Day(s) */
  DAY = 'DAY',
  /** Hour(s) */
  HOUR = 'HOUR',
  /** Minute(s) */
  MINUTE = 'MINUTE',
  /** Month(s) */
  MONTH = 'MONTH',
  /** Week(s) */
  WEEK = 'WEEK',
  /** Year(s) */
  YEAR = 'YEAR'
}

/** Represents a City in the system */
export type City = {
  __typename: 'City';
  activeUserAddressCount: Scalars['Int']['output'];
  areas: Maybe<Array<Maybe<Area>>>;
  country: Country;
  countryId: Scalars['ID']['output'];
  /** The creation date of the City */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDays: Array<Maybe<CityDeliveryDaysEnum>>;
  deliveryType: Maybe<CityDeliveryTypeEnum>;
  dontAssignChildren: Scalars['Boolean']['output'];
  dontAssignParent: Scalars['Boolean']['output'];
  geometry: Maybe<GeometryData>;
  geometryColor: Scalars['Color']['output'];
  geometryStatus: GeometryStatusEnum;
  /** The City's ID */
  id: Scalars['ID']['output'];
  locations: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  parentRegion: Region;
  productRanges: Maybe<Array<Maybe<ProductRangeEnum>>>;
  region: Region;
  regionId: Scalars['ID']['output'];
  sameAsChildren: Array<Maybe<City>>;
  sameAsParent: Maybe<City>;
  status: GeoStatusEnum;
  /** The update date of the City */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userAddressCount: Scalars['Int']['output'];
  warehouse: Maybe<Warehouse>;
};

export type CityCourierWarehouseResponse = {
  __typename: 'CityCourierWarehouseResponse';
  cityCourierWarehouseId: Maybe<Scalars['ID']['output']>;
  courierId: Scalars['ID']['output'];
  courierName: Scalars['String']['output'];
  courierWarehouseId: Scalars['ID']['output'];
  warehouseId: Scalars['ID']['output'];
  warehouseName: Scalars['String']['output'];
};

/** City Delivery Days */
export enum CityDeliveryDaysEnum {
  /** Monday */
  MONDAY = 'MONDAY',
  /** Sunday */
  SUNDAY = 'SUNDAY'
}

/** City Delivery Type */
export enum CityDeliveryTypeEnum {
  /** Local */
  LOCAL = 'LOCAL',
  /** Local Outlier */
  LOCAL_OUTLIER = 'LOCAL_OUTLIER',
  /** National */
  NATIONAL = 'NATIONAL',
  /** Not Assigned */
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  /** Outlier */
  OUTLIER = 'OUTLIER'
}

export type CityFilter = {
  name: Scalars['String']['input'];
};

/** City filtering options */
export type CityFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  countries?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDays?: InputMaybe<Array<CityDeliveryDaysEnum>>;
  deliveryTypes?: InputMaybe<Array<CityDeliveryTypeEnum>>;
  dontAssignParent?: InputMaybe<Scalars['Boolean']['input']>;
  geometryStatus?: InputMaybe<Array<GeometryStatusEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  notRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyUnassignedWarehouses?: InputMaybe<Scalars['Boolean']['input']>;
  parentRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  productRanges?: InputMaybe<Array<ProductRangeEnum>>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  rollupType?: InputMaybe<Array<CityRollupStatus>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<GeoStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CityInput = {
  id: Scalars['ID']['input'];
};

/** Properties list object */
export type CityList = {
  __typename: 'CityList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of City records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<City>>;
  /** Number of City records skipped */
  skip: Scalars['Int']['output'];
};

/** City ordering options */
export type CityOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CityOrderEnum>;
};

/** Fields available to order Properties */
export enum CityOrderEnum {
  COUNTRIES = 'COUNTRIES',
  CREATED_AT = 'CREATED_AT',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  GEOMETRY_STAUS = 'GEOMETRY_STAUS',
  NAME = 'NAME',
  PARENT_REGIONS = 'PARENT_REGIONS',
  REGIONS = 'REGIONS',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum CityRollupStatus {
  /** Children */
  CHILDREN = 'CHILDREN',
  /** Non Children */
  NON_CHILDREN = 'NON_CHILDREN',
  /** Non Prents */
  NON_PARENTS = 'NON_PARENTS',
  /** Parents */
  PARENTS = 'PARENTS'
}

export type ClientInterface = {
  __typename: 'ClientInterface';
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  possibleTypes: Maybe<Array<Maybe<ClientInterfaceType>>>;
};

export type ClientInterfaceType = {
  __typename: 'ClientInterfaceType';
  name: Scalars['String']['output'];
};

export enum CommunicationActivationTypeEnum {
  /** Manual */
  MANUAL = 'MANUAL',
  /** Scheduled */
  SCHEDULED = 'SCHEDULED'
}

export type CommunicationBatchStatus = {
  __typename: 'CommunicationBatchStatus';
  errors: Scalars['Int']['output'];
  of: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

/** Represents a CommunicationPlatformCustomField in the system */
export type CommunicationPlatformCustomField = {
  __typename: 'CommunicationPlatformCustomField';
  /** The creation date of the CommunicationPlatformCustomField */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  /** The update date of the CommunicationPlatformCustomField */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Fields to add a new CommunicationPlatformCustomField */
export type CommunicationPlatformCustomFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum CommunicationStatusEnum {
  /** Waiting */
  WAITING = 'WAITING',
  /** Working */
  WORKING = 'WORKING'
}

/** Represents a CommunicationTemplate in the system */
export type CommunicationTemplate = {
  __typename: 'CommunicationTemplate';
  activationType: CommunicationActivationTypeEnum;
  /** The creation date of the CommunicationTemplate */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Boolean']['output'];
  emailBatchStatus: CommunicationBatchStatus;
  group: UserGroupsEnum;
  /** The CommunicationTemplate's ID */
  id: Scalars['ID']['output'];
  lastSendDate: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pushNotification: Scalars['Boolean']['output'];
  pushNotificationBatchStatus: CommunicationBatchStatus;
  scheduledDay: Maybe<WeekDayEnum>;
  scheduledTime: Maybe<Scalars['Time']['output']>;
  sms: Scalars['Boolean']['output'];
  smsBatchStatus: CommunicationBatchStatus;
  status: CommunicationStatusEnum;
  /** The update date of the CommunicationTemplate */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** CommunicationTemplate filtering options */
export type CommunicationTemplateFilters = {
  activationTypes?: InputMaybe<Array<CommunicationActivationTypeEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<UserGroupsEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledDays?: InputMaybe<Array<WeekDayEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<CommunicationStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CommunicationTemplateList = {
  __typename: 'CommunicationTemplateList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CommunicationTemplate records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CommunicationTemplate>>;
  /** Number of CommunicationTemplate records skipped */
  skip: Scalars['Int']['output'];
};

/** CommunicationTemplate ordering options */
export type CommunicationTemplateOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CommunicationTemplateOrderEnum>;
};

/** Fields available to order Properties */
export enum CommunicationTemplateOrderEnum {
  ACTIVATION_TYPE = 'ACTIVATION_TYPE',
  CREATED_AT = 'CREATED_AT',
  GROUP = 'GROUP',
  LAST_SEND_DATE = 'LAST_SEND_DATE',
  NAME = 'NAME',
  SCHEDULED_DAY = 'SCHEDULED_DAY',
  SCHEDULED_TIME = 'SCHEDULED_TIME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type Configuration = {
  __typename: 'Configuration';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  options: Maybe<Array<Maybe<ConfigurationOption>>>;
  title: Scalars['String']['output'];
};

export type ConfigurationOption = {
  __typename: 'ConfigurationOption';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CookingTimeRange = {
  __typename: 'CookingTimeRange';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type Coordinate = {
  __typename: 'Coordinate';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CoordinateInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

/** Represents a Country in the system */
export type Country = {
  __typename: 'Country';
  activeUserAddressCount: Scalars['Int']['output'];
  areas: Maybe<Array<Maybe<Area>>>;
  cities: Maybe<Array<Maybe<City>>>;
  code: Maybe<Scalars['String']['output']>;
  /** The creation date of the Country */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  geometry: Maybe<GeometryData>;
  geometryColor: Scalars['Color']['output'];
  geometryStatus: GeometryStatusEnum;
  /** The Country's ID */
  id: Scalars['ID']['output'];
  locations: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  regions: Maybe<Array<Maybe<Region>>>;
  status: GeoStatusEnum;
  /** The update date of the Country */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userAddressCount: Scalars['Int']['output'];
};

/** Country filtering options */
export type CountryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  geometryStatus?: InputMaybe<Array<InputMaybe<GeometryStatusEnum>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<GeoStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CountryList = {
  __typename: 'CountryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Country records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Country>>;
  /** Number of Country records skipped */
  skip: Scalars['Int']['output'];
};

/** Country ordering options */
export type CountryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CountryOrderEnum>;
};

/** Fields available to order Properties */
export enum CountryOrderEnum {
  CODE = 'CODE',
  CREATED_AT = 'CREATED_AT',
  GEOMETRY_STATUS = 'GEOMETRY_STATUS',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Courier in the system */
export type Courier = {
  __typename: 'Courier';
  appendParcelIndexToBarcode: Maybe<Scalars['Boolean']['output']>;
  barcodeType: BarcodeType;
  batchSize: Maybe<Scalars['Int']['output']>;
  courierBoxes: Maybe<Array<Maybe<CourierBox>>>;
  courierDeliveries: Maybe<Array<Maybe<CourierDelivery>>>;
  courierDeliveryDays: Maybe<Array<Maybe<CourierDeliveryDay>>>;
  courierWarehouses: Maybe<Array<Maybe<CourierWarehouse>>>;
  /** The creation date of the Courier */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  delayedIntegrationFeedback: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The Courier's ID */
  id: Scalars['ID']['output'];
  identity: Maybe<CourierIdentityEnum>;
  maxVolume: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  processGroupCouriers: Maybe<Array<Maybe<ProcessGroupCourier>>>;
  trackingLevel: CourierTrackingLevel;
  /** The update date of the Courier */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  volumeType: Maybe<CourierVolumeType>;
};

/** Represents a CourierBox in the system */
export type CourierBox = {
  __typename: 'CourierBox';
  box: Box;
  courier: Courier;
  courierIdentifier: Scalars['String']['output'];
  /** The creation date of the CourierBox */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  distanceConverstion: Scalars['Float']['output'];
  /** The CourierBox's ID */
  id: Scalars['ID']['output'];
  massConverstion: Scalars['Float']['output'];
  /** The update date of the CourierBox */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** CourierBox filtering options */
export type CourierBoxFilters = {
  boxId?: InputMaybe<Scalars['ID']['input']>;
  boxIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  courierId?: InputMaybe<Scalars['ID']['input']>;
  courierIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CourierBoxList = {
  __typename: 'CourierBoxList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierBox records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierBox>>;
  /** Number of CourierBox records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierBox ordering options */
export type CourierBoxOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierBoxOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierBoxOrderEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a CourierDelivery in the system */
export type CourierDelivery = {
  __typename: 'CourierDelivery';
  courier: Courier;
  courierBucketReference: Maybe<Scalars['String']['output']>;
  courierDeliveryStatus: CourierDeliveryStatusEnum;
  /** The creation date of the CourierDelivery */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Scalars['DateTime']['output'];
  deliveryItems: Maybe<Array<Maybe<DeliveryItem>>>;
  estimatedCollectionTime: Maybe<Scalars['DateTime']['output']>;
  history: Maybe<Array<Maybe<CourierDeliveryHistory>>>;
  /** The CourierDelivery's ID */
  id: Scalars['ID']['output'];
  orderType: OrderTypeEnum;
  /** The update date of the CourierDelivery */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};

/** Represents a CourierDeliveryDay in the system */
export type CourierDeliveryDay = {
  __typename: 'CourierDeliveryDay';
  courier: Courier;
  /** The creation date of the CourierDeliveryDay */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** Week Day position representation 1 = Monday, 7 = Sunday */
  dayOfWeek: Scalars['Int']['output'];
  /** The CourierDeliveryDay's ID */
  id: Scalars['ID']['output'];
  /** The update date of the CourierDeliveryDay */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** CourierDeliveryDay filtering options */
export type CourierDeliveryDayFilters = {
  courierId?: InputMaybe<Scalars['ID']['input']>;
  courierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CourierDeliveryDayList = {
  __typename: 'CourierDeliveryDayList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierDeliveryDay records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierDeliveryDay>>;
  /** Number of CourierDeliveryDay records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierDeliveryDay ordering options */
export type CourierDeliveryDayOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierDeliveryDayOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierDeliveryDayOrderEnum {
  COURIER_ID = 'COURIER_ID',
  CREATED_AT = 'CREATED_AT',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  UPDATED_AT = 'UPDATED_AT'
}

/** CourierDelivery filtering options */
export type CourierDeliveryFilters = {
  courierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderTypes?: InputMaybe<Array<OrderTypeEnum>>;
  status?: InputMaybe<Array<CourierDeliveryStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Represents a CourierDeliveryHistory in the system */
export type CourierDeliveryHistory = {
  __typename: 'CourierDeliveryHistory';
  courierDelivery: Maybe<CourierDelivery>;
  /** The creation date of the CourierDeliveryHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  eventData: Scalars['JSON']['output'];
  eventType: DeliveryHistoryEventType;
  /** The CourierDeliveryHistory's ID */
  id: Scalars['ID']['output'];
};

/** CourierDeliveryHistory filtering options */
export type CourierDeliveryHistoryFilters = {
  courierDeliveryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventType?: InputMaybe<DeliveryHistoryEventType>;
  eventTypes?: InputMaybe<Array<InputMaybe<DeliveryHistoryEventType>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CourierDeliveryHistoryList = {
  __typename: 'CourierDeliveryHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierDeliveryHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierDeliveryHistory>>;
  /** Number of CourierDeliveryHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierDeliveryHistory ordering options */
export type CourierDeliveryHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierDeliveryHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierDeliveryHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  EVENT_TYPE = 'EVENT_TYPE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type CourierDeliveryList = {
  __typename: 'CourierDeliveryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierDelivery records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierDelivery>>;
  /** Number of CourierDelivery records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierDelivery ordering options */
export type CourierDeliveryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierDeliveryOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierDeliveryOrderEnum {
  COURIER_ID = 'COURIER_ID',
  CREATED_AT = 'CREATED_AT',
  DELIVERY_DATE = 'DELIVERY_DATE',
  UPDATED_AT = 'UPDATED_AT',
  WAREHOUSE_ID = 'WAREHOUSE_ID'
}

export enum CourierDeliveryStatusEnum {
  /** Awaiting Courier Feedback */
  AWAITING_COURIER_FEEDBACK = 'AWAITING_COURIER_FEEDBACK',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** List Complete */
  LIST_COMPLETE = 'LIST_COMPLETE',
  /** Minimum Volume Complete */
  MINIMUM_VOLUME_COMPLETE = 'MINIMUM_VOLUME_COMPLETE',
  /** Pending */
  PENDING = 'PENDING',
  /** Pending Manual Intervention */
  PENDING_MANUAL_INTERVENTION = 'PENDING_MANUAL_INTERVENTION',
  /** Preparing List */
  PREPARING_LIST = 'PREPARING_LIST',
  /** Preparing Minimum Volume */
  PREPARING_MINIMUM_VOLUME = 'PREPARING_MINIMUM_VOLUME',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Processing Courier Feedback */
  PROCESSING_COURIER_FEEDBACK = 'PROCESSING_COURIER_FEEDBACK',
  /** Skipped */
  SKIPPED = 'SKIPPED'
}

/** Courier filtering options */
export type CourierFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum CourierIdentityEnum {
  /** DSV */
  DSV = 'DSV',
  /** MDS */
  MDS = 'MDS',
  /** My Courier */
  MY_COURIER = 'MY_COURIER',
  /** Picup */
  PICUP = 'PICUP',
  /** Temp */
  TEMP = 'TEMP'
}

export enum CourierItemStatusEnum {
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Delivered */
  DELIVERED = 'DELIVERED',
  /** Delivery Failed */
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  /** Enroute */
  ENROUTE = 'ENROUTE',
  /** Near Location */
  NEAR_LOCATION = 'NEAR_LOCATION',
  /** Pending Collection */
  PENDING_PICK_UP = 'PENDING_PICK_UP',
  /** Redelivery */
  REDILVERY = 'REDILVERY'
}

/** Properties list object */
export type CourierList = {
  __typename: 'CourierList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Courier records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Courier>>;
  /** Number of Courier records skipped */
  skip: Scalars['Int']['output'];
};

/** Courier ordering options */
export type CourierOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type CourierSplitReportFile = {
  __typename: 'CourierSplitReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type CourierStatusChangeEvent = ItemHistoryEventData & {
  __typename: 'CourierStatusChangeEvent';
  newStatus: CourierItemStatusEnum;
  note: Maybe<Scalars['String']['output']>;
  oldStatus: CourierItemStatusEnum;
};

/** Level at which a courier tracks parcels */
export enum CourierTrackingLevel {
  /** Per Order */
  PER_ORDER = 'PER_ORDER',
  /** Per Parcel */
  PER_PARCEL = 'PER_PARCEL'
}

export enum CourierTypeEnum {
  /** My Courier */
  MY_COURIER = 'MY_COURIER',
  /** Picup */
  PICUP = 'PICUP',
  /** UCOOK Driver */
  UCOOK_DRIVER = 'UCOOK_DRIVER',
  /** UCOOK Staff */
  UCOOK_STAFF = 'UCOOK_STAFF',
  /** Wumdrop */
  WUMDROP = 'WUMDROP'
}

export enum CourierVolumeType {
  /** Fixed Count */
  FIXED_COUNT = 'FIXED_COUNT',
  /** Order Percentage */
  ORDER_PERCENTAGE = 'ORDER_PERCENTAGE'
}

/** Represents a CourierWarehouse in the system */
export type CourierWarehouse = {
  __typename: 'CourierWarehouse';
  courier: Maybe<Courier>;
  courierReference: Maybe<Scalars['String']['output']>;
  /** The creation date of the CourierWarehouse */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The CourierWarehouse's ID */
  id: Scalars['ID']['output'];
  maxVolume: Maybe<Scalars['Int']['output']>;
  minVolume: Maybe<Scalars['Int']['output']>;
  /** The update date of the CourierWarehouse */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  volumeType: Maybe<CourierVolumeType>;
  warehouse: Maybe<Warehouse>;
};

/** Represents a CourierWarehouseCity in the system */
export type CourierWarehouseCity = {
  __typename: 'CourierWarehouseCity';
  city: City;
  courierWarehouse: CourierWarehouse;
  /** The creation date of the CourierWarehouseCity */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The CourierWarehouseCity's ID */
  id: Scalars['ID']['output'];
  /** The update date of the CourierWarehouseCity */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a CourierWarehouseCityDeliveryDay in the system */
export type CourierWarehouseCityDeliveryDay = {
  __typename: 'CourierWarehouseCityDeliveryDay';
  courierDeliveryDay: CourierDeliveryDay;
  courierWarehouseCity: CourierWarehouseCity;
  /** The creation date of the CourierWarehouseCityDeliveryDay */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The CourierWarehouseCityDeliveryDay's ID */
  id: Scalars['ID']['output'];
  /** The update date of the CourierWarehouseCityDeliveryDay */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** CourierWarehouseCityDeliveryDay filtering options */
export type CourierWarehouseCityDeliveryDayFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CourierWarehouseCityDeliveryDayList = {
  __typename: 'CourierWarehouseCityDeliveryDayList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierWarehouseCityDeliveryDay records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierWarehouseCityDeliveryDay>>;
  /** Number of CourierWarehouseCityDeliveryDay records skipped */
  skip: Scalars['Int']['output'];
};

export type CourierWarehouseCityDeliveryDayOption = {
  __typename: 'CourierWarehouseCityDeliveryDayOption';
  courierDeliveryDayId: Scalars['ID']['output'];
  courierWarehouseCityDeliveryDayId: Maybe<Scalars['ID']['output']>;
  courierWarehouseCityId: Scalars['ID']['output'];
  dayOfWeek: Scalars['Int']['output'];
};

/** CourierWarehouseCityDeliveryDay ordering options */
export type CourierWarehouseCityDeliveryDayOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierWarehouseCityDeliveryDayOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierWarehouseCityDeliveryDayOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** CourierWarehouseCity filtering options */
export type CourierWarehouseCityFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type CourierWarehouseCityList = {
  __typename: 'CourierWarehouseCityList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierWarehouseCity records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierWarehouseCity>>;
  /** Number of CourierWarehouseCity records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierWarehouseCity ordering options */
export type CourierWarehouseCityOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierWarehouseCityOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierWarehouseCityOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** CourierWarehouse filtering options */
export type CourierWarehouseFilters = {
  courierId?: InputMaybe<Scalars['ID']['input']>;
  courierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type CourierWarehouseList = {
  __typename: 'CourierWarehouseList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CourierWarehouse records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CourierWarehouse>>;
  /** Number of CourierWarehouse records skipped */
  skip: Scalars['Int']['output'];
};

/** CourierWarehouse ordering options */
export type CourierWarehouseOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CourierWarehouseOrderEnum>;
};

/** Fields available to order Properties */
export enum CourierWarehouseOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export type CreateSubscriptionOrderInput = {
  menuId: Scalars['ID']['input'];
  shouldBill?: InputMaybe<Scalars['Boolean']['input']>;
  shouldComplete?: InputMaybe<Scalars['Boolean']['input']>;
  shouldNotify?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

/** Represents a CreditCard in the system */
export type CreditCard = {
  __typename: 'CreditCard';
  binNumber: Maybe<Scalars['String']['output']>;
  cardBrand: CreditCardTypeEnum;
  cardNickname: Maybe<Scalars['String']['output']>;
  /** The creation date of the CreditCard */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  expiryMonth: Scalars['ExpiryMonth']['output'];
  expiryYear: Scalars['ExpiryYear']['output'];
  /** The CreditCard's ID */
  id: Scalars['ID']['output'];
  invalidationMessage: Maybe<Scalars['String']['output']>;
  /** Is the User's Default Credit Card */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  isValidated: Maybe<Scalars['Boolean']['output']>;
  last4Digits: Maybe<Scalars['String']['output']>;
  nameOnCard: Scalars['String']['output'];
  providers: Array<PaymentProviderEnum>;
  /** The update date of the CreditCard */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type CreditCardErrorReportFile = {
  __typename: 'CreditCardErrorReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** CreditCard filtering options */
export type CreditCardFilters = {
  binNumber?: InputMaybe<Scalars['String']['input']>;
  cardBrand?: InputMaybe<CreditCardTypeEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiryMonth?: InputMaybe<Scalars['ExpiryMonth']['input']>;
  expiryYear?: InputMaybe<Scalars['ExpiryYear']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last4Digits?: InputMaybe<Scalars['String']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  nameOnCard?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type CreditCardList = {
  __typename: 'CreditCardList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of CreditCard records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<CreditCard>>;
  /** Number of CreditCard records skipped */
  skip: Scalars['Int']['output'];
};

/** CreditCard ordering options */
export type CreditCardOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<CreditCardOrderEnum>;
};

/** Fields available to order Properties */
export enum CreditCardOrderEnum {
  CARD_BRAND = 'CARD_BRAND',
  CARD_NICKNAME = 'CARD_NICKNAME',
  CREATED_AT = 'CREATED_AT',
  EXPIRY_MONTH = 'EXPIRY_MONTH',
  EXPIRY_YEAR = 'EXPIRY_YEAR',
  NAME_ON_CARD = 'NAME_ON_CARD',
  UPDATED_AT = 'UPDATED_AT',
  USER_ID = 'USER_ID'
}

export enum CreditCardTypeEnum {
  /** American express */
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  /** Diners club */
  DINERS_CLUB = 'DINERS_CLUB',
  /** Discover */
  DISCOVER = 'DISCOVER',
  /** Elo */
  ELO = 'ELO',
  /** Hiper */
  HIPER = 'HIPER',
  /** Hipercard */
  HIPERCARD = 'HIPERCARD',
  /** Jcb */
  JCB = 'JCB',
  /** Maestro */
  MAESTRO = 'MAESTRO',
  /** Mastercard */
  MASTERCARD = 'MASTERCARD',
  /** Mir */
  MIR = 'MIR',
  /** Unionpay */
  UNIONPAY = 'UNIONPAY',
  /** Visa */
  VISA = 'VISA'
}

export type CreditCardValidation = {
  __typename: 'CreditCardValidation';
  id: Scalars['ID']['output'];
  isValidated: Scalars['Boolean']['output'];
  validationUrl: Maybe<Scalars['String']['output']>;
};

export enum CustomerServiceDepartmentEnum {
  /** Food */
  FOOD = 'FOOD',
  /** Logistics */
  LOGISTICS = 'LOGISTICS',
  /** Marketing */
  MARKETING = 'MARKETING',
  /** Operations */
  OPERATIONS = 'OPERATIONS',
  /** Procurement */
  PROCUREMENT = 'PROCUREMENT',
  /** Tech */
  TECH = 'TECH'
}

export enum CustomerServiceStatusEnum {
  /** Normal */
  NORMAL = 'NORMAL',
  /** Requires Attention */
  REQUIRES_ATTENTION = 'REQUIRES_ATTENTION'
}

export type DashboardFrozenMeal = {
  __typename: 'DashboardFrozenMeal';
  category: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  pgName: Maybe<Scalars['String']['output']>;
  price: Maybe<Scalars['Float']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
};

export type DashboardMealKit = {
  __typename: 'DashboardMealKit';
  category: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** Represents a DashboardUserMenu in the system */
export type DashboardUserMenu = {
  __typename: 'DashboardUserMenu';
  allReductions: Maybe<Scalars['Float']['output']>;
  assignedUserPoints: Maybe<Scalars['Float']['output']>;
  billingDate: Maybe<Scalars['DateTime']['output']>;
  center: Maybe<Coordinate>;
  discounts: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  frozenmeals: Maybe<Array<Maybe<DashboardFrozenMeal>>>;
  grandTotal: Maybe<Scalars['Float']['output']>;
  /** The DashboardUserMenu's ID */
  id: Maybe<Scalars['ID']['output']>;
  mealkits: Maybe<Array<Maybe<DashboardMealKit>>>;
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: PortionSizeEnum;
  region: Maybe<Scalars['String']['output']>;
};

/** DashboardUserMenu filtering options */
export type DashboardUserMenuFilters = {
  dateRange?: InputMaybe<DateRangeFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Properties list object */
export type DashboardUserMenuList = {
  __typename: 'DashboardUserMenuList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DashboardUserMenu records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DashboardUserMenu>>;
  /** Number of DashboardUserMenu records skipped */
  skip: Scalars['Int']['output'];
};

/** DashboardUserMenu ordering options */
export type DashboardUserMenuOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DashboardUserMenuOrderEnum>;
};

/** Fields available to order Properties */
export enum DashboardUserMenuOrderEnum {
  ID = 'ID'
}

export enum DataTypeEnum {
  /** Boolean */
  BOOLEAN = 'BOOLEAN',
  /** Int */
  INT = 'INT',
  /** String */
  STRING = 'STRING'
}

export type DateRangeFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};

/** Fields to Remove Stock */
export type DecreaseStockInput = {
  amount: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['ID']['input'];
  type: StockDecreaseTypeEnum;
  warehouse: Scalars['ID']['input'];
};

export type DeleteRegisteredUserInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a DeletedUser in the system */
export type DeletedUser = {
  __typename: 'DeletedUser';
  /** The creation date of the DeletedUser */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** The DeletedUser's ID */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  /** The update date of the DeletedUser */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['String']['output'];
};

/** DeletedUser filtering options */
export type DeletedUserFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DeletedUserList = {
  __typename: 'DeletedUserList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DeletedUser records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DeletedUser>>;
  /** Number of DeletedUser records skipped */
  skip: Scalars['Int']['output'];
};

/** DeletedUser ordering options */
export type DeletedUserOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeletedUserOrderEnum>;
};

/** Fields available to order Properties */
export enum DeletedUserOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type DeliveryDate = {
  __typename: 'DeliveryDate';
  date: Scalars['DateTime']['output'];
  endTime: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  startTime: Maybe<Scalars['Time']['output']>;
};

export type DeliveryDateInput = {
  date: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
};

export enum DeliveryDayOptionEnum {
  /** MONDAY */
  MONDAY = 'MONDAY',
  /** SUNDAY */
  SUNDAY = 'SUNDAY'
}

export type DeliveryHistoryEvent = {
  note: Maybe<Scalars['String']['output']>;
};

export enum DeliveryHistoryEventType {
  /** Status Changed */
  STATUS_CHANGE = 'STATUS_CHANGE'
}

/** Represents a DeliveryItem in the system */
export type DeliveryItem = {
  __typename: 'DeliveryItem';
  addressString: Scalars['String']['output'];
  center: Coordinate;
  courierBucketTripNumber: Maybe<Scalars['String']['output']>;
  courierDelivery: Maybe<CourierDelivery>;
  courierStatus: Maybe<CourierItemStatusEnum>;
  courierTripSquence: Maybe<Scalars['String']['output']>;
  /** The creation date of the DeliveryItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryItemParcels: Maybe<Array<Maybe<DeliveryItemParcel>>>;
  history: Maybe<Array<Maybe<DeliveryItemHistory>>>;
  /** The DeliveryItem's ID */
  id: Scalars['ID']['output'];
  order: Maybe<Order>;
  orderAddress: Maybe<OrderAddress>;
  placementStatus: PlacementStatusEnum;
  trackingNumber: Maybe<Scalars['String']['output']>;
  /** The update date of the DeliveryItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
};

/** DeliveryItem filtering options */
export type DeliveryItemFilters = {
  courierDeliveryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  courierStatus?: InputMaybe<Array<CourierItemStatusEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  placementStatus?: InputMaybe<Array<PlacementStatusEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a DeliveryItemHistory in the system */
export type DeliveryItemHistory = {
  __typename: 'DeliveryItemHistory';
  /** The creation date of the DeliveryItemHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryItem: Maybe<DeliveryItem>;
  eventData: Scalars['JSON']['output'];
  eventType: ItemHistoryEventTypeEnum;
  /** The DeliveryItemHistory's ID */
  id: Scalars['ID']['output'];
};

/** DeliveryItemHistory filtering options */
export type DeliveryItemHistoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DeliveryItemHistoryList = {
  __typename: 'DeliveryItemHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DeliveryItemHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DeliveryItemHistory>>;
  /** Number of DeliveryItemHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** DeliveryItemHistory ordering options */
export type DeliveryItemHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeliveryItemHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum DeliveryItemHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type DeliveryItemList = {
  __typename: 'DeliveryItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DeliveryItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DeliveryItem>>;
  /** Number of DeliveryItem records skipped */
  skip: Scalars['Int']['output'];
};

/** DeliveryItem ordering options */
export type DeliveryItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeliveryItemOrderEnum>;
};

/** Fields available to order Properties */
export enum DeliveryItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a DeliveryItemParcel in the system */
export type DeliveryItemParcel = {
  __typename: 'DeliveryItemParcel';
  barcodeType: Maybe<BarcodeType>;
  box: Maybe<Box>;
  courierBucketTripNumber: Maybe<Scalars['String']['output']>;
  courierCode: Maybe<Scalars['String']['output']>;
  courierTripSquence: Maybe<Scalars['String']['output']>;
  courierTripType: Maybe<Scalars['String']['output']>;
  /** The creation date of the DeliveryItemParcel */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryItem: Maybe<DeliveryItem>;
  /** The DeliveryItemParcel's ID */
  id: Scalars['ID']['output'];
  orderBox: Maybe<OrderBox>;
  thirdPartyAssignedCourier: Maybe<Courier>;
  thirdPartyTrackingCode: Maybe<Scalars['String']['output']>;
  trackingNumber: Maybe<Scalars['String']['output']>;
  /** The update date of the DeliveryItemParcel */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** DeliveryItemParcel filtering options */
export type DeliveryItemParcelFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DeliveryItemParcelList = {
  __typename: 'DeliveryItemParcelList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DeliveryItemParcel records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DeliveryItemParcel>>;
  /** Number of DeliveryItemParcel records skipped */
  skip: Scalars['Int']['output'];
};

/** DeliveryItemParcel ordering options */
export type DeliveryItemParcelOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeliveryItemParcelOrderEnum>;
};

/** Fields available to order Properties */
export enum DeliveryItemParcelOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type DeliveryItemsStatusTotal = {
  __typename: 'DeliveryItemsStatusTotal';
  status: PlacementStatusEnum;
  total: Maybe<Scalars['Int']['output']>;
};

export type DeliveryItemsTotal = {
  __typename: 'DeliveryItemsTotal';
  statusTotals: Maybe<Array<DeliveryItemsStatusTotal>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type DeliveryReminderSMS = {
  __typename: 'DeliveryReminderSMS';
  dispatchDate: Maybe<Scalars['Date']['output']>;
  sent: Maybe<Scalars['Boolean']['output']>;
};

/** Represents a DeliverySchedule in the system */
export type DeliverySchedule = {
  __typename: 'DeliverySchedule';
  active: Scalars['Boolean']['output'];
  /** The creation date of the DeliverySchedule */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  exclusion: Maybe<Array<Maybe<ScheduleCategoryExclusion>>>;
  /** The DeliverySchedule's ID */
  id: Scalars['ID']['output'];
  inclusion: Maybe<Array<Maybe<ScheduleCategoryInclusion>>>;
  name: Scalars['String']['output'];
  scheduleCron: Scalars['String']['output'];
  targetDeliveryWeekDay: Scalars['Int']['output'];
  /** The update date of the DeliverySchedule */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** DeliverySchedule filtering options */
export type DeliveryScheduleFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DeliveryScheduleList = {
  __typename: 'DeliveryScheduleList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DeliverySchedule records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DeliverySchedule>>;
  /** Number of DeliverySchedule records skipped */
  skip: Scalars['Int']['output'];
};

/** DeliverySchedule ordering options */
export type DeliveryScheduleOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeliveryScheduleOrderEnum>;
};

/** Fields available to order Properties */
export enum DeliveryScheduleOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type DeliveryStatusChangeEvent = DeliveryHistoryEvent & {
  __typename: 'DeliveryStatusChangeEvent';
  newStatus: CourierDeliveryStatusEnum;
  note: Maybe<Scalars['String']['output']>;
  oldStatus: CourierDeliveryStatusEnum;
};

/** Summary of the Delivery */
export type DeliverySummary = {
  __typename: 'DeliverySummary';
  list: Maybe<Array<DeliverySummaryItem>>;
};

/** Summary Item for Delivery */
export type DeliverySummaryItem = {
  __typename: 'DeliverySummaryItem';
  count: Scalars['Int']['output'];
  status: PlacementStatusEnum;
};

export enum DeliveryTagEnum {
  /** Incorrect Delivery */
  INCORRECT_DELIVERY = 'INCORRECT_DELIVERY',
  /** Lost in the system */
  LOST_IN_THE_SYSTEM = 'LOST_IN_THE_SYSTEM',
  /** Stickering Issue */
  STICKERING_ISSUE = 'STICKERING_ISSUE'
}

/** Represents a Device in the system */
export type Device = {
  __typename: 'Device';
  /** The creation date of the Device */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The devices FCM token */
  fcmToken: Maybe<Scalars['String']['output']>;
  /** The Devices ID */
  id: Scalars['ID']['output'];
  /** The Devices type */
  type: RegisteredDeviceTypeEnum;
  /** The update date of the Device */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The devices UserAgent String */
  userAgent: Maybe<Scalars['String']['output']>;
  userCommunicationPlatformData: Maybe<Array<UserCommunicationPlatformData>>;
  userDevices: Array<UserDevice>;
};

/** Device filtering options */
export type DeviceFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeviceInfo = {
  __typename: 'DeviceInfo';
  id: Scalars['ID']['output'];
  isBot: Scalars['Boolean']['output'];
};

/** Properties list object */
export type DeviceList = {
  __typename: 'DeviceList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Device records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Device>>;
  /** Number of Device records skipped */
  skip: Scalars['Int']['output'];
};

/** Device ordering options */
export type DeviceOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DeviceOrderEnum>;
};

/** Fields available to order Properties */
export enum DeviceOrderEnum {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  UPDATED_AT = 'UPDATED_AT'
}

export enum DeviceTypeEnum {
  /** Desktop */
  DESKTOP = 'DESKTOP',
  /** Mobile */
  MOBILE = 'MOBILE',
  /** Tablet */
  TABLET = 'TABLET',
  /** Ultra */
  ULTRA = 'ULTRA'
}

/** Represents a Discount in the system */
export type Discount = {
  __typename: 'Discount';
  active: Scalars['Boolean']['output'];
  acuDiscountType: AcuDiscountTypeEnum;
  allCodes: DiscountCodeList;
  applicationEndDate: Maybe<Scalars['DateTime']['output']>;
  banner: Maybe<DiscountBanner>;
  campaignName: Scalars['String']['output'];
  canStack: Scalars['Boolean']['output'];
  canStackWith: Maybe<Array<Maybe<CanStackWith>>>;
  cannotStackWith: Maybe<Array<Maybe<CannotStackWith>>>;
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDays: Maybe<Array<Maybe<WeekDayEnum>>>;
  discountType: DiscountTypeEnum;
  dropOffDays: Maybe<Scalars['Int']['output']>;
  frequency: Maybe<DiscountFrequencyEnum>;
  /** The Discount's ID */
  id: Scalars['ID']['output'];
  internalDescription: Scalars['String']['output'];
  isAppOnly: Scalars['Boolean']['output'];
  maxFrequencyAmount: Maybe<Scalars['Int']['output']>;
  maxOrderCount: Maybe<Scalars['Int']['output']>;
  maximumAccountUsage: Maybe<Scalars['Int']['output']>;
  maximumSystemUsage: Maybe<Scalars['Int']['output']>;
  mealKitPortionSizes: Maybe<Array<Maybe<PortionSizeEnum>>>;
  minOrderCount: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  needsDiscoveryHealthMeal: Maybe<Scalars['Boolean']['output']>;
  numberOfReapplications: Maybe<Scalars['Int']['output']>;
  orderType: OrderTypeEnum;
  productRanges: Maybe<Array<Maybe<ProductRangeEnum>>>;
  redemptionEndDate: Maybe<Scalars['DateTime']['output']>;
  shouldAutomaticallyReApply: Maybe<Scalars['Boolean']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: DiscountStatusEnum;
  subvalidationIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags: Maybe<Array<Maybe<DiscountTag>>>;
  termsAndConditions: Maybe<DiscountTermsAndConditions>;
  title: Scalars['String']['output'];
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  validationApplication: Maybe<DiscountApplicationTypeEnum>;
  validationId: Maybe<Scalars['String']['output']>;
  validationIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  validationType: DiscountValidationTypeEnum;
  validationValue: Maybe<Scalars['Int']['output']>;
  values: Maybe<Array<DiscountValue>>;
};


/** Represents a Discount in the system */
export type DiscountallCodesArgs = {
  args?: InputMaybe<AllCodesArgs>;
};

export enum DiscountApplicationTypeEnum {
  /** Cart */
  CART = 'CART',
  /** Category */
  CATEGORY = 'CATEGORY',
  /** Product List */
  PRODUCT_LIST = 'PRODUCT_LIST',
  /** Sku */
  SKU = 'SKU'
}

export type DiscountBanner = {
  __typename: 'DiscountBanner';
  ctaLink: Scalars['String']['output'];
  ctaTitle: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DiscountBannerInput = {
  ctaLink: Scalars['String']['input'];
  ctaTitle: Scalars['String']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Represents a DiscountCampaign in the system */
export type DiscountCampaign = {
  __typename: 'DiscountCampaign';
  codePrefix: Scalars['String']['output'];
  /** The creation date of the DiscountCampaign */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discount: Discount;
  discountCampaignCodesFile: Maybe<DiscountCampaignCodesFile>;
  discountCampaignCodesFileStatus: DiscountCampaignCodesFileStatus;
  hasGenerated: Scalars['Boolean']['output'];
  /** The DiscountCampaign's ID */
  id: Scalars['ID']['output'];
  maximumAccountUsage: Maybe<Scalars['Int']['output']>;
  maximumSystemUsage: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  numberOfCodes: Scalars['Int']['output'];
  numberOfUsages: Scalars['Int']['output'];
  shouldAutomaticallyReApply: Scalars['Boolean']['output'];
  uniqueToUser: Scalars['Boolean']['output'];
  /** The update date of the DiscountCampaign */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type DiscountCampaignCodesFile = {
  __typename: 'DiscountCampaignCodesFile';
  archives: Maybe<Archive>;
  campaignId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  status: DiscountCampaignCodesFileStatus;
  updatedAt: Scalars['Date']['output'];
};

export enum DiscountCampaignCodesFileStatus {
  /** Available */
  AVAILABLE = 'AVAILABLE',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Created */
  NOT_CREATED = 'NOT_CREATED',
  /** Uploading */
  UPLOADING = 'UPLOADING'
}

/** DiscountCampaign filtering options */
export type DiscountCampaignFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discounts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  uniqueToUser?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DiscountCampaignList = {
  __typename: 'DiscountCampaignList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DiscountCampaign records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DiscountCampaign>>;
  /** Number of DiscountCampaign records skipped */
  skip: Scalars['Int']['output'];
};

/** DiscountCampaign ordering options */
export type DiscountCampaignOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DiscountCampaignOrderEnum>;
};

/** Fields available to order Properties */
export enum DiscountCampaignOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISCOUNT = 'DISCOUNT',
  NAME = 'NAME',
  UNIQUE_TO_USER = 'UNIQUE_TO_USER',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a DiscountCode in the system */
export type DiscountCode = {
  __typename: 'DiscountCode';
  active: Scalars['Boolean']['output'];
  appliedToCartCount: Scalars['Int']['output'];
  appliedToOrderCount: Scalars['Int']['output'];
  campaign: Maybe<DiscountCampaign>;
  code: Scalars['String']['output'];
  /** The creation date of the DiscountCode */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Discount;
  /** The DiscountCode's ID */
  id: Scalars['ID']['output'];
  maximumAccountUsage: Maybe<Scalars['Int']['output']>;
  maximumSystemUsage: Maybe<Scalars['Int']['output']>;
  shouldAutomaticallyReApply: Scalars['Boolean']['output'];
  /** The update date of the DiscountCode */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  usage: Scalars['Int']['output'];
  userUsage: Maybe<Array<DiscountCodeUserUsage>>;
};


/** Represents a DiscountCode in the system */
export type DiscountCodeuserUsageArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** DiscountCode filtering options */
export type DiscountCodeFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discounts?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  includeCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  onlyCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type DiscountCodeList = {
  __typename: 'DiscountCodeList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DiscountCode records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DiscountCode>>;
  /** Number of DiscountCode records skipped */
  skip: Scalars['Int']['output'];
};

/** DiscountCode ordering options */
export type DiscountCodeOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DiscountCodeOrderEnum>;
};

/** Fields available to order Properties */
export enum DiscountCodeOrderEnum {
  ACTIVE = 'ACTIVE',
  CODE = 'CODE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type DiscountCodeUserUsage = {
  __typename: 'DiscountCodeUserUsage';
  id: Scalars['ID']['output'];
  usage: Scalars['Int']['output'];
  user: User;
};

/** Discount filtering options */
export type DiscountFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<DiscountStatusEnum>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  types?: InputMaybe<Array<DiscountTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DiscountFrequencyEnum {
  /** Monthly */
  MONTHLY = 'MONTHLY',
  /** Weekly */
  WEEKLY = 'WEEKLY'
}

export enum DiscountInvalidReasonEnum {
  /** After Application End Date */
  AFTER_APPLICATION_END_DATE = 'AFTER_APPLICATION_END_DATE',
  /** After Redemption End Date */
  AFTER_REDEMPTION_END_DATE = 'AFTER_REDEMPTION_END_DATE',
  /** Already Applied */
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  /** Before Start Date */
  BEFORE_START_DATE = 'BEFORE_START_DATE',
  /** Cannot Stack */
  CANNOT_STACK = 'CANNOT_STACK',
  /** Code Not Active */
  CODE_NOT_ACTIVE = 'CODE_NOT_ACTIVE',
  /** Code Used More Than Maximum For Account */
  CODE_USED_MORE_THAN_MAXIMUM_FOR_ACCOUNT = 'CODE_USED_MORE_THAN_MAXIMUM_FOR_ACCOUNT',
  /** Code Used More Than Maximum For System */
  CODE_USED_MORE_THAN_MAXIMUM_FOR_SYSTEM = 'CODE_USED_MORE_THAN_MAXIMUM_FOR_SYSTEM',
  /** Does Not Meet Alcohol Requirements */
  DOES_NOT_MEET_ALCOHOL_PREFERENCES = 'DOES_NOT_MEET_ALCOHOL_PREFERENCES',
  /** Does Not Meet Card Payment Requirements */
  DOES_NOT_MEET_CARD_PAYMENT_REQUIREMENTS = 'DOES_NOT_MEET_CARD_PAYMENT_REQUIREMENTS',
  /** Does Not Meet Delivery Day Requirements */
  DOES_NOT_MEET_DELIVERY_DAY_REQUIREMENTS = 'DOES_NOT_MEET_DELIVERY_DAY_REQUIREMENTS',
  /** Does Not Meet Discovery Health Requirements */
  DOES_NOT_MEET_DISCOVERY_HEALTHY_DINING = 'DOES_NOT_MEET_DISCOVERY_HEALTHY_DINING',
  /** Does Not Meet Dropoff Requirements */
  DOES_NOT_MEET_DROPOFF_REQUIREMENTS = 'DOES_NOT_MEET_DROPOFF_REQUIREMENTS',
  /** Does Not Meet Maximum Order Requirements */
  DOES_NOT_MEET_MAXIMUM_ORDER_REQUIREMENTS = 'DOES_NOT_MEET_MAXIMUM_ORDER_REQUIREMENTS',
  /** Does Not Meet Minimum Order Requirements */
  DOES_NOT_MEET_MINIMUM_ORDER_REQUIREMENTS = 'DOES_NOT_MEET_MINIMUM_ORDER_REQUIREMENTS',
  /** Does Not Meet Potion Size Requirements */
  DOES_NOT_MEET_PORTION_SIZE_REQUIREMENTS = 'DOES_NOT_MEET_PORTION_SIZE_REQUIREMENTS',
  /** Does Not Meet Product Quantity Requirements */
  DOES_NOT_MEET_PRODUCT_QUANTITY_REQUIREMENTS = 'DOES_NOT_MEET_PRODUCT_QUANTITY_REQUIREMENTS',
  /** Does Not Meet Rand Value Requirements */
  DOES_NOT_MEET_RAND_VALUE_REQUIREMENTS = 'DOES_NOT_MEET_RAND_VALUE_REQUIREMENTS',
  /** Incorrect Order Type */
  INCORRECT_ORDER_TYPE = 'INCORRECT_ORDER_TYPE',
  /** Legacy Unknown */
  LEGACY_UNKNOWN = 'LEGACY_UNKNOWN',
  /** Maximum Number Of Reapplications */
  MAXIMUM_NUMBER_OF_REAPPLICATIONS = 'MAXIMUM_NUMBER_OF_REAPPLICATIONS',
  /** Not Active */
  NOT_ACTIVE = 'NOT_ACTIVE',
  /** Unique To User Code Already Applied */
  UNIQUE_TO_USER_CODE_ALREADY_APPLIED = 'UNIQUE_TO_USER_CODE_ALREADY_APPLIED'
}

/** Properties list object */
export type DiscountList = {
  __typename: 'DiscountList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Discount records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Discount>>;
  /** Number of Discount records skipped */
  skip: Scalars['Int']['output'];
};

/** Discount ordering options */
export type DiscountOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DiscountOrderEnum>;
};

/** Fields available to order Properties */
export enum DiscountOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum DiscountStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Invalid */
  INVALID = 'INVALID',
  /** Valid */
  VALID = 'VALID'
}

/** Represents a DiscountTag in the system */
export type DiscountTag = {
  __typename: 'DiscountTag';
  /** The creation date of the DiscountTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discount: Discount;
  /** The DiscountTag's ID */
  id: Scalars['ID']['output'];
  tagLevel: DiscountTagLevelEnum;
  title: Scalars['String']['output'];
  /** The update date of the DiscountTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** DiscountTag filtering options */
export type DiscountTagFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagLevel?: InputMaybe<DiscountTagLevelEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Tag Levels for discounts */
export enum DiscountTagLevelEnum {
  /** Objective */
  OBJECTIVE = 'OBJECTIVE',
  /** Primary */
  PRIMARY = 'PRIMARY',
  /** Secondary */
  SECONDARY = 'SECONDARY'
}

/** Properties list object */
export type DiscountTagList = {
  __typename: 'DiscountTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DiscountTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DiscountTag>>;
  /** Number of DiscountTag records skipped */
  skip: Scalars['Int']['output'];
};

/** DiscountTag ordering options */
export type DiscountTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DiscountTagOrderEnum>;
};

/** Fields available to order Properties */
export enum DiscountTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export type DiscountTermsAndConditions = {
  __typename: 'DiscountTermsAndConditions';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DiscountTermsAndConditionsInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum DiscountTypeEnum {
  /** Automatic */
  AUTOMATIC = 'AUTOMATIC',
  /** Coupon */
  COUPON = 'COUPON'
}

export enum DiscountValidationTypeEnum {
  /** None */
  NONE = 'NONE',
  /** Payment Card */
  PAYMENT_CARD = 'PAYMENT_CARD',
  /** Product Quantity */
  PRODUCT_QUANTITY = 'PRODUCT_QUANTITY',
  /** Rand Value */
  RAND_VALUE = 'RAND_VALUE'
}

/** Represents a DiscountValue in the system */
export type DiscountValue = {
  __typename: 'DiscountValue';
  application: Maybe<DiscountApplicationTypeEnum>;
  /** The creation date of the DiscountValue */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The DiscountValue's ID */
  id: Scalars['ID']['output'];
  orderType: Maybe<OrderTypeEnum>;
  quantity: Maybe<Scalars['Int']['output']>;
  subvalueIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: DiscountValueTypeEnum;
  /** The update date of the DiscountValue */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Maybe<Scalars['Float']['output']>;
  valueId: Maybe<Scalars['String']['output']>;
};

export type DiscountValueInput = {
  application?: InputMaybe<DiscountApplicationTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  subvalueIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: DiscountValueTypeEnum;
  value?: InputMaybe<Scalars['Int']['input']>;
  valueId?: InputMaybe<Scalars['String']['input']>;
};

export enum DiscountValueTypeEnum {
  /** Gift */
  GIFT = 'GIFT',
  /** Percent */
  PERCENT = 'PERCENT',
  /** Rand */
  RAND = 'RAND'
}

export type DiscoveryPartnershipVerifyInput = {
  inputFieldType: HealthyDiningFieldTypeEnum;
  inputFieldValue?: InputMaybe<Scalars['String']['input']>;
  partnershipPlatform: PartnershipPlatformEnum;
  user: Scalars['ID']['input'];
};

export type DiscoveryPartnershipVerifyResponse = {
  __typename: 'DiscoveryPartnershipVerifyResponse';
  message: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum DiscoverySyncStateEnum {
  /** Customer Not Verified */
  CUSTOMER_NOT_VERIFIED = 'CUSTOMER_NOT_VERIFIED',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Not Synced */
  NOT_SYNCED = 'NOT_SYNCED',
  /** Sync Complete */
  SYNC_COMPLETE = 'SYNC_COMPLETE',
  /** Sync Failed */
  SYNC_FAILED = 'SYNC_FAILED'
}

/** Represents a DispatchDay in the system */
export type DispatchDay = {
  __typename: 'DispatchDay';
  boxLogicCount: Scalars['Int']['output'];
  boxLogicRun: Scalars['Boolean']['output'];
  /** The creation date of the DispatchDay */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dispatchDate: Scalars['Date']['output'];
  /** The DispatchDay's ID */
  id: Scalars['ID']['output'];
  menu: Maybe<Menu>;
  orderCount: Scalars['Int']['output'];
  orderIssues: Maybe<LiveReport>;
  orderListAllOnDemandOrders: Maybe<LiveReportGroup>;
  orderListAllSubscriptionOrders: Maybe<LiveReportGroup>;
  orderListInBoxAddOns: Maybe<LiveReportGroup>;
  orderListOutBoxAddOns: Maybe<LiveReportGroup>;
  orderListSubContainerInBoxAddOns: Maybe<LiveReportGroup>;
  orderListSubContainerOutBoxAddOns: Maybe<LiveReportGroup>;
  orderListSubscriptionsWithInBoxAddOns: Maybe<LiveReportGroup>;
  orderListSubscriptionsWithoutInBoxAddOns: Maybe<LiveReportGroup>;
  orderType: OrderTypeEnum;
  reportsStatus: DispatchDayReportsStatusEnum;
  shipmentBreakdown: Maybe<LiveReport>;
  shipmentDetails: Maybe<LiveReport>;
  shipmentsCount: Scalars['Int']['output'];
  shipmentsRun: Scalars['Boolean']['output'];
  shippingConsignment: Maybe<ShippingConsignment>;
  syncRun: Scalars['Boolean']['output'];
  /** The update date of the DispatchDay */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
  waybills: Maybe<Download>;
};

export type DispatchDayBackgroundTask = BackgroundTask & {
  __typename: 'DispatchDayBackgroundTask';
  dispatchDay: DispatchDay;
  downloadType: Maybe<DownloadTypeEnum>;
  id: Maybe<Scalars['ID']['output']>;
  objectType: Scalars['String']['output'];
  reportType: Maybe<LiveReportTypeEnum>;
  updateType: BackgroundTaskUpdateEnum;
};

/** DispatchDay filtering options */
export type DispatchDayFilters = {
  dispatchDate?: InputMaybe<Scalars['Date']['input']>;
  dispatchDateRange?: InputMaybe<DateRangeFilter>;
  /** Only here for generating */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Only here for generating */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderType?: InputMaybe<Array<OrderTypeEnum>>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Within  the last X days, defaults to 7 */
  withinDays?: InputMaybe<Scalars['Int']['input']>;
};

/** Properties list object */
export type DispatchDayList = {
  __typename: 'DispatchDayList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of DispatchDay records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<DispatchDay>>;
  /** Number of DispatchDay records skipped */
  skip: Scalars['Int']['output'];
};

/** DispatchDay ordering options */
export type DispatchDayOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<DispatchDayOrderEnum>;
};

/** Fields available to order Properties */
export enum DispatchDayOrderEnum {
  DISPATCH_DATE = 'DISPATCH_DATE',
  WAREHOUSE = 'WAREHOUSE'
}

export enum DispatchDayReportsStatusEnum {
  /** Generated */
  GENERATED = 'GENERATED',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Generated */
  NOT_GENERATED = 'NOT_GENERATED',
  /** Partially Generated */
  PARTIALLY_GENERATED = 'PARTIALLY_GENERATED'
}

export type Download = {
  __typename: 'Download';
  completion: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  fileLocation: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: DownloadStatusEnum;
};

export enum DownloadStatusEnum {
  /** Generated */
  GENERATED = 'GENERATED',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Generated */
  NOT_GENERATED = 'NOT_GENERATED',
  /** Queued */
  QUEUED = 'QUEUED',
  /** Uploading */
  UPLOADING = 'UPLOADING'
}

export enum DownloadTypeEnum {
  /** Pick and Pack File */
  PICK_AND_PACK_FILE = 'PICK_AND_PACK_FILE',
  /** Waybill Stickers */
  WAYBILL_STICKERS = 'WAYBILL_STICKERS'
}

export type EnumDescription = {
  __typename: 'EnumDescription';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  values: Maybe<Array<Maybe<EnumValue>>>;
};

export type EnumValue = {
  __typename: 'EnumValue';
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EventCostHistoryList = {
  __typename: 'EventCostHistoryList';
  list: Array<EventCostHistoryListItem>;
};

/** Intermediate list item */
export type EventCostHistoryListItem = {
  __typename: 'EventCostHistoryListItem';
  costPerUnit: Maybe<Scalars['Float']['output']>;
  /** The creation date of the ProductWarhouseCostHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  productId: Scalars['ID']['output'];
  productName: Scalars['String']['output'];
  productWarehouseId: Maybe<Scalars['ID']['output']>;
  sku: Scalars['String']['output'];
  syncStatus: EventCostHistoryStatusEnum;
  taxClassName: Maybe<Scalars['String']['output']>;
  taxPercentage: Maybe<Scalars['Float']['output']>;
  /** The update date of the ProductWarhouseCostHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouseId: Scalars['ID']['output'];
  warehouseName: Scalars['String']['output'];
};

export type EventCostHistoryListOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  field?: InputMaybe<EventCostHistoryOrderEnum>;
};

export enum EventCostHistoryOrderEnum {
  COST_PER_UNIT = 'COST_PER_UNIT',
  CREATED_AT = 'CREATED_AT',
  PRODUCT_NAME = 'PRODUCT_NAME',
  SYNC_STATUS = 'SYNC_STATUS',
  TAX_PERCENTAGE = 'TAX_PERCENTAGE',
  UPDATED_AT = 'UPDATED_AT',
  WARHOUSE_NAME = 'WARHOUSE_NAME'
}

export enum EventCostHistoryStatusEnum {
  COMPLETE = 'COMPLETE',
  MISSING_HISTORY_ITEM = 'MISSING_HISTORY_ITEM',
  MISSING_PRODUCT_WAREHOUSE = 'MISSING_PRODUCT_WAREHOUSE'
}

/** Represents a Excel in the system */
export type Excel = File & {
  __typename: 'Excel';
  categories: Maybe<Array<Maybe<FileCategory>>>;
  /** The creation date of the Excel */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  extension: Scalars['String']['output'];
  /** The Excel's ID */
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The update date of the Excel */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Int']['output'];
};

/** Excel filtering options */
export type ExcelFilters = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ExcelList = {
  __typename: 'ExcelList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Excel records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Excel>>;
  /** Number of Excel records skipped */
  skip: Scalars['Int']['output'];
};

/** Excel ordering options */
export type ExcelOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ExcelOrderEnum>;
};

/** Fields available to order Properties */
export enum ExcelOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ExpiredGoodsTagEnum {
  /** Expired chicken */
  EXPIRED_CHICKEN = 'EXPIRED_CHICKEN',
  /** Expired Dairy */
  EXPIRED_DAIRY = 'EXPIRED_DAIRY',
  /** Expired dry goods */
  EXPIRED_DRY_GOODS = 'EXPIRED_DRY_GOODS',
  /** Expired Fish */
  EXPIRED_FISH = 'EXPIRED_FISH',
  /** Expired Pasta */
  EXPIRED_PASTA = 'EXPIRED_PASTA',
  /** Expired pork */
  EXPIRED_PORK = 'EXPIRED_PORK',
  /** Expired red meat */
  EXPIRED_RED_MEAT = 'EXPIRED_RED_MEAT',
  /** Expired Sauces */
  EXPIRED_SAUCES = 'EXPIRED_SAUCES',
  /** Expired veg */
  EXPIRED_VEG = 'EXPIRED_VEG'
}

/** Represents a File in the system */
export type File = {
  categories: Maybe<Array<Maybe<FileCategory>>>;
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  extension: Scalars['String']['output'];
  /** The File's ID */
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a FileCategory in the system */
export type FileCategory = {
  __typename: 'FileCategory';
  /** The creation date of the FileCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  files: Maybe<Array<Maybe<File>>>;
  /** The FileCategory's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the FileCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** FileCategory filtering options */
export type FileCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FileCategoryList = {
  __typename: 'FileCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of FileCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FileCategory>>;
  /** Number of FileCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** FileCategory ordering options */
export type FileCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FileCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum FileCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** File filtering options */
export type FileFilters = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** File Generation Status */
export enum FileGenerationStatus {
  /** Error */
  ERROR = 'ERROR',
  /** Generated */
  GENERATED = 'GENERATED',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Generated */
  NOT_GENERATED = 'NOT_GENERATED'
}

/** Properties list object */
export type FileList = {
  __typename: 'FileList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of File records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<File>>;
  /** Number of File records skipped */
  skip: Scalars['Int']['output'];
};

/** File ordering options */
export type FileOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FileOrderEnum>;
};

/** Fields available to order Properties */
export enum FileOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type FileUploadInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  file: UploadFileInput;
  name: Scalars['String']['input'];
};

export type FlaggedUserMenusReportFile = {
  __typename: 'FlaggedUserMenusReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Represents a Forecast in the system */
export type Forecast = {
  __typename: 'Forecast';
  cartCount: Scalars['Int']['output'];
  deliveryType: CityDeliveryTypeEnum;
  dispatchDate: Scalars['Date']['output'];
  /** The Forecast's ID */
  id: Scalars['ID']['output'];
  warehouse: Warehouse;
  warehouseId: Maybe<Scalars['ID']['output']>;
};

export type ForecastDateRangeFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};

/** Forecast filtering options */
export type ForecastFilters = {
  deliveryTypes?: InputMaybe<Array<CityDeliveryTypeEnum>>;
  dispatchDate?: InputMaybe<Scalars['Date']['input']>;
  dispatchDateRange?: InputMaybe<ForecastDateRangeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type ForecastList = {
  __typename: 'ForecastList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Forecast records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Forecast>>;
  /** Number of Forecast records skipped */
  skip: Scalars['Int']['output'];
};

/** Forecast ordering options */
export type ForecastOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ForecastOrderEnum>;
};

/** Fields available to order Properties */
export enum ForecastOrderEnum {
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DISPATCH_DATE = 'DISPATCH_DATE',
  WAREHOUSE = 'WAREHOUSE'
}

/** Type of Fragility used for packaging purposes */
export enum FragilityEnum {
  /** Brittle */
  BRITTLE = 'BRITTLE',
  /** Egg */
  EGG = 'EGG',
  /** Soft */
  SOFT = 'SOFT',
  /** Standard */
  STANDARD = 'STANDARD',
  /** Strong */
  STRONG = 'STRONG'
}

/** Represents a FriendReference in the system */
export type FriendReference = {
  __typename: 'FriendReference';
  active: Maybe<Scalars['Boolean']['output']>;
  /** The creation date of the FriendReference */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  emailTime: Maybe<Scalars['DateTime']['output']>;
  history: Maybe<Array<Maybe<ReferenceHistory>>>;
  /** The FriendReference's ID */
  id: Scalars['ID']['output'];
  link: Maybe<Scalars['String']['output']>;
  loyaltyTier: Maybe<LoyaltyTier>;
  name: Maybe<Scalars['String']['output']>;
  referralKey: Maybe<Scalars['String']['output']>;
  referredUser: Maybe<User>;
  referredUserName: Maybe<Scalars['String']['output']>;
  status: Maybe<UserReferralStatusEnum>;
  /** The update date of the FriendReference */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

/** FriendReference filtering options */
export type FriendReferenceFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  referredBy?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<UserReferralStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type FriendReferenceList = {
  __typename: 'FriendReferenceList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of FriendReference records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FriendReference>>;
  /** Number of FriendReference records skipped */
  skip: Scalars['Int']['output'];
};

/** FriendReference ordering options */
export type FriendReferenceOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FriendReferenceOrderEnum>;
};

/** Fields available to order Properties */
export enum FriendReferenceOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  REFERRED_USER = 'REFERRED_USER',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT',
  USER = 'USER'
}

/** Represents a FrozenCategory in the system */
export type FrozenCategory = {
  __typename: 'FrozenCategory';
  background: Maybe<CategoryBackgroundEnum>;
  /** The creation date of the FrozenCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The FrozenCategory's ID */
  id: Scalars['ID']['output'];
  longDescription: Maybe<Scalars['String']['output']>;
  pageDataSection: Maybe<PageDataSection>;
  status: FrozenCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the FrozenCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** FrozenCategory filtering options */
export type FrozenCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<FrozenCategoryStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FrozenCategoryList = {
  __typename: 'FrozenCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of FrozenCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FrozenCategory>>;
  /** Number of FrozenCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** FrozenCategory ordering options */
export type FrozenCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum FrozenCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum FrozenCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** Represents a FrozenInstruction in the system */
export type FrozenInstruction = {
  __typename: 'FrozenInstruction';
  /** The creation date of the FrozenInstruction */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The FrozenInstruction's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  index: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** The update date of the FrozenInstruction */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** FrozenInstruction filtering options */
export type FrozenInstructionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FrozenInstructionList = {
  __typename: 'FrozenInstructionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of FrozenInstruction records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FrozenInstruction>>;
  /** Number of FrozenInstruction records skipped */
  skip: Scalars['Int']['output'];
};

/** FrozenInstruction ordering options */
export type FrozenInstructionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenInstructionOrderEnum>;
};

/** Fields available to order Properties */
export enum FrozenInstructionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Frozen in the system */
export type FrozenMeal = Product & {
  __typename: 'FrozenMeal';
  allergens: Array<Allergen>;
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  chef: Maybe<Chef>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  featuredSuppliers: Maybe<Array<Maybe<Supplier>>>;
  frozenCategories: Array<FrozenCategory>;
  frozenInstructions: Array<FrozenInstruction>;
  frozenTags: Array<FrozenTag>;
  group: Maybe<ProductGroup>;
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  nutrition: NutritionalInfo;
  packingCode: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  portionSize: FrozenPortionSizeEnum;
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  subTitle: Scalars['String']['output'];
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

export enum FrozenMealAllergenEnum {
  /** Gluten */
  GLUTEN = 'GLUTEN',
  /** Cows Milk */
  MILK = 'MILK',
  /** Vegetables */
  VEGETABLES = 'VEGETABLES',
  /** Wheat */
  WHEAT = 'WHEAT'
}

/** Represents a FrozenMeal in the system */
export type FrozenMealDish = ProductGroup & {
  __typename: 'FrozenMealDish';
  allergens: Maybe<Array<Allergen>>;
  cartItemIds: Array<Scalars['String']['output']>;
  chef: Maybe<Chef>;
  configurationOptions: Maybe<Array<Maybe<Configuration>>>;
  coverImage: Image;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discoveryHealthyDiningItem: Maybe<Scalars['Boolean']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  featuredSuppliers: Maybe<Array<Supplier>>;
  frozenCategories: Maybe<Array<FrozenCategory>>;
  frozenTags: Maybe<Array<FrozenTag>>;
  groupProducts: Maybe<Array<Maybe<FrozenMeal>>>;
  id: Scalars['ID']['output'];
  ingredients: Maybe<Scalars['String']['output']>;
  isValid: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
  nutrition: Maybe<NutritionalInfo>;
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  quantityInCart: Scalars['Int']['output'];
  related: Array<FrozenMealDish>;
  sentIngredients: Array<Scalars['String']['output']>;
  servingSuggestions: Maybe<Array<Maybe<ServingSuggestion>>>;
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  story: Maybe<Scalars['String']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  type: ProductGroupTypeEnum;
  upSellText: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** FrozenMeal filtering options */
export type FrozenMealDishFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chefs?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  excludeCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  frozenTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FrozenMealDishList = {
  __typename: 'FrozenMealDishList';
  /** The filtering aggregation for FrozenMealDish */
  aggregation: Maybe<ProductAggregation>;
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list: Array<Maybe<FrozenMealDish>>;
  skip: Scalars['Int']['output'];
};

/** FrozenMeal ordering options */
export type FrozenMealDishOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenMealDishOrderEnum>;
};

/** Fields available to order Properties */
export enum FrozenMealDishOrderEnum {
  CHEF = 'CHEF',
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** Frozen filtering options */
export type FrozenMealFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chefs?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  frozenCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  frozenTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FrozenMealList = {
  __typename: 'FrozenMealList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Frozen records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FrozenMeal>>;
  /** Number of Frozen records skipped */
  skip: Scalars['Int']['output'];
};

/** Frozen ordering options */
export type FrozenMealOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenMealOrderEnum>;
};

/** Fields available to order Properties */
export enum FrozenMealOrderEnum {
  CHEF = 'CHEF',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum FrozenPortionSizeEnum {
  /** Serves 4 */
  SERVES_FOUR = 'SERVES_FOUR',
  /** Serves 1 */
  SERVES_ONE = 'SERVES_ONE',
  /** Serves 2 */
  SERVES_TWO = 'SERVES_TWO'
}

/** Represents a FrozenTag in the system */
export type FrozenTag = {
  __typename: 'FrozenTag';
  available: Maybe<Scalars['Boolean']['output']>;
  /** The creation date of the FrozenTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Scalars['Int']['output'];
  icon: Maybe<Image>;
  /** The FrozenTag's ID */
  id: Scalars['ID']['output'];
  pageDataSection: Maybe<PageDataSection>;
  tagType: FrozenTagTypeEnum;
  title: Scalars['String']['output'];
  /** The update date of the FrozenTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** FrozenTag filtering options */
export type FrozenTagFilters = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<FrozenTagTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type FrozenTagList = {
  __typename: 'FrozenTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of FrozenTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<FrozenTag>>;
  /** Number of FrozenTag records skipped */
  skip: Scalars['Int']['output'];
};

/** FrozenTag ordering options */
export type FrozenTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<FrozenTagOrderEnum>;
};

/** Fields available to order Properties */
export enum FrozenTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Frozen Tag Type Enum */
export enum FrozenTagTypeEnum {
  /** Preferences */
  PREFERENCE = 'PREFERENCE'
}

export enum GeoStatusEnum {
  /** Not Supported */
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  /** Supported */
  SUPPORTED = 'SUPPORTED',
  /** Unknown */
  UNKNOWN = 'UNKNOWN'
}

export type GeometryData = {
  __typename: 'GeometryData';
  bounds: GeometryRectangle;
  location: Coordinate;
  placeId: Scalars['String']['output'];
};

export type GeometryInput = {
  bounds: GeometryRectangleInput;
  location: CoordinateInput;
};

export type GeometryRectangle = {
  __typename: 'GeometryRectangle';
  northeast: Coordinate;
  southwest: Coordinate;
};

export type GeometryRectangleInput = {
  northeast: CoordinateInput;
  southwest: CoordinateInput;
};

export enum GeometryStatusEnum {
  /** Available */
  AVAILABLE = 'AVAILABLE',
  /** Not Available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** Unknown */
  UNKNOWN = 'UNKNOWN'
}

/** Represents a Gift in the system */
export type Gift = Product & {
  __typename: 'Gift';
  alcoholic: Maybe<GiftAlcoholStatusEnum>;
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the Gift */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  details: Array<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<ProductImage>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  shortDescription: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the Gift */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

/** Shows Whether Gift Contains Alcohol */
export enum GiftAlcoholStatusEnum {
  /** Alcoholic */
  ALCOHOLIC = 'ALCOHOLIC',
  /** Non-alcoholic */
  NON_ALCOHOLIC = 'NON_ALCOHOLIC',
  /** Undefined */
  UNDEFINED = 'UNDEFINED'
}

/** Gift filtering options */
export type GiftFilters = {
  alcoholic?: InputMaybe<Array<GiftAlcoholStatusEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GiftList = {
  __typename: 'GiftList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Gift records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Gift>>;
  /** Number of Gift records skipped */
  skip: Scalars['Int']['output'];
};

/** Gift ordering options */
export type GiftOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<GiftOrderEnum>;
};

/** Fields available to order Properties */
export enum GiftOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a GuestUser in the system */
export type GuestUser = User & {
  __typename: 'GuestUser';
  activeCart: Cart;
  activeMenu: Maybe<UserMenu>;
  acuRef: Scalars['String']['output'];
  addresses: Maybe<Array<Maybe<UserAddress>>>;
  cart: Maybe<Cart>;
  checkoutStatus: UserCheckoutStatus;
  /** The creation date of the GuestUser */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  csStatus: CustomerServiceStatusEnum;
  currentDevice: Maybe<Device>;
  email: Scalars['Email']['output'];
  emailIsVerified: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  hasDefaultAddress: Maybe<Scalars['Boolean']['output']>;
  /** The GuestUser's ID */
  id: Scalars['ID']['output'];
  isBot: Scalars['Boolean']['output'];
  isOnDemand: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['Phone']['output']>;
  phoneIsVerified: Maybe<Scalars['Boolean']['output']>;
  profileImage: Maybe<Image>;
  status: UserStatusEnum;
  subscriptions: Maybe<Array<Maybe<UserSubscription>>>;
  tests: Maybe<Array<Maybe<AbTest>>>;
  title: Maybe<TitleEnum>;
  /** The update date of the GuestUser */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userDevices: Maybe<Array<Maybe<UserDevice>>>;
  userRoles: Maybe<Array<Maybe<UserRole>>>;
};

/** Fields to hard update an existing Location */
export type HardUpdateLocationInput = {
  area?: InputMaybe<Scalars['ID']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum: BuildingTypeEnum;
  center: CoordinateInput;
  city?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['ID']['input']>;
  fullAddress: Scalars['String']['input'];
  locationName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['ID']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum HealthyDiningFieldTypeEnum {
  /** ID Number */
  ID_NUMBER = 'ID_NUMBER',
  /** Passport Number */
  PASSPORT = 'PASSPORT'
}

/** Represents a Image in the system */
export type Image = File & {
  __typename: 'Image';
  alt: Scalars['String']['output'];
  categories: Maybe<Array<Maybe<FileCategory>>>;
  /** The creation date of the Image */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  encoding: ImageEncodingEnum;
  extension: Scalars['String']['output'];
  hasTransparency: Scalars['Boolean']['output'];
  /** The Image's ID */
  id: Scalars['ID']['output'];
  imageInfo: ImageInfo;
  isDefault: Scalars['Boolean']['output'];
  isVector: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  staticId: Maybe<Scalars['String']['output']>;
  /** The update date of the Image */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  url: Maybe<Scalars['URI']['output']>;
};


/** Represents a Image in the system */
export type ImageurlArgs = {
  encoding?: InputMaybe<ImageEncodingEnum>;
  height?: InputMaybe<Scalars['Int']['input']>;
  scale?: InputMaybe<ImageScaleEnum>;
  transforms?: InputMaybe<Array<InputMaybe<ImageTransform>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export enum ImageEncodingEnum {
  /** JPEG */
  JPEG = 'JPEG',
  /** PNG */
  PNG = 'PNG',
  /** SVG */
  SVG = 'SVG',
  /** WebP */
  WEBP = 'WEBP'
}

/** Image filtering options */
export type ImageFilters = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encoding?: InputMaybe<Array<ImageEncodingEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImageInfo = {
  __typename: 'ImageInfo';
  height: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

/** Properties list object */
export type ImageList = {
  __typename: 'ImageList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Image records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Image>>;
  /** Number of Image records skipped */
  skip: Scalars['Int']['output'];
};

/** Image ordering options */
export type ImageOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ImageOrderEnum>;
};

/** Fields available to order Properties */
export enum ImageOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ImageScaleEnum {
  /** Scale to Contain */
  CONTAIN = 'CONTAIN',
  /** Scale to Cover */
  COVER = 'COVER',
  /** Scale to Fit */
  FIT = 'FIT'
}

export enum ImageSizeEnum {
  /** Large */
  LARGE = 'LARGE',
  /** Medium */
  MEDIUM = 'MEDIUM',
  /** Small */
  SMALL = 'SMALL'
}

export type ImageTransform = {
  type: ImageTransformTypeEnum;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum ImageTransformTypeEnum {
  /** Blur */
  BLUR = 'BLUR',
  /** CMYK Color Space */
  CMYK = 'CMYK',
  /** Gaussian Blur */
  GAUSSIAN_BLUR = 'GAUSSIAN_BLUR',
  /** Grey Scale */
  GREY_SCALE = 'GREY_SCALE',
  /** Opacity */
  OPACITY = 'OPACITY',
  /** Pixelate */
  PIXELATE = 'PIXELATE',
  /** Posterize */
  POSTERIZE = 'POSTERIZE',
  /** Sepia */
  SEPIA = 'SEPIA'
}

export enum ImageVariantEnum {
  /** Normal */
  NORMAL = 'NORMAL',
  /** Portrait */
  PORTRAIT = 'PORTRAIT'
}

export type InactiveMealsReportFile = {
  __typename: 'InactiveMealsReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Fields to Add Stock */
export type IncreaseStockInput = {
  amount: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['ID']['input'];
  type: StockIncreaseTypeEnum;
  warehouse: Scalars['ID']['input'];
};

/** Represents a Ingredient in the system */
export type Ingredient = Product & {
  __typename: 'Ingredient';
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the Ingredient */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  displayTitle: Scalars['String']['output'];
  group: Maybe<ProductGroup>;
  /** The Ingredient's ID */
  id: Scalars['ID']['output'];
  ingredientType: IngredientTypeEnum;
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the Ingredient */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

/** Ingredient filtering options */
export type IngredientFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ingredientType?: InputMaybe<Array<IngredientTypeEnum>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  syncStatus?: InputMaybe<Array<ProductSyncStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type IngredientList = {
  __typename: 'IngredientList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Ingredient records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Ingredient>>;
  /** Number of Ingredient records skipped */
  skip: Scalars['Int']['output'];
};

/** Ingredient ordering options */
export type IngredientOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<IngredientOrderEnum>;
};

/** Fields available to order Properties */
export enum IngredientOrderEnum {
  CREATED_AT = 'CREATED_AT',
  INGREDIENT_TYPE = 'INGREDIENT_TYPE',
  NAME = 'NAME',
  SYNC_STATUS = 'SYNC_STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type IngredientPortionQuantityCartReportFile = {
  __typename: 'IngredientPortionQuantityCartReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type IngredientPortionQuantityOrderReportFile = {
  __typename: 'IngredientPortionQuantityOrderReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type IngredientQuantityCartReportFile = {
  __typename: 'IngredientQuantityCartReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type IngredientQuantityOrderReportFile = {
  __typename: 'IngredientQuantityOrderReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export enum IngredientTagEnum {
  /** Damaged Ingredient */
  DAMAGED_INGREDIENT = 'DAMAGED_INGREDIENT',
  /** Incorrect Ingredient */
  INCORRECT_INGREDIENT = 'INCORRECT_INGREDIENT',
  /** Missing Ingredient */
  MISSING_INGREDIENT = 'MISSING_INGREDIENT'
}

export enum IngredientTypeEnum {
  /** Dairy */
  DAIRY = 'DAIRY',
  /** Dried Fruit/Nuts/Seeds */
  DRIEDFNS = 'DRIEDFNS',
  /** Dry */
  DRY = 'DRY',
  /** Fish */
  FISH = 'FISH',
  /** Freezer Ingredient */
  FREEZERIN = 'FREEZERIN',
  /** Fresh */
  FRESH = 'FRESH',
  /** Grains */
  GRAINS = 'GRAINS',
  /** Liquids */
  LIQUIDS = 'LIQUIDS',
  /** Meat */
  MEAT = 'MEAT',
  /** Mezze */
  MEZZE = 'MEZZE',
  /** Noodles/Pasta */
  NOODLESPAS = 'NOODLESPAS',
  /** Pastes */
  PASTES = 'PASTES',
  /** Poultry */
  POULTRY = 'POULTRY',
  /** Ready-Made */
  READYMADE = 'READYMADE',
  /** Red Meat */
  REDMEAT = 'REDMEAT',
  /** Sauces */
  SAUCES = 'SAUCES',
  /** Spices/Stock */
  SPICESSTOC = 'SPICESSTOC',
  /** Sub Assembly */
  SUB_ASSEMBLY = 'SUB_ASSEMBLY',
  /** Sweeteners */
  SWEETENERS = 'SWEETENERS',
  /** Tinned Products */
  TINNEDPROD = 'TINNEDPROD',
  /** Veg Protein */
  VEGPROTEIN = 'VEGPROTEIN',
  /** Wine */
  WINE = 'WINE'
}

export type IsleProduct = BabyBoxTub | FrozenMeal | FrozenMealDish | Wine;

export type ItemHistoryEventData = {
  note: Maybe<Scalars['String']['output']>;
};

export enum ItemHistoryEventTypeEnum {
  /** Courier Status Change */
  COURIER_STATUS_CHANGE = 'COURIER_STATUS_CHANGE',
  /** Placement Status Change */
  PLACEMENT_STATUS_CHANGE = 'PLACEMENT_STATUS_CHANGE'
}

/** Represents a Lead in the system */
export type Lead = {
  __typename: 'Lead';
  campaign: Maybe<Scalars['String']['output']>;
  /** The converted date of the Lead */
  convertedAt: Maybe<Scalars['DateTime']['output']>;
  /** The creation date of the Lead */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  data: Maybe<Scalars['JSON']['output']>;
  email: Scalars['Email']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  /** The Lead's ID */
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  medium: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['Phone']['output']>;
  referredBy: Maybe<User>;
  referredById: Maybe<Scalars['ID']['output']>;
  source: Maybe<Scalars['String']['output']>;
  status: LeadStatusEnum;
  unsubscribed: Maybe<Scalars['Boolean']['output']>;
  /** The update date of the Lead */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
  userId: Maybe<Scalars['ID']['output']>;
};

/** Lead filtering options */
export type LeadFilters = {
  convertedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxConvertedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minConvertedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<LeadStatusEnum>>>;
  unsubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type LeadList = {
  __typename: 'LeadList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Lead records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Lead>>;
  /** Number of Lead records skipped */
  skip: Scalars['Int']['output'];
};

/** Lead ordering options */
export type LeadOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<LeadOrderEnum>;
};

/** Fields available to order Properties */
export enum LeadOrderEnum {
  CREATED_AT = 'CREATED_AT',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  STATUS = 'STATUS',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  UPDATED_AT = 'UPDATED_AT'
}

/** Lead Status */
export enum LeadStatusEnum {
  /** Converted */
  CONVERTED = 'CONVERTED',
  /** Junk */
  JUNK = 'JUNK',
  /** Unknown */
  UNKNOWN = 'UNKNOWN'
}

/** Represents a LeadUpload in the system */
export type LeadUpload = {
  __typename: 'LeadUpload';
  campaign: Maybe<Scalars['String']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  /** The creation date of the LeadUpload */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  duplicate: Maybe<Scalars['Int']['output']>;
  /** The LeadUpload's ID */
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  invalid: Maybe<Scalars['Int']['output']>;
  leadUploadFile: Maybe<Excel>;
  name: Scalars['String']['output'];
  successful: Maybe<Scalars['Int']['output']>;
  triggered: Maybe<Scalars['Boolean']['output']>;
  /** The update date of the LeadUpload */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** LeadUpload filtering options */
export type LeadUploadFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  triggered?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type LeadUploadList = {
  __typename: 'LeadUploadList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of LeadUpload records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<LeadUpload>>;
  /** Number of LeadUpload records skipped */
  skip: Scalars['Int']['output'];
};

/** LeadUpload ordering options */
export type LeadUploadOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<LeadUploadOrderEnum>;
};

/** Fields available to order Properties */
export enum LeadUploadOrderEnum {
  CAMPAIGN = 'CAMPAIGN',
  COUNT = 'COUNT',
  CREATED_AT = 'CREATED_AT',
  DUPLICATE = 'DUPLICATE',
  IDENTIFIER = 'IDENTIFIER',
  INVALID = 'INVALID',
  NAME = 'NAME',
  SUCCESSFUL = 'SUCCESSFUL',
  TRIGGERED = 'TRIGGERED',
  UPDATED_AT = 'UPDATED_AT'
}

/** Temporary item */
export type LegacyCartCostItem = CartCostItem & {
  __typename: 'LegacyCartCostItem';
  canBeRemoved: Scalars['Boolean']['output'];
  id: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type LiveReport = {
  completion: Scalars['Int']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: LiveReportStatusEnum;
};

export type LiveReportColumn = {
  __typename: 'LiveReportColumn';
  dataType: LiveReportDataTypeEnum;
  enumType: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum LiveReportDataTypeEnum {
  /** Boolean */
  BOOLEAN = 'BOOLEAN',
  /** Date */
  DATE = 'DATE',
  /** Enum */
  ENUM = 'ENUM',
  /** Float */
  FLOAT = 'FLOAT',
  /** Integer */
  INT = 'INT',
  /** Link */
  LINK = 'LINK',
  /** String */
  STRING = 'STRING'
}

export enum LiveReportFormat {
  /** Spreadsheet */
  SPREADSHEET = 'SPREADSHEET'
}

export type LiveReportGroup = {
  __typename: 'LiveReportGroup';
  completion: Scalars['Int']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reports: Array<LiveReport>;
  status: LiveReportStatusEnum;
};

export enum LiveReportStatusEnum {
  /** Generated */
  GENERATED = 'GENERATED',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Generated */
  NOT_GENERATED = 'NOT_GENERATED',
  /** Queued */
  QUEUED = 'QUEUED'
}

export type LiveReportTab = {
  __typename: 'LiveReportTab';
  columns: Array<LiveReportColumn>;
  data: Array<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export enum LiveReportTypeEnum {
  /** Dispatch Day Shippers */
  DISPATCH_DAY_SHIPPERS = 'DISPATCH_DAY_SHIPPERS',
  /** Menu Order Issues */
  MENU_ORDER_ISSUES = 'MENU_ORDER_ISSUES',
  /** Menu Shipment Breakdown */
  MENU_SHIPMENT_BREAKDOWN = 'MENU_SHIPMENT_BREAKDOWN',
  /** Order List All On-Demand Orders */
  ORDER_LIST_ALL_ON_DEMAND_ORDERS = 'ORDER_LIST_ALL_ON_DEMAND_ORDERS',
  /** Order List All Subscription Orders */
  ORDER_LIST_ALL_SUBSCRIPTION_ORDERS = 'ORDER_LIST_ALL_SUBSCRIPTION_ORDERS',
  /** Order List In-Box Add Ons */
  ORDER_LIST_IN_BOX_ADD_ONS = 'ORDER_LIST_IN_BOX_ADD_ONS',
  /** Order List Out-Box Add Ons */
  ORDER_LIST_OUT_BOX_ADD_ONS = 'ORDER_LIST_OUT_BOX_ADD_ONS',
  /** Order List Subscription Without In-Box Add Ons */
  ORDER_LIST_SUBSCRIPTION_WITHOUT_IN_BOX_ADD_ONS = 'ORDER_LIST_SUBSCRIPTION_WITHOUT_IN_BOX_ADD_ONS',
  /** Order List Subscription With In-Box Add Ons */
  ORDER_LIST_SUBSCRIPTION_WITH_IN_BOX_ADD_ONS = 'ORDER_LIST_SUBSCRIPTION_WITH_IN_BOX_ADD_ONS',
  /** Order List Sub Container In-Box Add Ons */
  ORDER_LIST_SUB_CONTAINER_IN_BOX_ADD_ONS = 'ORDER_LIST_SUB_CONTAINER_IN_BOX_ADD_ONS',
  /** Order List Sub Container Out-Box Add Ons */
  ORDER_LIST_SUB_CONTAINER_OUT_BOX_ADD_ONS = 'ORDER_LIST_SUB_CONTAINER_OUT_BOX_ADD_ONS'
}

export type LiveSpreadsheetReport = LiveReport & {
  __typename: 'LiveSpreadsheetReport';
  completion: Scalars['Int']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: LiveReportStatusEnum;
  tabs: Array<LiveReportTab>;
};

export type LocalCheckout = {
  __typename: 'LocalCheckout';
  cartErrors: Maybe<Scalars['JSONObject']['output']>;
  cartItems: Maybe<Array<Maybe<Scalars['JSONObject']['output']>>>;
  creditCardId: Maybe<Scalars['String']['output']>;
  dispatchDate: Maybe<Scalars['String']['output']>;
  forceSubscription: Maybe<Scalars['Boolean']['output']>;
  forcedStep: Maybe<CheckoutStepEnum>;
  hasSeenAbout: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  open: Scalars['Boolean']['output'];
  orderId: Maybe<Scalars['String']['output']>;
  shouldShowAbout: Scalars['Boolean']['output'];
  shouldShowModal: Scalars['Boolean']['output'];
  step: CheckoutStepEnum;
  type: CheckoutTypeEnum;
  userAddressId: Maybe<Scalars['String']['output']>;
};

export type LocalTrackingData = {
  __typename: 'LocalTrackingData';
  admarulaUTMTracker: Maybe<Scalars['String']['output']>;
  facebookBrowserId: Maybe<Scalars['String']['output']>;
  facebookClickId: Maybe<Scalars['String']['output']>;
  googleClickId: Maybe<Scalars['String']['output']>;
  googleSessionId: Maybe<Scalars['Int']['output']>;
  instagramClickId: Maybe<Scalars['String']['output']>;
  referrerUrl: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
  tikTokClickId: Maybe<Scalars['String']['output']>;
};

/** Represents a Location in the system */
export type Location = LocationInterface & {
  __typename: 'Location';
  address: Maybe<Scalars['String']['output']>;
  area: Maybe<Area>;
  buildingName: Maybe<Scalars['String']['output']>;
  buildingTypeEnum: Maybe<BuildingTypeEnum>;
  center: Coordinate;
  city: Maybe<City>;
  country: Country;
  /** The creation date of the Location */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  fullAddress: Scalars['String']['output'];
  /** The Location's ID */
  id: Scalars['ID']['output'];
  isIncomplete: Scalars['Boolean']['output'];
  issues: Array<LocationIssuesEnum>;
  legacyAddress: Maybe<Scalars['String']['output']>;
  locationName: Maybe<Scalars['String']['output']>;
  mdsAddressId: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  needsAttention: Scalars['Boolean']['output'];
  parentCity: Maybe<City>;
  parentRegion: Region;
  placeId: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  region: Region;
  shortId: Maybe<Scalars['String']['output']>;
  status: LocationStatusEnum;
  streetName: Maybe<Scalars['String']['output']>;
  streetNumber: Maybe<Scalars['String']['output']>;
  types: Maybe<Array<Scalars['String']['output']>>;
  unitNumber: Maybe<Scalars['String']['output']>;
  /** The update date of the Location */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Location filtering options */
export type LocationFilters = {
  areas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  cities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  countries?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  needsAttention?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  parentCities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<LocationStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LocationInterface = {
  address: Maybe<Scalars['String']['output']>;
  area: Maybe<Area>;
  buildingName: Maybe<Scalars['String']['output']>;
  buildingTypeEnum: Maybe<BuildingTypeEnum>;
  center: Maybe<Coordinate>;
  city: Maybe<City>;
  country: Country;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  fullAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mdsAddressId: Maybe<Scalars['Int']['output']>;
  parentCity: Maybe<City>;
  parentRegion: Region;
  placeId: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  region: Region;
  shortId: Maybe<Scalars['String']['output']>;
  streetName: Maybe<Scalars['String']['output']>;
  streetNumber: Maybe<Scalars['String']['output']>;
  unitNumber: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum LocationIssuesEnum {
  /** Incomplete */
  INCOMPLETE = 'INCOMPLETE',
  /** Invalid City */
  INVALID_CITY = 'INVALID_CITY',
  /** Not Geocoded */
  NOT_GEOCODED = 'NOT_GEOCODED',
  /** No City */
  NO_CITY = 'NO_CITY',
  /** No Postal Code */
  NO_POSTAL_CODE = 'NO_POSTAL_CODE',
  /** No Street Name */
  NO_STREET_NAME = 'NO_STREET_NAME'
}

/** Properties list object */
export type LocationList = {
  __typename: 'LocationList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Location records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Location>>;
  /** Number of Location records skipped */
  skip: Scalars['Int']['output'];
};

export type LocationLookup = {
  __typename: 'LocationLookup';
  fullAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationName: Maybe<Scalars['String']['output']>;
  types: Maybe<Array<Scalars['String']['output']>>;
};

/** Location ordering options */
export type LocationOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<LocationOrderEnum>;
};

/** Fields available to order Properties */
export enum LocationOrderEnum {
  ADDRESS = 'ADDRESS',
  BUILDING_NAME = 'BUILDING_NAME',
  BUILDING_TYPE_ENUM = 'BUILDING_TYPE_ENUM',
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PARENT_CITIES = 'PARENT_CITIES',
  PARENT_REGIONS = 'PARENT_REGIONS',
  POSTAL_CODE = 'POSTAL_CODE',
  REGIONS = 'REGIONS',
  UNIT_NUMBER = 'UNIT_NUMBER',
  UPDATED_AT = 'UPDATED_AT'
}

export enum LocationStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** Represents a LoyaltyTier in the system */
export type LoyaltyTier = {
  __typename: 'LoyaltyTier';
  /** The creation date of the LoyaltyTier */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discount: Maybe<Discount>;
  friendReferences: Maybe<Array<Maybe<FriendReference>>>;
  /** The LoyaltyTier's ID */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nextLoyaltyTier: Maybe<LoyaltyTier>;
  orderCount: Scalars['Int']['output'];
  referrerPoints: Maybe<Scalars['Int']['output']>;
  /** The update date of the LoyaltyTier */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** LoyaltyTier filtering options */
export type LoyaltyTierFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  orderCount?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type LoyaltyTierList = {
  __typename: 'LoyaltyTierList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of LoyaltyTier records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<LoyaltyTier>>;
  /** Number of LoyaltyTier records skipped */
  skip: Scalars['Int']['output'];
};

/** LoyaltyTier ordering options */
export type LoyaltyTierOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<LoyaltyTierOrderEnum>;
};

/** Fields available to order Properties */
export enum LoyaltyTierOrderEnum {
  CREATED_AT = 'CREATED_AT',
  IS_ACTIVE = 'IS_ACTIVE',
  NAME = 'NAME',
  ORDER_COUNT = 'ORDER_COUNT',
  UPDATED_AT = 'UPDATED_AT'
}

export type MainNavContainer = NavItem & {
  __typename: 'MainNavContainer';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isExternalNavigation: Maybe<Scalars['Boolean']['output']>;
  items: Array<SubNavItem>;
  open: Scalars['Boolean']['output'];
  pillTitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type MainNavItem = NavItem & {
  __typename: 'MainNavItem';
  active: Scalars['Boolean']['output'];
  exactPath: Scalars['Boolean']['output'];
  href: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isExternalNavigation: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
  pillTitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type MainNavMultiItemContainer = NavItem & {
  __typename: 'MainNavMultiItemContainer';
  active: Scalars['Boolean']['output'];
  href: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  open: Scalars['Boolean']['output'];
  path: Scalars['String']['output'];
  pillTitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Represents a ManualEmail in the system */
export type ManualEmail = {
  __typename: 'ManualEmail';
  /** The creation date of the ManualEmail */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emailType: ManualEmailEnum;
  /** The ManualEmail's ID */
  id: Scalars['ID']['output'];
  /** The update date of the ManualEmail */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum ManualEmailEnum {
  /** Billing Recommendation */
  BILLING_RECOMMENDATION = 'BILLING_RECOMMENDATION',
  /** Billing Reminder */
  BILLING_REMINDER = 'BILLING_REMINDER'
}

/** ManualEmail filtering options */
export type ManualEmailFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  emailType?: InputMaybe<Array<ManualEmailEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ManualEmailList = {
  __typename: 'ManualEmailList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ManualEmail records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ManualEmail>>;
  /** Number of ManualEmail records skipped */
  skip: Scalars['Int']['output'];
};

/** ManualEmail ordering options */
export type ManualEmailOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ManualEmailOrderEnum>;
};

/** Fields available to order Properties */
export enum ManualEmailOrderEnum {
  CREATED_AT = 'CREATED_AT',
  EMAIL_TYPE = 'EMAIL_TYPE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MarketProduct in the system */
export type MarketProduct = Product & {
  __typename: 'MarketProduct';
  allergens: Array<Allergen>;
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  boxRecipeCard: Maybe<Pdf>;
  brand: Maybe<MarketProductBrand>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the MarketProduct */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  group: Maybe<ProductGroup>;
  /** The MarketProduct's ID */
  id: Scalars['ID']['output'];
  images: Array<Image>;
  ingredients: Maybe<Array<ProductIngredient>>;
  isOccasionBox: Scalars['Boolean']['output'];
  isWeekendBox: Scalars['Boolean']['output'];
  marketProductCategories: Array<MarketProductCategory>;
  marketProductTags: Array<MarketProductTag>;
  name: Scalars['String']['output'];
  origin: Maybe<Scalars['String']['output']>;
  packageIngredients: Array<Scalars['String']['output']>;
  packingCode: Maybe<Scalars['String']['output']>;
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  related: Array<MarketProduct>;
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  shortDescription: Maybe<Scalars['String']['output']>;
  size: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageInstructions: Maybe<Scalars['String']['output']>;
  storageTemp: ProductStorageTempEnum;
  subCategory: Maybe<MarketProductSubCategory>;
  syncStatus: ProductSyncStatusEnum;
  tags: Array<MarketProductTag>;
  taxCategory: TaxCategoryTypeEnum;
  upSellText: Maybe<Scalars['String']['output']>;
  /** The update date of the MarketProduct */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
  warning: Maybe<Scalars['String']['output']>;
};

/** Represents a MarketProductBrand in the system */
export type MarketProductBrand = {
  __typename: 'MarketProductBrand';
  /** The creation date of the MarketProductBrand */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The MarketProductBrand's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  name: Scalars['String']['output'];
  products: Array<MarketProduct>;
  /** The update date of the MarketProductBrand */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MarketProductBrand filtering options */
export type MarketProductBrandFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  subCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MarketProductBrandList = {
  __typename: 'MarketProductBrandList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MarketProductBrand records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MarketProductBrand>>;
  /** Number of MarketProductBrand records skipped */
  skip: Scalars['Int']['output'];
};

/** MarketProductBrand ordering options */
export type MarketProductBrandOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MarketProductBrandOrderEnum>;
};

/** Fields available to order Properties */
export enum MarketProductBrandOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MarketProductCategory in the system */
export type MarketProductCategory = {
  __typename: 'MarketProductCategory';
  /** The creation date of the MarketProductCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  /** The MarketProductCategory's ID */
  id: Scalars['ID']['output'];
  marketProducts: Array<MarketProduct>;
  pageDataSection: Maybe<Scalars['ID']['output']>;
  slug: Scalars['String']['output'];
  status: MarketProductCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the MarketProductCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};


/** Represents a MarketProductCategory in the system */
export type MarketProductCategorymarketProductsArgs = {
  filters?: InputMaybe<MarketProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** MarketProductCategory filtering options */
export type MarketProductCategoryFilters = {
  brands?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketProductCategoryStatusEnum>;
  subCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MarketProductCategoryList = {
  __typename: 'MarketProductCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MarketProductCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MarketProductCategory>>;
  /** Number of MarketProductCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** MarketProductCategory ordering options */
export type MarketProductCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MarketProductCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum MarketProductCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum MarketProductCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** MarketProduct filtering options */
export type MarketProductFilters = {
  brands?: InputMaybe<Array<Scalars['ID']['input']>>;
  cities?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isOccasionBox?: InputMaybe<Scalars['Boolean']['input']>;
  isWeekendBox?: InputMaybe<Scalars['Boolean']['input']>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketProductTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MarketProductIncludeExcludeType = {
  excludes?: InputMaybe<Array<Scalars['ID']['input']>>;
  includes?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type MarketProductList = {
  __typename: 'MarketProductList';
  /** The filtering aggregation for MarketProduct */
  aggregation: Maybe<ProductAggregation>;
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MarketProduct records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MarketProduct>>;
  /** Number of MarketProduct records skipped */
  skip: Scalars['Int']['output'];
};

/** MarketProduct ordering options */
export type MarketProductOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MarketProductOrderEnum>;
};

/** Fields available to order Properties */
export enum MarketProductOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MarketProductSubCategory in the system */
export type MarketProductSubCategory = {
  __typename: 'MarketProductSubCategory';
  /** The creation date of the MarketProductSubCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The MarketProductSubCategory's ID */
  id: Scalars['ID']['output'];
  products: Array<MarketProduct>;
  status: MarketProductSubCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the MarketProductSubCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MarketProductSubCategory filtering options */
export type MarketProductSubCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MarketProductSubCategoryList = {
  __typename: 'MarketProductSubCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MarketProductSubCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MarketProductSubCategory>>;
  /** Number of MarketProductSubCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** MarketProductSubCategory ordering options */
export type MarketProductSubCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MarketProductSubCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum MarketProductSubCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum MarketProductSubCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** Represents a MarketProductTag in the system */
export type MarketProductTag = {
  __typename: 'MarketProductTag';
  /** The creation date of the MarketProductTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Scalars['Int']['output'];
  icon: Maybe<Image>;
  /** The MarketProductTag's ID */
  id: Scalars['ID']['output'];
  pageDataSection: Maybe<Scalars['ID']['output']>;
  tagType: MarketProductTagTypeEnum;
  title: Scalars['String']['output'];
  /** The update date of the MarketProductTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MarketProductTag filtering options */
export type MarketProductTagFilters = {
  brands?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  subCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagType?: InputMaybe<Array<MarketProductTagTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MarketProductTagList = {
  __typename: 'MarketProductTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MarketProductTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MarketProductTag>>;
  /** Number of MarketProductTag records skipped */
  skip: Scalars['Int']['output'];
};

/** MarketProductTag ordering options */
export type MarketProductTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MarketProductTagOrderEnum>;
};

/** Fields available to order Properties */
export enum MarketProductTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** MarketProductTag Type Enum */
export enum MarketProductTagTypeEnum {
  /** Preference */
  PREFERENCE = 'PREFERENCE'
}

export type MasterOrderReport = {
  __typename: 'MasterOrderReport';
  count: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  list: Array<MasterOrderReportItem>;
  skip: Scalars['Int']['output'];
};

export enum MasterOrderReportFieldEnum {
  ACU_WAREHOUSE_CODE = 'ACU_WAREHOUSE_CODE',
  AREA_NAME = 'AREA_NAME',
  BILLING_FAILED = 'BILLING_FAILED',
  CITY_NAME = 'CITY_NAME',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DISPATCH_DATE = 'DISPATCH_DATE',
  FULL_ADDRESS = 'FULL_ADDRESS',
  HAS_PAID_FULL_AMOUNT = 'HAS_PAID_FULL_AMOUNT',
  IS_ORDER_CANCELLED = 'IS_ORDER_CANCELLED',
  ORDER_DARE = 'ORDER_DARE',
  ORDER_TYPE = 'ORDER_TYPE',
  PHONE = 'PHONE',
  REGION_NAME = 'REGION_NAME',
  SHIPPER_NAME = 'SHIPPER_NAME',
  SHORT_ID_NUMBER = 'SHORT_ID_NUMBER',
  SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS',
  USER_EMAIL = 'USER_EMAIL',
  USER_NAME = 'USER_NAME',
  WAREHOUSE = 'WAREHOUSE'
}

export type MasterOrderReportFilters = {
  areaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  billingFailed?: InputMaybe<Scalars['Boolean']['input']>;
  cityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  deliveryTypes?: InputMaybe<Array<CityDeliveryTypeEnum>>;
  dispatchDateFrom?: InputMaybe<Scalars['Date']['input']>;
  dispatchDateTo?: InputMaybe<Scalars['Date']['input']>;
  hasPaidFullAmount?: InputMaybe<Scalars['Boolean']['input']>;
  isOrderCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  orderDateFrom?: InputMaybe<Scalars['Date']['input']>;
  orderDateTo?: InputMaybe<Scalars['Date']['input']>;
  orderTypes?: InputMaybe<Array<OrderTypeEnum>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shipperIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  subscriptionStatuses?: InputMaybe<Array<UserSubscriptionStatusEnum>>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MasterOrderReportItem = {
  __typename: 'MasterOrderReportItem';
  acuWarehouseCode: Maybe<Scalars['String']['output']>;
  areaName: Maybe<Scalars['String']['output']>;
  billingFailed: Maybe<Scalars['Boolean']['output']>;
  cityName: Maybe<Scalars['String']['output']>;
  deliveryType: Maybe<CityDeliveryTypeEnum>;
  dispatchDate: Maybe<Scalars['Date']['output']>;
  fullAddress: Maybe<Scalars['String']['output']>;
  hasPaidFullAmount: Maybe<Scalars['Boolean']['output']>;
  isOrderCancelled: Maybe<Scalars['Boolean']['output']>;
  orderDate: Maybe<Scalars['Date']['output']>;
  orderShortId: Scalars['String']['output'];
  orderType: OrderTypeEnum;
  phone: Scalars['String']['output'];
  regionName: Maybe<Scalars['String']['output']>;
  shipperName: Maybe<Scalars['String']['output']>;
  subscriptionStatus: UserSubscriptionStatusEnum;
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
  warehouse: Maybe<Scalars['String']['output']>;
};

export type MasterOrderReportOrder = {
  direction?: InputMaybe<OrderDirectionEnum>;
  field?: InputMaybe<MasterOrderReportFieldEnum>;
};

/** Represents a MealKit in the system */
export type MealKit = Product & {
  __typename: 'MealKit';
  activeParings: Maybe<Array<MenuDishWine>>;
  allergens: Array<Allergen>;
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  chef: Maybe<Chef>;
  chefsTips: Array<Scalars['String']['output']>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  cookWithin: Scalars['Int']['output'];
  coverImage: Image;
  /** The creation date of the MealKit */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  featuredSuppliers: Maybe<Array<Maybe<Supplier>>>;
  flags: Maybe<Array<MealKitFlagEnum>>;
  fromYourKitchen: Array<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  handsOnTime: Scalars['Int']['output'];
  /** The MealKit's ID */
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  instructions: Array<MealKitInstruction>;
  mealKitCategories: Array<MealKitCategory>;
  name: Scalars['String']['output'];
  nutrition: NutritionalInfo;
  overallTime: Scalars['Int']['output'];
  packingVolume: Scalars['Float']['output'];
  portionSize: PortionSizeEnum;
  prepStation: Array<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  recipeCard: Maybe<Pdf>;
  recipeCardError: Maybe<Scalars['String']['output']>;
  recipeCardStatus: BackgroundTaskStatusEnum;
  recipeCardWithCropMarks: Maybe<Pdf>;
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  subTitle: Scalars['String']['output'];
  syncStatus: ProductSyncStatusEnum;
  tags: Array<MealKitTag>;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the MealKit */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

/** Represents a MealKitCategory in the system */
export type MealKitCategory = {
  __typename: 'MealKitCategory';
  background: Maybe<CategoryBackgroundEnum>;
  canSubscribe: Maybe<Scalars['Boolean']['output']>;
  costClass: MealKitCostClass;
  /** The creation date of the MealKitCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  /** The MealKitCategory's ID */
  id: Scalars['ID']['output'];
  longDescription: Maybe<Scalars['String']['output']>;
  maxMealCount: Scalars['Int']['output'];
  minMealCount: Scalars['Int']['output'];
  pageDataSection: Maybe<PageDataSection>;
  pricing: Maybe<Array<Maybe<MealKitCategoryPrice>>>;
  status: MealKitCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the MealKitCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MealKitCategory filtering options */
export type MealKitCategoryFilters = {
  canSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<MealKitCategoryStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitCategoryList = {
  __typename: 'MealKitCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKitCategory>>;
  /** Number of MealKitCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitCategory ordering options */
export type MealKitCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export type MealKitCategoryPrice = {
  __typename: 'MealKitCategoryPrice';
  id: Scalars['String']['output'];
  maxPrice: Scalars['Float']['output'];
  maxPricePerPortion: Scalars['Float']['output'];
  minPrice: Scalars['Float']['output'];
  minPricePerPortion: Scalars['Float']['output'];
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: NumberOfPortionsEnum;
};

export enum MealKitCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE',
  /** Staged */
  STAGED = 'STAGED'
}

export enum MealKitClassification {
  /** Premium */
  PREMIUM = 'PREMIUM',
  /** Saver */
  SAVER = 'SAVER',
  /** Standard */
  STANDARD = 'STANDARD'
}

export enum MealKitCostClass {
  /** Premium */
  PREMIUM = 'PREMIUM',
  /** Saver */
  SAVER = 'SAVER',
  /** Standard */
  STANDARD = 'STANDARD'
}

/** Represents a MealKitDefaultPricing in the system */
export type MealKitDefaultPricing = {
  __typename: 'MealKitDefaultPricing';
  category: MealKitCategory;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The MealKitDefaultPricing's ID */
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  /** The creation date of the MealKitDefaultPricing */
  servingSize: MealKitServingSizeEnum;
  /** The update date of the MealKitDefaultPricing */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MealKitDefaultPricing filtering options */
export type MealKitDefaultPricingFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitDefaultPricingList = {
  __typename: 'MealKitDefaultPricingList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitDefaultPricing records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<MealKitDefaultPricing>;
  /** Number of MealKitDefaultPricing records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitDefaultPricing ordering options */
export type MealKitDefaultPricingOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitDefaultPricingOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitDefaultPricingOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export enum MealKitDeliveryOptionEnum {
  /** Monday 09:00 - 18:00 */
  MONDAY = 'MONDAY',
  /** Sunday 11:00 - 18:00 */
  SUNDAY = 'SUNDAY'
}

/** Represents a MealKitDish in the system */
export type MealKitDish = ProductGroup & {
  __typename: 'MealKitDish';
  activeParings: Maybe<Array<MenuDishWine>>;
  allergens: Maybe<Array<Allergen>>;
  availableUpSellWines: Maybe<Array<Maybe<Wine>>>;
  cartItemIds: Array<Scalars['String']['output']>;
  chef: Maybe<Chef>;
  chefsTips: Maybe<Array<Scalars['String']['output']>>;
  configurationOptions: Maybe<Array<Maybe<Configuration>>>;
  cookWithin: Maybe<Scalars['Int']['output']>;
  costClass: Maybe<MealKitCostClass>;
  coverImage: Image;
  /** The creation date of the MealKitDish */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discoveryHealthyDiningItem: Maybe<Scalars['Boolean']['output']>;
  featuredSuppliers: Maybe<Array<Supplier>>;
  fromYourKitchen: Maybe<Array<Scalars['String']['output']>>;
  groupProducts: Maybe<Array<Maybe<MealKit>>>;
  handsOnTime: Maybe<CookingTimeRange>;
  /** The MealKitDish's ID */
  id: Scalars['ID']['output'];
  isValid: Maybe<Scalars['Boolean']['output']>;
  isVegitarian: Scalars['Boolean']['output'];
  mealKitCategories: Maybe<Array<MealKitCategory>>;
  name: Scalars['String']['output'];
  nutrition: Maybe<NutritionalInfo>;
  nutritionPerServing: Maybe<NutritionalInfo>;
  overallTime: Maybe<CookingTimeRange>;
  prepStation: Maybe<Array<Scalars['String']['output']>>;
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  quantityInCart: Scalars['Int']['output'];
  recipeCardStatus: BackgroundTaskStatusEnum;
  related: Array<MealKitDish>;
  sentIngredients: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  subTitle: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<MealKitTag>>;
  type: ProductGroupTypeEnum;
  upSellFrozenMealDishes: Maybe<Array<Maybe<FrozenMealDish>>>;
  upSellMarketProducts: Maybe<Array<Maybe<MarketProduct>>>;
  upSellText: Maybe<Scalars['String']['output']>;
  upSellWines: Maybe<Array<Maybe<Wine>>>;
  /** The update date of the MealKitDish */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MealKitDish filtering options */
export type MealKitDishFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chefs?: InputMaybe<Array<Scalars['ID']['input']>>;
  cookWithin?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  mealKitCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitDishList = {
  __typename: 'MealKitDishList';
  /** The filtering aggregation for MealKitDish */
  aggregation: Maybe<ProductAggregation>;
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitDish records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKitDish>>;
  /** Number of MealKitDish records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitDish ordering options */
export type MealKitDishOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitDishOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitDishOrderEnum {
  CHEF = 'CHEF',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** MealKit filtering options */
export type MealKitFilters = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chefs?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  flags?: InputMaybe<Array<MealKitFlagEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  mealKitCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** MealKit Flags */
export enum MealKitFlagEnum {
  /** Invalid meal kit portion */
  INVALID_MEALKIT_PORTION = 'INVALID_MEALKIT_PORTION'
}

/** Represents a MealKitInstruction in the system */
export type MealKitInstruction = {
  __typename: 'MealKitInstruction';
  /** The creation date of the MealKitInstruction */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The MealKitInstruction's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  index: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** The update date of the MealKitInstruction */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MealKitInstruction filtering options */
export type MealKitInstructionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitInstructionList = {
  __typename: 'MealKitInstructionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitInstruction records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKitInstruction>>;
  /** Number of MealKitInstruction records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitInstruction ordering options */
export type MealKitInstructionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitInstructionOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitInstructionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type MealKitList = {
  __typename: 'MealKitList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKit records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKit>>;
  /** Number of MealKit records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKit ordering options */
export type MealKitOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitOrderEnum {
  CHEF = 'CHEF',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MealKitPlan in the system */
export type MealKitPlan = Product & {
  __typename: 'MealKitPlan';
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the MealKitPlan */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  /** The MealKitPlan's ID */
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the MealKitPlan */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

/** MealKitPlan filtering options */
export type MealKitPlanFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitPlanList = {
  __typename: 'MealKitPlanList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitPlan records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKitPlan>>;
  /** Number of MealKitPlan records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitPlan ordering options */
export type MealKitPlanOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitPlanOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitPlanOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum MealKitServingSizeEnum {
  SERVES_1 = 'SERVES_1',
  SERVES_2 = 'SERVES_2',
  SERVES_3 = 'SERVES_3',
  SERVES_4 = 'SERVES_4'
}

export type MealKitSubscription = OrderSubscription & {
  __typename: 'MealKitSubscription';
  categories: Maybe<Array<Maybe<MealKitCategory>>>;
  id: Scalars['ID']['output'];
  minMealsPerCategory: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Represents a MealKitSubscriptionFee in the system */
export type MealKitSubscriptionFee = Product & {
  __typename: 'MealKitSubscriptionFee';
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the MealKitSubscriptionFee */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  /** The MealKitSubscriptionFee's ID */
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  shouldDisplay: Scalars['Boolean']['output'];
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the MealKitSubscriptionFee */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  volume: Scalars['Int']['output'];
  warehouses: Array<ProductWarehouse>;
};

/** MealKitSubscriptionFee filtering options */
export type MealKitSubscriptionFeeFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitSubscriptionFeeList = {
  __typename: 'MealKitSubscriptionFeeList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitSubscriptionFee records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<MealKitSubscriptionFee>;
  /** Number of MealKitSubscriptionFee records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitSubscriptionFee ordering options */
export type MealKitSubscriptionFeeOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitSubscriptionFeeOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitSubscriptionFeeOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MealKitTag in the system */
export type MealKitTag = {
  __typename: 'MealKitTag';
  /** The creation date of the MealKitTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Scalars['Int']['output'];
  icon: Maybe<Image>;
  /** The MealKitTag's ID */
  id: Scalars['ID']['output'];
  pageDataSection: Maybe<PageDataSection>;
  tagType: MealKitTagTypeEnum;
  title: Scalars['String']['output'];
  /** The update date of the MealKitTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MealKitTag filtering options */
export type MealKitTagFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<MealKitTagTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MealKitTagList = {
  __typename: 'MealKitTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MealKitTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MealKitTag>>;
  /** Number of MealKitTag records skipped */
  skip: Scalars['Int']['output'];
};

/** MealKitTag ordering options */
export type MealKitTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MealKitTagOrderEnum>;
};

/** Fields available to order Properties */
export enum MealKitTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Meal Kit Tag Type Enum */
export enum MealKitTagTypeEnum {
  /** Preferences */
  PREFERENCE = 'PREFERENCE',
  /** Protein */
  PROTEIN = 'PROTEIN'
}

export enum MealTagEnum {
  /** Incorrect Meal */
  INCORRECT_MEAL = 'INCORRECT_MEAL'
}

/** Represents a Menu in the system */
export type Menu = {
  __typename: 'Menu';
  addressUpdateAfterConversionReportFile: Maybe<Report>;
  addressUpdateAfterConversionReportStatus: BackgroundTaskStatusEnum;
  billCount: Scalars['Int']['output'];
  billPercent: Scalars['Int']['output'];
  billingDate: Scalars['DateTime']['output'];
  categories: Array<MenuCategory>;
  checkCount: Scalars['Int']['output'];
  checkPercent: Scalars['Int']['output'];
  checkingDate: Scalars['DateTime']['output'];
  closeCount: Scalars['Int']['output'];
  closePercent: Scalars['Int']['output'];
  completeCount: Scalars['Int']['output'];
  completePercent: Scalars['Int']['output'];
  courierSplitReportFile: Maybe<CourierSplitReportFile>;
  courierSplitReportFileStatus: BackgroundTaskStatusEnum;
  /** The creation date of the Menu */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  creditCardErrorReportFile: Maybe<CreditCardErrorReportFile>;
  creditCardErrorReportFileStatus: BackgroundTaskStatusEnum;
  deliveryDates: Maybe<Array<Maybe<DeliveryDate>>>;
  deliveryReminderSMS: Maybe<Array<Maybe<DeliveryReminderSMS>>>;
  errors: Array<UserMenuError>;
  fixCount: Scalars['Int']['output'];
  fixPercent: Scalars['Int']['output'];
  flaggedUserMenusReportFile: Maybe<FlaggedUserMenusReportFile>;
  flaggedUserMenusReportFileStatus: BackgroundTaskStatusEnum;
  /** The Menu's ID */
  id: Scalars['ID']['output'];
  inactiveMealsReportFile: Maybe<InactiveMealsReportFile>;
  inactiveMealsReportFileStatus: BackgroundTaskStatusEnum;
  ingredientPortionQuantityCartReportFile: Maybe<IngredientPortionQuantityCartReportFile>;
  ingredientPortionQuantityCartReportFileStatus: BackgroundTaskStatusEnum;
  ingredientPortionQuantityOrderReportFile: Maybe<IngredientPortionQuantityOrderReportFile>;
  ingredientPortionQuantityOrderReportFileStatus: BackgroundTaskStatusEnum;
  ingredientQuantityCartReportFile: Maybe<IngredientQuantityCartReportFile>;
  ingredientQuantityCartReportFileStatus: BackgroundTaskStatusEnum;
  ingredientQuantityOrderReportFile: Maybe<IngredientQuantityOrderReportFile>;
  ingredientQuantityOrderReportFileStatus: BackgroundTaskStatusEnum;
  isBilled: Scalars['Boolean']['output'];
  isBilling: Scalars['Boolean']['output'];
  isChecked: Scalars['Boolean']['output'];
  isChecking: Scalars['Boolean']['output'];
  isClosed: Scalars['Boolean']['output'];
  isClosing: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCompleting: Scalars['Boolean']['output'];
  isFixed: Scalars['Boolean']['output'];
  isFixing: Scalars['Boolean']['output'];
  isNotified: Scalars['Boolean']['output'];
  isNotifying: Scalars['Boolean']['output'];
  isOpened: Scalars['Boolean']['output'];
  isOpening: Scalars['Boolean']['output'];
  isResumed: Scalars['Boolean']['output'];
  isResuming: Scalars['Boolean']['output'];
  maxMealsPerCategory: Maybe<Scalars['Int']['output']>;
  menuCategories: Array<Maybe<MenuMealKitDishCategory>>;
  menuDishes: MealKitDishList;
  menuIngredientBreakdownReportFile: Maybe<MenuIngredientBreakdownReportFile>;
  menuIngredientBreakdownReportFileStatus: BackgroundTaskStatusEnum;
  menuIngredientReport: Maybe<MenuIngredientReportFile>;
  menuIngredientReportStatus: BackgroundTaskStatusEnum;
  menuItemClassReportFile: Maybe<MenuItemClassReportFile>;
  menuItemClassReportFileStatus: BackgroundTaskStatusEnum;
  menuTags: Array<Maybe<MenuMealKitDishTag>>;
  minMealsPerCategory: Maybe<Scalars['Int']['output']>;
  nextMenu: Maybe<Menu>;
  noWarehouseReportFile: Maybe<NoWarehouseReportFile>;
  noWarehouseReportFileStatus: BackgroundTaskStatusEnum;
  notificationCount: Scalars['Int']['output'];
  notificationPercent: Scalars['Int']['output'];
  openCount: Scalars['Int']['output'];
  openPercent: Scalars['Int']['output'];
  openingDate: Scalars['DateTime']['output'];
  orderCountReportFile: Maybe<OrderCountReportFile>;
  orderCountReportFileStatus: BackgroundTaskStatusEnum;
  orderIssuesReport: Maybe<LiveSpreadsheetReport>;
  previousMenu: Maybe<Menu>;
  procOrderReportFile: Maybe<ProcReportOrderFile>;
  procOrderReportFileStatus: BackgroundTaskStatusEnum;
  published: Scalars['Boolean']['output'];
  recipeCardStatus: BackgroundTaskStatusEnum;
  resumeCount: Scalars['Int']['output'];
  resumePercent: Scalars['Int']['output'];
  shippingBreakdown: Maybe<LiveSpreadsheetReport>;
  stats: MenuStats;
  status: MenuStatusEnum;
  stickerStatus: BackgroundTaskStatusEnum;
  stuckOrdersReportFile: Maybe<StuckOrdersReportFile>;
  stuckOrdersReportFileStatus: BackgroundTaskStatusEnum;
  subscription: MealKitSubscription;
  subscriptionAddonCartsReportFile: Maybe<SubscriptionAddonCartsReportFile>;
  subscriptionAddonCartsReportFileStatus: BackgroundTaskStatusEnum;
  subscriptionAddonOrdersReportFile: Maybe<SubscriptionAddonOrdersReportFile>;
  subscriptionAddonOrdersReportFileStatus: BackgroundTaskStatusEnum;
  subscriptionAsOnDemandReportFile: Maybe<SubscriptionAsOnDemandReportFile>;
  subscriptionAsOnDemandReportFileStatus: BackgroundTaskStatusEnum;
  totalBillCount: Scalars['Int']['output'];
  totalCheckCount: Scalars['Int']['output'];
  totalCloseCount: Scalars['Int']['output'];
  totalCompleteCount: Scalars['Int']['output'];
  totalFixCount: Scalars['Int']['output'];
  totalNotificationCount: Scalars['Int']['output'];
  totalOpenCount: Scalars['Int']['output'];
  totalResumeCount: Scalars['Int']['output'];
  unsavedMenusReportFile: Maybe<UnsavedMenusReportFile>;
  unsavedMenusReportFileStatus: BackgroundTaskStatusEnum;
  /** The update date of the Menu */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  weekIdentifier: Scalars['String']['output'];
  wines: Array<MenuDishWine>;
  zeroItemOrderReportFile: Maybe<ZeroItemOrderReportFile>;
  zeroItemOrderReportFileStatus: BackgroundTaskStatusEnum;
};


/** Represents a Menu in the system */
export type MenumenuCategoriesArgs = {
  filters?: InputMaybe<MenuMealKitDishFilters>;
};


/** Represents a Menu in the system */
export type MenumenuDishesArgs = {
  filters?: InputMaybe<MenuMealKitDishFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuMealKitDishOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents a Menu in the system */
export type MenumenuTagsArgs = {
  filters?: InputMaybe<MenuMealKitDishFilters>;
};

export type MenuBackgroundTask = BackgroundTask & {
  __typename: 'MenuBackgroundTask';
  downloadType: Maybe<DownloadTypeEnum>;
  id: Maybe<Scalars['ID']['output']>;
  menu: Menu;
  objectType: Scalars['String']['output'];
  reportType: Maybe<LiveReportTypeEnum>;
  updateType: BackgroundTaskUpdateEnum;
};

/** Represents a MenuCategory in the system */
export type MenuCategory = {
  __typename: 'MenuCategory';
  category: MealKitCategory;
  costClassAtBilling: Maybe<MealKitCostClass>;
  /** The creation date of the MenuCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dish: MealKitDish;
  displayIndex: Scalars['Int']['output'];
  /** The MenuCategory's ID */
  id: Scalars['ID']['output'];
  menu: Menu;
  preference: Scalars['Int']['output'];
  /** The update date of the MenuCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MenuCategory filtering options */
export type MenuCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  menus?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MenuCategoryList = {
  __typename: 'MenuCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MenuCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MenuCategory>>;
  /** Number of MenuCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** MenuCategory ordering options */
export type MenuCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MenuDishWine in the system */
export type MenuDishWine = {
  __typename: 'MenuDishWine';
  /** The creation date of the MenuDishWine */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dish: MealKitDish;
  /** The MenuDishWine's ID */
  id: Scalars['ID']['output'];
  menu: Menu;
  note: Scalars['String']['output'];
  /** The update date of the MenuDishWine */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  wine: Wine;
};

/** MenuDishWine filtering options */
export type MenuDishWineFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MenuDishWineList = {
  __typename: 'MenuDishWineList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MenuDishWine records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MenuDishWine>>;
  /** Number of MenuDishWine records skipped */
  skip: Scalars['Int']['output'];
};

/** MenuDishWine ordering options */
export type MenuDishWineOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuDishWineOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuDishWineOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Menu filtering options */
export type MenuFilters = {
  billingDate?: InputMaybe<Scalars['DateTime']['input']>;
  billingDateRange?: InputMaybe<openingDateRangeFilter>;
  checkingDate?: InputMaybe<Scalars['DateTime']['input']>;
  checkingDateRange?: InputMaybe<openingDateRangeFilter>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isBilled?: InputMaybe<Scalars['Boolean']['input']>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  isInProgress?: InputMaybe<Scalars['Boolean']['input']>;
  isOpened?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isResumed?: InputMaybe<Scalars['Boolean']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  openingDate?: InputMaybe<Scalars['DateTime']['input']>;
  openingDateRange?: InputMaybe<openingDateRangeFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<MenuStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MenuIngredientBreakdownReportFile = {
  __typename: 'MenuIngredientBreakdownReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type MenuIngredientReportFile = {
  __typename: 'MenuIngredientReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type MenuItemClassReportFile = {
  __typename: 'MenuItemClassReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Properties list object */
export type MenuList = {
  __typename: 'MenuList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Menu records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Menu>>;
  /** Number of Menu records skipped */
  skip: Scalars['Int']['output'];
};

export type MenuMealKitDishCategory = {
  __typename: 'MenuMealKitDishCategory';
  active: Maybe<Scalars['Boolean']['output']>;
  available: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  icon: Maybe<Image>;
  longDescription: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

export type MenuMealKitDishFilters = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  cookWithin?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MenuMealKitDishOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuMealKitDishOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuMealKitDishOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type MenuMealKitDishTag = {
  __typename: 'MenuMealKitDishTag';
  active: Maybe<Scalars['Boolean']['output']>;
  available: Maybe<Scalars['Boolean']['output']>;
  icon: Maybe<Image>;
  title: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

/** Menu ordering options */
export type MenuOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuOrderEnum {
  BILLING_DATE = 'BILLING_DATE',
  CHECKING_DATE = 'CHECKING_DATE',
  CLOSING_DATE = 'CLOSING_DATE',
  COMPLETION_DATE = 'COMPLETION_DATE',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  OPENING_DATE = 'OPENING_DATE',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type MenuOrderRegionBreakdown = {
  __typename: 'MenuOrderRegionBreakdown';
  menuOrderRegionItems: Array<MenuOrderRegionItem>;
};

export type MenuOrderRegionItem = {
  __typename: 'MenuOrderRegionItem';
  orderCount: Scalars['String']['output'];
  regionName: Scalars['String']['output'];
};

/** Represents a MenuProductCart in the system */
export type MenuProductCart = {
  __typename: 'MenuProductCart';
  categoryName: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  itemProduct: Product;
  menu: Menu;
  portionSize: Maybe<Scalars['String']['output']>;
  productName: Scalars['String']['output'];
  productRange: ProductRangeEnum;
  productType: Scalars['String']['output'];
  warehouse: Maybe<Warehouse>;
};

/** MenuProductCart filtering options */
export type MenuProductCartFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  menus?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productRanges?: InputMaybe<Array<ProductRangeEnum>>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MenuProductCartList = {
  __typename: 'MenuProductCartList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MenuProductCart records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MenuProductCart>>;
  /** Number of MenuProductCart records skipped */
  skip: Scalars['Int']['output'];
};

/** MenuProductCart ordering options */
export type MenuProductCartOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuProductCartOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuProductCartOrderEnum {
  COUNT = 'COUNT',
  MENU = 'MENU',
  PORTION = 'PORTION',
  PRODUCT = 'PRODUCT',
  WAREHOUSE = 'WAREHOUSE'
}

/** Represents a MenuProductOrder in the system */
export type MenuProductOrder = {
  __typename: 'MenuProductOrder';
  acumaticaWarehouseId: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  /** The creation date of the MenuProductOrder */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The MenuProductOrder's ID */
  id: Scalars['ID']['output'];
  itemProduct: Product;
  menu: Menu;
  productRange: ProductRangeEnum;
  /** The update date of the MenuProductOrder */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};

/** MenuProductOrder filtering options */
export type MenuProductOrderFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  menus?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productRanges?: InputMaybe<Array<ProductRangeEnum>>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MenuProductOrderList = {
  __typename: 'MenuProductOrderList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MenuProductOrder records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MenuProductOrder>>;
  /** Number of MenuProductOrder records skipped */
  skip: Scalars['Int']['output'];
};

/** MenuProductOrder ordering options */
export type MenuProductOrderOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuProductOrderOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuProductOrderOrderEnum {
  COUNT = 'COUNT',
  MENU = 'MENU',
  PORTION = 'PORTION',
  PRODUCT = 'PRODUCT',
  WAREHOUSE = 'WAREHOUSE'
}

export enum MenuQueueTypeEnum {
  /** Bill */
  BILL = 'BILL',
  /** Check */
  CHECK = 'CHECK',
  /** Close */
  CLOSE = 'CLOSE',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Fix */
  FIX = 'FIX',
  /** Notify */
  NOTIFY = 'NOTIFY',
  /** Open */
  OPEN = 'OPEN',
  /** Resume */
  RESUME = 'RESUME'
}

export type MenuStats = {
  __typename: 'MenuStats';
  active: Scalars['Int']['output'];
  cancelledOrders: Scalars['Int']['output'];
  conversionFails: Scalars['Int']['output'];
  convertedToOrder: Scalars['Int']['output'];
  failed: Scalars['Int']['output'];
  flagged: Scalars['Int']['output'];
  guest: Scalars['Int']['output'];
  notPaid: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  systemFails: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalSuccessfulOrders: Scalars['Int']['output'];
};

export enum MenuStatusEnum {
  /** Billed */
  BILLED = 'BILLED',
  /** Billing */
  BILLING = 'BILLING',
  /** Checked */
  CHECKED = 'CHECKED',
  /** Checking */
  CHECKING = 'CHECKING',
  /** Closed */
  CLOSED = 'CLOSED',
  /** Closing */
  CLOSING = 'CLOSING',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Completing */
  COMPLETING = 'COMPLETING',
  /** Fixed */
  FIXED = 'FIXED',
  /** Fixing */
  FIXING = 'FIXING',
  /** Invalid */
  INVALID = 'INVALID',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Notified */
  NOTIFIED = 'NOTIFIED',
  /** Notifying */
  NOTIFYING = 'NOTIFYING',
  /** Not Available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** Opened */
  OPENED = 'OPENED',
  /** Opening */
  OPENING = 'OPENING',
  /** Pending */
  PENDING = 'PENDING',
  /** Percentage */
  PERCENTAGE = 'PERCENTAGE',
  /** Resumed */
  RESUMED = 'RESUMED',
  /** Resuming */
  RESUMING = 'RESUMING'
}

export type MenuSystemChange = SystemChange & {
  __typename: 'MenuSystemChange';
  id: Scalars['ID']['output'];
  menu: Menu;
  status: MenuStatusEnum;
};

/** Represents a MenuWarehouse in the system */
export type MenuWarehouse = {
  __typename: 'MenuWarehouse';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The MenuWarehouse's ID */
  id: Scalars['ID']['output'];
  menu: Menu;
  newSignUpOrderLimit: Scalars['Int']['output'];
  reactivationOrderLimit: Scalars['Int']['output'];
  /** The update date of the MenuWarehouse */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};

/** MenuWarehouse filtering options */
export type MenuWarehouseFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  menuIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type MenuWarehouseList = {
  __typename: 'MenuWarehouseList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MenuWarehouse records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MenuWarehouse>>;
  /** Number of MenuWarehouse records skipped */
  skip: Scalars['Int']['output'];
};

/** MenuWarehouse ordering options */
export type MenuWarehouseOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<MenuWarehouseOrderEnum>;
};

/** Fields available to order Properties */
export enum MenuWarehouseOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a MinimumOrderValue in the system */
export type MinimumOrderValue = {
  __typename: 'MinimumOrderValue';
  areaType: Maybe<CityDeliveryTypeEnum>;
  /** The creation date of the MinimumOrderValue */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryFee: Maybe<Scalars['String']['output']>;
  /** The MinimumOrderValue's ID */
  id: Scalars['ID']['output'];
  mov: Maybe<Scalars['String']['output']>;
  orderType: Maybe<OrderTypeEnum>;
  productRange: Maybe<ProductRangeEnum>;
  /** The update date of the MinimumOrderValue */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** MinimumOrderValue filtering options */
export type MinimumOrderValueFilters = {
  areaType?: InputMaybe<CityDeliveryTypeEnum>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryFee?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  mov?: InputMaybe<Scalars['String']['input']>;
  orderType?: InputMaybe<OrderTypeEnum>;
  productRange?: InputMaybe<ProductRangeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type MinimumOrderValueList = {
  __typename: 'MinimumOrderValueList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of MinimumOrderValue records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<MinimumOrderValue>>;
  /** Number of MinimumOrderValue records skipped */
  skip: Scalars['Int']['output'];
};

/** MinimumOrderValue ordering options */
export type MinimumOrderValueOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<Scalars['String']['input']>;
};

/** Fields available to order Properties */
export enum MinimumOrderValueOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type Modals = {
  __typename: 'Modals';
  addLocation: Scalars['Boolean']['output'];
  checkOut: Scalars['Boolean']['output'];
  deliveryDayUnavailable: Scalars['Boolean']['output'];
  deliveryUnavailable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  knowYourCraftMeals: Scalars['Boolean']['output'];
  knowYourDishes: Scalars['Boolean']['output'];
  logIn: Scalars['Boolean']['output'];
  notifyMarketProducts: Scalars['Boolean']['output'];
  pageModal: Scalars['Boolean']['output'];
  pageModalHasCookie: Scalars['Boolean']['output'];
  pageModalIdentifier: Scalars['String']['output'];
  pauseSubscription: Scalars['Boolean']['output'];
  pauseSubscriptionReason: Scalars['Boolean']['output'];
  signUp: Scalars['Boolean']['output'];
  toggle: Scalars['Boolean']['output'];
};

/** Month Weeks */
export enum MonthWeekEnum {
  /** 1st */
  FIRST = 'FIRST',
  /** 4th */
  FOURTH = 'FOURTH',
  /** 2nd */
  SECOND = 'SECOND',
  /** 3rd */
  THIRD = 'THIRD'
}

export type Mutation = {
  __typename: 'Mutation';
  abandonCart: Maybe<Scalars['Boolean']['output']>;
  /** Accept eat with in days */
  acceptUserMenuCookWithInDays: UserMenuCartResponse;
  /** Accept price difference */
  acceptUserMenuPriceDifference: UserMenuCartResponse;
  activateAllCodesForDiscountCampaign: Scalars['Boolean']['output'];
  /** Un-Pause a subscription */
  activateSubscription: UserSubscription;
  activeCampaignPreferenceSync: Maybe<Scalars['Boolean']['output']>;
  activeCampaignSync: Maybe<Scalars['Boolean']['output']>;
  acuGetIngredientCostsFromAcumatica: Scalars['Boolean']['output'];
  acuSyncSubscriptionOrderToAcumatica: Scalars['Boolean']['output'];
  /** Add a new AbTest */
  addAbTest: AbTest;
  /** Add a new AbTestBucket */
  addAbTestBucket: AbTestBucket;
  /** Add a new AbTestBucketUser */
  addAbTestBucketUser: AbTestBucketUser;
  /** Add a new AbTestFilter */
  addAbTestFilter: AbTestFilter;
  /** Add a new AcumaticaDiscountNonStockSku */
  addAcumaticaDiscountNonStockSku: AcumaticaDiscountNonStockSku;
  /** Add a new AcumaticaProductTypeNonStockSku */
  addAcumaticaProductTypeNonStockSku: AcumaticaProductTypeNonStockSku;
  /** Bulk insert User Preferences */
  addAllUserPreferences: CategorisedUserPreferencesList;
  /** Add a new Allergen */
  addAllergen: Allergen;
  /** Add and Validate Credit Card */
  addAndValidateCreditCard: CreditCardValidation;
  /** Add a new Archive */
  addArchive: Archive;
  addBabyBox: BabyBox;
  /** Add a new BabyBoxCategory */
  addBabyBoxCategory: BabyBoxCategory;
  /** Add a new BabyBoxTag */
  addBabyBoxTag: BabyBoxTag;
  addBabyBoxTub: BabyBoxTub;
  /** Add a new BabyBoxTubDetail */
  addBabyBoxTubDetail: BabyBoxTubDetail;
  /** Add a new BankBinNumber */
  addBankBinNumber: BankBinNumber;
  /** Add a new Barcode */
  addBarcode: Barcode;
  /** Add a new Box */
  addBox: Box;
  /** Add a new BoxRestriction */
  addBoxRestriction: BoxRestriction;
  /** Add a new BoxSubContainer */
  addBoxSubContainer: BoxSubContainer;
  /** Add a new Branch */
  addBranch: Branch;
  /** Add a new Brand */
  addBrand: Brand;
  /** Add a new Category */
  addCategory: Category;
  /** Add a new CategoryGroup */
  addCategoryGroup: CategoryGroup;
  /** Add a new Chef */
  addChef: Chef;
  /** Add a new CommunicationTemplate */
  addCommunicationTemplate: CommunicationTemplate;
  addCouponToCart: Cart;
  addCouponToOrder: Scalars['Boolean']['output'];
  /** Add a new Courier */
  addCourier: Courier;
  /** Add a new CourierBox */
  addCourierBox: CourierBox;
  /** Add a new CourierDelivery */
  addCourierDelivery: CourierDelivery;
  /** Add a new CourierDeliveryDay */
  addCourierDeliveryDay: CourierDeliveryDay;
  /** Add a new CourierWarehouse */
  addCourierWarehouse: CourierWarehouse;
  /** Add a new CourierWarehouseCity */
  addCourierWarehouseCity: CourierWarehouseCity;
  /** Add a new CourierWarehouseCityDeliveryDay */
  addCourierWarehouseCityDeliveryDay: CourierWarehouseCityDeliveryDay;
  /** Add a new DeletedUser */
  addDeletedUser: DeletedUser;
  /** Add a new DeliveryItem */
  addDeliveryItem: DeliveryItem;
  /** Add a new DeliveryItemParcel */
  addDeliveryItemParcel: DeliveryItemParcel;
  /** Add a new DeliverySchedule */
  addDeliverySchedule: DeliverySchedule;
  /** Rather use register */
  addDevice: Scalars['ID']['output'];
  /** Add a new Discount */
  addDiscount: Discount;
  /** Add a new DiscountCampaign */
  addDiscountCampaign: DiscountCampaign;
  /** Add a new DiscountCode */
  addDiscountCode: DiscountCode;
  /** Add a new DiscountTag */
  addDiscountTag: DiscountTag;
  addDiscountToCartsBypassValidations: Scalars['Boolean']['output'];
  /** Add a dish to a users menu */
  addDishToUserMenu: UserMenuCartResponse;
  /** Add a new Excel */
  addExcel: Excel;
  /** Add a new FileCategory */
  addFileCategory: FileCategory;
  /** Add a new FriendReference */
  addFriendReference: FriendReference;
  /** Add a new FrozenCategory */
  addFrozenCategory: FrozenCategory;
  /** Add a new FrozenInstruction */
  addFrozenInstruction: FrozenInstruction;
  /** Add a new Frozen */
  addFrozenMeal: FrozenMeal;
  addFrozenMealDish: FrozenMealDish;
  /** Add a new FrozenTag */
  addFrozenTag: FrozenTag;
  /** Add a new Gift */
  addGift: Gift;
  addGoogleClientId: Scalars['Boolean']['output'];
  /** Add a new GuestUser */
  addGuestUser: GuestUser;
  /** Add a new Image */
  addImage: Image;
  /** Add a new Ingredient */
  addIngredient: Ingredient;
  /** Add an item to Cart */
  addItemToCart: CartProductCount;
  /** Add an item to Order */
  addItemToOrder: Scalars['Boolean']['output'];
  /** Add a new Lead */
  addLead: Lead;
  /** Add a new LeadUpload */
  addLeadUpload: LeadUpload;
  /** Add a new Location */
  addLocation: Location;
  /** Add a new LoyaltyTier */
  addLoyaltyTier: LoyaltyTier;
  /** Add a new ManualEmail */
  addManualEmail: ManualEmail;
  /** Add a new MarketProduct */
  addMarketProduct: MarketProduct;
  /** Add a new MarketProductBrand */
  addMarketProductBrand: MarketProductBrand;
  /** Add a new MarketProductCategory */
  addMarketProductCategory: MarketProductCategory;
  /** Add a new MarketProductSubCategory */
  addMarketProductSubCategory: MarketProductSubCategory;
  /** Add a new MarketProductTag */
  addMarketProductTag: MarketProductTag;
  /** Add a new MealKit */
  addMealKit: MealKit;
  /** Add a new MealKitCategory */
  addMealKitCategory: MealKitCategory;
  /** Add a new MealKitDefaultPricing */
  addMealKitDefaultPricing: MealKitDefaultPricing;
  /** Add a new MealKitDish */
  addMealKitDish: MealKitDish;
  /** Add a flag to MealKit */
  addMealKitFlag: MealKit;
  /** Add a new MealKitInstruction */
  addMealKitInstruction: MealKitInstruction;
  /** Add a new MealKitPlan */
  addMealKitPlan: MealKitPlan;
  /** Add a new MealKitSubscriptionFee */
  addMealKitSubscriptionFee: MealKitSubscriptionFee;
  /** Add a new MealKitTag */
  addMealKitTag: MealKitTag;
  /** Add a new Menu */
  addMenu: Menu;
  /** Add a new MenuCategory */
  addMenuCategory: MenuCategory;
  /** Add a new MenuDishWine */
  addMenuDishWine: MenuDishWine;
  /** Add a new MenuWarehouse */
  addMenuWarehouse: MenuWarehouse;
  /** Add a new MinimumOrderValue */
  addMinimumOrderValue: MinimumOrderValue;
  /** Add a new OrderAddress */
  addOrderAddress: OrderAddress;
  /** Add a new OrderBox */
  addOrderBox: OrderBox;
  /** Add a new OrderBoxItem */
  addOrderBoxItem: OrderBoxItem;
  /** Add a new OrderHistory */
  addOrderHistory: OrderHistory;
  /** Add a new OrderItemRating */
  addOrderItemRating: OrderItemRating;
  /** Add a new Rating */
  addOrderRating: OrderRating;
  /** Add a new OrderSubContainer */
  addOrderSubContainer: OrderSubContainer;
  /** Add a new OrderTaxDiscount */
  addOrderTaxDiscount: OrderTaxDiscount;
  /** Add a new OrderTaxProduct */
  addOrderTaxProduct: OrderTaxProduct;
  /** Add a new OrderTaxProductType */
  addOrderTaxProductType: OrderTaxProductType;
  /** Add a new OrderTaxProductTypeDiscount */
  addOrderTaxProductTypeDiscount: OrderTaxProductTypeDiscount;
  /** Add a new OrderTaxReceipt */
  addOrderTaxReceipt: OrderTaxReceipt;
  /** Add a new PackingVolumeDefault */
  addPackingVolumeDefault: PackingVolumeDefault;
  /** Add a new PageBanner */
  addPageBanner: PageBanner;
  /** Add a new PageBannerSection */
  addPageBannerSection: PageBannerSection;
  /** Add a new PageCard */
  addPageCard: PageCard;
  /** Add a new PageCardSection */
  addPageCardSection: PageCardSection;
  /** Add a new PageDataSection */
  addPageDataSection: PageDataSection;
  /** Add a new PageDetail */
  addPageDetail: PageDetail;
  /** Add a new PageDetailSection */
  addPageDetailSection: PageDetailSection;
  /** Add a new PageGridItem */
  addPageGridItem: PageGridItem;
  /** Add a new PageGridSection */
  addPageGridSection: PageGridSection;
  /** Add a new PageMediaSection */
  addPageMediaSection: PageMediaSection;
  /** Add a new PageModal */
  addPageModal: PageModal;
  /** Add a new PageNotification */
  addPageNotification: PageNotification;
  /** Add a new PageNotificationGroup */
  addPageNotificationGroup: PageNotificationGroup;
  /** Add a new PageSectionGroup */
  addPageSectionGroup: PageSectionGroup;
  /** Add a new PageTab */
  addPageTab: PageTab;
  /** Add a new PageTabItem */
  addPageTabItem: PageTabItem;
  /** Add a new PageTabSection */
  addPageTabSection: PageTabSection;
  /** Add a new PageTextCard */
  addPageTextCard: PageTextCard;
  /** Add a new PageTextCardSection */
  addPageTextCardSection: PageTextCardSection;
  /** Add a new PageTextSection */
  addPageTextSection: PageTextSection;
  /** Add a new PageTileSection */
  addPageTileSection: PageTileSection;
  /** Add a new Pdf */
  addPdf: Pdf;
  /** Add a new Preference */
  addPreference: Preference;
  /** Add a new PreferenceCategory */
  addPreferenceCategory: PreferenceCategory;
  /** Add a new ProcessGroup */
  addProcessGroup: ProcessGroup;
  /** Add a new ProcessGroupCourier */
  addProcessGroupCourier: ProcessGroupCourier;
  /** Add a new ProductAvailabilityEmail */
  addProductAvailabilityEmail: ProductAvailabilityEmail;
  /** Add a new ProductImage */
  addProductImage: ProductImage;
  /** Add a new ProductIngredient */
  addProductIngredient: ProductIngredient;
  /** Add a new ProductWarhouseCostHistory */
  addProductWarhouseCostHistory: ProductWarhouseCostHistory;
  /** Add a new PublicHoliday */
  addPublicHoliday: PublicHoliday;
  /** Add a new Refund */
  addRefund: Refund;
  /** Add a new RefundHistory */
  addRefundHistory: RefundHistory;
  /** Add a new RefundReason */
  addRefundReason: RefundReason;
  /** Add a new RefundReportingReason */
  addRefundReportingReason: RefundReportingReason;
  /** Add a new RegisteredUser */
  addRegisteredUser: RegisteredUser;
  /** Add a new Report */
  addReport: Report;
  /** Add a new ReportFileStatus */
  addReportFile: ReportFileStatusEnum;
  /** Add a new Role */
  addRole: Role;
  /** Add a new ScheduleCategoryExclusion */
  addScheduleCategoryExclusion: ScheduleCategoryExclusion;
  /** Add a new ScheduleCategoryInclusion */
  addScheduleCategoryInclusion: ScheduleCategoryInclusion;
  /** Add a new ServingSuggestion */
  addServingSuggestion: ServingSuggestion;
  /** Add a new ShipperBox */
  addShipperBox: ShipperBox;
  /** Add a new ShipperWarehouse */
  addShipperWarehouse: ShipperWarehouse;
  /** Add a new SmsTemplate */
  addSmsTemplate: SmsTemplate;
  /** Add a new SubContainer */
  addSubContainer: SubContainer;
  /** Add a new SubContainerOrderItem */
  addSubContainerOrderItem: SubContainerOrderItem;
  /** Add a new SubContainerRestriction */
  addSubContainerRestriction: SubContainerRestriction;
  /** Add a new SubscriptionPriceHistory */
  addSubscriptionPriceHistory: SubscriptionPriceHistory;
  /** Add a new Supplier */
  addSupplier: Supplier;
  /** Add a new TaxClass */
  addTaxClass: TaxClass;
  /** Add a new TaxReceiptEvent */
  addTaxReceiptEvent: TaxReceiptEvent;
  /** Add tracking data to cart */
  addTrackingDataToCart: Cart;
  /** Add a new TransactionReport */
  addTransactionReport: TransactionReport;
  /** Update User Preferences or Add if its missing */
  addUpdateCategorisedUserPreference: CategorisedUserPreferencesList;
  /** Adds or Updates a UserPreference */
  addUpdateUserPreference: UserPreference;
  /** Add a new UserAddress */
  addUserAddress: UserAddress;
  /** Add a new UserDevice */
  addUserDevice: UserDevice;
  /** Add a new UserDiscount */
  addUserDiscount: UserDiscount;
  /** Add a new UserHistory Event */
  addUserHistory: UserHistory;
  /** Add a new UserPartnership */
  addUserPartnership: UserPartnership;
  /** Add a new UserPreference */
  addUserPreference: UserPreference;
  /** Add a new UserRole */
  addUserRole: UserRole;
  /** Add a new UserSubscription */
  addUserSubscription: UserSubscription;
  addUserToAbTest: Scalars['Boolean']['output'];
  /** Add a new Vendor */
  addVendor: Vendor;
  /** Add a new Vineyard */
  addVineyard: Vineyard;
  /** Add a new Warehouse */
  addWarehouse: Warehouse;
  /** Add a new WarehouseLocation */
  addWarehouseLocation: WarehouseLocation;
  /** Add a new Wine */
  addWine: Wine;
  /** Add a new WineCampaign */
  addWineCampaign: WineCampaign;
  /** Add a new WineCategory */
  addWineCategory: WineCategory;
  /** Add a new WineMaker */
  addWineMaker: WineMaker;
  /** Add a new WineTag */
  addWineTag: WineTag;
  admarulaRequest: Scalars['Boolean']['output'];
  /** Admin Sign up */
  adminSignUp: Maybe<Scalars['Boolean']['output']>;
  applyPerDishPricingToCurrentMenu: Scalars['Boolean']['output'];
  approveAllRequiredRefunds: Array<Maybe<Refund>>;
  /** Assign Boxes to Order */
  assignBoxes: Order;
  assignDesignationAndKeys: Scalars['Boolean']['output'];
  /** Assign an Order to a Courier */
  assignOrderToCourier: Order;
  /** Give a Role a permission */
  assignPermission: Scalars['Boolean']['output'];
  /** Assign Dish Keys for the Menu */
  assignUserMenuDishKeys: Scalars['Boolean']['output'];
  attemptLocationRectification: Location;
  /** Mark Event as attended to */
  attendToEvent: UserHistory;
  /** Bill Menu */
  billMenu: Menu;
  /** Bill an Order */
  billOrder: Order;
  buildCourierReport: Scalars['Boolean']['output'];
  buildOccasionCartReport: Scalars['Boolean']['output'];
  buildOccasionReport: Scalars['Boolean']['output'];
  /** Set generation of reciept order id */
  calculateReciept: Maybe<Scalars['Boolean']['output']>;
  /** Update Orders Call Status */
  callStatusUpdate: Order;
  /** Cancel an Order */
  cancelOrder: Order;
  cancelOrderRevision: Order;
  categoryTree: Maybe<Scalars['JSON']['output']>;
  /** Add an item to Cart */
  changeCartItemQuantity: CartProductCount;
  /** Change Dispatch Day */
  changeDispatchDay: Scalars['Boolean']['output'];
  /** Add an item to Order */
  changeOrderItemQuantity: Scalars['Boolean']['output'];
  checkAndQueueAcumaticaSubContainerSync: Maybe<Scalars['Boolean']['output']>;
  /** Check Menu */
  checkMenu: Menu;
  /** Run the Check function on a user Menu */
  checkUserMenu: Scalars['Boolean']['output'];
  checkoutCart: Checkout;
  clearAllCacheKeys: Scalars['Boolean']['output'];
  clearCacheKeys: Scalars['Boolean']['output'];
  clearContentCache: Scalars['Boolean']['output'];
  clearPageModalCache: Scalars['Boolean']['output'];
  clearPageNotificationCache: Scalars['Boolean']['output'];
  clearProductCache: Scalars['Boolean']['output'];
  /** Close Menu */
  closeMenu: Menu;
  /** Complete Menu */
  completeMenu: Menu;
  /** Complete an Order */
  completeOrder: Order;
  confirmEvent: Scalars['Boolean']['output'];
  convertCartToOrder: Maybe<Scalars['Boolean']['output']>;
  /** Convert Lead to User */
  convertLead: Lead;
  correctUserMenus: Maybe<Scalars['Boolean']['output']>;
  /** Create subscription order */
  createSubscriptionOrder: Menu;
  /** Create the unique packing id codes for the sub containers */
  createUniqueCodesForSubContainers: DispatchDay;
  deactivateAllCodesForDiscountCampaign: Scalars['Boolean']['output'];
  /** Decrease stcok for a product at a warehouse */
  decreaseStock: Scalars['Boolean']['output'];
  /** Update a RegisteredUser to a delete status */
  deleteRegisteredUser: RegisteredUser;
  /** Discovery Partnership Verification */
  discoveryPartnershipVerify: DiscoveryPartnershipVerifyResponse;
  /** Download AddressIssues report */
  downloadAddressIssuesReport: Report;
  downloadAddressUpdateAfterConversionReport: Scalars['Boolean']['output'];
  downloadCourierSplitReport: Scalars['Boolean']['output'];
  downloadCreditCardErrorReport: Scalars['Boolean']['output'];
  downloadDiscountCampaignCodes: Scalars['URI']['output'];
  downloadFlaggedUserMenusReport: Scalars['Boolean']['output'];
  /** Download Forecast */
  downloadForecast: Scalars['URI']['output'];
  downloadInactiveMealsReport: Scalars['Boolean']['output'];
  /** Download Ingredient Cost Report */
  downloadIngredientCostReport: Scalars['Boolean']['output'];
  /** Download Ingredient Portion Quantity Cart Report File */
  downloadIngredientPortionQuantityCartReportFile: Scalars['Boolean']['output'];
  /** Download Ingredient Portion Quantity Order Report File */
  downloadIngredientPortionQuantityOrderReportFile: Scalars['Boolean']['output'];
  /** Download Ingredient Quantity Cart Report File */
  downloadIngredientQuantityCartReportFile: Scalars['Boolean']['output'];
  /** Download Ingredient Quantity Order Report File */
  downloadIngredientQuantityOrderReportFile: Scalars['Boolean']['output'];
  downloadLeadUploadTemplate: Scalars['URI']['output'];
  downloadMasterOrderReport: Report;
  downloadMenuIngredientBreakdownReport: Scalars['Boolean']['output'];
  /** Download Menu Ingredient Report */
  downloadMenuIngredientReport: Scalars['Boolean']['output'];
  downloadMenuItemClassReport: Scalars['Boolean']['output'];
  downloadMenuProductCartSheet: Scalars['URI']['output'];
  downloadNoWarehouseReport: Scalars['Boolean']['output'];
  downloadOrderCountReport: Scalars['Boolean']['output'];
  downloadOrderCountsReport: Scalars['URI']['output'];
  /** Download Receipts for event */
  downloadReceipts: Scalars['Boolean']['output'];
  downloadStuckOrdersReport: Scalars['Boolean']['output'];
  downloadSubscriptionAddonCartsReport: Scalars['Boolean']['output'];
  downloadSubscriptionAddonOrdersReport: Scalars['Boolean']['output'];
  downloadSubscriptionAsOnDemandReport: Scalars['Boolean']['output'];
  /** Download Summary */
  downloadSummary: Scalars['Boolean']['output'];
  /** Download Tax Category */
  downloadTaxCategory: Scalars['Boolean']['output'];
  downloadUnsavedMenusReport: Scalars['Boolean']['output'];
  downloadZeroItemOrdersReport: Scalars['Boolean']['output'];
  duplicateOrderForRevision: Order;
  duplicatePortion: Maybe<Scalars['Boolean']['output']>;
  finaliseOrderRevision: Order;
  fixBilling: Scalars['Boolean']['output'];
  fixEmailPreferences: Maybe<Scalars['Boolean']['output']>;
  fixEmptyCarts: Scalars['Boolean']['output'];
  fixEmptyOrderaddress: Maybe<Scalars['Boolean']['output']>;
  fixErroredMenus: Scalars['Boolean']['output'];
  fixLegacyMenu: Maybe<Scalars['Boolean']['output']>;
  fixLocations: Scalars['Boolean']['output'];
  fixLunch: Scalars['Boolean']['output'];
  /** Fix Menu */
  fixMenu: Menu;
  fixNoItemCartsForCurrentMenu: Scalars['Boolean']['output'];
  fixServesThreeSKU: Maybe<Scalars['Boolean']['output']>;
  fixUserAddresses: Scalars['Boolean']['output'];
  /** Force end billing step */
  forceEndMenuBillingStep: Scalars['Boolean']['output'];
  generateDiscountCampaignCodes: Scalars['Boolean']['output'];
  /** Generate Dispatch Day Download */
  generateDispatchDayDownload: Scalars['Boolean']['output'];
  /** Generate Dispatch Day Report */
  generateDispatchDayReport: Scalars['Boolean']['output'];
  /** Integrate on demand shipments */
  generateDispatchDayShipments: DispatchDay;
  generateMenuReport: Scalars['Boolean']['output'];
  generateMenuStickers: Scalars['Boolean']['output'];
  generatePageSection: Scalars['Boolean']['output'];
  generatePaymentLink: Scalars['String']['output'];
  /** Generate Receipt */
  generateReceipt: Maybe<Scalars['Boolean']['output']>;
  /** Download a MealKit Recipe Card */
  generateRecipeCard: MealKit;
  generateRecipeCards: Menu;
  generateResumeUserList: Scalars['Boolean']['output'];
  /** Generate Transaction Report */
  generateTransactionReport: Scalars['Boolean']['output'];
  /** Impersonate User */
  generateUserImpersonationToken: Scalars['String']['output'];
  getDiscoverySyncData: Maybe<Scalars['Boolean']['output']>;
  getSummary: Maybe<Scalars['Boolean']['output']>;
  /** Update an existing Location */
  hardUpdateLocation: Location;
  /** Register Impersonation */
  impersonateUser: RegisteredUser;
  /** Increase stcok for a product at a warehouse */
  increaseStock: Scalars['Boolean']['output'];
  /** Associate device with FCM */
  linkDeviceWithFCM: Maybe<Scalars['String']['output']>;
  /** Lookup status */
  lookupItem: Scalars['Boolean']['output'];
  /** Lookup status */
  lookupItemsStatus: Scalars['Boolean']['output'];
  /** Manual intervention */
  manuallyInterven: Scalars['Boolean']['output'];
  /** Manual intervention */
  manuallyIntervenAllFailedItems: Scalars['Boolean']['output'];
  /** Manually mark an order as paid */
  manuallyMarkOrderAsPaid: Order;
  markOrderBoxesAsReturned: Maybe<Scalars['Boolean']['output']>;
  moveMenusToNewPerDishPricingModel: Scalars['Boolean']['output'];
  netcoreBatchAdd: Scalars['Boolean']['output'];
  netcorePurchaseEvent: Scalars['Boolean']['output'];
  netcoreSingleUserAttributeSync: Scalars['Boolean']['output'];
  /** Notify Menu */
  notifyMenu: Menu;
  oneSignalSync: Maybe<Scalars['Boolean']['output']>;
  /** Open Menu */
  openMenu: Menu;
  parseEssentialsSheet: Maybe<Scalars['String']['output']>;
  /** Pause a subscription */
  pauseSubscription: UserSubscription;
  /** Pause reason for subscription */
  pauseSubscriptionReason: UserSubscription;
  /** Pre cache the images for all dishes in this menu */
  preCacheMenuDishImages: Scalars['Boolean']['output'];
  /** Process Delayed Feedback */
  processDelayedFeedback: Scalars['Boolean']['output'];
  /** Process refund */
  processRefund: Refund;
  processUpload: Scalars['Boolean']['output'];
  /** Push Assembly Demand */
  pushAssemblyDemand: Scalars['ID']['output'];
  queueFullProductSync: Scalars['Boolean']['output'];
  /** Manually queue up stock sync with Acumatica */
  queueManualStockSync: Scalars['Boolean']['output'];
  /** queue order Reciepting */
  queueOrderRecieptCalculation: Scalars['Boolean']['output'];
  randomiseCarts: Scalars['String']['output'];
  reAssignShippingConsignemnt: ShippingConsignment;
  reGeocodeCity: City;
  reGeocodeLocation: Location;
  reIndexFrozenMealDishServingSuggestions: Maybe<Scalars['Boolean']['output']>;
  reIndexFrozenMealInstructions: Maybe<Scalars['Boolean']['output']>;
  reIndexMealKitInstructions: Maybe<Scalars['Boolean']['output']>;
  reIndexProductIngredients: Maybe<Scalars['Boolean']['output']>;
  realtimeSyncContact: Scalars['Boolean']['output'];
  recalculateAllStock: Scalars['Boolean']['output'];
  recalculateStock: ProductWarehouse;
  /** Receipt Menu */
  receiptMenu: Scalars['Boolean']['output'];
  redeemUserPointsForCart: Maybe<Cart>;
  /** Redeem User Points for an Order */
  redeemUserPointsForOrder: Order;
  referFriend: FriendReference;
  /** Refer a person */
  referPerson: Lead;
  /** Reset upload Meal kits from google sheets */
  refreshMealkitUploaderSheet: Scalars['Boolean']['output'];
  /** Refund an Order */
  refundOrder: Order;
  regenerateTransactionReport: Scalars['Boolean']['output'];
  registerCommunicationPlatform: UserCommunicationPlatformData;
  /** Register a new Device */
  registerDevice: DeviceInfo;
  /** Delete a AbTest */
  removeAbTest: Scalars['ID']['output'];
  /** Delete a AbTestBucket */
  removeAbTestBucket: Scalars['ID']['output'];
  /** Delete a AbTestBucketUser */
  removeAbTestBucketUser: Scalars['ID']['output'];
  /** Delete a AbTestFilter */
  removeAbTestFilter: Scalars['ID']['output'];
  /** Delete a AcumaticaDiscountNonStockSku */
  removeAcumaticaDiscountNonStockSku: Scalars['ID']['output'];
  /** Delete a AcumaticaProductTypeNonStockSku */
  removeAcumaticaProductTypeNonStockSku: Scalars['ID']['output'];
  /** Delete a Allergen */
  removeAllergen: Scalars['ID']['output'];
  /** Delete a Archive */
  removeArchive: Scalars['ID']['output'];
  removeBabyBox: Scalars['ID']['output'];
  /** Delete a BabyBoxCategory */
  removeBabyBoxCategory: Scalars['ID']['output'];
  /** Delete a BabyBoxTag */
  removeBabyBoxTag: Scalars['ID']['output'];
  removeBabyBoxTub: Scalars['ID']['output'];
  /** Delete a BabyBoxTubDetail */
  removeBabyBoxTubDetail: Scalars['ID']['output'];
  /** Delete a BankBinNumber */
  removeBankBinNumber: Scalars['ID']['output'];
  /** Delete a Barcode */
  removeBarcode: Scalars['ID']['output'];
  /** Delete a Box */
  removeBox: Scalars['ID']['output'];
  /** Delete a BoxRestriction */
  removeBoxRestriction: Scalars['ID']['output'];
  /** Delete a BoxSubContainer */
  removeBoxSubContainer: Scalars['ID']['output'];
  /** Delete a Branch */
  removeBranch: Scalars['ID']['output'];
  /** Delete a Brand */
  removeBrand: Scalars['ID']['output'];
  /** Delete a Category */
  removeCategory: Scalars['ID']['output'];
  /** Delete a CategoryGroup */
  removeCategoryGroup: Scalars['ID']['output'];
  /** Delete a Chef */
  removeChef: Scalars['ID']['output'];
  /** Delete a CommunicationTemplate */
  removeCommunicationTemplate: Scalars['ID']['output'];
  removeCouponFromCart: Cart;
  removeCouponFromOrder: Scalars['Boolean']['output'];
  /** Delete a Courier */
  removeCourier: Scalars['ID']['output'];
  /** Delete a CourierBox */
  removeCourierBox: Scalars['ID']['output'];
  /** Delete a CourierDelivery */
  removeCourierDelivery: Scalars['ID']['output'];
  /** Delete a CourierDeliveryDay */
  removeCourierDeliveryDay: Scalars['ID']['output'];
  /** Delete a CourierWarehouse */
  removeCourierWarehouse: Scalars['ID']['output'];
  /** Delete a CourierWarehouseCity */
  removeCourierWarehouseCity: Scalars['ID']['output'];
  /** Delete a CourierWarehouseCityDeliveryDay */
  removeCourierWarehouseCityDeliveryDay: Scalars['ID']['output'];
  /** Delete a CreditCard */
  removeCreditCard: Scalars['ID']['output'];
  /** Delete a DeletedUser */
  removeDeletedUser: Scalars['ID']['output'];
  /** Delete a DeliveryItem */
  removeDeliveryItem: Scalars['ID']['output'];
  /** Delete a DeliveryItemParcel */
  removeDeliveryItemParcel: Scalars['ID']['output'];
  /** Delete a DeliverySchedule */
  removeDeliverySchedule: Scalars['ID']['output'];
  /** Delete a Device */
  removeDevice: Scalars['ID']['output'];
  /** Delete a Discount */
  removeDiscount: Scalars['ID']['output'];
  /** Delete a DiscountCampaign */
  removeDiscountCampaign: Scalars['ID']['output'];
  /** Delete a DiscountCode */
  removeDiscountCode: Scalars['ID']['output'];
  /** Delete a DiscountTag */
  removeDiscountTag: Scalars['ID']['output'];
  /** Remove a dish from a users menu */
  removeDishFromUserMenu: UserMenuCartResponse;
  /** Delete a Excel */
  removeExcel: Scalars['ID']['output'];
  /** Delete a FileCategory */
  removeFileCategory: Scalars['ID']['output'];
  /** Delete a FriendReference */
  removeFriendReference: Scalars['ID']['output'];
  /** Delete a FrozenCategory */
  removeFrozenCategory: Scalars['ID']['output'];
  /** Delete a FrozenInstruction */
  removeFrozenInstruction: Scalars['ID']['output'];
  /** Delete a Frozen */
  removeFrozenMeal: Scalars['ID']['output'];
  removeFrozenMealDish: Scalars['ID']['output'];
  /** Delete a FrozenTag */
  removeFrozenTag: Scalars['ID']['output'];
  /** Delete a Gift */
  removeGift: Scalars['ID']['output'];
  /** Delete a GuestUser */
  removeGuestUser: Scalars['ID']['output'];
  /** Delete a Image */
  removeImage: Scalars['ID']['output'];
  /** Delete a Ingredient */
  removeIngredient: Scalars['ID']['output'];
  /** Remove an item to Cart */
  removeItemFromCart: CartProductCount;
  /** Remove an item to Order */
  removeItemFromOrder: Scalars['Boolean']['output'];
  /** Delete a Lead */
  removeLead: Scalars['ID']['output'];
  /** Delete a LeadUpload */
  removeLeadUpload: Scalars['ID']['output'];
  /** Delete a Location */
  removeLocation: Scalars['ID']['output'];
  /** Delete a LoyaltyTier */
  removeLoyaltyTier: Scalars['ID']['output'];
  removeManualEmail: Scalars['ID']['output'];
  /** Delete a MarketProduct */
  removeMarketProduct: Scalars['ID']['output'];
  /** Delete a MarketProductBrand */
  removeMarketProductBrand: Scalars['ID']['output'];
  /** Delete a MarketProductCategory */
  removeMarketProductCategory: Scalars['ID']['output'];
  /** Delete a MarketProductSubCategory */
  removeMarketProductSubCategory: Scalars['ID']['output'];
  /** Delete a MarketProductTag */
  removeMarketProductTag: Scalars['ID']['output'];
  /** Delete a MealKit */
  removeMealKit: Scalars['ID']['output'];
  /** Delete a MealKitCategory */
  removeMealKitCategory: Scalars['ID']['output'];
  /** Delete a MealKitDefaultPricing */
  removeMealKitDefaultPricing: Scalars['ID']['output'];
  /** Delete a MealKitDish */
  removeMealKitDish: Scalars['ID']['output'];
  /** Remove a flag from MealKit */
  removeMealKitFlag: MealKit;
  /** Delete a MealKitInstruction */
  removeMealKitInstruction: Scalars['ID']['output'];
  /** Delete a MealKitPlan */
  removeMealKitPlan: Scalars['ID']['output'];
  /** Delete a MealKitSubscriptionFee */
  removeMealKitSubscriptionFee: Scalars['ID']['output'];
  /** Delete a MealKitTag */
  removeMealKitTag: Scalars['ID']['output'];
  /** Delete a Menu */
  removeMenu: Scalars['ID']['output'];
  /** Delete a MenuCategory */
  removeMenuCategory: Scalars['ID']['output'];
  /** Delete a MenuDishWine */
  removeMenuDishWine: Scalars['ID']['output'];
  /** Delete a MenuWarehouse */
  removeMenuWarehouse: Scalars['ID']['output'];
  /** Delete a MinimumOrderValue */
  removeMinimumOrderValue: Scalars['ID']['output'];
  /** Delete a OrderAddress */
  removeOrderAddress: Scalars['ID']['output'];
  /** Delete a OrderBox */
  removeOrderBox: Scalars['ID']['output'];
  /** Delete a OrderBoxItem */
  removeOrderBoxItem: Scalars['ID']['output'];
  /** Delete a OrderHistory */
  removeOrderHistory: Scalars['ID']['output'];
  /** Delete a OrderItemRating */
  removeOrderItemRating: Scalars['ID']['output'];
  /** Delete a Rating */
  removeOrderRating: Scalars['ID']['output'];
  /** Delete a OrderSubContainer */
  removeOrderSubContainer: Scalars['ID']['output'];
  /** Delete a OrderTaxDiscount */
  removeOrderTaxDiscount: Scalars['ID']['output'];
  /** Delete a OrderTaxProduct */
  removeOrderTaxProduct: Scalars['ID']['output'];
  /** Delete a OrderTaxProductType */
  removeOrderTaxProductType: Scalars['ID']['output'];
  /** Delete a OrderTaxProductTypeDiscount */
  removeOrderTaxProductTypeDiscount: Scalars['ID']['output'];
  /** Delete a OrderTaxReceipt */
  removeOrderTaxReceipt: Scalars['ID']['output'];
  /** Delete a PackingVolumeDefault */
  removePackingVolumeDefault: Scalars['ID']['output'];
  /** Delete a PageBanner */
  removePageBanner: Scalars['ID']['output'];
  /** Delete a PageBannerSection */
  removePageBannerSection: Scalars['ID']['output'];
  /** Delete a PageCard */
  removePageCard: Scalars['ID']['output'];
  /** Delete a PageCardSection */
  removePageCardSection: Scalars['ID']['output'];
  /** Delete a PageDataSection */
  removePageDataSection: Scalars['ID']['output'];
  /** Delete a PageDetail */
  removePageDetail: Scalars['ID']['output'];
  /** Delete a PageDetailSection */
  removePageDetailSection: Scalars['ID']['output'];
  /** Delete a PageGridItem */
  removePageGridItem: Scalars['ID']['output'];
  /** Delete a PageGridSection */
  removePageGridSection: Scalars['ID']['output'];
  /** Delete a PageMediaSection */
  removePageMediaSection: Scalars['ID']['output'];
  /** Delete a PageModal */
  removePageModal: Scalars['ID']['output'];
  /** Delete a PageNotification */
  removePageNotification: Scalars['ID']['output'];
  /** Delete a PageNotificationGroup */
  removePageNotificationGroup: Scalars['ID']['output'];
  /** Delete a PageSectionGroup */
  removePageSectionGroup: Scalars['ID']['output'];
  /** Delete a PageTab */
  removePageTab: Scalars['ID']['output'];
  /** Delete a PageTabItem */
  removePageTabItem: Scalars['ID']['output'];
  /** Delete a PageTabSection */
  removePageTabSection: Scalars['ID']['output'];
  /** Delete a PageTextCard */
  removePageTextCard: Scalars['ID']['output'];
  /** Delete a PageTextCardSection */
  removePageTextCardSection: Scalars['ID']['output'];
  /** Delete a PageTextSection */
  removePageTextSection: Scalars['ID']['output'];
  /** Delete a PageTileSection */
  removePageTileSection: Scalars['ID']['output'];
  /** Delete a Pdf */
  removePdf: Scalars['ID']['output'];
  /** Delete a Preference */
  removePreference: Scalars['ID']['output'];
  /** Delete a PreferenceCategory */
  removePreferenceCategory: Scalars['ID']['output'];
  /** Delete a ProcessGroup */
  removeProcessGroup: Scalars['ID']['output'];
  /** Delete a ProcessGroupCourier */
  removeProcessGroupCourier: Scalars['ID']['output'];
  /** Delete a ProductAvailabilityEmail */
  removeProductAvailabilityEmail: Scalars['ID']['output'];
  /** Delete a ProductImage */
  removeProductImage: Scalars['ID']['output'];
  /** Delete a ProductIngredient */
  removeProductIngredient: Scalars['ID']['output'];
  /** Delete a ProductWarhouseCostHistory */
  removeProductWarhouseCostHistory: Scalars['ID']['output'];
  /** Delete a PublicHoliday */
  removePublicHoliday: Scalars['ID']['output'];
  /** Delete a RefundReason */
  removeRefundReason: Scalars['ID']['output'];
  /** Delete a RefundReportingReason */
  removeRefundReportingReason: Scalars['ID']['output'];
  /** Delete a RegisteredUser */
  removeRegisteredUser: Scalars['ID']['output'];
  /** Delete a Report */
  removeReport: Scalars['ID']['output'];
  /** Delete a Role */
  removeRole: Scalars['ID']['output'];
  /** Delete a ScheduleCategoryExclusion */
  removeScheduleCategoryExclusion: Scalars['ID']['output'];
  /** Delete a ScheduleCategoryInclusion */
  removeScheduleCategoryInclusion: Scalars['ID']['output'];
  /** Delete a ServingSuggestion */
  removeServingSuggestion: Scalars['ID']['output'];
  /** Delete a ShipperBox */
  removeShipperBox: Scalars['ID']['output'];
  /** Delete a ShipperWarehouse */
  removeShipperWarehouse: Scalars['ID']['output'];
  /** Delete a SmsTemplate */
  removeSmsTemplate: Scalars['ID']['output'];
  /** Delete a SubContainer */
  removeSubContainer: Scalars['ID']['output'];
  /** Delete a SubContainerOrderItem */
  removeSubContainerOrderItem: Scalars['ID']['output'];
  /** Delete a SubContainerRestriction */
  removeSubContainerRestriction: Scalars['ID']['output'];
  /** Delete a SubscriptionPriceHistory */
  removeSubscriptionPriceHistory: Scalars['ID']['output'];
  /** Delete a Supplier */
  removeSupplier: Scalars['ID']['output'];
  /** Delete a TaxClass */
  removeTaxClass: Scalars['ID']['output'];
  /** Delete a TaxReceiptEvent */
  removeTaxReceiptEvent: Scalars['ID']['output'];
  /** Delete a TransactionReport */
  removeTransactionReport: Scalars['ID']['output'];
  /** Delete a UserAddress */
  removeUserAddress: Scalars['ID']['output'];
  /** Delete a UserDevice */
  removeUserDevice: Scalars['ID']['output'];
  /** Delete a UserDiscount */
  removeUserDiscount: Scalars['ID']['output'];
  removeUserFromAbTest: Scalars['Boolean']['output'];
  /** Delete a UserHistory */
  removeUserHistory: Scalars['ID']['output'];
  /** Delete a UserPartnership */
  removeUserPartnership: Scalars['ID']['output'];
  /** Delete a UserPreference */
  removeUserPreference: Scalars['ID']['output'];
  /** Delete a UserRole */
  removeUserRole: Scalars['ID']['output'];
  /** Delete a UserSubscription */
  removeUserSubscription: Scalars['ID']['output'];
  /** Delete a Vendor */
  removeVendor: Scalars['ID']['output'];
  /** Delete a Vineyard */
  removeVineyard: Scalars['ID']['output'];
  /** Delete a Warehouse */
  removeWarehouse: Scalars['ID']['output'];
  /** Delete a WarehouseLocation */
  removeWarehouseLocation: Scalars['ID']['output'];
  /** Delete a Wine */
  removeWine: Scalars['ID']['output'];
  /** Delete a WineCampaign */
  removeWineCampaign: Scalars['ID']['output'];
  /** Delete a WineCategory */
  removeWineCategory: Scalars['ID']['output'];
  /** Delete a WineMaker */
  removeWineMaker: Scalars['ID']['output'];
  /** Delete a WineTag */
  removeWineTag: Scalars['ID']['output'];
  /** Reorder the index of page card group modals */
  reorderPageCards: Scalars['Boolean']['output'];
  /** Reorder the index of page section group sections */
  reorderPageSectionGroupSections: Scalars['Boolean']['output'];
  /** Reorder the index of categories preference */
  reorderPreferenceCategoriesPreferences: Scalars['Boolean']['output'];
  repairUserMenusConfigsandUserMenusDishes: Scalars['Boolean']['output'];
  /** Replace a new Location */
  replaceLocation: Location;
  /** Reset Password */
  requestPasswordReset: Scalars['Boolean']['output'];
  requestPhoneVerification: Maybe<Scalars['Boolean']['output']>;
  requeueAllFailed: Scalars['Boolean']['output'];
  requeueContainerSync: Scalars['Boolean']['output'];
  /** Resend failed order Email */
  resendFailedOrderEmail: Scalars['Boolean']['output'];
  /** Resend failed order sms */
  resendFailedOrderSMS: Scalars['Boolean']['output'];
  resendReferralEmail: Scalars['String']['output'];
  /** Resend Successful order email */
  resendSuccessfulOrderEmail: Scalars['Boolean']['output'];
  /** Reset Errors */
  resetMenuError: Menu;
  /** Reset Password */
  resetPassword: Scalars['Boolean']['output'];
  resetQueryCache: Maybe<Scalars['Boolean']['output']>;
  /** Reset user menu */
  resetUserMenu: UserMenuCartResponse;
  resolveDupelicateSkus: Scalars['Boolean']['output'];
  /** Reset Bucket */
  restartCourierDeliveryProcess: CourierDelivery;
  /** Restore a user menu from it's last saved configuration */
  restoreUserMenu: UserMenuCartResponse;
  /** Resume Menu */
  resumeMenu: Menu;
  resyncSubAssemblies: Maybe<Scalars['Boolean']['output']>;
  /** Retake the snapshot */
  retakeSnapshot: Scalars['Boolean']['output'];
  /** RetroactiveFix */
  retroactiveReferralFix: Maybe<Scalars['Boolean']['output']>;
  /** Retry all Failed Items */
  retryAllFailedItems: Scalars['Boolean']['output'];
  /** RetryFailedItem */
  retryItem: Scalars['Boolean']['output'];
  /** Retry failed refund */
  retryRefund: Refund;
  /** Retry integration */
  retryShipmentIntegration: Shipment;
  revokeAdminAccess: Maybe<Scalars['Boolean']['output']>;
  rollupCities: Scalars['Boolean']['output'];
  /** Assign Boxes */
  runDispatchDayBoxAssignment: DispatchDay;
  /** Run watch dog process */
  runMenuWatchDog: Scalars['Boolean']['output'];
  /** Run an operation on a Shipping Consignment */
  runShippingConsignmentOperation: ShippingConsignment;
  saveHistoricDiscoverySyncData: Scalars['Boolean']['output'];
  /** Save any changes to the user menu, The menu will be reverted to the last saved instance before billing */
  saveUserMenu: UserMenuSave;
  saveUserMenus: Maybe<Scalars['Boolean']['output']>;
  saveUserPreferenceByToken: Maybe<UserPreferenceByToken>;
  sendDeliveryReminderSMS: Scalars['Boolean']['output'];
  sendEmails: Scalars['Boolean']['output'];
  /** Send Invitation */
  sendInvitation: Maybe<Scalars['Boolean']['output']>;
  /** Send Payment link for an Order */
  sendPaymentLinkForOrder: Order;
  setAvailableStockForProductRange: Maybe<Scalars['Boolean']['output']>;
  /** Set Bucket Reference */
  setCourierDeliveryBucketReference: CourierDelivery;
  /** set default address */
  setDefaultAddress: UserAddress;
  setLocationCity: Scalars['Boolean']['output'];
  /** Set Menu Categories Display Order */
  setMenuCategoriesDisplayOrder: Scalars['Boolean']['output'];
  /** Set Order credit card */
  setOrderCreditCard: Order;
  /** Set Order delivery Address */
  setOrderDeliveryAddress: Order;
  setPageSections: Maybe<Scalars['Boolean']['output']>;
  setStockTypeForProductRange: Maybe<Scalars['Boolean']['output']>;
  /** Ship an Order */
  shipOrder: Order;
  /** Skip Failed Items */
  skipFailedItems: Scalars['Boolean']['output'];
  /** Skip Item */
  skipItem: Scalars['Boolean']['output'];
  /** Start process for Date */
  startProcess: Scalars['Boolean']['output'];
  subscribeToAcList: Maybe<Scalars['Boolean']['output']>;
  swapOutDish: Scalars['Boolean']['output'];
  /** Triggers sync for all orders that have not synced */
  syncAllDiscoveryOrders: Maybe<Scalars['Boolean']['output']>;
  /** Sync all cut off orders to Acumatica */
  syncDispatchDayOrders: DispatchDay;
  syncIngredients: Scalars['Boolean']['output'];
  /** Sync Menu Orders */
  syncMenuOrders: Scalars['ID']['output'];
  syncMenuSummaryOrders: Scalars['Boolean']['output'];
  /** Trigger discovery sync for specific order */
  syncOneDiscoveryOrder: Maybe<Scalars['Boolean']['output']>;
  /** Sync an Order */
  syncOrder: Order;
  syncOrderSubContainers: Maybe<Scalars['Boolean']['output']>;
  /** Sync Product with Acumatica */
  syncProduct: Product;
  syncProductCost: Product;
  /** Sync User to Acumatica */
  syncUserToAcumatica: Maybe<Scalars['Boolean']['output']>;
  /** Sync wine campaign to page sections */
  syncWineCampaign: Scalars['Boolean']['output'];
  /** Debit payment - Debits the account of the end customer and credits the merchant account. */
  takePayment: Payment;
  testMenus: Maybe<Scalars['Boolean']['output']>;
  testPayment: Scalars['ID']['output'];
  testPeachStatusCodes: Maybe<Scalars['Boolean']['output']>;
  testRecipeCard: Scalars['String']['output'];
  testRefund: Scalars['Boolean']['output'];
  testSlack: Scalars['Boolean']['output'];
  testSocketChange: Scalars['String']['output'];
  tiktokRequest: Scalars['Boolean']['output'];
  /** Trigger Sending of an Communicatioon Template */
  triggerCommunicationTemplate: Maybe<Scalars['Boolean']['output']>;
  /** Trigger an ManualEmail */
  triggerManualEmail: Scalars['Boolean']['output'];
  /** Trigger Sending of an SMS Template */
  triggerSmsTemplate: Maybe<Scalars['Boolean']['output']>;
  unVerifyUserPhone: Maybe<Scalars['Boolean']['output']>;
  /** Remove a permission from a role */
  unassignPermission: Scalars['Boolean']['output'];
  /** Update an existing AbTest */
  updateAbTest: AbTest;
  /** Update an existing AbTestBucket */
  updateAbTestBucket: AbTestBucket;
  /** Update an existing AbTestBucketUser */
  updateAbTestBucketUser: AbTestBucketUser;
  /** Update an existing AbTestFilter */
  updateAbTestFilter: AbTestFilter;
  /** Update an existing AcumaticaDiscountNonStockSku */
  updateAcumaticaDiscountNonStockSku: AcumaticaDiscountNonStockSku;
  /** Update an existing AcumaticaProductTypeNonStockSku */
  updateAcumaticaProductTypeNonStockSku: AcumaticaProductTypeNonStockSku;
  updateAffectedUserMenus: Scalars['Boolean']['output'];
  /** Update an existing Allergen */
  updateAllergen: Allergen;
  /** Update an existing Archive */
  updateArchive: Archive;
  updateBabyBox: BabyBox;
  /** Update an existing BabyBoxCategory */
  updateBabyBoxCategory: BabyBoxCategory;
  /** Update an existing BabyBoxTag */
  updateBabyBoxTag: BabyBoxTag;
  updateBabyBoxTub: BabyBoxTub;
  /** Update an existing BabyBoxTubDetail */
  updateBabyBoxTubDetail: BabyBoxTubDetail;
  /** Update an existing BankBinNumber */
  updateBankBinNumber: BankBinNumber;
  /** Update an existing Barcode */
  updateBarcode: Barcode;
  /** Update an existing Box */
  updateBox: Box;
  /** Update an existing BoxRestriction */
  updateBoxRestriction: BoxRestriction;
  /** Update an existing BoxSubContainer */
  updateBoxSubContainer: BoxSubContainer;
  /** Update an existing Branch */
  updateBranch: Branch;
  /** Update an existing Brand */
  updateBrand: Brand;
  /** Update an existing Category */
  updateCategory: Category;
  /** Update an existing CategoryGroup */
  updateCategoryGroup: CategoryGroup;
  /** Update an existing Chef */
  updateChef: Chef;
  /** Update an existing City */
  updateCity: City;
  /** Update an existing CommunicationTemplate */
  updateCommunicationTemplate: CommunicationTemplate;
  /** Update an existing Country */
  updateCountry: Country;
  /** Update an existing Courier */
  updateCourier: Courier;
  /** Update an existing CourierBox */
  updateCourierBox: CourierBox;
  /** Update an existing CourierDelivery */
  updateCourierDelivery: CourierDelivery;
  /** Update an existing CourierDeliveryDay */
  updateCourierDeliveryDay: CourierDeliveryDay;
  /** Update an existing CourierWarehouse */
  updateCourierWarehouse: CourierWarehouse;
  /** Update an existing CourierWarehouseCity */
  updateCourierWarehouseCity: CourierWarehouseCity;
  /** Update an existing CourierWarehouseCityDeliveryDay */
  updateCourierWarehouseCityDeliveryDay: CourierWarehouseCityDeliveryDay;
  /** Update an existing CreditCard */
  updateCreditCard: CreditCard;
  /** Update an existing DeletedUser */
  updateDeletedUser: DeletedUser;
  /** Update an existing DeliveryItem */
  updateDeliveryItem: DeliveryItem;
  /** Update an existing DeliveryItemParcel */
  updateDeliveryItemParcel: DeliveryItemParcel;
  /** Update an existing DeliverySchedule */
  updateDeliverySchedule: DeliverySchedule;
  /** Update an existing Device */
  updateDevice: Device;
  /** Update an existing Discount */
  updateDiscount: Discount;
  /** Update an existing DiscountCampaign */
  updateDiscountCampaign: DiscountCampaign;
  /** Update an existing DiscountCode */
  updateDiscountCode: DiscountCode;
  /** Update an existing DiscountTag */
  updateDiscountTag: DiscountTag;
  /** Update an existing Excel */
  updateExcel: Excel;
  /** Update an existing FileCategory */
  updateFileCategory: FileCategory;
  /** Update an existing FriendReference */
  updateFriendReference: FriendReference;
  /** Update an existing FrozenCategory */
  updateFrozenCategory: FrozenCategory;
  /** Update an existing FrozenInstruction */
  updateFrozenInstruction: FrozenInstruction;
  /** Update an existing Frozen */
  updateFrozenMeal: FrozenMeal;
  updateFrozenMealDish: FrozenMealDish;
  /** Update an existing FrozenTag */
  updateFrozenTag: FrozenTag;
  /** Update an existing Gift */
  updateGift: Gift;
  /** Update an existing GuestUser */
  updateGuestUser: GuestUser;
  /** Update an existing Image */
  updateImage: Image;
  /** Update an existing Ingredient */
  updateIngredient: Ingredient;
  /** Update an existing Lead */
  updateLead: Lead;
  /** Update an existing LeadUpload */
  updateLeadUpload: LeadUpload;
  updateLegacyMealKitNumberEnum: Maybe<Scalars['Boolean']['output']>;
  /** Update a new Location */
  updateLocation: Location;
  /** Update an existing LoyaltyTier */
  updateLoyaltyTier: LoyaltyTier;
  /** Update an existing ManualEmail */
  updateManualEmail: ManualEmail;
  /** Update an existing MarketProduct */
  updateMarketProduct: MarketProduct;
  /** Update an existing MarketProductBrand */
  updateMarketProductBrand: MarketProductBrand;
  /** Update an existing MarketProductCategory */
  updateMarketProductCategory: MarketProductCategory;
  /** Update an existing MarketProductSubCategory */
  updateMarketProductSubCategory: MarketProductSubCategory;
  /** Update an existing MarketProductTag */
  updateMarketProductTag: MarketProductTag;
  /** Update an existing MealKit */
  updateMealKit: MealKit;
  /** Update an existing MealKitCategory */
  updateMealKitCategory: MealKitCategory;
  /** Update an existing MealKitDefaultPricing */
  updateMealKitDefaultPricing: MealKitDefaultPricing;
  /** Update an existing MealKitDish */
  updateMealKitDish: MealKitDish;
  /** Update an existing MealKitInstruction */
  updateMealKitInstruction: MealKitInstruction;
  /** Update an existing MealKitPlan */
  updateMealKitPlan: MealKitPlan;
  /** Update an existing MealKitSubscriptionFee */
  updateMealKitSubscriptionFee: MealKitSubscriptionFee;
  /** Update an existing MealKitTag */
  updateMealKitTag: MealKitTag;
  /** Update an existing Menu */
  updateMenu: Menu;
  /** Update an existing MenuCategory */
  updateMenuCategory: MenuCategory;
  /** Update an existing MenuDishWine */
  updateMenuDishWine: MenuDishWine;
  /** Update an existing MenuWarehouse */
  updateMenuWarehouse: MenuWarehouse;
  /** Update an existing MinimumOrderValue */
  updateMinimumOrderValue: MinimumOrderValue;
  /** Update an existing OrderAddress */
  updateOrderAddress: OrderAddress;
  /** Update Order Address Text */
  updateOrderAddressText: OrderAddress;
  /** Update an existing OrderBox */
  updateOrderBox: OrderBox;
  /** Update an existing OrderBoxItem */
  updateOrderBoxItem: OrderBoxItem;
  /** Update an existing OrderHistory */
  updateOrderHistory: OrderHistory;
  /** Update an existing OrderItemRating */
  updateOrderItemRating: OrderItemRating;
  /** Update an existing Rating */
  updateOrderRating: OrderRating;
  /** Update an existing OrderSubContainer */
  updateOrderSubContainer: OrderSubContainer;
  /** Update an existing OrderTaxDiscount */
  updateOrderTaxDiscount: OrderTaxDiscount;
  /** Update an existing OrderTaxProduct */
  updateOrderTaxProduct: OrderTaxProduct;
  /** Update an existing OrderTaxProductType */
  updateOrderTaxProductType: OrderTaxProductType;
  /** Update an existing OrderTaxProductTypeDiscount */
  updateOrderTaxProductTypeDiscount: OrderTaxProductTypeDiscount;
  /** Update an existing OrderTaxReceipt */
  updateOrderTaxReceipt: OrderTaxReceipt;
  /** Update an existing PackingVolumeDefault */
  updatePackingVolumeDefault: PackingVolumeDefault;
  /** Update an existing PageBanner */
  updatePageBanner: PageBanner;
  /** Update an existing PageBannerSection */
  updatePageBannerSection: PageBannerSection;
  /** Update an existing PageCard */
  updatePageCard: PageCard;
  /** Update an existing PageCardSection */
  updatePageCardSection: PageCardSection;
  /** Update an existing PageDataSection */
  updatePageDataSection: PageDataSection;
  /** Update an existing PageDetail */
  updatePageDetail: PageDetail;
  /** Update an existing PageDetailSection */
  updatePageDetailSection: PageDetailSection;
  /** Update an existing PageGridItem */
  updatePageGridItem: PageGridItem;
  /** Update an existing PageGridSection */
  updatePageGridSection: PageGridSection;
  /** Update an existing PageMediaSection */
  updatePageMediaSection: PageMediaSection;
  /** Update an existing PageModal */
  updatePageModal: PageModal;
  /** Update an existing PageNotification */
  updatePageNotification: PageNotification;
  /** Update an existing PageNotificationGroup */
  updatePageNotificationGroup: PageNotificationGroup;
  /** Update an existing PageSectionGroup */
  updatePageSectionGroup: PageSectionGroup;
  /** Update an existing PageTab */
  updatePageTab: PageTab;
  /** Update an existing PageTabItem */
  updatePageTabItem: PageTabItem;
  /** Update an existing PageTabSection */
  updatePageTabSection: PageTabSection;
  /** Update an existing PageTextCard */
  updatePageTextCard: PageTextCard;
  /** Update an existing PageTextCardSection */
  updatePageTextCardSection: PageTextCardSection;
  /** Update an existing PageTextSection */
  updatePageTextSection: PageTextSection;
  /** Update an existing PageTileSection */
  updatePageTileSection: PageTileSection;
  /** Update an existing Pdf */
  updatePdf: Pdf;
  /** Update an existing Preference */
  updatePreference: Preference;
  /** Update an existing PreferenceCategory */
  updatePreferenceCategory: PreferenceCategory;
  /** Update an existing ProcessGroup */
  updateProcessGroup: ProcessGroup;
  /** Update an existing ProcessGroupCourier */
  updateProcessGroupCourier: ProcessGroupCourier;
  /** Update an existing ProductAvailabilityEmail */
  updateProductAvailabilityEmail: ProductAvailabilityEmail;
  /** Update an existing ProductImage */
  updateProductImage: ProductImage;
  /** Update an existing ProductIngredient */
  updateProductIngredient: ProductIngredient;
  /** Update an existing ProductWarehouse */
  updateProductWarehouse: ProductWarehouse;
  /** Update an existing ProductWarhouseCostHistory */
  updateProductWarhouseCostHistory: ProductWarhouseCostHistory;
  /** Update an existing PublicHoliday */
  updatePublicHoliday: PublicHoliday;
  /** Update an existing RefundReason */
  updateRefundReason: RefundReason;
  /** Update an existing RefundReportingReason */
  updateRefundReportingReason: RefundReportingReason;
  /** Update an existing Region */
  updateRegion: Region;
  /** Update an existing RegisteredUser */
  updateRegisteredUser: RegisteredUser;
  /** Update an existing Report */
  updateReport: Report;
  /** Update an existing Role */
  updateRole: Role;
  /** Update an existing ScheduleCategoryExclusion */
  updateScheduleCategoryExclusion: ScheduleCategoryExclusion;
  /** Update an existing ScheduleCategoryInclusion */
  updateScheduleCategoryInclusion: ScheduleCategoryInclusion;
  /** Update an existing ServingSuggestion */
  updateServingSuggestion: ServingSuggestion;
  /** Update the shipments address */
  updateShipmentAddress: Shipment;
  /** Update an existing ShipperBox */
  updateShipperBox: ShipperBox;
  /** Update an existing ShipperWarehouse */
  updateShipperWarehouse: ShipperWarehouse;
  /** Update an existing SmsTemplate */
  updateSmsTemplate: SmsTemplate;
  /** Update an existing SubContainer */
  updateSubContainer: SubContainer;
  /** Update an existing SubContainerOrderItem */
  updateSubContainerOrderItem: SubContainerOrderItem;
  /** Update an existing SubContainerRestriction */
  updateSubContainerRestriction: SubContainerRestriction;
  /** Update an existing SubscriptionPriceHistory */
  updateSubscriptionPriceHistory: SubscriptionPriceHistory;
  /** Update an existing Supplier */
  updateSupplier: Supplier;
  /** Update an existing TaxClass */
  updateTaxClass: TaxClass;
  /** Update an existing TaxReceiptEvent */
  updateTaxReceiptEvent: TaxReceiptEvent;
  /** Update an existing TransactionReport */
  updateTransactionReport: TransactionReport;
  /** Update an existing UserAddress */
  updateUserAddress: UserAddress;
  /** Update an existing UserDevice */
  updateUserDevice: UserDevice;
  /** Update an existing UserDiscount */
  updateUserDiscount: UserDiscount;
  /** Update an existing UserHistory Event */
  updateUserHistory: UserHistory;
  /** Update an existing UserMenu */
  updateUserMenu: UserMenuCartResponse;
  /** Update an existing UserPartnership */
  updateUserPartnership: UserPartnership;
  /** Update an existing UserPreference */
  updateUserPreference: UserPreference;
  /** Update User Preferences in a PreferenceCategory */
  updateUserPreferenceByCategory: CategorisedUserPreferencesList;
  /** Update an existing UserRole - FAKE code gen method DO NOT USE  */
  updateUserRole: UserRole;
  /** Update an existing UserSubscription */
  updateUserSubscription: UserSubscription;
  /** Update an existing Vendor */
  updateVendor: Vendor;
  /** Update an existing Vineyard */
  updateVineyard: Vineyard;
  /** Update an existing Warehouse */
  updateWarehouse: Warehouse;
  /** Update an existing WarehouseLocation */
  updateWarehouseLocation: WarehouseLocation;
  /** Update an existing WarehouseProductRange */
  updateWarehouseProductRange: WarehouseProductRange;
  /** Update an existing Wine */
  updateWine: Wine;
  /** Update an existing WineCampaign */
  updateWineCampaign: WineCampaign;
  /** Update an existing WineCategory */
  updateWineCategory: WineCategory;
  /** Update an existing WineMaker */
  updateWineMaker: WineMaker;
  /** Update an existing WineTag */
  updateWineTag: WineTag;
  uploadFBCatalogueItems: Maybe<Scalars['Boolean']['output']>;
  /** Upload Meal kits from google sheets */
  uploadMealkitsFromSheet: Scalars['Boolean']['output'];
  /** User Login */
  userLogin: User;
  userLogout: Maybe<Scalars['Boolean']['output']>;
  /** User Sign up */
  userSignUp: User;
  /** User Login */
  userSocialLogin: User;
  /** User Sign up */
  userSocialSignUp: User;
  /** Validate Credit Card */
  validateCreditCard: CreditCardValidation;
  validateDevice: Maybe<DeviceInfo>;
  validateFriendReference: ValidateResponse;
  verifyPhone: Maybe<Scalars['Boolean']['output']>;
};


export type MutationabandonCartArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationacceptUserMenuCookWithInDaysArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationacceptUserMenuPriceDifferenceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationactivateAllCodesForDiscountCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationactivateSubscriptionArgs = {
  id: Scalars['ID']['input'];
  reset?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationaddAbTestArgs = {
  input: AddAbTestInput;
};


export type MutationaddAbTestBucketArgs = {
  input: AddAbTestBucketInput;
};


export type MutationaddAbTestBucketUserArgs = {
  input: AddAbTestBucketUserInput;
};


export type MutationaddAbTestFilterArgs = {
  input: AddAbTestFilterInput;
};


export type MutationaddAcumaticaDiscountNonStockSkuArgs = {
  input: AddAcumaticaDiscountNonStockSkuInput;
};


export type MutationaddAcumaticaProductTypeNonStockSkuArgs = {
  input: AddAcumaticaProductTypeNonStockSkuInput;
};


export type MutationaddAllUserPreferencesArgs = {
  input?: InputMaybe<AddAllUserPreferencesInput>;
};


export type MutationaddAllergenArgs = {
  input: AddAllergenInput;
};


export type MutationaddAndValidateCreditCardArgs = {
  input: AddAndValidateCreditCardInput;
};


export type MutationaddArchiveArgs = {
  input: AddArchiveInput;
};


export type MutationaddBabyBoxArgs = {
  input: AddBabyBoxInput;
};


export type MutationaddBabyBoxCategoryArgs = {
  input: AddBabyBoxCategoryInput;
};


export type MutationaddBabyBoxTagArgs = {
  input: AddBabyBoxTagInput;
};


export type MutationaddBabyBoxTubArgs = {
  input: AddBabyBoxTubInput;
};


export type MutationaddBabyBoxTubDetailArgs = {
  input: AddBabyBoxTubDetailInput;
};


export type MutationaddBankBinNumberArgs = {
  input: AddBankBinNumberInput;
};


export type MutationaddBarcodeArgs = {
  input: AddBarcodeInput;
};


export type MutationaddBoxArgs = {
  input: AddBoxInput;
};


export type MutationaddBoxRestrictionArgs = {
  input: AddBoxRestrictionInput;
};


export type MutationaddBoxSubContainerArgs = {
  input: AddBoxSubContainerInput;
};


export type MutationaddBranchArgs = {
  input: AddBranchInput;
};


export type MutationaddBrandArgs = {
  input: AddBrandInput;
};


export type MutationaddCategoryArgs = {
  input: AddCategoryInput;
};


export type MutationaddCategoryGroupArgs = {
  input: AddCategoryGroupInput;
};


export type MutationaddChefArgs = {
  input: AddChefInput;
};


export type MutationaddCommunicationTemplateArgs = {
  input: AddCommunicationTemplateInput;
};


export type MutationaddCouponToCartArgs = {
  cartId?: InputMaybe<Scalars['ID']['input']>;
  coupon: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationaddCouponToOrderArgs = {
  coupon: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};


export type MutationaddCourierArgs = {
  input: AddCourierInput;
};


export type MutationaddCourierBoxArgs = {
  input: AddCourierBoxInput;
};


export type MutationaddCourierDeliveryArgs = {
  input: AddCourierDeliveryInput;
};


export type MutationaddCourierDeliveryDayArgs = {
  input: AddCourierDeliveryDayInput;
};


export type MutationaddCourierWarehouseArgs = {
  input: AddCourierWarehouseInput;
};


export type MutationaddCourierWarehouseCityArgs = {
  input: AddCourierWarehouseCityInput;
};


export type MutationaddCourierWarehouseCityDeliveryDayArgs = {
  input: AddCourierWarehouseCityDeliveryDayInput;
};


export type MutationaddDeletedUserArgs = {
  input: AddDeletedUserInput;
};


export type MutationaddDeliveryItemArgs = {
  input: AddDeliveryItemInput;
};


export type MutationaddDeliveryItemParcelArgs = {
  input: AddDeliveryItemParcelInput;
};


export type MutationaddDeliveryScheduleArgs = {
  input: AddDeliveryScheduleInput;
};


export type MutationaddDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationaddDiscountArgs = {
  input: AddDiscountInput;
};


export type MutationaddDiscountCampaignArgs = {
  input: AddDiscountCampaignInput;
};


export type MutationaddDiscountCodeArgs = {
  input: AddDiscountCodeInput;
};


export type MutationaddDiscountTagArgs = {
  input: AddDiscountTagInput;
};


export type MutationaddDiscountToCartsBypassValidationsArgs = {
  discountId?: InputMaybe<Scalars['String']['input']>;
  discountQuery?: InputMaybe<Scalars['String']['input']>;
};


export type MutationaddDishToUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: AddDishToUserMenuInput;
};


export type MutationaddExcelArgs = {
  input: AddExcelInput;
};


export type MutationaddFileCategoryArgs = {
  input: AddFileCategoryInput;
};


export type MutationaddFriendReferenceArgs = {
  input: AddFriendReferenceInput;
};


export type MutationaddFrozenCategoryArgs = {
  input: AddFrozenCategoryInput;
};


export type MutationaddFrozenInstructionArgs = {
  input: AddFrozenInstructionInput;
};


export type MutationaddFrozenMealArgs = {
  input: AddFrozenMealInput;
};


export type MutationaddFrozenMealDishArgs = {
  input: AddFrozenMealDishInput;
};


export type MutationaddFrozenTagArgs = {
  input: AddFrozenTagInput;
};


export type MutationaddGiftArgs = {
  input: AddGiftInput;
};


export type MutationaddGoogleClientIdArgs = {
  googleClientId: Scalars['String']['input'];
};


export type MutationaddGuestUserArgs = {
  input: AddGuestUserInput;
};


export type MutationaddImageArgs = {
  input: AddImageInput;
};


export type MutationaddIngredientArgs = {
  input: AddIngredientInput;
};


export type MutationaddItemToCartArgs = {
  cartId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationaddItemToOrderArgs = {
  orderId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationaddLeadArgs = {
  input: AddLeadInput;
};


export type MutationaddLeadUploadArgs = {
  input: AddLeadUploadInput;
};


export type MutationaddLocationArgs = {
  input: AddLocationInput;
};


export type MutationaddLoyaltyTierArgs = {
  input: AddLoyaltyTierInput;
};


export type MutationaddManualEmailArgs = {
  input: AddManualEmailInput;
};


export type MutationaddMarketProductArgs = {
  input: AddMarketProductInput;
};


export type MutationaddMarketProductBrandArgs = {
  input: AddMarketProductBrandInput;
};


export type MutationaddMarketProductCategoryArgs = {
  input: AddMarketProductCategoryInput;
};


export type MutationaddMarketProductSubCategoryArgs = {
  input: AddMarketProductSubCategoryInput;
};


export type MutationaddMarketProductTagArgs = {
  input: AddMarketProductTagInput;
};


export type MutationaddMealKitArgs = {
  input: AddMealKitInput;
};


export type MutationaddMealKitCategoryArgs = {
  input: AddMealKitCategoryInput;
};


export type MutationaddMealKitDefaultPricingArgs = {
  input: AddMealKitDefaultPricingInput;
};


export type MutationaddMealKitDishArgs = {
  input: AddMealKitDishInput;
};


export type MutationaddMealKitFlagArgs = {
  flag: MealKitFlagEnum;
  id: Scalars['ID']['input'];
};


export type MutationaddMealKitInstructionArgs = {
  input: AddMealKitInstructionInput;
};


export type MutationaddMealKitPlanArgs = {
  input: AddMealKitPlanInput;
};


export type MutationaddMealKitSubscriptionFeeArgs = {
  input: AddMealKitSubscriptionFeeInput;
};


export type MutationaddMealKitTagArgs = {
  input: AddMealKitTagInput;
};


export type MutationaddMenuArgs = {
  input: AddMenuInput;
};


export type MutationaddMenuCategoryArgs = {
  input: AddMenuCategoryInput;
};


export type MutationaddMenuDishWineArgs = {
  input: AddMenuDishWineInput;
};


export type MutationaddMenuWarehouseArgs = {
  input: AddMenuWarehouseInput;
};


export type MutationaddMinimumOrderValueArgs = {
  input: AddMinimumOrderValueInput;
};


export type MutationaddOrderAddressArgs = {
  input: AddOrderAddressInput;
};


export type MutationaddOrderBoxArgs = {
  input: AddOrderBoxInput;
};


export type MutationaddOrderBoxItemArgs = {
  input: AddOrderBoxItemInput;
};


export type MutationaddOrderHistoryArgs = {
  input: AddOrderHistoryInput;
};


export type MutationaddOrderItemRatingArgs = {
  input: AddOrderItemRatingInput;
};


export type MutationaddOrderRatingArgs = {
  input: AddOrderRatingInput;
};


export type MutationaddOrderSubContainerArgs = {
  input: AddOrderSubContainerInput;
};


export type MutationaddOrderTaxDiscountArgs = {
  input: AddOrderTaxDiscountInput;
};


export type MutationaddOrderTaxProductArgs = {
  input: AddOrderTaxProductInput;
};


export type MutationaddOrderTaxProductTypeArgs = {
  input: AddOrderTaxProductTypeInput;
};


export type MutationaddOrderTaxProductTypeDiscountArgs = {
  input: AddOrderTaxProductTypeDiscountInput;
};


export type MutationaddOrderTaxReceiptArgs = {
  input: AddOrderTaxReceiptInput;
};


export type MutationaddPackingVolumeDefaultArgs = {
  input: AddPackingVolumeDefaultInput;
};


export type MutationaddPageBannerArgs = {
  input: AddPageBannerInput;
};


export type MutationaddPageBannerSectionArgs = {
  input: AddPageBannerSectionInput;
};


export type MutationaddPageCardArgs = {
  input: AddPageCardInput;
};


export type MutationaddPageCardSectionArgs = {
  input: AddPageCardSectionInput;
};


export type MutationaddPageDataSectionArgs = {
  input: AddPageDataSectionInput;
};


export type MutationaddPageDetailArgs = {
  input: AddPageDetailInput;
};


export type MutationaddPageDetailSectionArgs = {
  input: AddPageDetailSectionInput;
};


export type MutationaddPageGridItemArgs = {
  input: AddPageGridItemInput;
};


export type MutationaddPageGridSectionArgs = {
  input: AddPageGridSectionInput;
};


export type MutationaddPageMediaSectionArgs = {
  input: AddPageMediaSectionInput;
};


export type MutationaddPageModalArgs = {
  input: AddPageModalInput;
};


export type MutationaddPageNotificationArgs = {
  input: AddPageNotificationInput;
};


export type MutationaddPageNotificationGroupArgs = {
  input: AddPageNotificationGroupInput;
};


export type MutationaddPageSectionGroupArgs = {
  input: AddPageSectionGroupInput;
};


export type MutationaddPageTabArgs = {
  input: AddPageTabInput;
};


export type MutationaddPageTabItemArgs = {
  input: AddPageTabItemInput;
};


export type MutationaddPageTabSectionArgs = {
  input: AddPageTabSectionInput;
};


export type MutationaddPageTextCardArgs = {
  input: AddPageTextCardInput;
};


export type MutationaddPageTextCardSectionArgs = {
  input: AddPageTextCardSectionInput;
};


export type MutationaddPageTextSectionArgs = {
  input: AddPageTextSectionInput;
};


export type MutationaddPageTileSectionArgs = {
  input: AddPageTileSectionInput;
};


export type MutationaddPdfArgs = {
  input: AddPdfInput;
};


export type MutationaddPreferenceArgs = {
  input: AddPreferenceInput;
};


export type MutationaddPreferenceCategoryArgs = {
  input: AddPreferenceCategoryInput;
};


export type MutationaddProcessGroupArgs = {
  input: AddProcessGroupInput;
};


export type MutationaddProcessGroupCourierArgs = {
  input: AddProcessGroupCourierInput;
};


export type MutationaddProductAvailabilityEmailArgs = {
  input: AddProductAvailabilityEmailInput;
};


export type MutationaddProductImageArgs = {
  input: AddProductImageInput;
};


export type MutationaddProductIngredientArgs = {
  input: AddProductIngredientInput;
};


export type MutationaddProductWarhouseCostHistoryArgs = {
  input: AddProductWarhouseCostHistoryInput;
};


export type MutationaddPublicHolidayArgs = {
  input: AddPublicHolidayInput;
};


export type MutationaddRefundArgs = {
  input: AddRefundInput;
};


export type MutationaddRefundHistoryArgs = {
  input: AddRefundHistoryInput;
};


export type MutationaddRefundReasonArgs = {
  input: AddRefundReasonInput;
};


export type MutationaddRefundReportingReasonArgs = {
  input: AddRefundReportingReasonInput;
};


export type MutationaddRegisteredUserArgs = {
  input: AddRegisteredUserInput;
};


export type MutationaddReportArgs = {
  input: AddReportInput;
};


export type MutationaddReportFileArgs = {
  type?: InputMaybe<ReportFileStatusTypeEnum>;
};


export type MutationaddRoleArgs = {
  input: AddRoleInput;
};


export type MutationaddScheduleCategoryExclusionArgs = {
  input: AddScheduleCategoryExclusionInput;
};


export type MutationaddScheduleCategoryInclusionArgs = {
  input: AddScheduleCategoryInclusionInput;
};


export type MutationaddServingSuggestionArgs = {
  input: AddServingSuggestionInput;
};


export type MutationaddShipperBoxArgs = {
  input: AddShipperBoxInput;
};


export type MutationaddShipperWarehouseArgs = {
  input: AddShipperWarehouseInput;
};


export type MutationaddSmsTemplateArgs = {
  input: AddSmsTemplateInput;
};


export type MutationaddSubContainerArgs = {
  input: AddSubContainerInput;
};


export type MutationaddSubContainerOrderItemArgs = {
  input: AddSubContainerOrderItemInput;
};


export type MutationaddSubContainerRestrictionArgs = {
  input: AddSubContainerRestrictionInput;
};


export type MutationaddSubscriptionPriceHistoryArgs = {
  input: AddSubscriptionPriceHistoryInput;
};


export type MutationaddSupplierArgs = {
  input: AddSupplierInput;
};


export type MutationaddTaxClassArgs = {
  input: AddTaxClassInput;
};


export type MutationaddTaxReceiptEventArgs = {
  input: AddTaxReceiptEventInput;
};


export type MutationaddTrackingDataToCartArgs = {
  trackingData?: InputMaybe<Scalars['JSONObject']['input']>;
};


export type MutationaddTransactionReportArgs = {
  input: AddTransactionReportInput;
};


export type MutationaddUpdateCategorisedUserPreferenceArgs = {
  input?: InputMaybe<AddUserPreferenceInput>;
};


export type MutationaddUpdateUserPreferenceArgs = {
  input?: InputMaybe<AddUserPreferenceInput>;
};


export type MutationaddUserAddressArgs = {
  input: AddUserAddressInput;
};


export type MutationaddUserDeviceArgs = {
  input: AddUserDeviceInput;
};


export type MutationaddUserDiscountArgs = {
  input: AddUserDiscountInput;
};


export type MutationaddUserHistoryArgs = {
  input: AddUserHistoryInput;
};


export type MutationaddUserPartnershipArgs = {
  input: AddUserPartnershipInput;
};


export type MutationaddUserPreferenceArgs = {
  input: AddUserPreferenceInput;
};


export type MutationaddUserRoleArgs = {
  input: AddUserRoleInput;
};


export type MutationaddUserSubscriptionArgs = {
  input: AddUserSubscriptionInput;
};


export type MutationaddUserToAbTestArgs = {
  input: AddUserToAbTestInput;
};


export type MutationaddVendorArgs = {
  input: AddVendorInput;
};


export type MutationaddVineyardArgs = {
  input: AddVineyardInput;
};


export type MutationaddWarehouseArgs = {
  input: AddWarehouseInput;
};


export type MutationaddWarehouseLocationArgs = {
  input: AddWarehouseLocationInput;
};


export type MutationaddWineArgs = {
  input: AddWineInput;
};


export type MutationaddWineCampaignArgs = {
  input: AddWineCampaignInput;
};


export type MutationaddWineCategoryArgs = {
  input: AddWineCategoryInput;
};


export type MutationaddWineMakerArgs = {
  input: AddWineMakerInput;
};


export type MutationaddWineTagArgs = {
  input: AddWineTagInput;
};


export type MutationadminSignUpArgs = {
  input: AdminSignUpInput;
  token: Scalars['JWT']['input'];
};


export type MutationassignBoxesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationassignDesignationAndKeysArgs = {
  menuId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationassignOrderToCourierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationassignPermissionArgs = {
  id: Scalars['ID']['input'];
  permissionId: Scalars['ID']['input'];
};


export type MutationassignUserMenuDishKeysArgs = {
  id: Scalars['ID']['input'];
};


export type MutationattemptLocationRectificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationattendToEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationbillMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationbillOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationbuildCourierReportArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  orderTypes: Array<OrderTypeEnum>;
  startDate: Scalars['Date']['input'];
  warehouseIds: Array<Scalars['ID']['input']>;
};


export type MutationbuildOccasionCartReportArgs = {
  productIds: Array<Scalars['ID']['input']>;
  salesEndDate?: InputMaybe<Scalars['Date']['input']>;
  salesStartDate: Scalars['Date']['input'];
};


export type MutationbuildOccasionReportArgs = {
  productIds: Array<Scalars['ID']['input']>;
  salesEndDate?: InputMaybe<Scalars['Date']['input']>;
  salesStartDate: Scalars['Date']['input'];
};


export type MutationcalculateRecieptArgs = {
  id: Scalars['ID']['input'];
};


export type MutationcallStatusUpdateArgs = {
  callStatus: CallStatusEnum;
  id: Scalars['ID']['input'];
};


export type MutationcancelOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationcancelOrderRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationcategoryTreeArgs = {
  group?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationchangeCartItemQuantityArgs = {
  cartId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationchangeDispatchDayArgs = {
  from: Scalars['Date']['input'];
  orderType?: InputMaybe<OrderTypeEnum>;
  to: Scalars['Date']['input'];
};


export type MutationchangeOrderItemQuantityArgs = {
  orderId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationcheckMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationcheckUserMenuArgs = {
  id: Scalars['ID']['input'];
};


export type MutationcheckoutCartArgs = {
  creditCardId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  userAddressId: Scalars['ID']['input'];
};


export type MutationclearCacheKeysArgs = {
  cacheKeys: Array<CacheKeyInput>;
};


export type MutationcloseMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationcompleteMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationcompleteOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationconfirmEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationconvertCartToOrderArgs = {
  cartId: Scalars['ID']['input'];
  creditCardId?: InputMaybe<Scalars['ID']['input']>;
  userAddressId: Scalars['ID']['input'];
};


export type MutationconvertLeadArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationcorrectUserMenusArgs = {
  menuId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreateSubscriptionOrderArgs = {
  input: CreateSubscriptionOrderInput;
};


export type MutationcreateUniqueCodesForSubContainersArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdeactivateAllCodesForDiscountCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdecreaseStockArgs = {
  input: DecreaseStockInput;
};


export type MutationdeleteRegisteredUserArgs = {
  id: Scalars['ID']['input'];
  input: DeleteRegisteredUserInput;
};


export type MutationdiscoveryPartnershipVerifyArgs = {
  input: DiscoveryPartnershipVerifyInput;
};


export type MutationdownloadAddressIssuesReportArgs = {
  filters?: InputMaybe<AddressIssueFilters>;
  order?: InputMaybe<Array<InputMaybe<AddressIssueOrder>>>;
};


export type MutationdownloadAddressUpdateAfterConversionReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadCourierSplitReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadCreditCardErrorReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadDiscountCampaignCodesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadFlaggedUserMenusReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadForecastArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadInactiveMealsReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadIngredientCostReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadIngredientPortionQuantityCartReportFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadIngredientPortionQuantityOrderReportFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadIngredientQuantityCartReportFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadIngredientQuantityOrderReportFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadMasterOrderReportArgs = {
  filters?: InputMaybe<MasterOrderReportFilters>;
  order?: InputMaybe<Array<InputMaybe<MasterOrderReportOrder>>>;
};


export type MutationdownloadMenuIngredientBreakdownReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadMenuIngredientReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadMenuItemClassReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadMenuProductCartSheetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadNoWarehouseReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadOrderCountReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadOrderCountsReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadReceiptsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadStuckOrdersReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadSubscriptionAddonCartsReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadSubscriptionAddonOrdersReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadSubscriptionAsOnDemandReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadSummaryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadTaxCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadUnsavedMenusReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdownloadZeroItemOrdersReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationduplicateOrderForRevisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationduplicatePortionArgs = {
  fromPortion: PortionSizeEnum;
  id: Scalars['ID']['input'];
  toPortion: PortionSizeEnum;
};


export type MutationfinaliseOrderRevisionArgs = {
  id: Scalars['ID']['input'];
  refundMethod: TransactionMethodEnum;
};


export type MutationfixBillingArgs = {
  limit: Scalars['Int']['input'];
};


export type MutationfixLegacyMenuArgs = {
  id: Scalars['ID']['input'];
};


export type MutationfixMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationgenerateDiscountCampaignCodesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationgenerateDispatchDayDownloadArgs = {
  downloadType: DownloadTypeEnum;
  id: Scalars['ID']['input'];
};


export type MutationgenerateDispatchDayReportArgs = {
  id: Scalars['ID']['input'];
  reportType?: InputMaybe<LiveReportTypeEnum>;
};


export type MutationgenerateDispatchDayShipmentsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationgenerateMenuReportArgs = {
  id: Scalars['ID']['input'];
  reportType?: InputMaybe<LiveReportTypeEnum>;
};


export type MutationgenerateMenuStickersArgs = {
  id: Scalars['ID']['input'];
};


export type MutationgeneratePaymentLinkArgs = {
  creditCardId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationgenerateReceiptArgs = {
  id: Scalars['ID']['input'];
};


export type MutationgenerateRecipeCardArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  menuId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationgenerateRecipeCardsArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationgenerateTransactionReportArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


export type MutationgenerateUserImpersonationTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationgetSummaryArgs = {
  menuId: Scalars['String']['input'];
};


export type MutationhardUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: HardUpdateLocationInput;
};


export type MutationimpersonateUserArgs = {
  token: Scalars['String']['input'];
};


export type MutationincreaseStockArgs = {
  input: IncreaseStockInput;
};


export type MutationlinkDeviceWithFCMArgs = {
  id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type MutationlookupItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationlookupItemsStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationmanuallyIntervenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationmanuallyIntervenAllFailedItemsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationmanuallyMarkOrderAsPaidArgs = {
  id: Scalars['ID']['input'];
};


export type MutationmarkOrderBoxesAsReturnedArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationnetcorePurchaseEventArgs = {
  cartId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationnetcoreSingleUserAttributeSyncArgs = {
  userId: Scalars['String']['input'];
};


export type MutationnotifyMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationoneSignalSyncArgs = {
  userId: Scalars['String']['input'];
};


export type MutationopenMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationpauseSubscriptionArgs = {
  id: Scalars['ID']['input'];
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
  untilMenu?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationpauseSubscriptionReasonArgs = {
  id: Scalars['ID']['input'];
  reasoning?: InputMaybe<Scalars['String']['input']>;
  reasons: Array<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationpreCacheMenuDishImagesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationprocessDelayedFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type MutationprocessRefundArgs = {
  action: ProcessRefundActionEnum;
  id: Scalars['ID']['input'];
};


export type MutationprocessUploadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationpushAssemblyDemandArgs = {
  id: Scalars['ID']['input'];
};


export type MutationqueueOrderRecieptCalculationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationrandomiseCartsArgs = {
  amount: Scalars['Int']['input'];
};


export type MutationreAssignShippingConsignemntArgs = {
  id: Scalars['ID']['input'];
  shipperWarehouseId: Scalars['ID']['input'];
};


export type MutationreGeocodeCityArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationreGeocodeLocationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationreIndexFrozenMealDishServingSuggestionsArgs = {
  frozenMealDishId?: InputMaybe<Scalars['ID']['input']>;
  servingSuggestionIds: Array<Scalars['ID']['input']>;
};


export type MutationreIndexFrozenMealInstructionsArgs = {
  frozenMealId?: InputMaybe<Scalars['ID']['input']>;
  instructionIds: Array<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationreIndexMealKitInstructionsArgs = {
  instructionIds: Array<Scalars['ID']['input']>;
  mealKitId: Scalars['ID']['input'];
};


export type MutationreIndexProductIngredientsArgs = {
  ingredientIds: Array<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
};


export type MutationrealtimeSyncContactArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationrecalculateStockArgs = {
  id: Scalars['ID']['input'];
};


export type MutationreceiptMenuArgs = {
  menuId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationredeemUserPointsForCartArgs = {
  id: Scalars['ID']['input'];
  points: Scalars['Int']['input'];
};


export type MutationredeemUserPointsForOrderArgs = {
  id: Scalars['ID']['input'];
  points: Scalars['Int']['input'];
};


export type MutationreferFriendArgs = {
  input: ReferFriendInput;
};


export type MutationreferPersonArgs = {
  input: AddLeadInput;
};


export type MutationrefreshMealkitUploaderSheetArgs = {
  dishIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationrefundOrderArgs = {
  id: Scalars['ID']['input'];
  input: RefundOrderInput;
};


export type MutationregenerateTransactionReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationregisterCommunicationPlatformArgs = {
  deviceId: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  platformType: UserCommunicationPlatformEnum;
};


export type MutationregisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationremoveAbTestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAbTestBucketArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAbTestBucketUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAbTestFilterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAcumaticaDiscountNonStockSkuArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAcumaticaProductTypeNonStockSkuArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveAllergenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveArchiveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBabyBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBabyBoxCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBabyBoxTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBabyBoxTubArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBabyBoxTubDetailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBankBinNumberArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBarcodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBoxRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBoxSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBranchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveBrandArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCategoryGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveChefArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCommunicationTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCouponFromCartArgs = {
  cartId?: InputMaybe<Scalars['ID']['input']>;
  coupon: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveCouponFromOrderArgs = {
  coupon: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};


export type MutationremoveCourierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierDeliveryDayArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierWarehouseCityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCourierWarehouseCityDeliveryDayArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveCreditCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDeletedUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDeliveryItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDeliveryItemParcelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDeliveryScheduleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDiscountCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDiscountTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveDishFromUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userMenuDishId: Scalars['ID']['input'];
};


export type MutationremoveExcelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFileCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFriendReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFrozenCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFrozenInstructionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFrozenMealArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFrozenMealDishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveFrozenTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveGiftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveGuestUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveImageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveIngredientArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveItemFromCartArgs = {
  cartId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};


export type MutationremoveItemFromOrderArgs = {
  orderId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};


export type MutationremoveLeadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveLeadUploadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveLoyaltyTierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveManualEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMarketProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMarketProductBrandArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMarketProductCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMarketProductSubCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMarketProductTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitDefaultPricingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitDishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitFlagArgs = {
  flag: MealKitFlagEnum;
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitInstructionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitPlanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitSubscriptionFeeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMealKitTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMenuArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMenuCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMenuDishWineArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMenuWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveMinimumOrderValueArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderBoxItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderItemRatingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderRatingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderTaxDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderTaxProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderTaxProductTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderTaxProductTypeDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveOrderTaxReceiptArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePackingVolumeDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageBannerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageBannerSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageCardSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageDataSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageDetailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageDetailSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageGridItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageGridSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageMediaSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageModalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageNotificationGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageSectionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTabArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTabItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTabSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTextCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTextCardSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTextSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePageTileSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePdfArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePreferenceCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProcessGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProcessGroupCourierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProductAvailabilityEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProductImageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProductIngredientArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveProductWarhouseCostHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremovePublicHolidayArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveRefundReasonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveRefundReportingReasonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveRegisteredUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveScheduleCategoryExclusionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveScheduleCategoryInclusionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveServingSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveShipperBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveShipperWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSmsTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSubContainerOrderItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSubContainerRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSubscriptionPriceHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveSupplierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveTaxClassArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveTaxReceiptEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveTransactionReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserFromAbTestArgs = {
  input: RemoveUserFromAbTestInput;
};


export type MutationremoveUserHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserPartnershipArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserPreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveVendorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveVineyardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWarehouseLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWineArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWineCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWineCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWineMakerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationremoveWineTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationreorderPageCardsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationreorderPageSectionGroupSectionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationreorderPreferenceCategoriesPreferencesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationrepairUserMenusConfigsandUserMenusDishesArgs = {
  menuIdentifier: Scalars['String']['input'];
};


export type MutationreplaceLocationArgs = {
  newId: Scalars['ID']['input'];
  oldId: Scalars['ID']['input'];
  userAddressId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationrequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationrequestPhoneVerificationArgs = {
  number: Scalars['Phone']['input'];
};


export type MutationrequeueContainerSyncArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresendFailedOrderEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresendFailedOrderSMSArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresendReferralEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresendSuccessfulOrderEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationresetMenuErrorArgs = {
  error: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationresetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['JWT']['input'];
};


export type MutationresetUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationrestartCourierDeliveryProcessArgs = {
  id: Scalars['ID']['input'];
};


export type MutationrestoreUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationresumeMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationretakeSnapshotArgs = {
  id: Scalars['ID']['input'];
};


export type MutationretryAllFailedItemsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationretryItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationretryRefundArgs = {
  id: Scalars['ID']['input'];
};


export type MutationretryShipmentIntegrationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationrevokeAdminAccessArgs = {
  id: Scalars['ID']['input'];
};


export type MutationrollupCitiesArgs = {
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationrunDispatchDayBoxAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationrunShippingConsignmentOperationArgs = {
  id: Scalars['ID']['input'];
  operation?: InputMaybe<ShippingConsignmentOptions>;
};


export type MutationsaveUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsaveUserPreferenceByTokenArgs = {
  input?: InputMaybe<SaveUserPreferenceByToken>;
};


export type MutationsendDeliveryReminderSMSArgs = {
  dispatchDate: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
};


export type MutationsendEmailsArgs = {
  email: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationsendInvitationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationsendPaymentLinkForOrderArgs = {
  id: Scalars['ID']['input'];
  validDuration?: InputMaybe<PaymentLinkValidDurationEnum>;
};


export type MutationsetAvailableStockForProductRangeArgs = {
  productRange: ProductRangeEnum;
  stockCount: Scalars['Int']['input'];
};


export type MutationsetCourierDeliveryBucketReferenceArgs = {
  bucketReference: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationsetDefaultAddressArgs = {
  id: Scalars['ID']['input'];
  placeId: Scalars['String']['input'];
};


export type MutationsetLocationCityArgs = {
  cityId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationsetMenuCategoriesDisplayOrderArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationsetOrderCreditCardArgs = {
  creditCardId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationsetOrderDeliveryAddressArgs = {
  id: Scalars['ID']['input'];
  userAddressId: Scalars['ID']['input'];
};


export type MutationsetStockTypeForProductRangeArgs = {
  productRange: ProductRangeEnum;
  stockType: ProductStockTypeEnum;
};


export type MutationshipOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationskipFailedItemsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationskipItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationstartProcessArgs = {
  date: Scalars['DateTime']['input'];
  orderType: OrderTypeEnum;
};


export type MutationsubscribeToAcListArgs = {
  data: Scalars['JSONObject']['input'];
};


export type MutationswapOutDishArgs = {
  menuId: Scalars['ID']['input'];
  newDish: Scalars['ID']['input'];
  oldDish: Scalars['ID']['input'];
};


export type MutationsyncDispatchDayOrdersArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncMenuOrdersArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncMenuSummaryOrdersArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncOneDiscoveryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncOrderSubContainersArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationsyncProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncProductCostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncUserToAcumaticaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationsyncWineCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationtakePaymentArgs = {
  id: Scalars['ID']['input'];
  input: PaymentInput;
};


export type MutationtestMenusArgs = {
  amount: Scalars['Int']['input'];
  duration?: InputMaybe<TestDurationEnum>;
  onDemandStockLevels?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationtestPaymentArgs = {
  creditCardId: Scalars['ID']['input'];
};


export type MutationtestRecipeCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationtestRefundArgs = {
  transactionId: Scalars['ID']['input'];
};


export type MutationtestSlackArgs = {
  message: Scalars['String']['input'];
  users?: InputMaybe<Array<Scalars['String']['input']>>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationtestSocketChangeArgs = {
  change: Scalars['String']['input'];
};


export type MutationtriggerCommunicationTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationtriggerManualEmailArgs = {
  id: Scalars['ID']['input'];
};


export type MutationtriggerSmsTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationunVerifyUserPhoneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationunassignPermissionArgs = {
  id: Scalars['ID']['input'];
  permissionId: Scalars['ID']['input'];
};


export type MutationupdateAbTestArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAbTestInput;
};


export type MutationupdateAbTestBucketArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAbTestBucketInput;
};


export type MutationupdateAbTestBucketUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAbTestBucketUserInput;
};


export type MutationupdateAbTestFilterArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAbTestFilterInput;
};


export type MutationupdateAcumaticaDiscountNonStockSkuArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAcumaticaDiscountNonStockSkuInput;
};


export type MutationupdateAcumaticaProductTypeNonStockSkuArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAcumaticaProductTypeNonStockSkuInput;
};


export type MutationupdateAllergenArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAllergenInput;
};


export type MutationupdateArchiveArgs = {
  id: Scalars['ID']['input'];
  input: UpdateArchiveInput;
};


export type MutationupdateBabyBoxArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBabyBoxInput;
};


export type MutationupdateBabyBoxCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBabyBoxCategoryInput;
};


export type MutationupdateBabyBoxTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBabyBoxTagInput;
};


export type MutationupdateBabyBoxTubArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBabyBoxTubInput;
};


export type MutationupdateBabyBoxTubDetailArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBabyBoxTubDetailInput;
};


export type MutationupdateBankBinNumberArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBankBinNumberInput;
};


export type MutationupdateBarcodeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBarcodeInput;
};


export type MutationupdateBoxArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBoxInput;
};


export type MutationupdateBoxRestrictionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBoxRestrictionInput;
};


export type MutationupdateBoxSubContainerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBoxSubContainerInput;
};


export type MutationupdateBranchArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBranchInput;
};


export type MutationupdateBrandArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBrandInput;
};


export type MutationupdateCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCategoryInput;
};


export type MutationupdateCategoryGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCategoryGroupInput;
};


export type MutationupdateChefArgs = {
  id: Scalars['ID']['input'];
  input: UpdateChefInput;
};


export type MutationupdateCityArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCityInput;
};


export type MutationupdateCommunicationTemplateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCommunicationTemplateInput;
};


export type MutationupdateCountryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCountryInput;
};


export type MutationupdateCourierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierInput;
};


export type MutationupdateCourierBoxArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierBoxInput;
};


export type MutationupdateCourierDeliveryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierDeliveryInput;
};


export type MutationupdateCourierDeliveryDayArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierDeliveryDayInput;
};


export type MutationupdateCourierWarehouseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierWarehouseInput;
};


export type MutationupdateCourierWarehouseCityArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierWarehouseCityInput;
};


export type MutationupdateCourierWarehouseCityDeliveryDayArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCourierWarehouseCityDeliveryDayInput;
};


export type MutationupdateCreditCardArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCreditCardInput;
};


export type MutationupdateDeletedUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeletedUserInput;
};


export type MutationupdateDeliveryItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeliveryItemInput;
};


export type MutationupdateDeliveryItemParcelArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeliveryItemParcelInput;
};


export type MutationupdateDeliveryScheduleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeliveryScheduleInput;
};


export type MutationupdateDeviceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeviceInput;
};


export type MutationupdateDiscountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountInput;
};


export type MutationupdateDiscountCampaignArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountCampaignInput;
};


export type MutationupdateDiscountCodeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountCodeInput;
};


export type MutationupdateDiscountTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountTagInput;
};


export type MutationupdateExcelArgs = {
  id: Scalars['ID']['input'];
  input: UpdateExcelInput;
};


export type MutationupdateFileCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFileCategoryInput;
};


export type MutationupdateFriendReferenceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFriendReferenceInput;
};


export type MutationupdateFrozenCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFrozenCategoryInput;
};


export type MutationupdateFrozenInstructionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFrozenInstructionInput;
};


export type MutationupdateFrozenMealArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFrozenMealInput;
};


export type MutationupdateFrozenMealDishArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFrozenMealDishInput;
};


export type MutationupdateFrozenTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFrozenTagInput;
};


export type MutationupdateGiftArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGiftInput;
};


export type MutationupdateGuestUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGuestUserInput;
};


export type MutationupdateImageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateImageInput;
};


export type MutationupdateIngredientArgs = {
  id: Scalars['ID']['input'];
  input: UpdateIngredientInput;
};


export type MutationupdateLeadArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLeadInput;
};


export type MutationupdateLeadUploadArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLeadUploadInput;
};


export type MutationupdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocationInput;
};


export type MutationupdateLoyaltyTierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLoyaltyTierInput;
};


export type MutationupdateManualEmailArgs = {
  id: Scalars['ID']['input'];
  input: UpdateManualEmailInput;
};


export type MutationupdateMarketProductArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMarketProductInput;
};


export type MutationupdateMarketProductBrandArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMarketProductBrandInput;
};


export type MutationupdateMarketProductCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMarketProductCategoryInput;
};


export type MutationupdateMarketProductSubCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMarketProductSubCategoryInput;
};


export type MutationupdateMarketProductTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMarketProductTagInput;
};


export type MutationupdateMealKitArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitInput;
};


export type MutationupdateMealKitCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitCategoryInput;
};


export type MutationupdateMealKitDefaultPricingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitDefaultPricingInput;
};


export type MutationupdateMealKitDishArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitDishInput;
};


export type MutationupdateMealKitInstructionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitInstructionInput;
};


export type MutationupdateMealKitPlanArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitPlanInput;
};


export type MutationupdateMealKitSubscriptionFeeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitSubscriptionFeeInput;
};


export type MutationupdateMealKitTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMealKitTagInput;
};


export type MutationupdateMenuArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMenuInput;
};


export type MutationupdateMenuCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMenuCategoryInput;
};


export type MutationupdateMenuDishWineArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMenuDishWineInput;
};


export type MutationupdateMenuWarehouseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMenuWarehouseInput;
};


export type MutationupdateMinimumOrderValueArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMinimumOrderValueInput;
};


export type MutationupdateOrderAddressArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderAddressInput;
};


export type MutationupdateOrderAddressTextArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderAddressTextInput;
};


export type MutationupdateOrderBoxArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderBoxInput;
};


export type MutationupdateOrderBoxItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderBoxItemInput;
};


export type MutationupdateOrderHistoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderHistoryInput;
};


export type MutationupdateOrderItemRatingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderItemRatingInput;
};


export type MutationupdateOrderRatingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderRatingInput;
};


export type MutationupdateOrderSubContainerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderSubContainerInput;
};


export type MutationupdateOrderTaxDiscountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderTaxDiscountInput;
};


export type MutationupdateOrderTaxProductArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderTaxProductInput;
};


export type MutationupdateOrderTaxProductTypeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderTaxProductTypeInput;
};


export type MutationupdateOrderTaxProductTypeDiscountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderTaxProductTypeDiscountInput;
};


export type MutationupdateOrderTaxReceiptArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderTaxReceiptInput;
};


export type MutationupdatePackingVolumeDefaultArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePackingVolumeDefaultInput;
};


export type MutationupdatePageBannerArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageBannerInput;
};


export type MutationupdatePageBannerSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageBannerSectionInput;
};


export type MutationupdatePageCardArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageCardInput;
};


export type MutationupdatePageCardSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageCardSectionInput;
};


export type MutationupdatePageDataSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageDataSectionInput;
};


export type MutationupdatePageDetailArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageDetailInput;
};


export type MutationupdatePageDetailSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageDetailSectionInput;
};


export type MutationupdatePageGridItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageGridItemInput;
};


export type MutationupdatePageGridSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageGridSectionInput;
};


export type MutationupdatePageMediaSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageMediaSectionInput;
};


export type MutationupdatePageModalArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageModalInput;
};


export type MutationupdatePageNotificationArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageNotificationInput;
};


export type MutationupdatePageNotificationGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageNotificationGroupInput;
};


export type MutationupdatePageSectionGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageSectionGroupInput;
};


export type MutationupdatePageTabArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTabInput;
};


export type MutationupdatePageTabItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTabItemInput;
};


export type MutationupdatePageTabSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTabSectionInput;
};


export type MutationupdatePageTextCardArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTextCardInput;
};


export type MutationupdatePageTextCardSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTextCardSectionInput;
};


export type MutationupdatePageTextSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTextSectionInput;
};


export type MutationupdatePageTileSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePageTileSectionInput;
};


export type MutationupdatePdfArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePdfInput;
};


export type MutationupdatePreferenceArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePreferenceInput;
};


export type MutationupdatePreferenceCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePreferenceCategoryInput;
};


export type MutationupdateProcessGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProcessGroupInput;
};


export type MutationupdateProcessGroupCourierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProcessGroupCourierInput;
};


export type MutationupdateProductAvailabilityEmailArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductAvailabilityEmailInput;
};


export type MutationupdateProductImageArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductImageInput;
};


export type MutationupdateProductIngredientArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductIngredientInput;
};


export type MutationupdateProductWarehouseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductWarehouseInput;
};


export type MutationupdateProductWarhouseCostHistoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProductWarhouseCostHistoryInput;
};


export type MutationupdatePublicHolidayArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePublicHolidayInput;
};


export type MutationupdateRefundReasonArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRefundReasonInput;
};


export type MutationupdateRefundReportingReasonArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRefundReportingReasonInput;
};


export type MutationupdateRegionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRegionInput;
};


export type MutationupdateRegisteredUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRegisteredUserInput;
};


export type MutationupdateReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateReportInput;
};


export type MutationupdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRoleInput;
};


export type MutationupdateScheduleCategoryExclusionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateScheduleCategoryExclusionInput;
};


export type MutationupdateScheduleCategoryInclusionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateScheduleCategoryInclusionInput;
};


export type MutationupdateServingSuggestionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateServingSuggestionInput;
};


export type MutationupdateShipmentAddressArgs = {
  address: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  latitude: Scalars['String']['input'];
  longitude: Scalars['String']['input'];
  updateCustomerAddress?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationupdateShipperBoxArgs = {
  id: Scalars['ID']['input'];
  input: UpdateShipperBoxInput;
};


export type MutationupdateShipperWarehouseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateShipperWarehouseInput;
};


export type MutationupdateSmsTemplateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSmsTemplateInput;
};


export type MutationupdateSubContainerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubContainerInput;
};


export type MutationupdateSubContainerOrderItemArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubContainerOrderItemInput;
};


export type MutationupdateSubContainerRestrictionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubContainerRestrictionInput;
};


export type MutationupdateSubscriptionPriceHistoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionPriceHistoryInput;
};


export type MutationupdateSupplierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSupplierInput;
};


export type MutationupdateTaxClassArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTaxClassInput;
};


export type MutationupdateTaxReceiptEventArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTaxReceiptEventInput;
};


export type MutationupdateTransactionReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTransactionReportInput;
};


export type MutationupdateUserAddressArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserAddressInput;
};


export type MutationupdateUserDeviceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserDeviceInput;
};


export type MutationupdateUserDiscountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserDiscountInput;
};


export type MutationupdateUserHistoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserHistoryInput;
};


export type MutationupdateUserMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: UpdateUserMenuInput;
};


export type MutationupdateUserPartnershipArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserPartnershipInput;
};


export type MutationupdateUserPreferenceArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserPreferenceInput;
};


export type MutationupdateUserPreferenceByCategoryArgs = {
  input?: InputMaybe<UpdateUserPreferenceCategoryInput>;
};


export type MutationupdateUserRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserRoleInput;
};


export type MutationupdateUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserSubscriptionInput;
};


export type MutationupdateVendorArgs = {
  id: Scalars['ID']['input'];
  input: UpdateVendorInput;
};


export type MutationupdateVineyardArgs = {
  id: Scalars['ID']['input'];
  input: UpdateVineyardInput;
};


export type MutationupdateWarehouseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWarehouseInput;
};


export type MutationupdateWarehouseLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWarehouseLocationInput;
};


export type MutationupdateWarehouseProductRangeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWarehouseProductRangeInput;
};


export type MutationupdateWineArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWineInput;
};


export type MutationupdateWineCampaignArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWineCampaignInput;
};


export type MutationupdateWineCategoryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWineCategoryInput;
};


export type MutationupdateWineMakerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWineMakerInput;
};


export type MutationupdateWineTagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWineTagInput;
};


export type MutationuploadMealkitsFromSheetArgs = {
  sheetId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationuserLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationuserSignUpArgs = {
  input: UserSignUpInput;
};


export type MutationuserSocialLoginArgs = {
  input: SocialLoginInput;
};


export type MutationuserSocialSignUpArgs = {
  input: SocialSignUpInput;
};


export type MutationvalidateCreditCardArgs = {
  failureRedirect?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  successRedirect?: InputMaybe<Scalars['String']['input']>;
};


export type MutationvalidateDeviceArgs = {
  token: Scalars['String']['input'];
};


export type MutationvalidateFriendReferenceArgs = {
  referralKey: Scalars['String']['input'];
};


export type MutationverifyPhoneArgs = {
  code: Scalars['String']['input'];
};

/** Represents a MyShop in the system */
export type MyShop = {
  __typename: 'MyShop';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Product records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Product>>;
  /** Number of Product records skipped */
  skip: Scalars['Int']['output'];
};

export type MyShopInputData = {
  productType: ProductRangeEnum;
};

export type NameValue = {
  __typename: 'NameValue';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type NavItem = {
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Navigation = {
  __typename: 'Navigation';
  activeNavItem: Maybe<Scalars['String']['output']>;
  activeSubNavItem: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<Maybe<NavItem>>;
  mobileMenuOpen: Scalars['Boolean']['output'];
  mobileOpenNavItem: Maybe<NavItem>;
  mobileSubMenuOpen: Scalars['Boolean']['output'];
  openNavItem: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  testing: Scalars['Boolean']['output'];
  userMobileMenuOpen: Scalars['Boolean']['output'];
};

export type NoWarehouseReportFile = {
  __typename: 'NoWarehouseReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export enum NumberOfPortionsEnum {
  /** 1 */
  SERVES_1 = 'SERVES_1',
  /** 2 */
  SERVES_2 = 'SERVES_2',
  /** 3 */
  SERVES_3 = 'SERVES_3',
  /** 4 */
  SERVES_4 = 'SERVES_4'
}

export type NutritionalInfo = {
  __typename: 'NutritionalInfo';
  carbs: Scalars['Float']['output'];
  cholesterol: Maybe<Scalars['Float']['output']>;
  energyInKiloCalories: Scalars['Float']['output'];
  energyInKilojoules: Scalars['Float']['output'];
  fat: Scalars['Float']['output'];
  fibre: Scalars['Float']['output'];
  protein: Scalars['Float']['output'];
  salt: Scalars['Float']['output'];
  saturatedFat: Scalars['Float']['output'];
  sugars: Scalars['Float']['output'];
};

export type NutritionalInfoInput = {
  carbs: Scalars['Float']['input'];
  cholesterol?: InputMaybe<Scalars['Float']['input']>;
  energyInKiloCalories: Scalars['Float']['input'];
  energyInKilojoules: Scalars['Float']['input'];
  fat: Scalars['Float']['input'];
  fibre: Scalars['Float']['input'];
  protein: Scalars['Float']['input'];
  salt: Scalars['Float']['input'];
  saturatedFat: Scalars['Float']['input'];
  sugars: Scalars['Float']['input'];
};

export enum ObjectFitEnum {
  /** Contain */
  CONTAIN = 'CONTAIN',
  /** Cover */
  COVER = 'COVER',
  /** Fill */
  FILL = 'FILL',
  /** None */
  NONE = 'NONE',
  /** Scale Down */
  SCALE_DOWN = 'SCALE_DOWN'
}

/** Represents a Order in the system */
export type Order = {
  __typename: 'Order';
  acuRef: Scalars['String']['output'];
  additions: Array<CartCostItem>;
  allAdditions: Maybe<Scalars['Float']['output']>;
  amountOwed: Maybe<Scalars['Float']['output']>;
  amountPaid: Maybe<Scalars['Float']['output']>;
  assignedUserPoints: Maybe<Scalars['Float']['output']>;
  awaitingReBill: Scalars['Boolean']['output'];
  billingFailed: Scalars['Boolean']['output'];
  billingStatusMessage: Maybe<Scalars['String']['output']>;
  callStatus: CallStatusEnum;
  createdAt: Scalars['DateTime']['output'];
  creditCard: Maybe<CreditCard>;
  deliveryItems: Array<DeliveryItem>;
  discountAmount: Maybe<Scalars['Float']['output']>;
  discountPercent: Maybe<Scalars['Float']['output']>;
  discoverySyncStatus: DiscoverySyncStateEnum;
  discoverySyncStatusMessage: Maybe<Scalars['String']['output']>;
  dispatchDate: Maybe<Scalars['Date']['output']>;
  grandTotalAmount: Maybe<Scalars['Float']['output']>;
  hasAddress: Scalars['Boolean']['output'];
  hasBeenAssignedToCourier: Scalars['Boolean']['output'];
  hasBoxesAssigned: Scalars['Boolean']['output'];
  hasPaidFullAmount: Scalars['Boolean']['output'];
  history: Maybe<Array<Maybe<OrderHistory>>>;
  /** The Order's ID */
  id: Scalars['ID']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCutOff: Scalars['Boolean']['output'];
  isInRevision: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  isRevision: Scalars['Boolean']['output'];
  isShipped: Scalars['Boolean']['output'];
  isSynced: OrderSyncStatusEnum;
  madeExternalPayment: Scalars['Boolean']['output'];
  needsRefund: Scalars['Boolean']['output'];
  orderAddress: Maybe<OrderAddress>;
  orderBoxes: Maybe<Array<OrderBox>>;
  orderItems: Array<OrderItem>;
  orderRating: Maybe<OrderRating>;
  orderSubContainers: Maybe<Array<OrderSubContainer>>;
  orderType: OrderTypeEnum;
  origin: OrderOriginEnum;
  pendingCancellationByRevision: Scalars['Boolean']['output'];
  productRanges: Maybe<Array<ProductRangeEnum>>;
  recieptFile: Maybe<Archive>;
  recieptFileStatus: FileGenerationStatus;
  reductions: Array<CartCostItem>;
  revisionOfOrderId: Maybe<Scalars['ID']['output']>;
  shortId: Scalars['String']['output'];
  status: OrderStatusEnum;
  subTotalAmount: Maybe<Scalars['Float']['output']>;
  syncAttempts: Scalars['Int']['output'];
  syncedAt: Maybe<Scalars['DateTime']['output']>;
  taxAmount: Maybe<Scalars['Float']['output']>;
  taxPercent: Maybe<Scalars['Float']['output']>;
  transactions: Array<Transaction>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  userMenu: Maybe<Array<UserMenu>>;
  warehouse: Maybe<Warehouse>;
};

/** Represents a OrderAddress in the system */
export type OrderAddress = LocationInterface & {
  __typename: 'OrderAddress';
  address: Maybe<Scalars['String']['output']>;
  area: Maybe<Area>;
  buildingName: Maybe<Scalars['String']['output']>;
  buildingTypeEnum: Maybe<BuildingTypeEnum>;
  center: Maybe<Coordinate>;
  city: Maybe<City>;
  country: Country;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  fullAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions: Maybe<Scalars['String']['output']>;
  location: Location;
  locationName: Maybe<Scalars['String']['output']>;
  mdsAddressId: Maybe<Scalars['Int']['output']>;
  mdsContactId: Maybe<Scalars['Int']['output']>;
  order: Order;
  parentCity: Maybe<City>;
  parentRegion: Region;
  placeId: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  region: Region;
  shortId: Maybe<Scalars['String']['output']>;
  streetName: Maybe<Scalars['String']['output']>;
  streetNumber: Maybe<Scalars['String']['output']>;
  types: Maybe<Array<Scalars['String']['output']>>;
  unitNumber: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderAddress filtering options */
export type OrderAddressFilters = {
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderAddressList = {
  __typename: 'OrderAddressList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderAddress records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderAddress>>;
  /** Number of OrderAddress records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderAddress ordering options */
export type OrderAddressOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderAddressOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderAddressOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  POSTAL_CODE = 'POSTAL_CODE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderBox in the system */
export type OrderBox = {
  __typename: 'OrderBox';
  box: Box;
  containedTypes: Scalars['JSON']['output'];
  /** The creation date of the OrderBox */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The OrderBox's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  maxIndex: Scalars['Int']['output'];
  order: Order;
  orderBoxItems: Maybe<Array<OrderBoxItem>>;
  orderSubContainers: Maybe<Array<OrderSubContainer>>;
  spaceUsed: Scalars['Float']['output'];
  /** The update date of the OrderBox */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  wasReturned: Scalars['Boolean']['output'];
};

/** OrderBox filtering options */
export type OrderBoxFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxIndex?: InputMaybe<Scalars['Int']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a OrderBoxItem in the system */
export type OrderBoxItem = {
  __typename: 'OrderBoxItem';
  /** The creation date of the OrderBoxItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The OrderBoxItem's ID */
  id: Scalars['ID']['output'];
  orderBox: OrderBox;
  orderItem: OrderItem;
  quantity: Scalars['Float']['output'];
  /** The update date of the OrderBoxItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderBoxItem filtering options */
export type OrderBoxItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderBoxItemList = {
  __typename: 'OrderBoxItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderBoxItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderBoxItem>>;
  /** Number of OrderBoxItem records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderBoxItem ordering options */
export type OrderBoxItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderBoxItemOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderBoxItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type OrderBoxList = {
  __typename: 'OrderBoxList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderBox records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderBox>>;
  /** Number of OrderBox records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderBox ordering options */
export type OrderBoxOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderBoxOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderBoxOrderEnum {
  CREATED_AT = 'CREATED_AT',
  INDEX = 'INDEX',
  MAX_INDEX = 'MAX_INDEX',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type OrderCountReportFile = {
  __typename: 'OrderCountReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export enum OrderDirectionEnum {
  /** Order Ascending */
  ASC = 'ASC',
  /** Order Descending */
  DESC = 'DESC'
}

export enum OrderEventEnum {
  /** Address Set */
  ADDRESS_SET = 'ADDRESS_SET',
  /** Assigned to courier */
  ASSIGNED_TO_COURIER = 'ASSIGNED_TO_COURIER',
  /** Billed */
  BILLED = 'BILLED',
  /** Billing Failed */
  BILLING_FAILED = 'BILLING_FAILED',
  /** Boxes assigned */
  BOXES_ASSIGNED = 'BOXES_ASSIGNED',
  /** Call status updated */
  CALL_STATUS_UPDATED = 'CALL_STATUS_UPDATED',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Cut off */
  CUT_OFF = 'CUT_OFF',
  /** Payment Made */
  PAYMENT_MADE = 'PAYMENT_MADE',
  /** Payment Method Set */
  PAYMENT_METHOD_SET = 'PAYMENT_METHOD_SET',
  /** Placed */
  PLACED = 'PLACED',
  /** Products updated */
  PRODUCTS_UPDATED = 'PRODUCTS_UPDATED',
  /** Re-billed */
  RE_BILLED = 'RE_BILLED',
  /** Re-funded */
  RE_FUNDED = 'RE_FUNDED',
  /** Shipped */
  SHIPPED = 'SHIPPED',
  /** Synced */
  SYNCED = 'SYNCED',
  /** User points updated */
  USER_POINTS_UPDATED = 'USER_POINTS_UPDATED'
}

/** Order filtering options */
export type OrderFilters = {
  awaitingReBill?: InputMaybe<Scalars['String']['input']>;
  callStatus?: InputMaybe<Array<CallStatusEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dispatchDate?: InputMaybe<Scalars['Date']['input']>;
  dispatchDateRange?: InputMaybe<DateRangeFilter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isCancelled?: InputMaybe<Scalars['String']['input']>;
  isSynced?: InputMaybe<Array<OrderSyncStatusEnum>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  orderDateRange?: InputMaybe<DateRangeFilter>;
  orderTypes?: InputMaybe<Array<OrderTypeEnum>>;
  origin?: InputMaybe<Array<OrderOriginEnum>>;
  portionSize?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Represents a OrderHistory in the system */
export type OrderHistory = {
  __typename: 'OrderHistory';
  admin: Maybe<User>;
  /** The creation date of the OrderHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  event: OrderHistoryEvent;
  extraData: Maybe<Scalars['JSONObject']['output']>;
  /** The OrderHistory's ID */
  id: Scalars['ID']['output'];
  /** The update date of the OrderHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum OrderHistoryEvent {
  /** Address Set */
  ADDRESS_SET = 'ADDRESS_SET',
  /** Assigned To Courier */
  ASSIGNED_TO_COURIER = 'ASSIGNED_TO_COURIER',
  /** Billed */
  BILLED = 'BILLED',
  /** Billing Failed */
  BILLING_FAILED = 'BILLING_FAILED',
  /** Boxes Assigned */
  BOXES_ASSIGNED = 'BOXES_ASSIGNED',
  /** Call Status Updated */
  CALL_STATUS_UPDATED = 'CALL_STATUS_UPDATED',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Cut Off */
  CUT_OFF = 'CUT_OFF',
  /** Payment Made */
  PAYMENT_MADE = 'PAYMENT_MADE',
  /** Payment Method Set */
  PAYMENT_METHOD_SET = 'PAYMENT_METHOD_SET',
  /** Placed */
  PLACED = 'PLACED',
  /** Products Updated */
  PRODUCTS_UPDATED = 'PRODUCTS_UPDATED',
  /** Re Billed */
  RE_BILLED = 'RE_BILLED',
  /** Re Funded */
  RE_FUNDED = 'RE_FUNDED',
  /** Shipped */
  SHIPPED = 'SHIPPED',
  /** Synced */
  SYNCED = 'SYNCED',
  /** User Points Updated */
  USER_POINTS_UPDATED = 'USER_POINTS_UPDATED'
}

/** OrderHistory filtering options */
export type OrderHistoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderHistoryList = {
  __typename: 'OrderHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderHistory>>;
  /** Number of OrderHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderHistory ordering options */
export type OrderHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderItem in the system */
export type OrderItem = {
  __typename: 'OrderItem';
  boxItems: Maybe<Array<OrderBoxItem>>;
  /** The creation date of the OrderItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discountAmount: Scalars['Float']['output'];
  grossPricePerUnitAtSale: Scalars['Float']['output'];
  /** The OrderItem's ID */
  id: Scalars['ID']['output'];
  isDisplayed: Scalars['Boolean']['output'];
  isFromSubscription: Scalars['Boolean']['output'];
  isProductGroupRated: Scalars['Boolean']['output'];
  order: Order;
  orderItemProduct: Product;
  orderItemRating: Maybe<OrderItemRating>;
  price: Scalars['Float']['output'];
  productName: Maybe<Scalars['String']['output']>;
  productSku: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  /** The update date of the OrderItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userMenu: Maybe<UserMenu>;
};

/** OrderItem filtering options */
export type OrderItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderItemList = {
  __typename: 'OrderItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderItem>>;
  /** Number of OrderItem records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderItem ordering options */
export type OrderItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderItemOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type OrderItemRating = {
  __typename: 'OrderItemRating';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  feedback: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderItem: Maybe<OrderItem>;
  selectedOptions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  value: Scalars['Int']['output'];
};

/** OrderItemRating filtering options */
export type OrderItemRatingFilters = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Properties list object */
export type OrderItemRatingList = {
  __typename: 'OrderItemRatingList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderItemRating records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderItemRating>>;
  /** Number of OrderItemRating records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderItemRating ordering options */
export type OrderItemRatingOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderItemRatingOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderItemRatingOrderEnum {
  NAME = 'NAME'
}

/** Properties list object */
export type OrderList = {
  __typename: 'OrderList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Order records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Order>>;
  /** Number of Order records skipped */
  skip: Scalars['Int']['output'];
};

/** Order ordering options */
export type OrderOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderOrderEnum {
  CALL_STATUS = 'CALL_STATUS',
  CREATED_AT = 'CREATED_AT',
  DISPATCH_DATE = 'DISPATCH_DATE',
  NAME = 'NAME',
  ORDER_TYPE = 'ORDER_TYPE',
  UPDATED_AT = 'UPDATED_AT',
  USER = 'USER',
  WAREHOUSE = 'WAREHOUSE'
}

export enum OrderOriginEnum {
  /** Old .NET System */
  DOT_NET = 'DOT_NET',
  /** Current */
  NODE = 'NODE',
  /** Test */
  TEST = 'TEST',
  /** Old WordPress System */
  WORD_PRESS = 'WORD_PRESS'
}

/** Represents a Rating in the system */
export type OrderRating = {
  __typename: 'OrderRating';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  feedback: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order: Maybe<Order>;
  selectedOptions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  value: Scalars['Int']['output'];
};

/** Rating filtering options */
export type OrderRatingFilters = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Properties list object */
export type OrderRatingList = {
  __typename: 'OrderRatingList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Rating records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderRating>>;
  /** Number of Rating records skipped */
  skip: Scalars['Int']['output'];
};

/** Rating ordering options */
export type OrderRatingOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderRatingOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderRatingOrderEnum {
  NAME = 'NAME'
}

export enum OrderStatusEnum {
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Failed */
  FAILED = 'FAILED',
  /** Outstanding Payment */
  OUTSTANDING_PAYMENT = 'OUTSTANDING_PAYMENT',
  /** Pending Payment */
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  /** Pending Refund */
  PENDING_REFUND = 'PENDING_REFUND',
  /** Placed */
  PLACED = 'PLACED',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Re-Billed */
  RE_BILLED = 'RE_BILLED'
}

export type OrderStickerInput = {
  fileName: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
};

/** Represents a OrderSubContainer in the system */
export type OrderSubContainer = {
  __typename: 'OrderSubContainer';
  acumaticaSyncStatus: AcuSubContainerSyncStatusEnum;
  containedTypes: Maybe<Scalars['JSON']['output']>;
  /** The creation date of the OrderSubContainer */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The OrderSubContainer's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  maxIndex: Scalars['Int']['output'];
  order: Order;
  orderBox: Maybe<OrderBox>;
  orderSubContainerOrderItems: Maybe<Array<SubContainerOrderItem>>;
  spaceUsed: Scalars['Float']['output'];
  subContainer: SubContainer;
  syncNotes: Maybe<Scalars['String']['output']>;
  /** The update date of the OrderSubContainer */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderSubContainer filtering options */
export type OrderSubContainerFilters = {
  acumaticaSyncStatus?: InputMaybe<Array<AcuSubContainerSyncStatusEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderSubContainerList = {
  __typename: 'OrderSubContainerList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderSubContainer records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderSubContainer>>;
  /** Number of OrderSubContainer records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderSubContainer ordering options */
export type OrderSubContainerOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderSubContainerOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderSubContainerOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type OrderSubscription = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum OrderSyncStatusEnum {
  /** Not Synced */
  NOT_SYNCED = 'NOT_SYNCED',
  /** Synced */
  SYNCED = 'SYNCED',
  /** Sync Failed */
  SYNC_FAILED = 'SYNC_FAILED',
  /** Sync In Progress */
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS'
}

/** Represents a OrderTaxAddition in the system */
export type OrderTaxAddition = {
  __typename: 'OrderTaxAddition';
  /** The creation date of the OrderTaxAddition */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  grossRevenue: Scalars['Float']['output'];
  /** The OrderTaxAddition's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  netRevenue: Scalars['Float']['output'];
  receipt: OrderTaxReceipt;
  taxOwed: Scalars['Float']['output'];
  taxPercent: Scalars['Float']['output'];
  /** The update date of the OrderTaxAddition */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderTaxAddition filtering options */
export type OrderTaxAdditionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxAdditionList = {
  __typename: 'OrderTaxAdditionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxAddition records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxAddition>>;
  /** Number of OrderTaxAddition records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxAddition ordering options */
export type OrderTaxAdditionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxAdditionOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxAdditionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  GROSS_REVENUE = 'GROSS_REVENUE',
  NAME = 'NAME',
  NET_REVENUE = 'NET_REVENUE',
  TAX_OWED = 'TAX_OWED',
  TAX_PERCENT = 'TAX_PERCENT',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderTaxDiscount in the system */
export type OrderTaxDiscount = {
  __typename: 'OrderTaxDiscount';
  /** The creation date of the OrderTaxDiscount */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Discount;
  /** The OrderTaxDiscount's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resultingValueReduction: Scalars['Float']['output'];
  taxProductType: OrderTaxProductType;
  /** The update date of the OrderTaxDiscount */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderTaxDiscount filtering options */
export type OrderTaxDiscountFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxDiscountList = {
  __typename: 'OrderTaxDiscountList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxDiscount records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxDiscount>>;
  /** Number of OrderTaxDiscount records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxDiscount ordering options */
export type OrderTaxDiscountOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxDiscountOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxDiscountOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  RESULTING_VALUE_REDUCTION = 'RESULTING_VALUE_REDUCTION',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderTaxProduct in the system */
export type OrderTaxProduct = {
  __typename: 'OrderTaxProduct';
  children: Maybe<Array<OrderTaxProduct>>;
  costPerUnitExclTax: Scalars['Float']['output'];
  costPerUnitInclTax: Scalars['Float']['output'];
  /** The creation date of the OrderTaxProduct */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  grossRevenue: Scalars['Float']['output'];
  /** The OrderTaxProduct's ID */
  id: Scalars['ID']['output'];
  netRevenue: Scalars['Float']['output'];
  orderProductDiscounts: Maybe<Array<OrderTaxProductDiscount>>;
  parent: Maybe<OrderTaxProduct>;
  product: Product;
  productType: OrderTaxProductType;
  quantity: Scalars['Float']['output'];
  receipt: OrderTaxReceipt;
  rollOverDiscountPercent: Scalars['Float']['output'];
  taxOwed: Scalars['Float']['output'];
  taxPercent: Scalars['Float']['output'];
  totalCostInclTax: Scalars['Float']['output'];
  totalDiscountPercent: Scalars['Float']['output'];
  /** The update date of the OrderTaxProduct */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a OrderTaxProductDiscount in the system */
export type OrderTaxProductDiscount = {
  __typename: 'OrderTaxProductDiscount';
  /** The creation date of the OrderTaxProductDiscount */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Discount;
  /** The OrderTaxProductDiscount's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  product: OrderTaxProduct;
  resultingValueReduction: Scalars['Float']['output'];
  /** The update date of the OrderTaxProductDiscount */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderTaxProductDiscount filtering options */
export type OrderTaxProductDiscountFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxProductDiscountList = {
  __typename: 'OrderTaxProductDiscountList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxProductDiscount records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxProductDiscount>>;
  /** Number of OrderTaxProductDiscount records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxProductDiscount ordering options */
export type OrderTaxProductDiscountOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxProductDiscountOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxProductDiscountOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  RESULTING_VALUE_REDUCTION = 'RESULTING_VALUE_REDUCTION',
  UPDATED_AT = 'UPDATED_AT'
}

/** OrderTaxProduct filtering options */
export type OrderTaxProductFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  taxProductTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxProductList = {
  __typename: 'OrderTaxProductList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxProduct records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxProduct>>;
  /** Number of OrderTaxProduct records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxProduct ordering options */
export type OrderTaxProductOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxProductOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxProductOrderEnum {
  COST_PER_UNIT_EXCL_TAX = 'COST_PER_UNIT_EXCL_TAX',
  COST_PER_UNIT_INCL_TAX = 'COST_PER_UNIT_INCL_TAX',
  CREATED_AT = 'CREATED_AT',
  GROSS_REVENUE = 'GROSS_REVENUE',
  NET_REVENUE = 'NET_REVENUE',
  QUANTITY = 'QUANTITY',
  ROLL_OVER_DISCOUNT_PERCENT = 'ROLL_OVER_DISCOUNT_PERCENT',
  TAX_OWED = 'TAX_OWED',
  TAX_PERCENT = 'TAX_PERCENT',
  TOTAL_COST_INCL_TAX = 'TOTAL_COST_INCL_TAX',
  TOTAL_DISCOUNT_PERCENT = 'TOTAL_DISCOUNT_PERCENT',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderTaxProductType in the system */
export type OrderTaxProductType = {
  __typename: 'OrderTaxProductType';
  /** The creation date of the OrderTaxProductType */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  grossRevenue: Scalars['Float']['output'];
  /** The OrderTaxProductType's ID */
  id: Scalars['ID']['output'];
  netNonTaxableRevenue: Scalars['Float']['output'];
  netRevenue: Scalars['Float']['output'];
  netTaxableRevenue: Scalars['Float']['output'];
  orderTaxReceipt: OrderTaxReceipt;
  productType: Scalars['String']['output'];
  productTypeDiscounts: Maybe<Array<OrderTaxProductTypeDiscount>>;
  products: OrderTaxProduct;
  relativeTaxPercent: Scalars['Float']['output'];
  rollOverDiscountPercent: Scalars['Float']['output'];
  taxOwed: Scalars['Float']['output'];
  totalDiscountPercent: Scalars['Float']['output'];
  /** The update date of the OrderTaxProductType */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a OrderTaxProductTypeDiscount in the system */
export type OrderTaxProductTypeDiscount = {
  __typename: 'OrderTaxProductTypeDiscount';
  /** The creation date of the OrderTaxProductTypeDiscount */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discount: Discount;
  /** The OrderTaxProductTypeDiscount's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resultingValueReduction: Scalars['Float']['output'];
  taxProductType: OrderTaxProductType;
  /** The update date of the OrderTaxProductTypeDiscount */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** OrderTaxProductTypeDiscount filtering options */
export type OrderTaxProductTypeDiscountFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  taxProductTypes?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxProductTypeDiscountList = {
  __typename: 'OrderTaxProductTypeDiscountList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxProductTypeDiscount records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxProductTypeDiscount>>;
  /** Number of OrderTaxProductTypeDiscount records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxProductTypeDiscount ordering options */
export type OrderTaxProductTypeDiscountOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxProductTypeDiscountOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxProductTypeDiscountOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  RESULTING_VALUE_REDUCTION = 'RESULTING_VALUE_REDUCTION',
  UPDATED_AT = 'UPDATED_AT'
}

/** OrderTaxProductType filtering options */
export type OrderTaxProductTypeFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receipts?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxProductTypeList = {
  __typename: 'OrderTaxProductTypeList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxProductType records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxProductType>>;
  /** Number of OrderTaxProductType records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxProductType ordering options */
export type OrderTaxProductTypeOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxProductTypeOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxProductTypeOrderEnum {
  CREATED_AT = 'CREATED_AT',
  GROSS_REVENUE = 'GROSS_REVENUE',
  NET_NON_TAXABLE_REVENUE = 'NET_NON_TAXABLE_REVENUE',
  NET_REVENUE = 'NET_REVENUE',
  NET_TAXABLE_REVENUE = 'NET_TAXABLE_REVENUE',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  ROLL_OVER_DISCOUNT_PERCENT = 'ROLL_OVER_DISCOUNT_PERCENT',
  TOTAL_DISCOUNT_PERCENT = 'TOTAL_DISCOUNT_PERCENT',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a OrderTaxReceipt in the system */
export type OrderTaxReceipt = {
  __typename: 'OrderTaxReceipt';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  grossRevenue: Scalars['Float']['output'];
  grossSubTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  netNonTaxableRevenue: Scalars['Float']['output'];
  netRevenue: Scalars['Float']['output'];
  netTaxableRevenue: Scalars['Float']['output'];
  order: Order;
  orderAdditions: Maybe<Array<OrderTaxAddition>>;
  orderDiscounts: Maybe<Array<OrderTaxDiscount>>;
  productTypes: Maybe<Array<OrderTaxProductType>>;
  receiptProducts: Maybe<Array<OrderTaxProduct>>;
  relativeTaxPercent: Scalars['Float']['output'];
  taxOwed: Scalars['Float']['output'];
  taxReceiptEvent: TaxReceiptEvent;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userPointsAssigned: Scalars['Float']['output'];
  userPointsAvailableAsDiscount: Scalars['Float']['output'];
};

/** OrderTaxReceipt filtering options */
export type OrderTaxReceiptFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type OrderTaxReceiptList = {
  __typename: 'OrderTaxReceiptList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of OrderTaxReceipt records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<OrderTaxReceipt>>;
  /** Number of OrderTaxReceipt records skipped */
  skip: Scalars['Int']['output'];
};

/** OrderTaxReceipt ordering options */
export type OrderTaxReceiptOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<OrderTaxReceiptOrderEnum>;
};

/** Fields available to order Properties */
export enum OrderTaxReceiptOrderEnum {
  CREATED_AT = 'CREATED_AT',
  GROSS_REVENUE = 'GROSS_REVENUE',
  NAME = 'NAME',
  NET_NON_TAXABLE_REVENUE = 'NET_NON_TAXABLE_REVENUE',
  NET_REVENUE = 'NET_REVENUE',
  NET_TAXABLE_REVENUE = 'NET_TAXABLE_REVENUE',
  RELATIVE_TAX_PERCENT = 'RELATIVE_TAX_PERCENT',
  SHORT_ID_NUMBER = 'SHORT_ID_NUMBER',
  TAX_OWED = 'TAX_OWED',
  UPDATED_AT = 'UPDATED_AT',
  USER_POINTS_ASSIGNED = 'USER_POINTS_ASSIGNED',
  USER_POINTS_AVAILABLE_AS_DISCOUNT = 'USER_POINTS_AVAILABLE_AS_DISCOUNT'
}

export enum OrderTypeEnum {
  /** On Demand */
  ON_DEMAND = 'ON_DEMAND',
  /** Subscription */
  SUBSCRIPTION = 'SUBSCRIPTION',
  /** Unknown or Any */
  UNKNOWN = 'UNKNOWN'
}

export enum OtherTagEnum {
  /** Other */
  OTHER = 'OTHER'
}

export enum PackagingTagEnum {
  /** Broken Seals */
  BROKEN_SEALS = 'BROKEN_SEALS',
  /** Incorrect label */
  INCORRECT_LABEL = 'INCORRECT_LABEL',
  /** Poor Quality */
  POOR_QUALITY = 'POOR_QUALITY',
  /** Too much plastic */
  TOO_MUCH_PLASTIC = 'TOO_MUCH_PLASTIC'
}

/** Represents a PackingVolumeDefault in the system */
export type PackingVolumeDefault = {
  __typename: 'PackingVolumeDefault';
  /** The creation date of the PackingVolumeDefault */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultPackingVolume: Scalars['Float']['output'];
  /** The PackingVolumeDefault's ID */
  id: Scalars['ID']['output'];
  productRange: ProductRangeEnum;
  /** The update date of the PackingVolumeDefault */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  variant: Maybe<Scalars['String']['output']>;
};

/** PackingVolumeDefault filtering options */
export type PackingVolumeDefaultFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productRange?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PackingVolumeDefaultList = {
  __typename: 'PackingVolumeDefaultList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PackingVolumeDefault records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PackingVolumeDefault>>;
  /** Number of PackingVolumeDefault records skipped */
  skip: Scalars['Int']['output'];
};

/** PackingVolumeDefault ordering options */
export type PackingVolumeDefaultOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PackingVolumeDefaultOrderEnum>;
};

/** Fields available to order Properties */
export enum PackingVolumeDefaultOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageBanner in the system */
export type PageBanner = {
  __typename: 'PageBanner';
  backgroundColor: Maybe<PageBannerBackgroundColorEnum>;
  bannerTitle: Scalars['String']['output'];
  /** The creation date of the PageBanner */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  descriptionColor: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  hasMobileImage: Scalars['Boolean']['output'];
  /** The PageBanner's ID */
  id: Scalars['ID']['output'];
  image: Image;
  imageHeight: Maybe<Scalars['String']['output']>;
  imageShadow: Maybe<Scalars['Boolean']['output']>;
  imageWidth: Maybe<Scalars['String']['output']>;
  link: Maybe<PageSectionLink>;
  mobileDescriptionColor: Maybe<Scalars['String']['output']>;
  mobileImage: Maybe<Image>;
  mobileObjectFit: Maybe<ObjectFitEnum>;
  mobileSubTitleColor: Maybe<Scalars['String']['output']>;
  mobileTitleColor: Maybe<Scalars['String']['output']>;
  objectFit: Maybe<ObjectFitEnum>;
  pageBannerSection: Maybe<PageBannerSection>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  subTitleColor: Maybe<Scalars['String']['output']>;
  titleColor: Maybe<Scalars['String']['output']>;
  /** The update date of the PageBanner */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  variation: PageBannerVariationEnum;
};

export enum PageBannerBackgroundColorEnum {
  /** Ash */
  ASH = 'ASH',
  /** Bitter */
  BITTER = 'BITTER',
  /** Boulder */
  BOULDER = 'BOULDER',
  /** Burnt Sienna */
  BURNT_SIENNA = 'BURNT_SIENNA',
  /** Chetwode Blue */
  CHETWODE_BLUE = 'CHETWODE_BLUE',
  /** Green Smokoe */
  GREEN_SMOKE = 'GREEN_SMOKE',
  /** Selective Yellow */
  SELECTIVE_YELLOW = 'SELECTIVE_YELLOW',
  /** Tundora */
  TUNDORA = 'TUNDORA'
}

/** PageBanner filtering options */
export type PageBannerFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageBannerSections?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<InputMaybe<PageBannerStatusEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageBannerList = {
  __typename: 'PageBannerList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageBanner records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageBanner>>;
  /** Number of PageBanner records skipped */
  skip: Scalars['Int']['output'];
};

/** PageBanner ordering options */
export type PageBannerOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageBannerOrderEnum>;
};

/** Fields available to order Properties */
export enum PageBannerOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageBannerSection in the system */
export type PageBannerSection = PageSection & {
  __typename: 'PageBannerSection';
  background: Maybe<PageSectionBackgroundEnum>;
  bannerHeight: Maybe<Scalars['String']['output']>;
  bannerSectionHeight: Maybe<Scalars['String']['output']>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageBannerSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currentBanners: Maybe<Array<Maybe<PageBanner>>>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageBannerSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  mobileBannerHeight: Maybe<Scalars['String']['output']>;
  mobileBannerSectionHeight: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pageBanners: Array<PageBanner>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageBannerSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageBannerSection filtering options */
export type PageBannerSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageBannerSectionList = {
  __typename: 'PageBannerSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageBannerSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageBannerSection>>;
  /** Number of PageBannerSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageBannerSection ordering options */
export type PageBannerSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageBannerSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageBannerSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PageBannerStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

export enum PageBannerVariationEnum {
  /** AD Banner */
  AD_BANNER = 'AD_BANNER',
  /** Campaign Banner */
  CAMPAIGN_BANNER = 'CAMPAIGN_BANNER',
  /** Compact Banner */
  COMPACT_BANNER = 'COMPACT_BANNER',
  /** Content Banner */
  CONTENT_BANNER = 'CONTENT_BANNER',
  /** Dashboard Banner */
  DASHBOARD_BANNER = 'DASHBOARD_BANNER',
  /** Elegant Banner */
  ELEGANT_BANNER = 'ELEGANT_BANNER',
  /** Hero Banner */
  HERO_BANNER = 'HERO_BANNER',
  /** Image Banner */
  IMAGE_BANNER = 'IMAGE_BANNER'
}

/** Represents a PageCard in the system */
export type PageCard = {
  __typename: 'PageCard';
  cardTitle: Scalars['String']['output'];
  /** The creation date of the PageCard */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<Image>;
  /** The PageCard's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  index: Scalars['Int']['output'];
  link: Maybe<PageSectionLink>;
  mobileObjectFit: Maybe<ObjectFitEnum>;
  objectFit: Maybe<ObjectFitEnum>;
  pageCardSection: Maybe<PageCardSection>;
  pageTileSection: Maybe<PageTileSection>;
  /** The update date of the PageCard */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageCard filtering options */
export type PageCardFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageCardSection?: InputMaybe<Scalars['ID']['input']>;
  pageTileSection?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageCardList = {
  __typename: 'PageCardList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageCard records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageCard>>;
  /** Number of PageCard records skipped */
  skip: Scalars['Int']['output'];
};

/** PageCard ordering options */
export type PageCardOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageCardOrderEnum>;
};

/** Fields available to order Properties */
export enum PageCardOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageCardSection in the system */
export type PageCardSection = PageSection & {
  __typename: 'PageCardSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  cardHeight: Maybe<Scalars['String']['output']>;
  cardSectionHeight: Maybe<Scalars['String']['output']>;
  /** The creation date of the PageCardSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageCardSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  mobileCardHeight: Maybe<Scalars['String']['output']>;
  mobileCardSectionHeight: Maybe<Scalars['String']['output']>;
  mobileShouldStack: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  pageCards: Array<PageCard>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageCardSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageCardSection filtering options */
export type PageCardSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageCardSectionList = {
  __typename: 'PageCardSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageCardSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageCardSection>>;
  /** Number of PageCardSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageCardSection ordering options */
export type PageCardSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageCardSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageCardSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageDataSection in the system */
export type PageDataSection = PageSection & {
  __typename: 'PageDataSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageDataSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dataSectionFilterVariation: Maybe<PageSectionDataFilterVariationEnum>;
  dataSectionVariation: Maybe<PageSectionDataVariationEnum>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  embeddedFormHeight: Maybe<PageSectionFormFactor>;
  embeddedFormId: Maybe<Scalars['String']['output']>;
  frozenCategories: Maybe<Array<Maybe<FrozenCategory>>>;
  frozenDishes: Array<FrozenMealDish>;
  frozenTags: Maybe<Array<Maybe<FrozenTag>>>;
  /** The PageDataSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  marketProductCategories: Maybe<Array<Maybe<MarketProductCategory>>>;
  marketProductTags: Maybe<Array<Maybe<MarketProductTag>>>;
  marketProducts: Array<MarketProduct>;
  mealKitCategories: Maybe<Array<Maybe<MealKitCategory>>>;
  mealKitDishes: Array<MealKitDish>;
  mealKitTags: Maybe<Array<Maybe<MealKitTag>>>;
  name: Scalars['String']['output'];
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageDataSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  viewAllUrl: Maybe<Scalars['String']['output']>;
  wineCategories: Maybe<Array<Maybe<WineCategory>>>;
  wineTags: Maybe<Array<Maybe<WineTag>>>;
  wines: Array<Wine>;
};

/** PageDataSection filtering options */
export type PageDataSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pageSectionGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageDataSectionList = {
  __typename: 'PageDataSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageDataSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageDataSection>>;
  /** Number of PageDataSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageDataSection ordering options */
export type PageDataSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageDataSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageDataSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageDetail in the system */
export type PageDetail = {
  __typename: 'PageDetail';
  /** The creation date of the PageDetail */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The PageDetail's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  imageHeight: Maybe<Scalars['String']['output']>;
  imageShadow: Maybe<Scalars['Boolean']['output']>;
  imageWidth: Maybe<Scalars['String']['output']>;
  mobileObjectFit: Maybe<ObjectFitEnum>;
  objectFit: Maybe<ObjectFitEnum>;
  pageDetailSection: Maybe<PageDetailSection>;
  title: Scalars['String']['output'];
  /** The update date of the PageDetail */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageDetail filtering options */
export type PageDetailFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageDetailList = {
  __typename: 'PageDetailList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageDetail records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageDetail>>;
  /** Number of PageDetail records skipped */
  skip: Scalars['Int']['output'];
};

/** PageDetail ordering options */
export type PageDetailOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageDetailOrderEnum>;
};

/** Fields available to order Properties */
export enum PageDetailOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageDetailSection in the system */
export type PageDetailSection = PageSection & {
  __typename: 'PageDetailSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageDetailSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageDetailSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pageDetails: Array<PageDetail>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageDetailSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageDetailSection filtering options */
export type PageDetailSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object  */
export type PageDetailSectionList = {
  __typename: 'PageDetailSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageDetailSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageDetailSection>>;
  /** Number of PageDetailSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageDetailSection ordering options */
export type PageDetailSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageDetailSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageDetailSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageGridItem in the system */
export type PageGridItem = {
  __typename: 'PageGridItem';
  /** The creation date of the PageGridItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  gridItemTitle: Scalars['String']['output'];
  /** The PageGridItem's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  imageHeight: Maybe<Scalars['String']['output']>;
  imageShadow: Maybe<Scalars['Boolean']['output']>;
  imageWidth: Maybe<Scalars['String']['output']>;
  mobileObjectFit: Maybe<ObjectFitEnum>;
  objectFit: Maybe<ObjectFitEnum>;
  pageGridSection: Maybe<PageGridSection>;
  titleColor: Maybe<Scalars['String']['output']>;
  /** The update date of the PageGridItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageGridItem filtering options */
export type PageGridItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageGridItemList = {
  __typename: 'PageGridItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageGridItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageGridItem>>;
  /** Number of PageGridItem records skipped */
  skip: Scalars['Int']['output'];
};

/** PageGridItem ordering options */
export type PageGridItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageGridItemOrderEnum>;
};

/** Fields available to order Properties */
export enum PageGridItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageGridSection in the system */
export type PageGridSection = PageSection & {
  __typename: 'PageGridSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  columns: PageSectionFormFactor;
  /** The creation date of the PageGridSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  gutterWidth: Maybe<Scalars['String']['output']>;
  /** The PageGridSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  link: Maybe<PageSectionLink>;
  name: Scalars['String']['output'];
  pageGridItems: Array<PageGridItem>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  spacingType: PageGridSpacingEnum;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageGridSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageGridSection filtering options */
export type PageGridSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageGridSectionList = {
  __typename: 'PageGridSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageGridSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageGridSection>>;
  /** Number of PageGridSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageGridSection ordering options */
export type PageGridSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageGridSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageGridSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PageGridSpacingEnum {
  /** Gutter */
  GUTTER = 'GUTTER',
  /** Space Between */
  SPACE_BETWEEN = 'SPACE_BETWEEN'
}

/** Represents a PageMediaSection in the system */
export type PageMediaSection = PageSection & {
  __typename: 'PageMediaSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  contents: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The creation date of the PageMediaSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageMediaSection's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  imageShadow: Maybe<Scalars['Boolean']['output']>;
  imageSize: Maybe<ImageSizeEnum>;
  imageVariant: Maybe<ImageVariantEnum>;
  index: Scalars['Int']['output'];
  links: Maybe<PageSectionLinks>;
  mobileObjectFit: Maybe<ObjectFitEnum>;
  name: Scalars['String']['output'];
  objectFit: Maybe<ObjectFitEnum>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageMediaSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  variation: Maybe<PageSectionMediaVariationEnum>;
  variationBackground: Maybe<PageSectionBackgroundEnum>;
  video: Maybe<Scalars['String']['output']>;
};

/** PageMediaSection filtering options */
export type PageMediaSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pageSectionGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageMediaSectionList = {
  __typename: 'PageMediaSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageMediaSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageMediaSection>>;
  /** Number of PageMediaSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageMediaSection ordering options */
export type PageMediaSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageMediaSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageMediaSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageModal in the system */
export type PageModal = {
  __typename: 'PageModal';
  actionLink: Maybe<Scalars['String']['output']>;
  actionTitle: Maybe<Scalars['String']['output']>;
  /** The creation date of the PageModal */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  descriptionColor: Maybe<Scalars['String']['output']>;
  hasMobileImage: Maybe<Scalars['Boolean']['output']>;
  /** The PageModal's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  image: Maybe<Image>;
  index: Maybe<Scalars['Int']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  locationLink: Maybe<Scalars['String']['output']>;
  mobileDescriptionColor: Maybe<Scalars['String']['output']>;
  mobileImage: Maybe<Image>;
  mobileSubTitleColor: Maybe<Scalars['String']['output']>;
  mobileTitleColor: Maybe<Scalars['String']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  subTitleColor: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleColor: Maybe<Scalars['String']['output']>;
  /** The update date of the PageModal */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageModal filtering options */
export type PageModalFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageModalList = {
  __typename: 'PageModalList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageModal records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageModal>>;
  /** Number of PageModal records skipped */
  skip: Scalars['Int']['output'];
};

/** PageModal ordering options */
export type PageModalOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageModalOrderEnum>;
};

/** Fields available to order Properties */
export enum PageModalOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageNotification in the system */
export type PageNotification = {
  __typename: 'PageNotification';
  additionalText: Maybe<Scalars['String']['output']>;
  backgroundColor: Maybe<Scalars['String']['output']>;
  canClose: Maybe<Scalars['Boolean']['output']>;
  countDownBackground: Maybe<Scalars['String']['output']>;
  /** The creation date of the PageNotification */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  ctaBackground: Maybe<ButtonColorEnum>;
  ctaText: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  icon: Maybe<Image>;
  /** The PageNotification's ID */
  id: Scalars['ID']['output'];
  isPopup: Maybe<Scalars['Boolean']['output']>;
  link: Maybe<Scalars['String']['output']>;
  linkText: Maybe<Scalars['String']['output']>;
  pageNotificationGroup: PageNotificationGroup;
  shouldCountDownDisplay: Maybe<Scalars['Boolean']['output']>;
  shouldIconDisplay: Maybe<Scalars['Boolean']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  text: Maybe<Scalars['String']['output']>;
  textColor: Maybe<Scalars['String']['output']>;
  /** The update date of the PageNotification */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageNotification filtering options */
export type PageNotificationFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNotificationGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a PageNotificationGroup in the system */
export type PageNotificationGroup = {
  __typename: 'PageNotificationGroup';
  /** The creation date of the PageNotificationGroup */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  currentNotifications: Maybe<Array<Maybe<PageNotification>>>;
  description: Maybe<Scalars['String']['output']>;
  /** The PageNotificationGroup's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pageNotifications: Maybe<Array<Maybe<PageNotification>>>;
  /** The update date of the PageNotificationGroup */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageNotificationGroup filtering options */
export type PageNotificationGroupFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageNotificationGroupList = {
  __typename: 'PageNotificationGroupList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageNotificationGroup records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageNotificationGroup>>;
  /** Number of PageNotificationGroup records skipped */
  skip: Scalars['Int']['output'];
};

/** PageNotificationGroup ordering options */
export type PageNotificationGroupOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageNotificationGroupOrderEnum>;
};

/** Fields available to order Properties */
export enum PageNotificationGroupOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type PageNotificationList = {
  __typename: 'PageNotificationList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageNotification records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageNotification>>;
  /** Number of PageNotification records skipped */
  skip: Scalars['Int']['output'];
};

/** PageNotification ordering options */
export type PageNotificationOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageNotificationOrderEnum>;
};

/** Fields available to order Properties */
export enum PageNotificationOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageSection in the system */
export type PageSection = {
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum PageSectionBackgroundEnum {
  /** Grey */
  GREY = 'GREY',
  /** Light Grey */
  LIGHT_GREY = 'LIGHT_GREY',
  /** Transparent */
  TRANSPARENT = 'TRANSPARENT',
  /** White */
  WHITE = 'WHITE'
}

export enum PageSectionDataFilterVariationEnum {
  /** Categories */
  CATEGORIES = 'CATEGORIES',
  /** Tags */
  TAGS = 'TAGS'
}

export enum PageSectionDataVariationEnum {
  /** Active Wine Campaign */
  ACTIVE_WINE_CAMPAIGN = 'ACTIVE_WINE_CAMPAIGN',
  /** Anchored Meal Kit Menu This Week */
  ANCHORED_MEAL_KIT_MENU_THIS_WEEK = 'ANCHORED_MEAL_KIT_MENU_THIS_WEEK',
  /** Baby Food What To Stock Up On */
  BABY_FOOD_WHAT_TO_STOCK_UP_ON = 'BABY_FOOD_WHAT_TO_STOCK_UP_ON',
  /** Craft Kids Meals Whats In Store This Week */
  CRAFT_KIDS_STORE_THIS_WEEK = 'CRAFT_KIDS_STORE_THIS_WEEK',
  /** Craft Meal Whats In Store This Week */
  CRAFT_MEAL_STORE_THIS_WEEK = 'CRAFT_MEAL_STORE_THIS_WEEK',
  /** Embedded Form */
  EMBEDDED_FORM = 'EMBEDDED_FORM',
  /** Featured Vineyards */
  FEATURED_VINEYARDS = 'FEATURED_VINEYARDS',
  /** Filtered Frozen Meals */
  FILTERED_FROZEN_MEALS = 'FILTERED_FROZEN_MEALS',
  /** Filtered Market Products */
  FILTERED_MARKET_PRODUCTS = 'FILTERED_MARKET_PRODUCTS',
  /** Filtered Meal Kits */
  FILTERED_MEAL_KITS = 'FILTERED_MEAL_KITS',
  /** Filtered Wines */
  FILTERED_WINES = 'FILTERED_WINES',
  /** Meal Kit On The Menu This Week */
  MEAL_KIT_ON_THE_MENU_THIS_WEEK = 'MEAL_KIT_ON_THE_MENU_THIS_WEEK',
  /** Meal Kit Plans */
  MEAL_KIT_PLANS = 'MEAL_KIT_PLANS',
  /** Meet Weaning Sense Email Block */
  MEET_WEANING_SENSE_EMAIL_BLOCK = 'MEET_WEANING_SENSE_EMAIL_BLOCK'
}

/** PageSection filtering options */
export type PageSectionFilters = {
  campaign?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageSectionGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  types?: InputMaybe<Array<PageSectionTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PageSectionFormFactor = {
  __typename: 'PageSectionFormFactor';
  desktop: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mobile: Maybe<Scalars['Int']['output']>;
  tablet: Maybe<Scalars['Int']['output']>;
};

export type PageSectionFormFactorInput = {
  desktop?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['Int']['input']>;
  tablet?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a PageSectionGroup in the system */
export type PageSectionGroup = {
  __typename: 'PageSectionGroup';
  /** The creation date of the PageSectionGroup */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The PageSectionGroup's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pageSections: Array<PageSection>;
  /** The update date of the PageSectionGroup */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageSectionGroup filtering options */
export type PageSectionGroupFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageSectionGroupList = {
  __typename: 'PageSectionGroupList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageSectionGroup records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageSectionGroup>>;
  /** Number of PageSectionGroup records skipped */
  skip: Scalars['Int']['output'];
};

/** PageSectionGroup ordering options */
export type PageSectionGroupOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageSectionGroupOrderEnum>;
};

/** Fields available to order Properties */
export enum PageSectionGroupOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type PageSectionLink = {
  __typename: 'PageSectionLink';
  color: Maybe<ButtonColorEnum>;
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PageSectionLinkInput = {
  color?: InputMaybe<ButtonColorEnum>;
  id?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PageSectionLinks = {
  __typename: 'PageSectionLinks';
  id: Scalars['ID']['output'];
  primary: Maybe<PageSectionLink>;
  secondary: Maybe<PageSectionLink>;
};

export type PageSectionLinksInput = {
  primary?: InputMaybe<PageSectionLinkInput>;
  secondary?: InputMaybe<PageSectionLinkInput>;
};

/** Properties list object */
export type PageSectionList = {
  __typename: 'PageSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageSection>>;
  /** Number of PageSection records skipped */
  skip: Scalars['Int']['output'];
};

export enum PageSectionMediaVariationEnum {
  /** Action Media */
  ACTION_MEDIA = 'ACTION_MEDIA',
  /** Center Card */
  CENTER_CARD = 'CENTER_CARD',
  /** Center Standard */
  CENTER_STANDARD = 'CENTER_STANDARD',
  /** Left Elegant */
  LEFT_ELEGANT = 'LEFT_ELEGANT',
  /** Left List */
  LEFT_LIST = 'LEFT_LIST',
  /** Left Standard */
  LEFT_STANDARD = 'LEFT_STANDARD',
  /** Right Elegant */
  RIGHT_ELEGANT = 'RIGHT_ELEGANT',
  /** Right List */
  RIGHT_LIST = 'RIGHT_LIST',
  /** Right Standard */
  RIGHT_STANDARD = 'RIGHT_STANDARD'
}

/** PageSection ordering options */
export type PageSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PageSectionTypeEnum {
  /** Page Banner Section */
  PAGE_BANNER_SECTION = 'PAGE_BANNER_SECTION',
  /** Page Card Section */
  PAGE_CARD_SECTION = 'PAGE_CARD_SECTION',
  /** Page Data Section */
  PAGE_DATA_SECTION = 'PAGE_DATA_SECTION',
  /** Page Detail Section */
  PAGE_DETAIL_SECTION = 'PAGE_DETAIL_SECTION',
  /** Page Grid Section */
  PAGE_GRID_SECTION = 'PAGE_GRID_SECTION',
  /** Page Media Section */
  PAGE_MEDIA_SECTION = 'PAGE_MEDIA_SECTION',
  /** Page Tab Section */
  PAGE_TAB_SECTION = 'PAGE_TAB_SECTION',
  /** Page Text Card Section */
  PAGE_TEXT_CARD_SECTION = 'PAGE_TEXT_CARD_SECTION',
  /** Page Text Section */
  PAGE_TEXT_SECTION = 'PAGE_TEXT_SECTION',
  /** Page Tile Section */
  PAGE_TILE_SECTION = 'PAGE_TILE_SECTION'
}

/** Represents a PageTab in the system */
export type PageTab = {
  __typename: 'PageTab';
  /** The creation date of the PageTab */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  header: Maybe<Scalars['String']['output']>;
  /** The PageTab's ID */
  id: Scalars['ID']['output'];
  pageTabItems: Array<PageTabItem>;
  pageTabSection: PageTabSection;
  /** The update date of the PageTab */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTab filtering options */
export type PageTabFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a PageTabItem in the system */
export type PageTabItem = {
  __typename: 'PageTabItem';
  /** The creation date of the PageTabItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The PageTabItem's ID */
  id: Scalars['ID']['output'];
  pageTab: PageTab;
  tabItemTitle: Scalars['String']['output'];
  /** The update date of the PageTabItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTabItem filtering options */
export type PageTabItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  tabItemTitle?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTabItemList = {
  __typename: 'PageTabItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTabItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTabItem>>;
  /** Number of PageTabItem records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTabItem ordering options */
export type PageTabItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTabItemOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTabItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type PageTabList = {
  __typename: 'PageTabList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTab records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTab>>;
  /** Number of PageTab records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTab ordering options */
export type PageTabOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTabOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTabOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageTabSection in the system */
export type PageTabSection = PageSection & {
  __typename: 'PageTabSection';
  activeTab: Maybe<Scalars['String']['output']>;
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageTabSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageTabSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pageSectionGroup: Maybe<PageSectionGroup>;
  pageTabs: Array<PageTab>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageTabSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTabSection filtering options */
export type PageTabSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTabSectionList = {
  __typename: 'PageTabSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTabSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTabSection>>;
  /** Number of PageTabSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTabSection ordering options */
export type PageTabSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTabSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTabSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageTextCard in the system */
export type PageTextCard = {
  __typename: 'PageTextCard';
  contentColumns: Maybe<PageSectionFormFactor>;
  contents: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The creation date of the PageTextCard */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  hideContentOverflow: Maybe<Scalars['Boolean']['output']>;
  /** The PageTextCard's ID */
  id: Scalars['ID']['output'];
  paddingBottom: Maybe<Scalars['String']['output']>;
  paddingTop: Maybe<Scalars['String']['output']>;
  pageTextCardSection: PageTextCardSection;
  rating: Maybe<Scalars['Int']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageTextCard */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTextCard filtering options */
export type PageTextCardFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageTextCardSection?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTextCardList = {
  __typename: 'PageTextCardList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTextCard records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTextCard>>;
  /** Number of PageTextCard records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTextCard ordering options */
export type PageTextCardOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTextCardOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTextCardOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageTextCardSection in the system */
export type PageTextCardSection = PageSection & {
  __typename: 'PageTextCardSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageTextCardSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageTextCardSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pageSectionGroup: Maybe<PageSectionGroup>;
  pageTextCards: Array<PageTextCard>;
  selectorId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageTextCardSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTextCardSection filtering options */
export type PageTextCardSectionFilters = {
  campaign?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageSectionGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTextCardSectionList = {
  __typename: 'PageTextCardSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTextCardSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTextCardSection>>;
  /** Number of PageTextCardSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTextCardSection ordering options */
export type PageTextCardSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTextCardSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTextCardSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageTextSection in the system */
export type PageTextSection = PageSection & {
  __typename: 'PageTextSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  contentColumns: Maybe<PageSectionFormFactor>;
  contents: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The creation date of the PageTextSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  hideContentOverflow: Maybe<Scalars['Boolean']['output']>;
  /** The PageTextSection's  ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  links: Maybe<PageSectionLinks>;
  name: Scalars['String']['output'];
  paddingBottom: Maybe<Scalars['String']['output']>;
  paddingTop: Maybe<Scalars['String']['output']>;
  pageSectionGroup: Maybe<PageSectionGroup>;
  selectorId: Maybe<Scalars['String']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  textAlign: Maybe<TextAlignEnum>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageTextSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTextSection filtering options */
export type PageTextSectionFilters = {
  campaign?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageSectionGroup?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTextSectionList = {
  __typename: 'PageTextSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTextSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTextSection>>;
  /** Number of PageTextSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTextSection ordering options */
export type PageTextSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTextSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTextSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PageTileSection in the system */
export type PageTileSection = PageSection & {
  __typename: 'PageTileSection';
  background: Maybe<PageSectionBackgroundEnum>;
  campaign: Maybe<Campaign>;
  /** The creation date of the PageTileSection */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The PageTileSection's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  link: Maybe<PageSectionLink>;
  name: Scalars['String']['output'];
  pageSectionGroup: Maybe<PageSectionGroup>;
  pageTiles: Array<PageCard>;
  selectorId: Maybe<Scalars['String']['output']>;
  tileVariation: Maybe<PageTileVariationEnum>;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the PageTileSection */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PageTileSection filtering options */
export type PageTileSectionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PageTileSectionList = {
  __typename: 'PageTileSectionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PageTileSection records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PageTileSection>>;
  /** Number of PageTileSection records skipped */
  skip: Scalars['Int']['output'];
};

/** PageTileSection ordering options */
export type PageTileSectionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PageTileSectionOrderEnum>;
};

/** Fields available to order Properties */
export enum PageTileSectionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PageTileVariationEnum {
  /** Grid */
  GRID = 'GRID',
  /** Image */
  IMAGE = 'IMAGE',
  /** Standard */
  STANDARD = 'STANDARD'
}

export enum PartnershipPlatformEnum {
  /** Discovery */
  DISCOVERY = 'DISCOVERY',
  /** Other */
  OTHER = 'OTHER'
}

export type PayOrderOutput = {
  __typename: 'PayOrderOutput';
  id: Scalars['ID']['output'];
  message: Maybe<Scalars['String']['output']>;
  redirectUrl: Maybe<Scalars['URI']['output']>;
  status: TransactionStatusEnum;
};

export type PayParameters = {
  __typename: 'PayParameters';
  parameters: Array<Maybe<NameValue>>;
  url: Scalars['URI']['output'];
};

/** Represents a Payment in the system */
export type Payment = Transaction & {
  __typename: 'Payment';
  /** The creation date of the Payment */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Credit Card associated to the transaction */
  creditCard: Maybe<CreditCard>;
  /** The description of the transaction */
  description: Scalars['String']['output'];
  /** Due amount */
  dueAmount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Payment method peach_payments/credit/voucher etc etc */
  method: TransactionMethodEnum;
  /** The Order associated to the transaction */
  order: Maybe<Order>;
  /** Paid amount */
  paidAmount: Scalars['Float']['output'];
  /** 3D Secure parameters needed to build the form */
  parameters: Maybe<Scalars['JSONObject']['output']>;
  /** Refunds associated to this payment object */
  refunds: Maybe<Array<Maybe<Refund>>>;
  /** Indicates the payment is awaiting 3D secure authentication */
  requires3DSecure: Scalars['Boolean']['output'];
  /** The status of the transaction */
  status: TransactionStatusEnum;
  /** The status message */
  statusMessage: Scalars['String']['output'];
  /** Additional Transaction Data */
  transactionData: Maybe<Scalars['JSON']['output']>;
  /** Transaction ID (peach_id most likely) */
  transactionId: Maybe<Scalars['String']['output']>;
  /** The update date of the Payment */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The User associated to the transaction */
  user: User;
  /** unsecure user fragment for display */
  userDetails: PaymentUserDetails;
};

/** Payment filtering options */
export type PaymentFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<TransactionStatusEnum>>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Fields to create an debit or credit payment */
export type PaymentInput = {
  cardNumber: Scalars['String']['input'];
  cvv: Scalars['String']['input'];
  expiryMonth: Scalars['ExpiryMonth']['input'];
  expiryYear: Scalars['ExpiryYear']['input'];
  nameOnCard: Scalars['String']['input'];
};

export enum PaymentLinkValidDurationEnum {
  /** Eight Hours */
  EIGHT_HOURS = 'EIGHT_HOURS',
  /** Twenty Four Hours */
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
  /** Two Hours */
  TWO_HOURS = 'TWO_HOURS',
  /** Unlimited */
  UNLIMITED = 'UNLIMITED'
}

/** Properties list object */
export type PaymentList = {
  __typename: 'PaymentList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Payment records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Payment>>;
  /** Number of Payment records skipped */
  skip: Scalars['Int']['output'];
};

/** Payment methods */
export enum PaymentMethod {
  ADMIN_ADJUSTMENT = 'ADMIN_ADJUSTMENT',
  CASH = 'CASH',
  COUPON_CODE = 'COUPON_CODE',
  EFT = 'EFT',
  FREE = 'FREE',
  GIFT_CARD = 'GIFT_CARD',
  PEACH_PAYMENTS_CARD = 'PEACH_PAYMENTS_CARD',
  POINTS = 'POINTS'
}

/** Payment ordering options */
export type PaymentOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PaymentOrderEnum>;
};

/** Fields available to order Properties */
export enum PaymentOrderEnum {
  CREATED_AT = 'CREATED_AT',
  METHOD = 'METHOD',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PaymentProviderEnum {
  /** Peach Payments */
  PEACH_PAYMENTS = 'PEACH_PAYMENTS',
  /** Peach Payments Test */
  PEACH_PAYMENTS_TEST = 'PEACH_PAYMENTS_TEST'
}

export type PaymentUserDetails = {
  __typename: 'PaymentUserDetails';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Represents a Pdf in the system */
export type Pdf = File & {
  __typename: 'Pdf';
  categories: Maybe<Array<Maybe<FileCategory>>>;
  /** The creation date of the Pdf */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  extension: Scalars['String']['output'];
  /** The Pdf's ID */
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The update date of the Pdf */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['Int']['output'];
};

/** Pdf filtering options */
export type PdfFilters = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PdfList = {
  __typename: 'PdfList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Pdf records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Pdf>>;
  /** Number of Pdf records skipped */
  skip: Scalars['Int']['output'];
};

/** Pdf ordering options */
export type PdfOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PdfOrderEnum>;
};

/** Fields available to order Properties */
export enum PdfOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Permission in the system */
export type Permission = {
  __typename: 'Permission';
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** The Permission's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  isOwned: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  objectType: Maybe<Scalars['String']['output']>;
  roles: Maybe<Array<Maybe<Role>>>;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Permission filtering options */
export type PermissionFilters = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<PermissionTypeEnum>>>;
};

/** Properties list object */
export type PermissionList = {
  __typename: 'PermissionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Permission records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Permission>>;
  /** Number of Permission records skipped */
  skip: Scalars['Int']['output'];
};

/** Permission ordering options */
export type PermissionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PermissionOrderEnum>;
};

/** Fields available to order Properties */
export enum PermissionOrderEnum {
  MODULE = 'MODULE',
  NAME = 'NAME',
  OBJECT_TYPE = 'OBJECT_TYPE',
  TYPE = 'TYPE'
}

/** The type of permission */
export enum PermissionTypeEnum {
  /** Dynamic Permission (Specified in Schema) */
  DYNAMIC_PERMISSION = 'DYNAMIC_PERMISSION',
  /** Static Permission (Specified in Code) */
  STATIC_PERMISSION = 'STATIC_PERMISSION'
}

export type PlaceDetails = {
  __typename: 'PlaceDetails';
  center: Coordinate;
  fullAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PlacementStatusChangeEvent = ItemHistoryEventData & {
  __typename: 'PlacementStatusChangeEvent';
  newStatus: PlacementStatusEnum;
  note: Maybe<Scalars['String']['output']>;
  oldStatus: PlacementStatusEnum;
};

export enum PlacementStatusEnum {
  /** Awaiting Feedback */
  AWAITING_FEEDBACK = 'AWAITING_FEEDBACK',
  /** Failed */
  FAILED = 'FAILED',
  /** Manual Intervention */
  MANUAL_INTERVENTION = 'MANUAL_INTERVENTION',
  /** Pending */
  PENDING = 'PENDING',
  /** Pending Feedback */
  PENDING_FEEDBACK = 'PENDING_FEEDBACK',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Retrying */
  RETRYING = 'RETRYING',
  /** Skipped */
  SKIPPED = 'SKIPPED',
  /** Successful */
  SUCCESSFUL = 'SUCCESSFUL'
}

export enum PoorQualityIngredientTagEnum {
  /** Foreign object- natural */
  FOREIGN_OBJECT_NATURAL = 'FOREIGN_OBJECT_NATURAL',
  /** Foreign object- unnatural */
  FOREIGN_OBJECT_UNNATURAL = 'FOREIGN_OBJECT_UNNATURAL',
  /** Poor quality bread */
  POOR_QUALITY_BREAD = 'POOR_QUALITY_BREAD',
  /** Poor quality chicken */
  POOR_QUALITY_CHICKEN = 'POOR_QUALITY_CHICKEN',
  /** Poor quality Dry goods item */
  POOR_QUALITY_DRY_GOODS_ITEM = 'POOR_QUALITY_DRY_GOODS_ITEM',
  /** Poor quality Fish */
  POOR_QUALITY_FISH = 'POOR_QUALITY_FISH',
  /** Poor quality Hard Veg */
  POOR_QUALITY_HARD_VEG = 'POOR_QUALITY_HARD_VEG',
  /** Poor quality Leaves */
  POOR_QUALITY_LEAVES = 'POOR_QUALITY_LEAVES',
  /** Poor quality red meat */
  POOR_QUALITY_RED_MEAT = 'POOR_QUALITY_RED_MEAT',
  /** Poor quality Veg */
  POOR_QUALITY_VEG = 'POOR_QUALITY_VEG'
}

export enum PortionSizeEnum {
  /** Serves 1 */
  SERVES_1 = 'SERVES_1',
  /** Serves 2 */
  SERVES_2 = 'SERVES_2',
  /** Serves 3 */
  SERVES_3 = 'SERVES_3',
  /** Serves 4 */
  SERVES_4 = 'SERVES_4'
}

export enum PortionSizeTagEnum {
  /** Chicken */
  CHICKEN = 'CHICKEN',
  /** Dry Goods */
  DRY_GOODS = 'DRY_GOODS',
  /** Fish */
  FISH = 'FISH',
  /** Hard Veg */
  HARD_VEG = 'HARD_VEG',
  /** Incorrect Portion */
  INCORRECT_PORTION = 'INCORRECT_PORTION',
  /** Leaves */
  LEAVES = 'LEAVES',
  /** Red Meat */
  RED_MEAT = 'RED_MEAT',
  /** Whole Meal */
  WHOLE_MEAL = 'WHOLE_MEAL'
}

/** Represents a Preference in the system */
export type Preference = {
  __typename: 'Preference';
  /** The creation date of the Preference */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dataType: DataTypeEnum;
  defaultValue: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  /** The Preference's ID */
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  options: Maybe<Scalars['String']['output']>;
  preferenceCategory: Maybe<PreferenceCategory>;
  /** The update date of the Preference */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
};

/** Represents a PreferenceCategory in the system */
export type PreferenceCategory = {
  __typename: 'PreferenceCategory';
  /** The creation date of the PreferenceCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The PreferenceCategory's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  preferences: Maybe<Array<Maybe<Preference>>>;
  /** The update date of the PreferenceCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** PreferenceCategory filtering options */
export type PreferenceCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PreferenceCategoryList = {
  __typename: 'PreferenceCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PreferenceCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PreferenceCategory>>;
  /** Number of PreferenceCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** PreferenceCategory ordering options */
export type PreferenceCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PreferenceCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum PreferenceCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Preference filtering options */
export type PreferenceFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataType?: InputMaybe<DataTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferenceCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type PreferenceList = {
  __typename: 'PreferenceList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Preference records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Preference>>;
  /** Number of Preference records skipped */
  skip: Scalars['Int']['output'];
};

/** Preference ordering options */
export type PreferenceOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PreferenceOrderEnum>;
};

/** Fields available to order Properties */
export enum PreferenceOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DATA_TYPE = 'DATA_TYPE',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type ProcReportOrderFile = {
  __typename: 'ProcReportOrderFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Represents a ProcessGroup in the system */
export type ProcessGroup = {
  __typename: 'ProcessGroup';
  /** The creation date of the ProcessGroup */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The ProcessGroup's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orderType: OrderTypeEnum;
  processGroupCouriers: Maybe<Array<Maybe<ProcessGroupCourier>>>;
  squencePosition: Scalars['Int']['output'];
  /** The update date of the ProcessGroup */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a ProcessGroupCourier in the system */
export type ProcessGroupCourier = {
  __typename: 'ProcessGroupCourier';
  courier: Maybe<Courier>;
  /** The creation date of the ProcessGroupCourier */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ProcessGroupCourier's ID */
  id: Scalars['ID']['output'];
  priority: Scalars['Int']['output'];
  processGroup: Maybe<ProcessGroup>;
  /** The update date of the ProcessGroupCourier */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProcessGroupCourier filtering options */
export type ProcessGroupCourierFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  processGroupId?: InputMaybe<Scalars['ID']['input']>;
  processGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProcessGroupCourierList = {
  __typename: 'ProcessGroupCourierList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProcessGroupCourier records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProcessGroupCourier>>;
  /** Number of ProcessGroupCourier records skipped */
  skip: Scalars['Int']['output'];
};

/** ProcessGroupCourier ordering options */
export type ProcessGroupCourierOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProcessGroupCourierOrderEnum>;
};

/** Fields available to order Properties */
export enum ProcessGroupCourierOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** ProcessGroup filtering options */
export type ProcessGroupFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProcessGroupList = {
  __typename: 'ProcessGroupList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProcessGroup records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProcessGroup>>;
  /** Number of ProcessGroup records skipped */
  skip: Scalars['Int']['output'];
};

/** ProcessGroup ordering options */
export type ProcessGroupOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProcessGroupOrderEnum>;
};

/** Fields available to order Properties */
export enum ProcessGroupOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProcessRefundActionEnum {
  /** Approve */
  APPROVE = 'APPROVE',
  /** Reject */
  REJECT = 'REJECT'
}

export enum ProcurementTypeEnum {
  /** Bulk */
  BULK = 'BULK',
  /** Pre-portioned */
  PREPORTIONED = 'PREPORTIONED'
}

/** Represents a Product in the system */
export type Product = {
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Maybe<Array<Maybe<Category>>>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  group: Maybe<ProductGroup>;
  /** The Product's ID */
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  name: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  syncStatus: ProductSyncStatusEnum;
  taxCategory: TaxCategoryTypeEnum;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: Array<ProductWarehouse>;
};

export type ProductAggregation = {
  __typename: 'ProductAggregation';
  id: Scalars['ID']['output'];
  sections: Array<ProductAggregationSection>;
};

export type ProductAggregationItem = {
  __typename: 'ProductAggregationItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ProductAggregationSection = {
  __typename: 'ProductAggregationSection';
  filterKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<ProductAggregationItem>;
  title: Scalars['String']['output'];
};

/** Represents a ProductAvailabilityEmail in the system */
export type ProductAvailabilityEmail = {
  __typename: 'ProductAvailabilityEmail';
  area: Maybe<Area>;
  city: Maybe<City>;
  /** The creation date of the ProductAvailabilityEmail */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  /** The ProductAvailabilityEmail's ID */
  id: Scalars['ID']['output'];
  /** The update date of the ProductAvailabilityEmail */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProductAvailabilityEmail filtering options */
export type ProductAvailabilityEmailFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProductAvailabilityEmailList = {
  __typename: 'ProductAvailabilityEmailList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductAvailabilityEmail records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductAvailabilityEmail>>;
  /** Number of ProductAvailabilityEmail records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductAvailabilityEmail ordering options */
export type ProductAvailabilityEmailOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductAvailabilityEmailOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductAvailabilityEmailOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProductAvailabilityEnum {
  /** available */
  AVAILABLE = 'AVAILABLE',
  /** not available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** unavailable for order type */
  UNAVAILABLE_FOR_ORDER_TYPE = 'UNAVAILABLE_FOR_ORDER_TYPE',
  /** unavailable geographically */
  UNAVAILABLE_GEOGRAPHICALLY = 'UNAVAILABLE_GEOGRAPHICALLY',
  /** unavailable on selected delivery day */
  UNAVAILABLE_ON_DELIVERY_DAY = 'UNAVAILABLE_ON_DELIVERY_DAY'
}

/** Product filtering options */
export type ProductFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ranges?: InputMaybe<Array<ProductRangeEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  types?: InputMaybe<Array<ProductTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Represents a ProductGroup in the system */
export type ProductGroup = {
  cartItemIds: Array<Scalars['String']['output']>;
  configurationOptions: Maybe<Array<Maybe<Configuration>>>;
  coverImage: Image;
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  discoveryHealthyDiningItem: Maybe<Scalars['Boolean']['output']>;
  groupProducts: Maybe<Array<Maybe<Product>>>;
  /** The ProductGroup's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  quantityInCart: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  type: ProductGroupTypeEnum;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProductGroup filtering options */
export type ProductGroupFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProductGroupList = {
  __typename: 'ProductGroupList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductGroup records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductGroup>>;
  /** Number of ProductGroup records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductGroup ordering options */
export type ProductGroupOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductGroupOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductGroupOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProductGroupTypeEnum {
  /** Product */
  PRODUCT = 'PRODUCT',
  /** Virtual */
  VIRTUAL = 'VIRTUAL'
}

/** Represents a ProductImage in the system */
export type ProductImage = {
  __typename: 'ProductImage';
  /** The creation date of the ProductImage */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ProductImage's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  index: Maybe<Scalars['Int']['output']>;
  isDefault: Maybe<Scalars['Boolean']['output']>;
  productGroupId: Maybe<Scalars['ID']['output']>;
  productId: Maybe<Scalars['ID']['output']>;
  /** The update date of the ProductImage */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a ProductIngredient in the system */
export type ProductIngredient = {
  __typename: 'ProductIngredient';
  action: Maybe<Scalars['String']['output']>;
  /** The creation date of the ProductIngredient */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  displayTitle: Scalars['String']['output'];
  displayUnit: Scalars['String']['output'];
  /** The ProductIngredient's ID */
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  ingredient: Product;
  parent: Product;
  quantity: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  unitType: Maybe<UnitTypeEnum>;
  /** The update date of the ProductIngredient */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProductIngredient filtering options */
export type ProductIngredientFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProductIngredientList = {
  __typename: 'ProductIngredientList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductIngredient records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductIngredient>>;
  /** Number of ProductIngredient records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductIngredient ordering options */
export type ProductIngredientOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductIngredientOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductIngredientOrderEnum {
  CREATED_AT = 'CREATED_AT',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export type ProductIsle = {
  __typename: 'ProductIsle';
  asileSlug: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  products: Array<IsleProduct>;
  title: Scalars['String']['output'];
};


export type ProductIsleproductsArgs = {
  order?: InputMaybe<Array<InputMaybe<FrozenMealDishOrder>>>;
};

export type ProductIsles = {
  __typename: 'ProductIsles';
  isles: Array<ProductIsle>;
};

/** Properties list object */
export type ProductList = {
  __typename: 'ProductList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Product records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Product>;
  /** Number of Product records skipped */
  skip: Scalars['Int']['output'];
};

export enum ProductOfferingsEnum {
  /** Dairy */
  DAIRY = 'DAIRY',
  /** Fresh */
  FRESH = 'FRESH',
  /** Herbs & Spices */
  HERBS_SPICES = 'HERBS_SPICES',
  /** Proteins */
  PROTEINS = 'PROTEINS',
  /** Wheat & Grains */
  WHEAT_GRAINS = 'WHEAT_GRAINS'
}

/** Product ordering options */
export type ProductOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PRICE = 'PRICE',
  RANGES = 'RANGES',
  STATUS = 'STATUS',
  TYPES = 'TYPES',
  UPDATED_AT = 'UPDATED_AT',
  VINEYARD = 'VINEYARD'
}

export enum ProductOrderTypeEnum {
  /** Both */
  BOTH = 'BOTH',
  /** Internal */
  INTERNAL = 'INTERNAL',
  /** On Demand */
  ON_DEMAND = 'ON_DEMAND',
  /** Subscription */
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export enum ProductRangeChangeDirection {
  /** Add */
  ADD = 'ADD',
  /** Remove */
  REMOVE = 'REMOVE'
}

export enum ProductRangeEnum {
  /** Baby Box */
  BABY_BOX = 'BABY_BOX',
  /** Craft Kids */
  CRAFT_KIDS = 'CRAFT_KIDS',
  /** Essentials */
  ESSENTIALS = 'ESSENTIALS',
  /** Frozen Meal */
  FROZEN_MEAL = 'FROZEN_MEAL',
  /** GIFT */
  GIFT = 'GIFT',
  /** Ingredient */
  INGREDIENT = 'INGREDIENT',
  /** Market */
  MARKET_PRODUCT = 'MARKET_PRODUCT',
  /** Meal Kit */
  MEAL_KIT = 'MEAL_KIT',
  /** Meal Kit Plan */
  MEAL_KIT_PLAN = 'MEAL_KIT_PLAN',
  /** Meal Kit Subscription Fee */
  MEAL_KIT_SUBSCRIPTION_FEE = 'MEAL_KIT_SUBSCRIPTION_FEE',
  /** Wine */
  WINE = 'WINE'
}

export enum ProductStatusEnum {
  /** Discontinued */
  DISCONTINUED = 'DISCONTINUED',
  /** Not Available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** Published */
  PUBLISHED = 'PUBLISHED',
  /** Staged */
  STAGED = 'STAGED'
}

/** Represents a ProductStock in the system */
export type ProductStock = {
  __typename: 'ProductStock';
  admin: Maybe<RegisteredUser>;
  amount: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  /** The creation date of the ProductStock */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ProductStock's ID */
  id: Scalars['ID']['output'];
  note: Maybe<Scalars['String']['output']>;
  physicalStockCount: Scalars['Float']['output'];
  previousStockCount: Scalars['Float']['output'];
  stockChangeType: StockChangeTypeEnum;
  stockCount: Scalars['Float']['output'];
  stockDecreaseType: Maybe<StockDecreaseTypeEnum>;
  stockIncreaseType: Maybe<StockIncreaseTypeEnum>;
  /** The update date of the ProductStock */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProductStock filtering options */
export type ProductStockFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productWarehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProductStockList = {
  __typename: 'ProductStockList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductStock records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductStock>>;
  /** Number of ProductStock records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductStock ordering options */
export type ProductStockOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductStockOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductStockOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProductStockStatusEnum {
  /** In Stock */
  IN_STOCK = 'IN_STOCK',
  /** Low Stock */
  LOW_STOCK = 'LOW_STOCK',
  /** Out of Stock */
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export enum ProductStockTypeEnum {
  /** Internal */
  INTERNAL = 'INTERNAL',
  /** Not Available */
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  /** Procured */
  PROCURED = 'PROCURED',
  /** Stock */
  STOCK = 'STOCK',
  /** Virtual */
  VIRTUAL = 'VIRTUAL'
}

export enum ProductStorageTempEnum {
  /** Ambient */
  AMBIENT = 'AMBIENT',
  /** Chilled */
  CHILLED = 'CHILLED',
  /** Frozen */
  FROZEN = 'FROZEN',
  /** None */
  NONE = 'NONE',
  /** Occasion */
  OCCASIION = 'OCCASIION'
}

export enum ProductSyncStatusEnum {
  /** Failed */
  FAILED = 'FAILED',
  /** Un-Synced */
  NOT_SYNCED = 'NOT_SYNCED',
  /** Synced */
  SYNCED = 'SYNCED'
}

export enum ProductTypeEnum {
  /** Finished Goods */
  FINISHED_GOODS = 'FINISHED_GOODS',
  /** Ingredient */
  INGREDIENT = 'INGREDIENT',
  /** Non Stock */
  NON_STOCK = 'NON_STOCK',
  /** Packaging */
  PACKAGING = 'PACKAGING',
  /** Subassembly */
  SUBASSEMBLY = 'SUBASSEMBLY',
  /** Virtual Ingredient */
  VIRTUAL_INGREDIENT = 'VIRTUAL_INGREDIENT'
}

/** Represents a ProductWarehouse in the system */
export type ProductWarehouse = {
  __typename: 'ProductWarehouse';
  cost: Scalars['Float']['output'];
  /** The creation date of the ProductWarehouse */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ProductWarehouse's ID */
  id: Scalars['ID']['output'];
  itemProduct: Product;
  /** The last sync date of the Costs from Acumatica */
  lastSyncedAt: Maybe<Scalars['DateTime']['output']>;
  lowStockAlertThreshold: Scalars['Float']['output'];
  orderType: ProductOrderTypeEnum;
  physicalStockCount: Scalars['Float']['output'];
  procurementType: ProcurementTypeEnum;
  stockCount: Scalars['Float']['output'];
  stockType: ProductStockTypeEnum;
  /** The update date of the ProductWarehouse */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vendors: Maybe<Array<Vendor>>;
  warehouse: Warehouse;
};

/** ProductWarehouse filtering options */
export type ProductWarehouseFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type ProductWarehouseList = {
  __typename: 'ProductWarehouseList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductWarehouse records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductWarehouse>>;
  /** Number of ProductWarehouse records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductWarehouse ordering options */
export type ProductWarehouseOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductWarehouseOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductWarehouseOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a ProductWarhouseCostHistory in the system */
export type ProductWarhouseCostHistory = {
  __typename: 'ProductWarhouseCostHistory';
  costPerUnit: Scalars['Float']['output'];
  /** The creation date of the ProductWarhouseCostHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ProductWarhouseCostHistory's ID */
  id: Scalars['ID']['output'];
  productWarehouse: ProductWarehouse;
  taxClass: Maybe<TaxClass>;
  taxPercentage: Scalars['Float']['output'];
  taxReceiptEvent: TaxReceiptEvent;
  /** The update date of the ProductWarhouseCostHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ProductWarhouseCostHistory filtering options */
export type ProductWarhouseCostHistoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ProductWarhouseCostHistoryList = {
  __typename: 'ProductWarhouseCostHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ProductWarhouseCostHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ProductWarhouseCostHistory>>;
  /** Number of ProductWarhouseCostHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** ProductWarhouseCostHistory ordering options */
export type ProductWarhouseCostHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductWarhouseCostHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum ProductWarhouseCostHistoryOrderEnum {
  COST_PER_UNIT = 'COST_PER_UNIT',
  CREATED_AT = 'CREATED_AT',
  TAX_PERCENTAGE = 'TAX_PERCENTAGE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a PublicHoliday in the system */
export type PublicHoliday = {
  __typename: 'PublicHoliday';
  /** The creation date of the PublicHoliday */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['Date']['output'];
  description: Maybe<Scalars['String']['output']>;
  /** The PublicHoliday's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the PublicHoliday */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  year: Scalars['String']['output'];
};

/** PublicHoliday filtering options */
export type PublicHolidayFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Properties list object */
export type PublicHolidayList = {
  __typename: 'PublicHolidayList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of PublicHoliday records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<PublicHoliday>>;
  /** Number of PublicHoliday records skipped */
  skip: Scalars['Int']['output'];
};

/** PublicHoliday ordering options */
export type PublicHolidayOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<PublicHolidayOrderEnum>;
};

/** Fields available to order Properties */
export enum PublicHolidayOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DATE = 'DATE',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  YEAR = 'YEAR'
}

export type Query = {
  __typename: 'Query';
  activeCart: Cart;
  activeMenu: Maybe<Menu>;
  activeMenuCategories: Maybe<ActiveMenuCategories>;
  activeMenuTags: Maybe<ActiveMenuTags>;
  activeWineCampaign: Maybe<WineCampaign>;
  allAbTestActiveBuckets: AbTestActiveBucketList;
  allAbTestActiveUserBuckets: AbTestActiveBucketList;
  /** Get a list of AbTestBucketUser with order and filtering options */
  allAbTestBucketUsers: AbTestBucketUserList;
  /** Get a list of AbTestBucket with order and filtering options */
  allAbTestBuckets: AbTestBucketList;
  /** Get a list of AbTestFilter with order and filtering options */
  allAbTestFilters: AbTestFilterList;
  /** Get a list of AbTest with order and filtering options */
  allAbTests: AbTestList;
  /** Get a list of AcumaticaDiscountNonStockSku with order and filtering options */
  allAcumaticaDiscountNonStockSkus: AcumaticaDiscountNonStockSkuList;
  /** Get a list of AcumaticaProductTypeNonStockSku with order and filtering options */
  allAcumaticaProductTypeNonStockSkus: AcumaticaProductTypeNonStockSkuList;
  /** Get a list of AddressIssue with order and filtering options */
  allAddressIssues: AddressIssueList;
  /** Get a list of Allergen with order and filtering options */
  allAllergens: AllergenList;
  /** Get a list of Area with order and filtering options */
  allAreas: AreaList;
  /** Get a list of BabyBoxCategory with order and filtering options */
  allBabyBoxCategories: BabyBoxCategoryList;
  /** Get a list of BabyBoxTag with order and filtering options */
  allBabyBoxTags: BabyBoxTagList;
  /** Get a list of BabyBoxTubDetail with order and filtering options */
  allBabyBoxTubDetails: BabyBoxTubDetailList;
  /** Get a list of BabyBoxTub with order and filtering options */
  allBabyBoxTubs: BabyBoxTubList;
  allBabyBoxes: BabyBoxList;
  /** Get a list of BankBinNumber with order and filtering options */
  allBankBinNumbers: BankBinNumberList;
  /** Get a list of Barcode with order and filtering options */
  allBarcodes: BarcodeList;
  /** Get a list of BoxRestriction with order and filtering options */
  allBoxRestrictions: BoxRestrictionList;
  /** Get a list of BoxSubContainer with order and filtering options */
  allBoxSubContainers: BoxSubContainerList;
  /** Get a list of Box with order and filtering options */
  allBoxes: BoxList;
  /** Get a list of Branch with order and filtering options */
  allBranches: BranchList;
  /** Get a list of Brand with order and filtering options */
  allBrands: BrandList;
  /** Get a list of Campaigns with order and filtering options */
  allCampaigns: CampaignList;
  /** Get a list of Cart with order and filtering options */
  allCarts: CartList;
  /** Get a list of Category with order and filtering options */
  allCategories: CategoryList;
  /** Get a list of CategoryGroup with order and filtering options */
  allCategoryGroups: CategoryGroupList;
  /** Get a list of Chef with order and filtering options */
  allChefs: ChefList;
  /** Get a list of City with order and filtering options */
  allCities: CityList;
  /** Get a list of CommunicationTemplate with order and filtering options */
  allCommunicationTemplates: CommunicationTemplateList;
  /** Get a list of Country with order and filtering options */
  allCountries: CountryList;
  /** Get a list of CourierBox with order and filtering options */
  allCourierBoxes: CourierBoxList;
  /** Get a list of CourierDelivery with order and filtering options */
  allCourierDeliveries: CourierDeliveryList;
  /** Get a list of CourierDeliveryDay with order and filtering options */
  allCourierDeliveryDays: CourierDeliveryDayList;
  /** Get a list of CourierDeliveryHistory with order and filtering options */
  allCourierDeliveryHistories: CourierDeliveryHistoryList;
  /** Get a list of CourierWarehouseCity with order and filtering options */
  allCourierWarehouseCities: CourierWarehouseCityList;
  /** Get a list of CourierWarehouseCityDeliveryDay with order and filtering options */
  allCourierWarehouseCityDeliveryDays: CourierWarehouseCityDeliveryDayList;
  /** Get a list of CourierWarehouse with order and filtering options */
  allCourierWarehouses: CourierWarehouseList;
  /** Get a list of Courier with order and filtering options */
  allCouriers: CourierList;
  /** Get a list of CreditCard with order and filtering options */
  allCreditCards: CreditCardList;
  /** Get a list of DashboardUserMenu with order and filtering options */
  allDashboardUserMenus: DashboardUserMenuList;
  /** Get a list of DeletedUser with order and filtering options */
  allDeletedUsers: DeletedUserList;
  /** Get a list of DeliveryItemHistory with order and filtering options */
  allDeliveryItemHistories: DeliveryItemHistoryList;
  /** Get a list of DeliveryItemParcel with order and filtering options */
  allDeliveryItemParcels: DeliveryItemParcelList;
  /** Get a list of DeliveryItem with order and filtering options */
  allDeliveryItems: DeliveryItemList;
  /** Get a list of DeliverySchedule with order and filtering options */
  allDeliverySchedules: DeliveryScheduleList;
  /** Get a list of Device with order and filtering options */
  allDevices: DeviceList;
  /** Get a list of DiscountCampaign with order and filtering options */
  allDiscountCampaigns: DiscountCampaignList;
  /** Get a list of DiscountCode with order and filtering options */
  allDiscountCodes: DiscountCodeList;
  /** Get a list of DiscountTag with order and filtering options */
  allDiscountTags: DiscountTagList;
  /** Get a list of Discounts with order and filtering options */
  allDiscounts: DiscountList;
  /** Get a list of DispatchDay with order and filtering options */
  allDispatchDays: DispatchDayList;
  /** Get a list of Excel with order and filtering options */
  allExcels: ExcelList;
  /** Get a list of FileCategory with order and filtering options */
  allFileCategories: FileCategoryList;
  /** Get a list of Files with order and filtering options */
  allFiles: FileList;
  /** Get a list of Forecast with order and filtering options */
  allForecasts: ForecastList;
  /** Get a list of FriendReference with order and filtering options */
  allFriendReferences: FriendReferenceList;
  /** Get a list of FrozenCategory with order and filtering options */
  allFrozenCategories: FrozenCategoryList;
  /** Get a list of FrozenInstruction with order and filtering options */
  allFrozenInstructions: FrozenInstructionList;
  /** Get a list of FrozenMealDish with order and filtering options */
  allFrozenMealDishes: FrozenMealDishList;
  /** Get a list of Frozen with order and filtering options */
  allFrozenMeals: FrozenMealList;
  /** Get a list of FrozenTag with order and filtering options */
  allFrozenTags: FrozenTagList;
  /** Get a list of Gift with order and filtering options */
  allGifts: GiftList;
  /** Get a list of Image with order and filtering options */
  allImages: ImageList;
  /** Get a list of Ingredient with order and filtering options */
  allIngredients: IngredientList;
  /** Get a list of LeadUpload with order and filtering options */
  allLeadUploads: LeadUploadList;
  /** Get a list of Lead with order and filtering options */
  allLeads: LeadList;
  /** Get a list of Location with order and filtering options */
  allLocations: LocationList;
  /** Get a list of LoyaltyTier with order and filtering options */
  allLoyaltyTiers: LoyaltyTierList;
  /** Get a list of ManualEmail with order and filtering options */
  allManualEmails: ManualEmailList;
  /** Get a list of MarketProductBrand with order and filtering options */
  allMarketProductBrands: MarketProductBrandList;
  /** Get a list of MarketProductCategory with order and filtering options */
  allMarketProductCategories: MarketProductCategoryList;
  /** Get a list of MarketProductSubCategory with order and filtering options */
  allMarketProductSubCategories: MarketProductSubCategoryList;
  /** Get a list of MarketProductTag with order and filtering options */
  allMarketProductTags: MarketProductTagList;
  /** Get a list of MarketProduct with order and filtering options */
  allMarketProducts: MarketProductList;
  /** Get a list of MealKitCategory with order and filtering options */
  allMealKitCategories: MealKitCategoryList;
  /** Get a list of MealKitDefaultPricing with order and filtering options */
  allMealKitDefaultPricing: MealKitDefaultPricingList;
  /** Get a list of MealKitDish with order and filtering options */
  allMealKitDishes: MealKitDishList;
  /** Get a list of MealKitInstruction with order and filtering options */
  allMealKitInstructions: MealKitInstructionList;
  /** Get a list of MealKitPlan with order and filtering options */
  allMealKitPlans: MealKitPlanList;
  /** Get a list of MealKitSubscriptionFee with order and filtering options */
  allMealKitSubscriptionFees: MealKitSubscriptionFeeList;
  /** Get a list of MealKitTag with order and filtering options */
  allMealKitTags: MealKitTagList;
  /** Get a list of MealKit with order and filtering options */
  allMealKits: MealKitList;
  /** Get a list of MenuCategory with order and filtering options */
  allMenuCategories: MenuCategoryList;
  /** Get a list of MenuDishWine with order and filtering options */
  allMenuDishWines: MenuDishWineList;
  /** Get a list of MenuProductCart with order and filtering options */
  allMenuProductCarts: MenuProductCartList;
  /** Get a list of MenuProductOrder with order and filtering options */
  allMenuProductOrders: MenuProductOrderList;
  /** Get a list of MenuWarehouse with order and filtering options */
  allMenuWarehouses: MenuWarehouseList;
  /** Get a list of Menu with order and filtering options */
  allMenus: MenuList;
  /** Get a list of MinimumOrderValue with order and filtering options */
  allMinimumOrderValues: MinimumOrderValueList;
  /** Get a list of OrderAddress with order and filtering options */
  allOrderAddresses: OrderAddressList;
  /** Get a list of OrderBoxItem with order and filtering options */
  allOrderBoxItems: OrderBoxItemList;
  /** Get a list of OrderBox with order and filtering options */
  allOrderBoxes: OrderBoxList;
  /** Get a list of OrderHistory with order and filtering options */
  allOrderHistory: OrderHistoryList;
  /** Get a list of OrderItemRating with order and filtering options */
  allOrderItemRatings: OrderItemRatingList;
  /** Get a list of OrderItem with order and filtering options */
  allOrderItems: OrderItemList;
  /** Get a list of Rating with order and filtering options */
  allOrderRatings: OrderRatingList;
  /** Get a list of OrderSubContainer with order and filtering options */
  allOrderSubContainers: OrderSubContainerList;
  /** Get a list of OrderTaxAddition with order and filtering options */
  allOrderTaxAdditions: OrderTaxAdditionList;
  /** Get a list of OrderTaxDiscount with order and filtering options */
  allOrderTaxDiscounts: OrderTaxDiscountList;
  /** Get a list of OrderTaxProductDiscount with order and filtering options */
  allOrderTaxProductDiscounts: OrderTaxProductDiscountList;
  /** Get a list of OrderTaxProductTypeDiscount with order and filtering options */
  allOrderTaxProductTypeDiscounts: OrderTaxProductTypeDiscountList;
  /** Get a list of OrderTaxProductType with order and filtering options */
  allOrderTaxProductTypes: OrderTaxProductTypeList;
  /** Get a list of OrderTaxProduct with order and filtering options */
  allOrderTaxProducts: OrderTaxProductList;
  /** Get a list of OrderTaxReceipt with order and filtering options */
  allOrderTaxReceipts: OrderTaxReceiptList;
  /** Get a list of Order with order and filtering options */
  allOrders: OrderList;
  /** Get a list of PackingVolumeDefault with order and filtering options */
  allPackingVolumeDefaults: PackingVolumeDefaultList;
  /** Get a list of PageBannerSection with order and filtering options */
  allPageBannerSections: PageBannerSectionList;
  /** Get a list of PageBanner with order and filtering options */
  allPageBanners: PageBannerList;
  /** Get a list of PageCardSection with order and filtering options */
  allPageCardSections: PageCardSectionList;
  /** Get a list of PageCard with order and filtering options */
  allPageCards: PageCardList;
  /** Get a list of PageDataSection with order and filtering options */
  allPageDataSections: PageDataSection;
  /** Get a list of PageDetailSection with order and filtering options */
  allPageDetailSections: PageDetailSectionList;
  /** Get a list of PageDetail with order and filtering options */
  allPageDetails: PageDetailList;
  /** Get a list of PageGridItem with order and filtering options */
  allPageGridItems: PageGridItemList;
  /** Get a list of PageGridSection with order and filtering options */
  allPageGridSections: PageGridSectionList;
  /** Get a list of PageMediaSection with order and filtering options */
  allPageMediaSections: PageMediaSection;
  /** Get a list of PageModal with order and filtering options */
  allPageModals: PageModalList;
  /** Get a list of PageNotificationGroup with order and filtering options */
  allPageNotificationGroups: PageNotificationGroupList;
  /** Get a list of PageNotification with order and filtering options */
  allPageNotifications: PageNotificationList;
  /** Get a list of PageSectionGroup with order and filtering options */
  allPageSectionGroups: PageSectionGroupList;
  /** Get a list of PageSections with order and filtering options */
  allPageSections: PageSectionList;
  /** Get a list of PageTabItem with order and filtering options */
  allPageTabItems: PageTabItemList;
  /** Get a list of PageTabSection with order and filtering options */
  allPageTabSections: PageTabSectionList;
  /** Get a list of PageTab with order and filtering options */
  allPageTabs: PageTabList;
  /** Get a list of PageTextCardSection with order and filtering options */
  allPageTextCardSections: PageTextCardSectionList;
  /** Get a list of PageTextCard with order and filtering options */
  allPageTextCards: PageTextCardList;
  /** Get a list of PageTextSection with order and filtering options */
  allPageTextSections: PageTextSectionList;
  /** Get a list of PageTileSection with order and filtering options */
  allPageTileSections: PageTileSectionList;
  /** Get a list of Payment with order and filtering options */
  allPayments: PaymentList;
  /** Get a list of Pdf with order and filtering options */
  allPdFs: PdfList;
  /** Get a list of Permissions with order and filtering options */
  allPermissions: PermissionList;
  /** Get a list of PreferenceCategory with order and filtering options */
  allPreferenceCategories: PreferenceCategoryList;
  /** Get a list of Preference with order and filtering options */
  allPreferences: PreferenceList;
  /** Get a list of ProcessGroupCourier with order and filtering options */
  allProcessGroupCouriers: ProcessGroupCourierList;
  /** Get a list of ProcessGroup with order and filtering options */
  allProcessGroups: ProcessGroupList;
  /** Get a list of ProductAvailabilityEmail with order and filtering options */
  allProductAvailabilityEmails: ProductAvailabilityEmailList;
  /** Get a list of ProductGroups with order and filtering options */
  allProductGroups: ProductGroupList;
  /** Get a list of ProductIngredient with order and filtering options */
  allProductIngredients: ProductIngredientList;
  /** Get a list of ProductStock with order and filtering options */
  allProductStocks: ProductStockList;
  /** Get a list of ProductWarehouse with order and filtering options */
  allProductWarehouses: ProductWarehouseList;
  /** Get a list of ProductWarhouseCostHistory with order and filtering options */
  allProductWarhouseCostHistories: ProductWarhouseCostHistoryList;
  /** Get a list of Products with order and filtering options */
  allProducts: ProductList;
  /** Get a list of PublicHoliday with order and filtering options */
  allPublicHolidays: PublicHolidayList;
  /** Get a list of RefundHistory with order and filtering options */
  allRefundHistories: RefundHistoryList;
  /** Get a list of RefundReason with order and filtering options */
  allRefundReasons: RefundReasonList;
  /** Get a list of RefundReportingReason with order and filtering options */
  allRefundReportingReasons: RefundReportingReasonList;
  /** Get a list of Refund with order and filtering options */
  allRefunds: RefundList;
  /** Get a list of Region with order and filtering options */
  allRegions: RegionList;
  /** Get a list of Report with order and filtering options */
  allReports: ReportList;
  /** Get a list of Role with order and filtering options */
  allRoles: RoleList;
  /** Get a list of ScheduleCategoryExclusion with order and filtering options */
  allScheduleCategoryExclusions: ScheduleCategoryExclusionList;
  /** Get a list of ScheduleCategoryInclusion with order and filtering options */
  allScheduleCategoryInclusions: ScheduleCategoryInclusionList;
  /** Get a list of ServingSuggestion with order and filtering options */
  allServingSuggestions: ServingSuggestionList;
  /** Get a list of ShipmentItem with order and filtering options */
  allShipmentItems: ShipmentItemList;
  /** Get a list of Shipment with order and filtering options */
  allShipments: ShipmentList;
  /** Get a list of ShipperBox with order and filtering options */
  allShipperBoxes: ShipperBoxList;
  /** Get a list of ShipperWarehouse with order and filtering options */
  allShipperWarehouses: ShipperWarehouseList;
  /** Get a list of Shipper with order and filtering options */
  allShippers: ShipperList;
  /** Get a list of ShippingConsignment with order and filtering options */
  allShippingConsignments: ShippingConsignmentList;
  /** Get a list of SmsTemplate with order and filtering options */
  allSmsTemplates: SmsTemplateList;
  /** Get a list of SubContainerOrderItem with order and filtering options */
  allSubContainerOrderItems: SubContainerOrderItemList;
  /** Get a list of SubContainerRestriction with order and filtering options */
  allSubContainerRestrictions: SubContainerRestrictionList;
  /** Get a list of SubContainer with order and filtering options */
  allSubContainers: SubContainerList;
  /** Get a list of SubscriptionPriceHistory with order and filtering options */
  allSubscriptionPriceHistories: SubscriptionPriceHistoryList;
  /** Get a list of Supplier with order and filtering options */
  allSuppliers: SupplierList;
  /** Get a list of TaxClass with order and filtering options */
  allTaxClasses: TaxClassList;
  /** Get a list of TaxReceiptEvent with order and filtering options */
  allTaxReceiptEvents: TaxReceiptEventList;
  /** Get a list of TransactionReport with order and filtering options */
  allTransactionReports: TransactionReportList;
  /** Get a list of Transactions with order and filtering options */
  allTransactions: TransactionList;
  /** Get a list of UserAddress with order and filtering options */
  allUserAddresses: UserAddressList;
  /** Get a list of UserCommunicationPlatformData with order and filtering options */
  allUserCommunicationPlatformData: UserCommunicationPlatformDataList;
  /** Get a list of UserDevice with order and filtering options */
  allUserDevices: UserDeviceList;
  /** Get a list of UserDiscount with order and filtering options */
  allUserDiscounts: UserDiscountList;
  /** Get a list of UserHistory with order and filtering options */
  allUserHistory: UserHistoryList;
  /** Get a list of UserMenu with order and filtering options */
  allUserMenus: UserMenuList;
  /** Get a list of UserPartnership with order and filtering options */
  allUserPartnerships: UserPartnershipList;
  /** Get a list of UserPreference with order and filtering options */
  allUserPreferences: UserPreferenceList;
  /** Get a list of UserRole with order and filtering options */
  allUserRoles: UserRoleList;
  /** Get a list of UserSubscription with order and filtering options */
  allUserSubscriptions: UserSubscriptionList;
  /** Get a list of User with order and filtering options */
  allUsers: UserList;
  /** Get User Preferences with defaults if not set */
  allUsersPreferencesOrDefaults: UsersPreferencesOrDefaultsList;
  /** Get a list of Vendor with order and filtering options */
  allVendors: VendorList;
  /** Get a list of Vineyard with order and filtering options */
  allVineyards: VineyardList;
  /** Get a list of WarehouseLocation with order and filtering options */
  allWarehouseLocations: WarehouseLocationList;
  /** Get a list of WarehouseProductRange with order and filtering options */
  allWarehouseProductRanges: WarehouseProductRangeList;
  /** Get a list of Warehouse with order and filtering options */
  allWarehouses: WarehouseList;
  /** Get a list of WineCampaign with order and filtering options */
  allWineCampaigns: WineCampaignList;
  /** Get a list of WineCategory with order and filtering options */
  allWineCategories: WineCategoryList;
  /** Get a list of WineMaker with order and filtering options */
  allWineMakers: WineMakerList;
  /** Get a list of WineTag with order and filtering options */
  allWineTags: WineTagList;
  /** Get a list of Wine with order and filtering options */
  allWines: WineList;
  app: App;
  /** Get a list of categorised User Preferences */
  categorisedUserPreferences: CategorisedUserPreferencesList;
  /** Get full category tree structure */
  categoryTree: Scalars['JSON']['output'];
  /** Check the status of a payment */
  checkPaymentStatus: PayOrderOutput;
  checkout: LocalCheckout;
  /** Get a count of AbTestBucketUser with filtering options */
  countAbTestBucketUsers: Scalars['Int']['output'];
  /** Get a count of AbTestBucket with filtering options */
  countAbTestBuckets: Scalars['Int']['output'];
  /** Get a count of AbTestFilter with filtering options */
  countAbTestFilters: Scalars['Int']['output'];
  /** Get a count of AbTest with filtering options */
  countAbTests: Scalars['Int']['output'];
  /** Get a count of AcumaticaDiscountNonStockSku with filtering options */
  countAcumaticaDiscountNonStockSkus: Scalars['Int']['output'];
  /** Get a count of AcumaticaProductTypeNonStockSku with filtering options */
  countAcumaticaProductTypeNonStockSkus: Scalars['Int']['output'];
  /** Get a count of AddressIssue with filtering options */
  countAddressIssues: Scalars['Int']['output'];
  /** Get a count of Allergen with filtering options */
  countAllergens: Scalars['Int']['output'];
  /** Get a count of Area with filtering options */
  countAreas: Scalars['Int']['output'];
  /** Get a count of BabyBoxCategory with filtering options */
  countBabyBoxCategories: Scalars['Int']['output'];
  /** Get a count of BabyBoxTag with filtering options */
  countBabyBoxTags: Scalars['Int']['output'];
  /** Get a count of BabyBoxTubDetail with filtering options */
  countBabyBoxTubDetails: Scalars['Int']['output'];
  /** Get a count of BabyBox with filtering options */
  countBabyBoxTubs: Scalars['Int']['output'];
  countBabyBoxes: Scalars['Int']['output'];
  /** Get a count of BankBinNumber with filtering options */
  countBankBinNumbers: Scalars['Int']['output'];
  /** Get a count of Barcode with filtering options */
  countBarcodes: Scalars['Int']['output'];
  /** Get a count of BoxRestriction with filtering options */
  countBoxRestrictions: Scalars['Int']['output'];
  /** Get a count of BoxSubContainer with filtering options */
  countBoxSubContainers: Scalars['Int']['output'];
  /** Get a count of Box with filtering options */
  countBoxes: Scalars['Int']['output'];
  /** Get a count of Branch with filtering options */
  countBranches: Scalars['Int']['output'];
  /** Get a count of Brand with filtering options */
  countBrands: Scalars['Int']['output'];
  /** Get a count of Campaign with filtering options */
  countCampaigns: Scalars['Int']['output'];
  /** Get a count of Cart with filtering options */
  countCarts: Scalars['Int']['output'];
  /** Get a count of Category with filtering options */
  countCategories: Scalars['Int']['output'];
  /** Get a count of CategoryGroup with filtering options */
  countCategoryGroups: Scalars['Int']['output'];
  /** Get a count of Chef with filtering options */
  countChefs: Scalars['Int']['output'];
  /** Get a count of City with filtering options */
  countCities: Scalars['Int']['output'];
  /** Get a count of CommunicationTemplate with filtering options */
  countCommunicationTemplates: Scalars['Int']['output'];
  /** Get a count of Country with filtering options */
  countCountries: Scalars['Int']['output'];
  /** Get a count of CourierBox with filtering options */
  countCourierBoxes: Scalars['Int']['output'];
  /** Get a count of CourierDelivery with filtering options */
  countCourierDeliveries: Scalars['Int']['output'];
  /** Get a count of CourierDeliveryDay with filtering options */
  countCourierDeliveryDays: Scalars['Int']['output'];
  /** Get a count of CourierDeliveryHistory with filtering options */
  countCourierDeliveryHistories: Scalars['Int']['output'];
  /** Get a count of CourierWarehouseCity with filtering options */
  countCourierWarehouseCities: Scalars['Int']['output'];
  /** Get a count of CourierWarehouseCityDeliveryDay with filtering options */
  countCourierWarehouseCityDeliveryDays: Scalars['Int']['output'];
  /** Get a count of CourierWarehouse with filtering options */
  countCourierWarehouses: Scalars['Int']['output'];
  /** Get a count of Courier with filtering options */
  countCouriers: Scalars['Int']['output'];
  /** Get a count of CreditCard with filtering options */
  countCreditCards: Scalars['Int']['output'];
  /** Get a count of DashboardUserMenu with filtering options */
  countDashboardUserMenus: Scalars['Int']['output'];
  /** Get a count of DeletedUser with filtering options */
  countDeletedUsers: Scalars['Int']['output'];
  /** Get a count of DeliveryItemHistory with filtering options */
  countDeliveryItemHistories: Scalars['Int']['output'];
  /** Get a count of DeliveryItemParcel with filtering options */
  countDeliveryItemParcels: Scalars['Int']['output'];
  /** Get a count of DeliveryItem with filtering options */
  countDeliveryItems: Scalars['Int']['output'];
  /** Get a count of DeliverySchedule with filtering options */
  countDeliverySchedules: Scalars['Int']['output'];
  /** Get a count of Device with filtering options */
  countDevices: Scalars['Int']['output'];
  /** Get a count of DiscountCampaign with filtering options */
  countDiscountCampaigns: Scalars['Int']['output'];
  /** Get a count of DiscountCode with filtering options */
  countDiscountCodes: Scalars['Int']['output'];
  /** Get a count of DiscountTag with filtering options */
  countDiscountTags: Scalars['Int']['output'];
  /** Get a count of Discount with filtering options */
  countDiscounts: Scalars['Int']['output'];
  /** Get a count of DispatchDay with filtering options */
  countDispatchDays: Scalars['Int']['output'];
  /** Get a count of Excel with filtering options */
  countExcels: Scalars['Int']['output'];
  /** Get a count of FileCategory with filtering options */
  countFileCategories: Scalars['Int']['output'];
  /** Get a count of File with filtering options */
  countFiles: Scalars['Int']['output'];
  /** Get a count of Forecast with filtering options */
  countForecasts: Scalars['Int']['output'];
  /** Get a count of FriendReference with filtering options */
  countFriendReferences: Scalars['Int']['output'];
  /** Get a count of FrozenCategory with filtering options */
  countFrozenCategories: Scalars['Int']['output'];
  /** Get a count of FrozenInstruction with filtering options */
  countFrozenInstructions: Scalars['Int']['output'];
  /** Get a count of FrozenMeal with filtering options */
  countFrozenMealDishes: Scalars['Int']['output'];
  /** Get a count of Frozen with filtering options */
  countFrozenMeals: Scalars['Int']['output'];
  /** Get a count of FrozenTag with filtering options */
  countFrozenTags: Scalars['Int']['output'];
  /** Get a count of Gift with filtering options */
  countGifts: Scalars['Int']['output'];
  /** Get a count of Image with filtering options */
  countImages: Scalars['Int']['output'];
  /** Get a count of Ingredient with filtering options */
  countIngredients: Scalars['Int']['output'];
  /** Get a count of LeadUpload with filtering options */
  countLeadUploads: Scalars['Int']['output'];
  /** Get a count of Lead with filtering options */
  countLeads: Scalars['Int']['output'];
  /** Get a count of Location with filtering options */
  countLocations: Scalars['Int']['output'];
  /** Get a count of LoyaltyTier with filtering options */
  countLoyaltyTiers: Scalars['Int']['output'];
  /** Get a count of ManualEmail with filtering options */
  countManualEmails: Scalars['Int']['output'];
  /** Get a count of MarketProductBrand with filtering options */
  countMarketProductBrands: Scalars['Int']['output'];
  /** Get a count of MarketProductCategory with filtering options */
  countMarketProductCategories: Scalars['Int']['output'];
  /** Get a count of MarketProductSubCategory with filtering options */
  countMarketProductSubCategories: Scalars['Int']['output'];
  /** Get a count of MarketProductTag with filtering options */
  countMarketProductTags: Scalars['Int']['output'];
  /** Get a count of MarketProduct with filtering options */
  countMarketProducts: Scalars['Int']['output'];
  /** Get a count of MealKitCategory with filtering options */
  countMealKitCategories: Scalars['Int']['output'];
  /** Get a count of MealKitDefaultPricing with filtering options */
  countMealKitDefaultPricing: Scalars['Int']['output'];
  /** Get a count of MealKitDish with filtering options */
  countMealKitDishes: Scalars['Int']['output'];
  /** Get a count of MealKitInstruction with filtering options */
  countMealKitInstructions: Scalars['Int']['output'];
  /** Get a count of MealKitPlan with filtering options */
  countMealKitPlans: Scalars['Int']['output'];
  /** Get a count of MealKitSubscriptionFee with filtering options */
  countMealKitSubscriptionFees: Scalars['Int']['output'];
  /** Get a count of MealKitTag with filtering options */
  countMealKitTags: Scalars['Int']['output'];
  /** Get a count of MealKit with filtering options */
  countMealKits: Scalars['Int']['output'];
  /** Get a count of MenuCategory with filtering options */
  countMenuCategories: Scalars['Int']['output'];
  /** Get a count of MenuDishWine with filtering options */
  countMenuDishWines: Scalars['Int']['output'];
  /** Get a count of MenuProductCart with filtering options */
  countMenuProductCarts: Scalars['Int']['output'];
  /** Get a count of MenuProductOrder with filtering options */
  countMenuProductOrders: Scalars['Int']['output'];
  /** Get a count of MenuWarehouse with filtering options */
  countMenuWarehouses: Scalars['Int']['output'];
  /** Get a count of Menu with filtering options */
  countMenus: Scalars['Int']['output'];
  /** Get a count of MinimumOrderValue with filtering options */
  countMinimumOrderValues: Scalars['Int']['output'];
  /** Get a count of OrderAddress with filtering options */
  countOrderAddresses: Scalars['Int']['output'];
  /** Get a count of OrderBoxItem with filtering options */
  countOrderBoxItems: Scalars['Int']['output'];
  /** Get a count of OrderBox with filtering options */
  countOrderBoxes: Scalars['Int']['output'];
  /** Get a count of OrderHistory with filtering options */
  countOrderHistory: Scalars['Int']['output'];
  /** Get a count of OrderItemRating with filtering options */
  countOrderItemRatings: Scalars['Int']['output'];
  /** Get a count of OrderItem with filtering options */
  countOrderItems: Scalars['Int']['output'];
  /** Get a count of Rating with filtering options */
  countOrderRatings: Scalars['Int']['output'];
  /** Get a count of OrderSubContainer with filtering options */
  countOrderSubContainers: Scalars['Int']['output'];
  /** Get a count of OrderTaxAddition with filtering options */
  countOrderTaxAdditions: Scalars['Int']['output'];
  /** Get a count of OrderTaxDiscount with filtering options */
  countOrderTaxDiscounts: Scalars['Int']['output'];
  /** Get a count of OrderTaxProductDiscount with filtering options */
  countOrderTaxProductDiscounts: Scalars['Int']['output'];
  /** Get a count of OrderTaxProductTypeDiscount with filtering options */
  countOrderTaxProductTypeDiscounts: Scalars['Int']['output'];
  /** Get a count of OrderTaxProductType with filtering options */
  countOrderTaxProductTypes: Scalars['Int']['output'];
  /** Get a count of OrderTaxProduct with filtering options */
  countOrderTaxProducts: Scalars['Int']['output'];
  /** Get a count of OrderTaxReceipt with filtering options */
  countOrderTaxReceipts: Scalars['Int']['output'];
  /** Get a count of Order with filtering options */
  countOrders: Scalars['Int']['output'];
  /** Get a count of PackingVolumeDefault with filtering options */
  countPackingVolumeDefaults: Scalars['Int']['output'];
  /** Get a count of PageBannerSection with filtering options */
  countPageBannerSections: Scalars['Int']['output'];
  /** Get a count of PageBanner with filtering options */
  countPageBanners: Scalars['Int']['output'];
  /** Get a count of PageCardSection with filtering options */
  countPageCardSections: Scalars['Int']['output'];
  /** Get a count of PageCard with filtering options */
  countPageCards: Scalars['Int']['output'];
  /** Get a count of PageDataSection with filtering options */
  countPageDataSections: Scalars['Int']['output'];
  /** Get a count of PageDetailSection with filtering options */
  countPageDetailSections: Scalars['Int']['output'];
  /** Get a count of PageDetail with filtering options */
  countPageDetails: Scalars['Int']['output'];
  /** Get a count of PageGridItem with filtering options */
  countPageGridItems: Scalars['Int']['output'];
  /** Get a count of PageGridSection with filtering options */
  countPageGridSections: Scalars['Int']['output'];
  /** Get a count of PageMediaSection with filtering options */
  countPageMediaSections: Scalars['Int']['output'];
  /** Get a count of PageModal with filtering options */
  countPageModals: Scalars['Int']['output'];
  /** Get a count of PageNotificationGroup with filtering options */
  countPageNotificationGroups: Scalars['Int']['output'];
  /** Get a count of PageNotification with filtering options */
  countPageNotifications: Scalars['Int']['output'];
  /** Get a count of PageSectionGroup with filtering options */
  countPageSectionGroups: Scalars['Int']['output'];
  /** Get a count of PageSection with filtering options */
  countPageSections: Scalars['Int']['output'];
  /** Get a count of PageTabItem with filtering options */
  countPageTabItems: Scalars['Int']['output'];
  /** Get a count of PageTabSection with filtering options */
  countPageTabSections: Scalars['Int']['output'];
  /** Get a count of PageTab with filtering options */
  countPageTabs: Scalars['Int']['output'];
  /** Get a count of PageTextCardSection with filtering options */
  countPageTextCardSections: Scalars['Int']['output'];
  /** Get a count of PageTextCard with filtering options */
  countPageTextCards: Scalars['Int']['output'];
  /** Get a count of PageTextSection with filtering options */
  countPageTextSections: Scalars['Int']['output'];
  /** Get a count of PageTileSection with filtering options */
  countPageTileSections: Scalars['Int']['output'];
  /** Get a count of Payment with filtering options */
  countPayments: Scalars['Int']['output'];
  /** Get a count of Pdf with filtering options */
  countPdFs: Scalars['Int']['output'];
  /** Get a count of Permission with filtering options */
  countPermissions: Scalars['Int']['output'];
  /** Get a count of PreferenceCategory with filtering options */
  countPreferenceCategories: Scalars['Int']['output'];
  /** Get a count of Preference with filtering options */
  countPreferences: Scalars['Int']['output'];
  /** Get a count of ProcessGroupCourier with filtering options */
  countProcessGroupCouriers: Scalars['Int']['output'];
  /** Get a count of ProcessGroup with filtering options */
  countProcessGroups: Scalars['Int']['output'];
  /** Get a count of ProductAvailabilityEmail with filtering options */
  countProductAvailabilityEmails: Scalars['Int']['output'];
  /** Get a count of ProductGroup with filtering options */
  countProductGroups: Scalars['Int']['output'];
  /** Get a count of ProductIngredient with filtering options */
  countProductIngredients: Scalars['Int']['output'];
  /** Get a count of ProductStock with filtering options */
  countProductStocks: Scalars['Int']['output'];
  /** Get a count of ProductWarehouse with filtering options */
  countProductWarehouses: Scalars['Int']['output'];
  /** Get a count of ProductWarhouseCostHistory with filtering options */
  countProductWarhouseCostHistories: Scalars['Int']['output'];
  /** Get a count of Product with filtering options */
  countProducts: Scalars['Int']['output'];
  /** Get a count of PublicHoliday with filtering options */
  countPublicHolidays: Scalars['Int']['output'];
  /** Get a count of RefundHistory with filtering options */
  countRefundHistories: Scalars['Int']['output'];
  /** Get a count of RefundReason with filtering options */
  countRefundReasons: Scalars['Int']['output'];
  /** Get a count of RefundReportingReason with filtering options */
  countRefundReportingReasons: Scalars['Int']['output'];
  /** Get a count of Refund with filtering options */
  countRefunds: Scalars['Int']['output'];
  /** Get a count of Region with filtering options */
  countRegions: Scalars['Int']['output'];
  /** Get a count of Report with filtering options */
  countReports: Scalars['Int']['output'];
  /** Get a count of Role with filtering options */
  countRoles: Scalars['Int']['output'];
  /** Get a count of ScheduleCategoryExclusion with filtering options */
  countScheduleCategoryExclusions: Scalars['Int']['output'];
  /** Get a count of ScheduleCategoryInclusion with filtering options */
  countScheduleCategoryInclusions: Scalars['Int']['output'];
  /** Get a count of ServingSuggestion with filtering options */
  countServingSuggestions: Scalars['Int']['output'];
  /** Get a count of ShipmentItem with filtering options */
  countShipmentItems: Scalars['Int']['output'];
  /** Get a count of Shipment with filtering options */
  countShipments: Scalars['Int']['output'];
  /** Get a count of ShipperBox with filtering options */
  countShipperBoxes: Scalars['Int']['output'];
  /** Get a count of ShipperWarehouse with filtering options */
  countShipperWarehouses: Scalars['Int']['output'];
  /** Get a count of Shipper with filtering options */
  countShippers: Scalars['Int']['output'];
  /** Get a count of ShippingConsignment with filtering options */
  countShippingConsignments: Scalars['Int']['output'];
  /** Get a count of SmsTemplate with filtering options */
  countSmsTemplates: Scalars['Int']['output'];
  /** Get a count of SubContainerOrderItem with filtering options */
  countSubContainerOrderItems: Scalars['Int']['output'];
  /** Get a count of SubContainerRestriction with filtering options */
  countSubContainerRestrictions: Scalars['Int']['output'];
  /** Get a count of SubContainer with filtering options */
  countSubContainers: Scalars['Int']['output'];
  /** Get a count of SubscriptionPriceHistory with filtering options */
  countSubscriptionPriceHistories: Scalars['Int']['output'];
  /** Get a count of Supplier with filtering options */
  countSuppliers: Scalars['Int']['output'];
  /** Get a count of TaxClass with filtering optns */
  countTaxClasses: Scalars['Int']['output'];
  /** Get a count of TaxReceiptEvent with filtering options */
  countTaxReceiptEvents: Scalars['Int']['output'];
  /** Get a count of TransactionReport with filtering options */
  countTransactionReports: Scalars['Int']['output'];
  /** Get a count of Transaction with filtering options */
  countTransactions: Scalars['Int']['output'];
  /** Unassigned City Count */
  countUnassignedCities: Scalars['Int']['output'];
  /** Get a count of UserAddress with filtering options */
  countUserAddresses: Scalars['Int']['output'];
  /** Get a count of UserCommunicationPlatformData with filtering options */
  countUserCommunicationPlatformData: Scalars['Int']['output'];
  /** Get a count of UserDevice with filtering options */
  countUserDevices: Scalars['Int']['output'];
  /** Get a count of UserDiscount with filtering options */
  countUserDiscounts: Scalars['Int']['output'];
  /** Get a count of UserHistory with filtering options */
  countUserHistory: Scalars['Int']['output'];
  /** Get a count of UserMenu with filtering options */
  countUserMenus: Scalars['Int']['output'];
  /** Get a count of UserPartnership with filtering options */
  countUserPartnerships: Scalars['Int']['output'];
  /** Get a count of UserPreference with filtering options */
  countUserPreferences: Scalars['Int']['output'];
  /** Get a count of UserRole with filtering options */
  countUserRoles: Scalars['Int']['output'];
  /** Get a count of UserSubscription with filtering options */
  countUserSubscriptions: Scalars['Int']['output'];
  /** Get a count of User with filtering options */
  countUsers: Scalars['Int']['output'];
  /** Get a count of Vendor with filtering options */
  countVendors: Scalars['Int']['output'];
  /** Get a count of Vineyard with filtering options */
  countVineyards: Scalars['Int']['output'];
  /** Get a count of WarehouseLocation with filtering options */
  countWarehouseLocations: Scalars['Int']['output'];
  /** Get a count of WarehouseProductRange with filtering options */
  countWarehouseProductRanges: Scalars['Int']['output'];
  /** Get a count of Warehouse with filtering options */
  countWarehouses: Scalars['Int']['output'];
  /** Get a count of WineCampaign with filtering options */
  countWineCampaigns: Scalars['Int']['output'];
  /** Get a count of WineCategory with filtering options */
  countWineCategories: Scalars['Int']['output'];
  /** Get a count of WineMaker with filtering options */
  countWineMakers: Scalars['Int']['output'];
  /** Get a count of WineTag with filtering options */
  countWineTags: Scalars['Int']['output'];
  /** Get a count of Wine with filtering options */
  countWines: Scalars['Int']['output'];
  courierDeliveryItemTotals: DeliveryItemsTotal;
  currentUser: Maybe<User>;
  fetchZeroCostItemsForMenu: Array<AcuInventoryItemDetail>;
  findOrderBoxByTrackingNumber: Array<OrderBox>;
  /** Gets an explict for an event */
  getAllEventCostHistoryItems: EventCostHistoryList;
  getAvailableDispatchDays: Maybe<AvailableDispatchDays>;
  /** Get Available weekdays for a subscription order */
  getAvailableSubscriptionDeliveryDays: Maybe<Array<CityDeliveryDaysEnum>>;
  getChefsTipsOptions: Array<Scalars['String']['output']>;
  /** Gets a list of all courier warehouses for city even if it isn't associated */
  getCityCourierWarehouses: Maybe<Array<CityCourierWarehouseResponse>>;
  getDeliveryDayOptionsForCourierWarehouseCity: Maybe<Array<CourierWarehouseCityDeliveryDayOption>>;
  getDetailedInputObjectConfig: Maybe<Scalars['JSON']['output']>;
  getEnum: Maybe<EnumDescription>;
  getEnums: Maybe<Array<Maybe<EnumDescription>>>;
  getFromYourKitchenOptions: Array<Scalars['String']['output']>;
  getInputObjectConfig: Maybe<Scalars['JSON']['output']>;
  getInterfaceTypes: Maybe<Array<Maybe<ClientInterface>>>;
  /** Master Order report */
  getMasterOrderReport: MasterOrderReport;
  getMenuRegionBreakdown: MenuOrderRegionBreakdown;
  getOrderSubscription: Maybe<OrderSubscription>;
  /** Get payment parameters for form */
  getPaymentParameters: PayParameters;
  getPrepStationOptions: Array<Scalars['String']['output']>;
  /** Get a ReportInfo by Type */
  getReportFile: ReportFileStatus;
  /** Get a specific ReportFileStatus by Type */
  getReportFileStatus: ReportFileStatusEnum;
  getTableData: Maybe<Scalars['String']['output']>;
  getTables: Maybe<Scalars['String']['output']>;
  /** check for unavailible Products  */
  getUnavailableProducts: Array<Maybe<Product>>;
  getUserIdByToken: Maybe<UserIdByToken>;
  /** Get the user's current points balance */
  getUserPointsBalance: Scalars['Float']['output'];
  getWineAisles: ProductIsles;
  lookupLocation: Array<Maybe<LocationLookup>>;
  modals: Modals;
  myShopAisle: MyShop;
  myShopItems: MyShop;
  navigation: Navigation;
  /** Get a list of all loyalty tiers above the supplied tier */
  nextLoyaltyTiers: Maybe<Array<LoyaltyTier>>;
  /** Get a specific AbTest by ID */
  oneAbTest: AbTest;
  /** Get a specific AbTestBucket by ID */
  oneAbTestBucket: AbTestBucket;
  /** Get a specific AbTestBucketUser by ID */
  oneAbTestBucketUser: AbTestBucketUser;
  /** Get a specific AbTestFilter by ID */
  oneAbTestFilter: AbTestFilter;
  /** Get a specific AcumaticaDiscountNonStockSku by ID */
  oneAcumaticaDiscountNonStockSku: AcumaticaDiscountNonStockSku;
  /** Get a specific AcumaticaProductTypeNonStockSku by ID */
  oneAcumaticaProductTypeNonStockSku: AcumaticaProductTypeNonStockSku;
  /** Get a specific AddressIssue by ID */
  oneAddressIssue: AddressIssue;
  /** Get a specific Allergen by ID */
  oneAllergen: Allergen;
  /** Get a specific Area by ID */
  oneArea: Area;
  oneBabyBox: BabyBox;
  /** Get a specific BabyBoxCategory by ID */
  oneBabyBoxCategory: BabyBoxCategory;
  /** Get a specific BabyBoxTag by ID */
  oneBabyBoxTag: BabyBoxTag;
  /** Get a specific BabyBox by ID */
  oneBabyBoxTub: BabyBoxTub;
  /** Get a specific BabyBoxTubDetail by ID */
  oneBabyBoxTubDetail: BabyBoxTubDetail;
  /** Get a specific BankBinNumber by ID */
  oneBankBinNumber: BankBinNumber;
  /** Get a specific Barcode by ID */
  oneBarcode: Barcode;
  /** Get a specific Box by ID */
  oneBox: Box;
  /** Get a specific BoxRestriction by ID */
  oneBoxRestriction: BoxRestriction;
  /** Get a specific BoxSubContainer by ID */
  oneBoxSubContainer: BoxSubContainer;
  /** Get a specific Branch by ID */
  oneBranch: Branch;
  /** Get a specific Brand by ID */
  oneBrand: Brand;
  /** Get a specific Campaign by ID */
  oneCampaign: Campaign;
  /** Get a specific Cart by ID */
  oneCart: Cart;
  /** Get a specific Category by ID */
  oneCategory: Category;
  /** Get a specific CategoryGroup by ID */
  oneCategoryGroup: CategoryGroup;
  /** Get a specific Chef by ID */
  oneChef: Chef;
  /** Get a specific City by ID */
  oneCity: City;
  /** Get a specific CommunicationTemplate by ID */
  oneCommunicationTemplate: CommunicationTemplate;
  /** Get a specific Country by ID */
  oneCountry: Country;
  /** Get a specific Courier by ID */
  oneCourier: Courier;
  /** Get a specific CourierBox by ID */
  oneCourierBox: CourierBox;
  /** Get a specific CourierDelivery by ID */
  oneCourierDelivery: CourierDelivery;
  /** Get a specific CourierDeliveryDay by ID */
  oneCourierDeliveryDay: CourierDeliveryDay;
  /** Get a specific CourierDeliveryHistory by ID */
  oneCourierDeliveryHistory: CourierDeliveryHistory;
  /** Get a specific CourierWarehouse by ID */
  oneCourierWarehouse: CourierWarehouse;
  /** Get a specific CourierWarehouseCity by ID */
  oneCourierWarehouseCity: CourierWarehouseCity;
  /** Get a specific CourierWarehouseCityDeliveryDay by ID */
  oneCourierWarehouseCityDeliveryDay: CourierWarehouseCityDeliveryDay;
  /** Get a specific CreditCard by ID */
  oneCreditCard: CreditCard;
  /** Get a specific DashboardUserMenu by ID */
  oneDashboardUserMenu: DashboardUserMenu;
  /** Get a specific DeletedUser by ID */
  oneDeletedUser: DeletedUser;
  /** Get a specific DeliveryItem by ID */
  oneDeliveryItem: DeliveryItem;
  /** Get a specific DeliveryItemHistory by ID */
  oneDeliveryItemHistory: DeliveryItemHistory;
  /** Get a specific DeliveryItemParcel by ID */
  oneDeliveryItemParcel: DeliveryItemParcel;
  /** Get a specific DeliverySchedule by ID */
  oneDeliverySchedule: DeliverySchedule;
  /** Get a specific Device by ID */
  oneDevice: Device;
  /** Get a specific Discount by ID */
  oneDiscount: Discount;
  /** Get a specific DiscountCampaign by ID */
  oneDiscountCampaign: DiscountCampaign;
  /** Get a specific DiscountCode by ID */
  oneDiscountCode: DiscountCode;
  /** Get a specific DiscountTag by ID */
  oneDiscountTag: DiscountTag;
  /** Get a specific DispatchDay by ID */
  oneDispatchDay: DispatchDay;
  /** Get a specific Excel by ID */
  oneExcel: Excel;
  /** Get a specific File by ID */
  oneFile: File;
  /** Get a specific FileCategory by ID */
  oneFileCategory: FileCategory;
  /** Get a specific Forecast by ID */
  oneForecast: Forecast;
  /** Get a specific FriendReference by ID */
  oneFriendReference: FriendReference;
  /** Get a specific FrozenCategory by ID */
  oneFrozenCategory: FrozenCategory;
  /** Get a specific FrozenInstruction by ID */
  oneFrozenInstruction: FrozenInstruction;
  /** Get a specific Frozen by ID */
  oneFrozenMeal: FrozenMeal;
  /** Get a specific FrozenMeal by ID */
  oneFrozenMealDish: FrozenMealDish;
  /** Get a specific FrozenTag by ID */
  oneFrozenTag: FrozenTag;
  /** Get a specific Gift by ID */
  oneGift: Gift;
  /** Get a specific Image by ID */
  oneImage: Image;
  /** Get a specific Ingredient by ID */
  oneIngredient: Ingredient;
  /** Get a specific Lead by ID */
  oneLead: Lead;
  /** Get a specific LeadUpload by ID */
  oneLeadUpload: LeadUpload;
  /** Get a specific Location by ID */
  oneLocation: Location;
  /** Get a specific LoyaltyTier by ID */
  oneLoyaltyTier: LoyaltyTier;
  /** Get a specific ManualEmail by ID */
  oneManualEmail: ManualEmail;
  /** Get a specific MarketProduct by ID */
  oneMarketProduct: MarketProduct;
  /** Get a specific MarketProductBrand by ID */
  oneMarketProductBrand: MarketProductBrand;
  /** Get a specific MarketProductCategory by ID */
  oneMarketProductCategory: MarketProductCategory;
  /** Get a specific MarketProductSubCategory by ID */
  oneMarketProductSubCategory: MarketProductSubCategory;
  /** Get a specific MarketProductTag by ID */
  oneMarketProductTag: MarketProductTag;
  /** Get a specific MealKit by ID */
  oneMealKit: MealKit;
  /** Get a specific MealKitCategory by ID */
  oneMealKitCategory: MealKitCategory;
  /** Get a specific MealKitDefaultPricing by ID */
  oneMealKitDefaultPricing: MealKitDefaultPricing;
  /** Get a specific MealKitDish by ID */
  oneMealKitDish: MealKitDish;
  /** Get a specific MealKitInstruction by ID */
  oneMealKitInstruction: MealKitInstruction;
  /** Get a specific MealKitPlan by ID */
  oneMealKitPlan: MealKitPlan;
  /** Get a specific MealKitSubscriptionFee by ID */
  oneMealKitSubscriptionFee: MealKitSubscriptionFee;
  /** Get a specific MealKitTag by ID */
  oneMealKitTag: MealKitTag;
  /** Get a specific Menu by ID */
  oneMenu: Menu;
  /** Get a specific MenuCategory by ID */
  oneMenuCategory: MenuCategory;
  /** Get a specific MenuDishWine by ID */
  oneMenuDishWine: MenuDishWine;
  /** Get a specific MenuProductCart by ID */
  oneMenuProductCart: MenuProductCart;
  /** Get a specific MenuProductOrder by ID */
  oneMenuProductOrder: MenuProductOrder;
  /** Get a specific MenuWarehouse by ID */
  oneMenuWarehouse: MenuWarehouse;
  /** Get a specific MinimumOrderValue by ID */
  oneMinimumOrderValue: MinimumOrderValue;
  /** Get a specific Order by ID */
  oneOrder: Order;
  /** Get a specific OrderAddress by ID */
  oneOrderAddress: OrderAddress;
  /** Get a specific OrderBox by ID */
  oneOrderBox: OrderBox;
  /** Get a specific OrderBoxItem by ID */
  oneOrderBoxItem: OrderBoxItem;
  /** Get a specific OrderHistory by ID */
  oneOrderHistory: OrderHistory;
  /** Get a specific OrderItem by ID */
  oneOrderItem: OrderItem;
  /** Get a specific OrderItemRating by ID */
  oneOrderItemRating: OrderItemRating;
  /** Get a specific Rating by ID */
  oneOrderRating: OrderRating;
  /** Get a specific OrderSubContainer by ID */
  oneOrderSubContainer: OrderSubContainer;
  /** Get a specific OrderTaxAddition by ID */
  oneOrderTaxAddition: OrderTaxAddition;
  /** Get a specific OrderTaxDiscount by ID */
  oneOrderTaxDiscount: OrderTaxDiscount;
  /** Get a specific OrderTaxProduct by ID */
  oneOrderTaxProduct: OrderTaxProduct;
  /** Get a specific OrderTaxProductDiscount by ID */
  oneOrderTaxProductDiscount: OrderTaxProductDiscount;
  /** Get a specific OrderTaxProductType by ID */
  oneOrderTaxProductType: OrderTaxProductType;
  /** Get a specific OrderTaxProductTypeDiscount by ID */
  oneOrderTaxProductTypeDiscount: OrderTaxProductTypeDiscount;
  /** Get a specific OrderTaxReceipt by ID */
  oneOrderTaxReceipt: OrderTaxReceipt;
  /** Get an order using a payment token */
  oneOrderWithPaymentToken: Maybe<Order>;
  /** Get a specific PackingVolumeDefault by ID */
  onePackingVolumeDefault: PackingVolumeDefault;
  /** Get a specific PageBanner by ID */
  onePageBanner: PageBanner;
  /** Get a specific PageBannerSection by ID */
  onePageBannerSection: Maybe<PageBannerSection>;
  /** Get a specific PageCard by ID */
  onePageCard: PageCard;
  /** Get a specific PageCardSection by ID */
  onePageCardSection: Maybe<PageCardSection>;
  /** Get a specific PageDataSection by ID */
  onePageDataSection: PageDataSection;
  /** Get a specific PageDetail by ID */
  onePageDetail: PageDetail;
  /** Get a specific PageDetailSection by ID */
  onePageDetailSection: PageDetailSection;
  /** Get a specific PageGridItem by ID */
  onePageGridItem: PageGridItem;
  /** Get a specific PageGridSection by ID */
  onePageGridSection: PageGridSection;
  /** Get a specific PageMediaSection by ID */
  onePageMediaSection: PageMediaSection;
  /** Get a specific PageModal by ID */
  onePageModal: Maybe<PageModal>;
  /** Get a specific PageNotification by ID */
  onePageNotification: PageNotification;
  /** Get a specific PageNotificationGroup by ID */
  onePageNotificationGroup: Maybe<PageNotificationGroup>;
  /** Get a specific PageSection by ID */
  onePageSection: PageSection;
  /** Get a specific PageSectionGroup by ID */
  onePageSectionGroup: PageSectionGroup;
  /** Get a specific PageTab by ID */
  onePageTab: PageTab;
  /** Get a specific PageTabItem by ID */
  onePageTabItem: PageTabItem;
  /** Get a specific PageTabSection by ID */
  onePageTabSection: PageTabSection;
  /** Get a specific PageTextCard by ID */
  onePageTextCard: PageTextCard;
  /** Get a specific PageTextCardSection by ID */
  onePageTextCardSection: PageTextCardSection;
  /** Get a specific PageTextSection by ID */
  onePageTextSection: PageTextSection;
  /** Get a specific PageTileSection by ID */
  onePageTileSection: Maybe<PageTileSection>;
  /** Get a specific Payment by ID */
  onePayment: Payment;
  /** Get a specific Payment by Token */
  onePaymentByToken: Payment;
  /** Get a specific Payment by Transaction Id */
  onePaymentByTransaction: Payment;
  /** Get a specific Pdf by ID */
  onePdf: Pdf;
  /** Get a specific Permission by ID */
  onePermission: Permission;
  /** Get a specific Preference by ID */
  onePreference: Preference;
  /** Get a specific PreferenceCategory by ID */
  onePreferenceCategory: PreferenceCategory;
  /** Get a specific ProcessGroup by ID */
  oneProcessGroup: ProcessGroup;
  /** Get a specific ProcessGroupCourier by ID */
  oneProcessGroupCourier: ProcessGroupCourier;
  /** Get a specific Product by ID */
  oneProduct: Product;
  /** Get a specific ProductAvailabilityEmail by ID */
  oneProductAvailabilityEmail: ProductAvailabilityEmail;
  /** Get a specific ProductGroup by ID */
  oneProductGroup: ProductGroup;
  /** Get a specific ProductIngredient by ID */
  oneProductIngredient: ProductIngredient;
  /** Get a specific ProductStock by ID */
  oneProductStock: ProductStock;
  /** Get a specific ProductWarehouse by ID */
  oneProductWarehouse: ProductWarehouse;
  /** Get a specific ProductWarhouseCostHistory by ID */
  oneProductWarhouseCostHistory: ProductWarhouseCostHistory;
  /** Get a specific PublicHoliday by ID */
  onePublicHoliday: PublicHoliday;
  /** Get a specific Refund by ID */
  oneRefund: Refund;
  /** Get a specific RefundHistory by ID */
  oneRefundHistory: RefundHistory;
  /** Get a specific RefundReason by ID */
  oneRefundReason: RefundReason;
  /** Get a specific RefundReportingReason by ID */
  oneRefundReportingReason: RefundReportingReason;
  /** Get a specific Region by ID */
  oneRegion: Region;
  /** Get a specific Report by ID */
  oneReport: Report;
  /** Get a specific Report by Identifier */
  oneReportByIdentifier: Report;
  /** Get a specific Role by ID */
  oneRole: Role;
  /** Get a specific ScheduleCategoryExclusion by ID */
  oneScheduleCategoryExclusion: ScheduleCategoryExclusion;
  /** Get a specific ScheduleCategoryInclusion by ID */
  oneScheduleCategoryInclusion: ScheduleCategoryInclusion;
  /** Get a specific ServingSuggestion by ID */
  oneServingSuggestion: ServingSuggestion;
  /** Get a specific Shipment by ID */
  oneShipment: Shipment;
  /** Get a specific ShipmentItem by ID */
  oneShipmentItem: ShipmentItem;
  /** Get a specific Shipper by ID */
  oneShipper: Shipper;
  /** Get a specific ShipperBox by ID */
  oneShipperBox: ShipperBox;
  /** Get a specific ShipperWarehouse by ID */
  oneShipperWarehouse: ShipperWarehouse;
  /** Get a specific ShippingConsignment by ID */
  oneShippingConsignment: ShippingConsignment;
  /** Get a specific SmsTemplate by ID */
  oneSmsTemplate: SmsTemplate;
  /** Get a specific SubContainer by ID */
  oneSubContainer: SubContainer;
  /** Get a specific SubContainerOrderItem by ID */
  oneSubContainerOrderItem: SubContainerOrderItem;
  /** Get a specific SubContainerRestriction by ID */
  oneSubContainerRestriction: SubContainerRestriction;
  /** Get a specific SubscriptionPriceHistory by ID */
  oneSubscriptionPriceHistory: SubscriptionPriceHistory;
  /** Get a specific Supplier by ID */
  oneSupplier: Supplier;
  /** Get a specific TaxClass by ID */
  oneTaxClass: TaxClass;
  /** Get a specific TaxReceiptEvent by ID */
  oneTaxReceiptEvent: TaxReceiptEvent;
  /** Get a specific Transaction by ID */
  oneTransaction: Transaction;
  /** Get a specific TransactionReport by ID */
  oneTransactionReport: TransactionReport;
  /** Get a specific User by ID */
  oneUser: User;
  /** Get a specific UserAddress by ID */
  oneUserAddress: UserAddress;
  /** Get a specific UserCommunicationPlatformData by ID */
  oneUserCommunicationPlatformData: UserCommunicationPlatformData;
  /** Get a specific UserDevice by ID */
  oneUserDevice: UserDevice;
  /** Get a specific UserDiscount by ID */
  oneUserDiscount: UserDiscount;
  /** Get a specific UserHistory by ID */
  oneUserHistory: UserHistory;
  /** Get a specific UserMenu by ID */
  oneUserMenu: UserMenu;
  /** Get a specific UserPartnership by ID */
  oneUserPartnership: UserPartnership;
  /** Get a specific UserPreference by ID */
  oneUserPreference: UserPreference;
  /** Get One User Preferences with defaults if not set */
  oneUserPreferenceOrDefault: UsersPreferencesOrDefaultsObject;
  /** Get a specific UserRole by ID */
  oneUserRole: UserRole;
  /** Get a specific UserSubscription by ID */
  oneUserSubscription: UserSubscription;
  /** Get a specific Vendor by ID */
  oneVendor: Vendor;
  /** Get a specific Vineyard by ID */
  oneVineyard: Vineyard;
  /** Get a specific Warehouse by ID */
  oneWarehouse: Warehouse;
  /** Get a specific WarehouseLocation by ID */
  oneWarehouseLocation: WarehouseLocation;
  /** Get a specific WarehouseProductRange by ID */
  oneWarehouseProductRange: WarehouseProductRange;
  /** Get a specific Wine by ID */
  oneWine: Wine;
  /** Get a specific WineCampaign by ID */
  oneWineCampaign: WineCampaign;
  /** Get a specific WineCategory by ID */
  oneWineCategory: WineCategory;
  /** Get a specific WineMaker by ID */
  oneWineMaker: WineMaker;
  /** Get a specific WineTag by ID */
  oneWineTag: WineTag;
  placeDetails: PlaceDetails;
  productIsles: ProductIsles;
  /** get products by city name */
  productsByCity: ProductList;
  /** get products for that region */
  productsByDefaultAddress: ProductList;
  /** Get a count of Order for display on Refer a Friend Dashboard */
  rafDashboardCount: Scalars['Int']['output'];
  /** Run simulation of boxing event */
  simulateBoxing: SimulationResponse;
  staticImage: Image;
  testAb: Maybe<AbTestResult>;
  /** Get a count of flags on user menu */
  userMenuFlagCount: UserMenuFlagCountList;
  /** Validate User Invite */
  validateInvite: Maybe<User>;
};


export type QueryactiveCartArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryactiveMenuArgs = {
  test?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryactiveMenuCategoriesArgs = {
  filters?: InputMaybe<MenuMealKitDishFilters>;
  test?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryactiveMenuTagsArgs = {
  filters?: InputMaybe<MenuMealKitDishFilters>;
  test?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryallAbTestActiveBucketsArgs = {
  filters?: InputMaybe<AbTestActiveBucketFilters>;
};


export type QueryallAbTestActiveUserBucketsArgs = {
  filters?: InputMaybe<AbTestActiveUserBucketFilters>;
};


export type QueryallAbTestBucketUsersArgs = {
  filters?: InputMaybe<AbTestBucketUserFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AbTestBucketUserOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAbTestBucketsArgs = {
  filters?: InputMaybe<AbTestBucketFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AbTestBucketOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAbTestFiltersArgs = {
  filters?: InputMaybe<AbTestFilterFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AbTestFilterOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAbTestsArgs = {
  filters?: InputMaybe<AbTestFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AbTestOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAcumaticaDiscountNonStockSkusArgs = {
  filters?: InputMaybe<AcumaticaDiscountNonStockSkuFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AcumaticaDiscountNonStockSkuOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAcumaticaProductTypeNonStockSkusArgs = {
  filters?: InputMaybe<AcumaticaProductTypeNonStockSkuFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AcumaticaProductTypeNonStockSkuOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAddressIssuesArgs = {
  filters?: InputMaybe<AddressIssueFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AddressIssueOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAllergensArgs = {
  filters?: InputMaybe<AllergenFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AllergenOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallAreasArgs = {
  filters?: InputMaybe<AreaFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AreaOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBabyBoxCategoriesArgs = {
  filters?: InputMaybe<BabyBoxCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BabyBoxCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBabyBoxTagsArgs = {
  filters?: InputMaybe<BabyBoxTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BabyBoxTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBabyBoxTubDetailsArgs = {
  filters?: InputMaybe<BabyBoxTubDetailFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BabyBoxTubDetailOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBabyBoxTubsArgs = {
  filters?: InputMaybe<BabyBoxTubFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BabyBoxTubOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBabyBoxesArgs = {
  filters?: InputMaybe<BabyBoxFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BabyBoxOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBankBinNumbersArgs = {
  filters?: InputMaybe<BankBinNumberFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BankBinNumberOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBarcodesArgs = {
  filters?: InputMaybe<BarcodeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BarcodeOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBoxRestrictionsArgs = {
  filters?: InputMaybe<BoxRestrictionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BoxRestrictionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBoxSubContainersArgs = {
  filters?: InputMaybe<BoxSubContainerFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BoxSubContainerOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBoxesArgs = {
  filters?: InputMaybe<BoxFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BoxOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBranchesArgs = {
  filters?: InputMaybe<BranchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BranchOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallBrandsArgs = {
  filters?: InputMaybe<BrandFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<BrandOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCampaignsArgs = {
  filters?: InputMaybe<CampaignFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CampaignOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCartsArgs = {
  filters?: InputMaybe<CartFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CartOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCategoriesArgs = {
  filters?: InputMaybe<CategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCategoryGroupsArgs = {
  filters?: InputMaybe<CategoryGroupFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CategoryGroupOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallChefsArgs = {
  filters?: InputMaybe<ChefFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ChefOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCitiesArgs = {
  filters?: InputMaybe<CityFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CityOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCommunicationTemplatesArgs = {
  filters?: InputMaybe<CommunicationTemplateFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CommunicationTemplateOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCountriesArgs = {
  filters?: InputMaybe<CountryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CountryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierBoxesArgs = {
  filters?: InputMaybe<CourierBoxFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierBoxOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierDeliveriesArgs = {
  filters?: InputMaybe<CourierDeliveryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierDeliveryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierDeliveryDaysArgs = {
  filters?: InputMaybe<CourierDeliveryDayFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierDeliveryDayOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierDeliveryHistoriesArgs = {
  filters?: InputMaybe<CourierDeliveryHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierDeliveryHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierWarehouseCitiesArgs = {
  filters?: InputMaybe<CourierWarehouseCityFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierWarehouseCityOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierWarehouseCityDeliveryDaysArgs = {
  filters?: InputMaybe<CourierWarehouseCityDeliveryDayFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierWarehouseCityDeliveryDayOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCourierWarehousesArgs = {
  filters?: InputMaybe<CourierWarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierWarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCouriersArgs = {
  filters?: InputMaybe<CourierFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CourierOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallCreditCardsArgs = {
  filters?: InputMaybe<CreditCardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<CreditCardOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDashboardUserMenusArgs = {
  filters?: InputMaybe<DashboardUserMenuFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DashboardUserMenuOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDeletedUsersArgs = {
  filters?: InputMaybe<DeletedUserFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeletedUserOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDeliveryItemHistoriesArgs = {
  filters?: InputMaybe<DeliveryItemHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeliveryItemHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDeliveryItemParcelsArgs = {
  filters?: InputMaybe<DeliveryItemParcelFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeliveryItemParcelOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDeliveryItemsArgs = {
  filters?: InputMaybe<DeliveryItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeliveryItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDeliverySchedulesArgs = {
  filters?: InputMaybe<DeliveryScheduleFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeliveryScheduleOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDevicesArgs = {
  filters?: InputMaybe<DeviceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DeviceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDiscountCampaignsArgs = {
  filters?: InputMaybe<DiscountCampaignFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DiscountCampaignOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDiscountCodesArgs = {
  filters?: InputMaybe<DiscountCodeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DiscountCodeOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDiscountTagsArgs = {
  filters?: InputMaybe<DiscountTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DiscountTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDiscountsArgs = {
  filters?: InputMaybe<DiscountFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DiscountOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallDispatchDaysArgs = {
  filters?: InputMaybe<DispatchDayFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<DispatchDayOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallExcelsArgs = {
  filters?: InputMaybe<ExcelFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ExcelOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFileCategoriesArgs = {
  filters?: InputMaybe<FileCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FileCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFilesArgs = {
  filters?: InputMaybe<FileFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FileOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallForecastsArgs = {
  filters?: InputMaybe<ForecastFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ForecastOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFriendReferencesArgs = {
  filters?: InputMaybe<FriendReferenceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FriendReferenceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFrozenCategoriesArgs = {
  filters?: InputMaybe<FrozenCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFrozenInstructionsArgs = {
  filters?: InputMaybe<FrozenInstructionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenInstructionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFrozenMealDishesArgs = {
  filters?: InputMaybe<FrozenMealDishFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenMealDishOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFrozenMealsArgs = {
  filters?: InputMaybe<FrozenMealFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenMealOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallFrozenTagsArgs = {
  filters?: InputMaybe<FrozenTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallGiftsArgs = {
  filters?: InputMaybe<GiftFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<GiftOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallImagesArgs = {
  filters?: InputMaybe<ImageFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallIngredientsArgs = {
  filters?: InputMaybe<IngredientFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<IngredientOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallLeadUploadsArgs = {
  filters?: InputMaybe<LeadUploadFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<LeadUploadOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallLeadsArgs = {
  filters?: InputMaybe<LeadFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<LeadOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallLocationsArgs = {
  filters?: InputMaybe<LocationFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<LocationOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallLoyaltyTiersArgs = {
  filters?: InputMaybe<LoyaltyTierFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<LoyaltyTierOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallManualEmailsArgs = {
  filters?: InputMaybe<ManualEmailFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ManualEmailOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMarketProductBrandsArgs = {
  filters?: InputMaybe<MarketProductBrandFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductBrandOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMarketProductCategoriesArgs = {
  filters?: InputMaybe<MarketProductCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMarketProductSubCategoriesArgs = {
  filters?: InputMaybe<MarketProductSubCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductSubCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMarketProductTagsArgs = {
  filters?: InputMaybe<MarketProductTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMarketProductsArgs = {
  filters?: InputMaybe<MarketProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MarketProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitCategoriesArgs = {
  filters?: InputMaybe<MealKitCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitDefaultPricingArgs = {
  filters?: InputMaybe<MealKitDefaultPricingFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitDefaultPricingOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitDishesArgs = {
  filters?: InputMaybe<MealKitDishFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitDishOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitInstructionsArgs = {
  filters?: InputMaybe<MealKitInstructionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitInstructionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitPlansArgs = {
  filters?: InputMaybe<MealKitPlanFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitPlanOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitSubscriptionFeesArgs = {
  filters?: InputMaybe<MealKitSubscriptionFeeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitSubscriptionFeeOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitTagsArgs = {
  filters?: InputMaybe<MealKitTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMealKitsArgs = {
  filters?: InputMaybe<MealKitFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MealKitOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenuCategoriesArgs = {
  filters?: InputMaybe<MenuCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenuDishWinesArgs = {
  filters?: InputMaybe<MenuDishWineFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuDishWineOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenuProductCartsArgs = {
  filters?: InputMaybe<MenuProductCartFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuProductCartOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenuProductOrdersArgs = {
  filters?: InputMaybe<MenuProductOrderFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuProductOrderOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenuWarehousesArgs = {
  filters?: InputMaybe<MenuWarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuWarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMenusArgs = {
  filters?: InputMaybe<MenuFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallMinimumOrderValuesArgs = {
  filters?: InputMaybe<MinimumOrderValueFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MinimumOrderValueOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderAddressesArgs = {
  filters?: InputMaybe<OrderAddressFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderAddressOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderBoxItemsArgs = {
  filters?: InputMaybe<OrderBoxItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderBoxItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderBoxesArgs = {
  filters?: InputMaybe<OrderBoxFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderBoxOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderHistoryArgs = {
  filters?: InputMaybe<OrderHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderItemRatingsArgs = {
  filters?: InputMaybe<OrderItemRatingFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderItemRatingOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderItemsArgs = {
  filters?: InputMaybe<OrderItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderRatingsArgs = {
  filters?: InputMaybe<OrderRatingFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderRatingOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderSubContainersArgs = {
  filters?: InputMaybe<OrderSubContainerFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderSubContainerOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxAdditionsArgs = {
  filters?: InputMaybe<OrderTaxAdditionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxAdditionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxDiscountsArgs = {
  filters?: InputMaybe<OrderTaxDiscountFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxDiscountOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxProductDiscountsArgs = {
  filters?: InputMaybe<OrderTaxProductDiscountFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxProductDiscountOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxProductTypeDiscountsArgs = {
  filters?: InputMaybe<OrderTaxProductTypeDiscountFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxProductTypeDiscountOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxProductTypesArgs = {
  filters?: InputMaybe<OrderTaxProductTypeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxProductTypeOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxProductsArgs = {
  filters?: InputMaybe<OrderTaxProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrderTaxReceiptsArgs = {
  filters?: InputMaybe<OrderTaxReceiptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderTaxReceiptOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallOrdersArgs = {
  filters?: InputMaybe<OrderFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPackingVolumeDefaultsArgs = {
  filters?: InputMaybe<PackingVolumeDefaultFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PackingVolumeDefaultOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBannerSectionsArgs = {
  filters?: InputMaybe<PageBannerSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageBannerSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBannersArgs = {
  filters?: InputMaybe<PageBannerFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageBannerOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageCardSectionsArgs = {
  filters?: InputMaybe<PageCardSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageCardSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageCardsArgs = {
  filters?: InputMaybe<PageCardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageCardOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageDataSectionsArgs = {
  filters?: InputMaybe<PageDataSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageDataSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageDetailSectionsArgs = {
  filters?: InputMaybe<PageDetailSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageDetailSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageDetailsArgs = {
  filters?: InputMaybe<PageDetailFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageDetailOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageGridItemsArgs = {
  filters?: InputMaybe<PageGridItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageGridItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageGridSectionsArgs = {
  filters?: InputMaybe<PageGridSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageGridSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageMediaSectionsArgs = {
  filters?: InputMaybe<PageMediaSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageMediaSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageModalsArgs = {
  filters?: InputMaybe<PageModalFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageModalOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageNotificationGroupsArgs = {
  filters?: InputMaybe<PageNotificationGroupFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageNotificationGroupOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageNotificationsArgs = {
  filters?: InputMaybe<PageNotificationFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageNotificationOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSectionGroupsArgs = {
  filters?: InputMaybe<PageSectionGroupFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageSectionGroupOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSectionsArgs = {
  filters?: InputMaybe<PageSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTabItemsArgs = {
  filters?: InputMaybe<PageTabItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTabItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTabSectionsArgs = {
  filters?: InputMaybe<PageTabSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTabSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTabsArgs = {
  filters?: InputMaybe<PageTabFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTabOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTextCardSectionsArgs = {
  filters?: InputMaybe<PageTextCardSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTextCardSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTextCardsArgs = {
  filters?: InputMaybe<PageTextCardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTextCardOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTextSectionsArgs = {
  filters?: InputMaybe<PageTextSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTextSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTileSectionsArgs = {
  filters?: InputMaybe<PageTileSectionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PageTileSectionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPaymentsArgs = {
  filters?: InputMaybe<PaymentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PaymentOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPdFsArgs = {
  filters?: InputMaybe<PdfFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PdfOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPermissionsArgs = {
  filters?: InputMaybe<PermissionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PermissionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPreferenceCategoriesArgs = {
  filters?: InputMaybe<PreferenceCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PreferenceCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPreferencesArgs = {
  filters?: InputMaybe<PreferenceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PreferenceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProcessGroupCouriersArgs = {
  filters?: InputMaybe<ProcessGroupCourierFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProcessGroupCourierOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProcessGroupsArgs = {
  filters?: InputMaybe<ProcessGroupFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProcessGroupOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductAvailabilityEmailsArgs = {
  filters?: InputMaybe<ProductAvailabilityEmailFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductAvailabilityEmailOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductGroupsArgs = {
  filters?: InputMaybe<ProductGroupFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductGroupOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductIngredientsArgs = {
  filters?: InputMaybe<ProductIngredientFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductIngredientOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductStocksArgs = {
  filters?: InputMaybe<ProductStockFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductStockOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductWarehousesArgs = {
  filters?: InputMaybe<ProductWarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductWarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductWarhouseCostHistoriesArgs = {
  filters?: InputMaybe<ProductWarhouseCostHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductWarhouseCostHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductsArgs = {
  filters?: InputMaybe<ProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPublicHolidaysArgs = {
  filters?: InputMaybe<PublicHolidayFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<PublicHolidayOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRefundHistoriesArgs = {
  filters?: InputMaybe<RefundHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RefundHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRefundReasonsArgs = {
  filters?: InputMaybe<RefundReasonFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RefundReasonOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRefundReportingReasonsArgs = {
  filters?: InputMaybe<RefundReportingReasonFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RefundReportingReasonOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRefundsArgs = {
  filters?: InputMaybe<RefundFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RefundOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRegionsArgs = {
  filters?: InputMaybe<RegionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RegionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallReportsArgs = {
  filters?: InputMaybe<ReportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ReportOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallRolesArgs = {
  filters?: InputMaybe<RoleFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<RoleOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallScheduleCategoryExclusionsArgs = {
  filters?: InputMaybe<ScheduleCategoryExclusionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ScheduleCategoryExclusionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallScheduleCategoryInclusionsArgs = {
  filters?: InputMaybe<ScheduleCategoryInclusionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ScheduleCategoryInclusionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallServingSuggestionsArgs = {
  filters?: InputMaybe<ServingSuggestionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ServingSuggestionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShipmentItemsArgs = {
  filters?: InputMaybe<ShipmentItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipmentItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShipmentsArgs = {
  filters?: InputMaybe<ShipmentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipmentOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShipperBoxesArgs = {
  filters?: InputMaybe<ShipperBoxFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipperBoxOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShipperWarehousesArgs = {
  filters?: InputMaybe<ShipperWarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipperWarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShippersArgs = {
  filters?: InputMaybe<ShipperFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipperOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallShippingConsignmentsArgs = {
  filters?: InputMaybe<ShippingConsignmentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShippingConsignmentOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSmsTemplatesArgs = {
  filters?: InputMaybe<SmsTemplateFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SmsTemplateOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSubContainerOrderItemsArgs = {
  filters?: InputMaybe<SubContainerOrderItemFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SubContainerOrderItemOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSubContainerRestrictionsArgs = {
  filters?: InputMaybe<SubContainerRestrictionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SubContainerRestrictionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSubContainersArgs = {
  filters?: InputMaybe<SubContainerFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SubContainerOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSubscriptionPriceHistoriesArgs = {
  filters?: InputMaybe<SubscriptionPriceHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SubscriptionPriceHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallSuppliersArgs = {
  filters?: InputMaybe<SupplierFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<SupplierOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallTaxClassesArgs = {
  filters?: InputMaybe<TaxClassFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<TaxClassOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallTaxReceiptEventsArgs = {
  filters?: InputMaybe<TaxReceiptEventFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<TaxReceiptEventOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallTransactionReportsArgs = {
  filters?: InputMaybe<TransactionReportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<TransactionReportOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallTransactionsArgs = {
  filters?: InputMaybe<TransactionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<TransactionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserAddressesArgs = {
  filters?: InputMaybe<UserAddressFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserAddressOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserCommunicationPlatformDataArgs = {
  filters?: InputMaybe<UserCommunicationPlatformDataFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserCommunicationPlatformDataOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserDevicesArgs = {
  filters?: InputMaybe<UserDeviceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserDeviceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserDiscountsArgs = {
  filters?: InputMaybe<UserDiscountFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserDiscountOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserHistoryArgs = {
  filters?: InputMaybe<UserHistoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserHistoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserMenusArgs = {
  filters?: InputMaybe<UserMenuFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserMenuOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserPartnershipsArgs = {
  filters?: InputMaybe<UserPartnershipFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserPartnershipOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserPreferencesArgs = {
  filters?: InputMaybe<UserPreferenceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserPreferenceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserRolesArgs = {
  filters?: InputMaybe<UserRoleFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserRoleOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUserSubscriptionsArgs = {
  filters?: InputMaybe<UserSubscriptionFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserSubscriptionOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUsersArgs = {
  filters?: InputMaybe<UserFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallUsersPreferencesOrDefaultsArgs = {
  filters?: InputMaybe<UserPreferenceFilters>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<UserPreferenceOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallVendorsArgs = {
  filters?: InputMaybe<VendorFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<VendorOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallVineyardsArgs = {
  filters?: InputMaybe<VineyardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<VineyardOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWarehouseLocationsArgs = {
  filters?: InputMaybe<WarehouseLocationFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WarehouseLocationOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWarehouseProductRangesArgs = {
  filters?: InputMaybe<WarehouseProductRangeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WarehouseProductRangeOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWarehousesArgs = {
  filters?: InputMaybe<WarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWineCampaignsArgs = {
  filters?: InputMaybe<WineCampaignFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineCampaignOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWineCategoriesArgs = {
  filters?: InputMaybe<WineCategoryFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineCategoryOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWineMakersArgs = {
  filters?: InputMaybe<WineMakerFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineMakerOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWineTagsArgs = {
  filters?: InputMaybe<WineTagFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineTagOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWinesArgs = {
  filters?: InputMaybe<WineFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycategorisedUserPreferencesArgs = {
  id: Scalars['ID']['input'];
};


export type QuerycategoryTreeArgs = {
  group?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerycheckPaymentStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QuerycountAbTestBucketUsersArgs = {
  filters?: InputMaybe<AbTestBucketUserFilters>;
};


export type QuerycountAbTestBucketsArgs = {
  filters?: InputMaybe<AbTestBucketFilters>;
};


export type QuerycountAbTestFiltersArgs = {
  filters?: InputMaybe<AbTestFilterFilters>;
};


export type QuerycountAbTestsArgs = {
  filters?: InputMaybe<AbTestFilters>;
};


export type QuerycountAcumaticaDiscountNonStockSkusArgs = {
  filters?: InputMaybe<AcumaticaDiscountNonStockSkuFilters>;
};


export type QuerycountAcumaticaProductTypeNonStockSkusArgs = {
  filters?: InputMaybe<AcumaticaProductTypeNonStockSkuFilters>;
};


export type QuerycountAddressIssuesArgs = {
  filters?: InputMaybe<AddressIssueFilters>;
};


export type QuerycountAllergensArgs = {
  filters?: InputMaybe<AllergenFilters>;
};


export type QuerycountAreasArgs = {
  filters?: InputMaybe<AreaFilters>;
};


export type QuerycountBabyBoxCategoriesArgs = {
  filters?: InputMaybe<BabyBoxCategoryFilters>;
};


export type QuerycountBabyBoxTagsArgs = {
  filters?: InputMaybe<BabyBoxTagFilters>;
};


export type QuerycountBabyBoxTubDetailsArgs = {
  filters?: InputMaybe<BabyBoxTubDetailFilters>;
};


export type QuerycountBabyBoxTubsArgs = {
  filters?: InputMaybe<BabyBoxTubFilters>;
};


export type QuerycountBabyBoxesArgs = {
  filters?: InputMaybe<BabyBoxFilters>;
};


export type QuerycountBankBinNumbersArgs = {
  filters?: InputMaybe<BankBinNumberFilters>;
};


export type QuerycountBarcodesArgs = {
  filters?: InputMaybe<BarcodeFilters>;
};


export type QuerycountBoxRestrictionsArgs = {
  filters?: InputMaybe<BoxRestrictionFilters>;
};


export type QuerycountBoxSubContainersArgs = {
  filters?: InputMaybe<BoxSubContainerFilters>;
};


export type QuerycountBoxesArgs = {
  filters?: InputMaybe<BoxFilters>;
};


export type QuerycountBranchesArgs = {
  filters?: InputMaybe<BranchFilters>;
};


export type QuerycountBrandsArgs = {
  filters?: InputMaybe<BrandFilters>;
};


export type QuerycountCampaignsArgs = {
  filters?: InputMaybe<CampaignFilters>;
};


export type QuerycountCartsArgs = {
  filters?: InputMaybe<CartFilters>;
};


export type QuerycountCategoriesArgs = {
  filters?: InputMaybe<CategoryFilters>;
};


export type QuerycountCategoryGroupsArgs = {
  filters?: InputMaybe<CategoryGroupFilters>;
};


export type QuerycountChefsArgs = {
  filters?: InputMaybe<ChefFilters>;
};


export type QuerycountCitiesArgs = {
  filters?: InputMaybe<CityFilters>;
};


export type QuerycountCommunicationTemplatesArgs = {
  filters?: InputMaybe<CommunicationTemplateFilters>;
};


export type QuerycountCountriesArgs = {
  filters?: InputMaybe<CountryFilters>;
};


export type QuerycountCourierBoxesArgs = {
  filters?: InputMaybe<CourierBoxFilters>;
};


export type QuerycountCourierDeliveriesArgs = {
  filters?: InputMaybe<CourierDeliveryFilters>;
};


export type QuerycountCourierDeliveryDaysArgs = {
  filters?: InputMaybe<CourierDeliveryDayFilters>;
};


export type QuerycountCourierDeliveryHistoriesArgs = {
  filters?: InputMaybe<CourierDeliveryHistoryFilters>;
};


export type QuerycountCourierWarehouseCitiesArgs = {
  filters?: InputMaybe<CourierWarehouseCityFilters>;
};


export type QuerycountCourierWarehouseCityDeliveryDaysArgs = {
  filters?: InputMaybe<CourierWarehouseCityDeliveryDayFilters>;
};


export type QuerycountCourierWarehousesArgs = {
  filters?: InputMaybe<CourierWarehouseFilters>;
};


export type QuerycountCouriersArgs = {
  filters?: InputMaybe<CourierFilters>;
};


export type QuerycountCreditCardsArgs = {
  filters?: InputMaybe<CreditCardFilters>;
};


export type QuerycountDashboardUserMenusArgs = {
  filters?: InputMaybe<DashboardUserMenuFilters>;
};


export type QuerycountDeletedUsersArgs = {
  filters?: InputMaybe<DeletedUserFilters>;
};


export type QuerycountDeliveryItemHistoriesArgs = {
  filters?: InputMaybe<DeliveryItemHistoryFilters>;
};


export type QuerycountDeliveryItemParcelsArgs = {
  filters?: InputMaybe<DeliveryItemParcelFilters>;
};


export type QuerycountDeliveryItemsArgs = {
  filters?: InputMaybe<DeliveryItemFilters>;
};


export type QuerycountDeliverySchedulesArgs = {
  filters?: InputMaybe<DeliveryScheduleFilters>;
};


export type QuerycountDevicesArgs = {
  filters?: InputMaybe<DeviceFilters>;
};


export type QuerycountDiscountCampaignsArgs = {
  filters?: InputMaybe<DiscountCampaignFilters>;
};


export type QuerycountDiscountCodesArgs = {
  filters?: InputMaybe<DiscountCodeFilters>;
};


export type QuerycountDiscountTagsArgs = {
  filters?: InputMaybe<DiscountTagFilters>;
};


export type QuerycountDiscountsArgs = {
  filters?: InputMaybe<DiscountFilters>;
};


export type QuerycountDispatchDaysArgs = {
  filters?: InputMaybe<DispatchDayFilters>;
};


export type QuerycountExcelsArgs = {
  filters?: InputMaybe<ExcelFilters>;
};


export type QuerycountFileCategoriesArgs = {
  filters?: InputMaybe<FileCategoryFilters>;
};


export type QuerycountFilesArgs = {
  filters?: InputMaybe<FileFilters>;
};


export type QuerycountForecastsArgs = {
  filters?: InputMaybe<ForecastFilters>;
};


export type QuerycountFriendReferencesArgs = {
  filters?: InputMaybe<FriendReferenceFilters>;
};


export type QuerycountFrozenCategoriesArgs = {
  filters?: InputMaybe<FrozenCategoryFilters>;
};


export type QuerycountFrozenInstructionsArgs = {
  filters?: InputMaybe<FrozenInstructionFilters>;
};


export type QuerycountFrozenMealDishesArgs = {
  filters?: InputMaybe<FrozenMealDishFilters>;
};


export type QuerycountFrozenMealsArgs = {
  filters?: InputMaybe<FrozenMealFilters>;
};


export type QuerycountFrozenTagsArgs = {
  filters?: InputMaybe<FrozenTagFilters>;
};


export type QuerycountGiftsArgs = {
  filters?: InputMaybe<GiftFilters>;
};


export type QuerycountImagesArgs = {
  filters?: InputMaybe<ImageFilters>;
};


export type QuerycountIngredientsArgs = {
  filters?: InputMaybe<IngredientFilters>;
};


export type QuerycountLeadUploadsArgs = {
  filters?: InputMaybe<LeadUploadFilters>;
};


export type QuerycountLeadsArgs = {
  filters?: InputMaybe<LeadFilters>;
};


export type QuerycountLocationsArgs = {
  filters?: InputMaybe<LocationFilters>;
};


export type QuerycountLoyaltyTiersArgs = {
  filters?: InputMaybe<LoyaltyTierFilters>;
};


export type QuerycountManualEmailsArgs = {
  filters?: InputMaybe<ManualEmailFilters>;
};


export type QuerycountMarketProductBrandsArgs = {
  filters?: InputMaybe<MarketProductBrandFilters>;
};


export type QuerycountMarketProductCategoriesArgs = {
  filters?: InputMaybe<MarketProductCategoryFilters>;
};


export type QuerycountMarketProductSubCategoriesArgs = {
  filters?: InputMaybe<MarketProductSubCategoryFilters>;
};


export type QuerycountMarketProductTagsArgs = {
  filters?: InputMaybe<MarketProductTagFilters>;
};


export type QuerycountMarketProductsArgs = {
  filters?: InputMaybe<MarketProductFilters>;
};


export type QuerycountMealKitCategoriesArgs = {
  filters?: InputMaybe<MealKitCategoryFilters>;
};


export type QuerycountMealKitDefaultPricingArgs = {
  filters?: InputMaybe<MealKitDefaultPricingFilters>;
};


export type QuerycountMealKitDishesArgs = {
  filters?: InputMaybe<MealKitDishFilters>;
};


export type QuerycountMealKitInstructionsArgs = {
  filters?: InputMaybe<MealKitInstructionFilters>;
};


export type QuerycountMealKitPlansArgs = {
  filters?: InputMaybe<MealKitPlanFilters>;
};


export type QuerycountMealKitSubscriptionFeesArgs = {
  filters?: InputMaybe<MealKitSubscriptionFeeFilters>;
};


export type QuerycountMealKitTagsArgs = {
  filters?: InputMaybe<MealKitTagFilters>;
};


export type QuerycountMealKitsArgs = {
  filters?: InputMaybe<MealKitFilters>;
};


export type QuerycountMenuCategoriesArgs = {
  filters?: InputMaybe<MenuCategoryFilters>;
};


export type QuerycountMenuDishWinesArgs = {
  filters?: InputMaybe<MenuDishWineFilters>;
};


export type QuerycountMenuProductCartsArgs = {
  filters?: InputMaybe<MenuProductCartFilters>;
};


export type QuerycountMenuProductOrdersArgs = {
  filters?: InputMaybe<MenuProductOrderFilters>;
};


export type QuerycountMenuWarehousesArgs = {
  filters?: InputMaybe<MenuWarehouseFilters>;
};


export type QuerycountMenusArgs = {
  filters?: InputMaybe<MenuFilters>;
};


export type QuerycountMinimumOrderValuesArgs = {
  filters?: InputMaybe<MinimumOrderValueFilters>;
};


export type QuerycountOrderAddressesArgs = {
  filters?: InputMaybe<OrderAddressFilters>;
};


export type QuerycountOrderBoxItemsArgs = {
  filters?: InputMaybe<OrderBoxItemFilters>;
};


export type QuerycountOrderBoxesArgs = {
  filters?: InputMaybe<OrderBoxFilters>;
};


export type QuerycountOrderHistoryArgs = {
  filters?: InputMaybe<OrderHistoryFilters>;
};


export type QuerycountOrderItemRatingsArgs = {
  filters?: InputMaybe<OrderItemRatingFilters>;
};


export type QuerycountOrderItemsArgs = {
  filters?: InputMaybe<OrderItemFilters>;
};


export type QuerycountOrderRatingsArgs = {
  filters?: InputMaybe<OrderRatingFilters>;
};


export type QuerycountOrderSubContainersArgs = {
  filters?: InputMaybe<OrderSubContainerFilters>;
};


export type QuerycountOrderTaxAdditionsArgs = {
  filters?: InputMaybe<OrderTaxAdditionFilters>;
};


export type QuerycountOrderTaxDiscountsArgs = {
  filters?: InputMaybe<OrderTaxDiscountFilters>;
};


export type QuerycountOrderTaxProductDiscountsArgs = {
  filters?: InputMaybe<OrderTaxProductDiscountFilters>;
};


export type QuerycountOrderTaxProductTypeDiscountsArgs = {
  filters?: InputMaybe<OrderTaxProductTypeDiscountFilters>;
};


export type QuerycountOrderTaxProductTypesArgs = {
  filters?: InputMaybe<OrderTaxProductTypeFilters>;
};


export type QuerycountOrderTaxProductsArgs = {
  filters?: InputMaybe<OrderTaxProductFilters>;
};


export type QuerycountOrderTaxReceiptsArgs = {
  filters?: InputMaybe<OrderTaxReceiptFilters>;
};


export type QuerycountOrdersArgs = {
  filters?: InputMaybe<OrderFilters>;
};


export type QuerycountPackingVolumeDefaultsArgs = {
  filters?: InputMaybe<PackingVolumeDefaultFilters>;
};


export type QuerycountPageBannerSectionsArgs = {
  filters?: InputMaybe<PageBannerSectionFilters>;
};


export type QuerycountPageBannersArgs = {
  filters?: InputMaybe<PageBannerFilters>;
};


export type QuerycountPageCardSectionsArgs = {
  filters?: InputMaybe<PageCardSectionFilters>;
};


export type QuerycountPageCardsArgs = {
  filters?: InputMaybe<PageCardFilters>;
};


export type QuerycountPageDataSectionsArgs = {
  filters?: InputMaybe<PageDataSectionFilters>;
};


export type QuerycountPageDetailSectionsArgs = {
  filters?: InputMaybe<PageDetailSectionFilters>;
};


export type QuerycountPageDetailsArgs = {
  filters?: InputMaybe<PageDetailFilters>;
};


export type QuerycountPageGridItemsArgs = {
  filters?: InputMaybe<PageGridItemFilters>;
};


export type QuerycountPageGridSectionsArgs = {
  filters?: InputMaybe<PageGridSectionFilters>;
};


export type QuerycountPageMediaSectionsArgs = {
  filters?: InputMaybe<PageMediaSectionFilters>;
};


export type QuerycountPageModalsArgs = {
  filters?: InputMaybe<PageModalFilters>;
};


export type QuerycountPageNotificationGroupsArgs = {
  filters?: InputMaybe<PageNotificationGroupFilters>;
};


export type QuerycountPageNotificationsArgs = {
  filters?: InputMaybe<PageNotificationFilters>;
};


export type QuerycountPageSectionGroupsArgs = {
  filters?: InputMaybe<PageSectionGroupFilters>;
};


export type QuerycountPageSectionsArgs = {
  filters?: InputMaybe<PageSectionFilters>;
};


export type QuerycountPageTabItemsArgs = {
  filters?: InputMaybe<PageTabItemFilters>;
};


export type QuerycountPageTabSectionsArgs = {
  filters?: InputMaybe<PageTabSectionFilters>;
};


export type QuerycountPageTabsArgs = {
  filters?: InputMaybe<PageTabFilters>;
};


export type QuerycountPageTextCardSectionsArgs = {
  filters?: InputMaybe<PageTextCardSectionFilters>;
};


export type QuerycountPageTextCardsArgs = {
  filters?: InputMaybe<PageTextCardFilters>;
};


export type QuerycountPageTextSectionsArgs = {
  filters?: InputMaybe<PageTextSectionFilters>;
};


export type QuerycountPageTileSectionsArgs = {
  filters?: InputMaybe<PageTileSectionFilters>;
};


export type QuerycountPaymentsArgs = {
  filters?: InputMaybe<PaymentFilters>;
};


export type QuerycountPdFsArgs = {
  filters?: InputMaybe<PdfFilters>;
};


export type QuerycountPermissionsArgs = {
  filters?: InputMaybe<PermissionFilters>;
};


export type QuerycountPreferenceCategoriesArgs = {
  filters?: InputMaybe<PreferenceCategoryFilters>;
};


export type QuerycountPreferencesArgs = {
  filters?: InputMaybe<PreferenceFilters>;
};


export type QuerycountProcessGroupCouriersArgs = {
  filters?: InputMaybe<ProcessGroupCourierFilters>;
};


export type QuerycountProcessGroupsArgs = {
  filters?: InputMaybe<ProcessGroupFilters>;
};


export type QuerycountProductAvailabilityEmailsArgs = {
  filters?: InputMaybe<ProductAvailabilityEmailFilters>;
};


export type QuerycountProductGroupsArgs = {
  filters?: InputMaybe<ProductGroupFilters>;
};


export type QuerycountProductIngredientsArgs = {
  filters?: InputMaybe<ProductIngredientFilters>;
};


export type QuerycountProductStocksArgs = {
  filters?: InputMaybe<ProductStockFilters>;
};


export type QuerycountProductWarehousesArgs = {
  filters?: InputMaybe<ProductWarehouseFilters>;
};


export type QuerycountProductWarhouseCostHistoriesArgs = {
  filters?: InputMaybe<ProductWarhouseCostHistoryFilters>;
};


export type QuerycountProductsArgs = {
  filters?: InputMaybe<ProductFilters>;
};


export type QuerycountPublicHolidaysArgs = {
  filters?: InputMaybe<PublicHolidayFilters>;
};


export type QuerycountRefundHistoriesArgs = {
  filters?: InputMaybe<RefundHistoryFilters>;
};


export type QuerycountRefundReasonsArgs = {
  filters?: InputMaybe<RefundReasonFilters>;
};


export type QuerycountRefundReportingReasonsArgs = {
  filters?: InputMaybe<RefundReportingReasonFilters>;
};


export type QuerycountRefundsArgs = {
  filters?: InputMaybe<RefundFilters>;
};


export type QuerycountRegionsArgs = {
  filters?: InputMaybe<RegionFilters>;
};


export type QuerycountReportsArgs = {
  filters?: InputMaybe<ReportFilters>;
};


export type QuerycountRolesArgs = {
  filters?: InputMaybe<RoleFilters>;
};


export type QuerycountScheduleCategoryExclusionsArgs = {
  filters?: InputMaybe<ScheduleCategoryExclusionFilters>;
};


export type QuerycountScheduleCategoryInclusionsArgs = {
  filters?: InputMaybe<ScheduleCategoryInclusionFilters>;
};


export type QuerycountServingSuggestionsArgs = {
  filters?: InputMaybe<ServingSuggestionFilters>;
};


export type QuerycountShipmentItemsArgs = {
  filters?: InputMaybe<ShipmentItemFilters>;
};


export type QuerycountShipmentsArgs = {
  filters?: InputMaybe<ShipmentFilters>;
};


export type QuerycountShipperBoxesArgs = {
  filters?: InputMaybe<ShipperBoxFilters>;
};


export type QuerycountShipperWarehousesArgs = {
  filters?: InputMaybe<ShipperWarehouseFilters>;
};


export type QuerycountShippersArgs = {
  filters?: InputMaybe<ShipperFilters>;
};


export type QuerycountShippingConsignmentsArgs = {
  filters?: InputMaybe<ShippingConsignmentFilters>;
};


export type QuerycountSmsTemplatesArgs = {
  filters?: InputMaybe<SmsTemplateFilters>;
};


export type QuerycountSubContainerOrderItemsArgs = {
  filters?: InputMaybe<SubContainerOrderItemFilters>;
};


export type QuerycountSubContainerRestrictionsArgs = {
  filters?: InputMaybe<SubContainerRestrictionFilters>;
};


export type QuerycountSubContainersArgs = {
  filters?: InputMaybe<SubContainerFilters>;
};


export type QuerycountSubscriptionPriceHistoriesArgs = {
  filters?: InputMaybe<SubscriptionPriceHistoryFilters>;
};


export type QuerycountSuppliersArgs = {
  filters?: InputMaybe<SupplierFilters>;
};


export type QuerycountTaxClassesArgs = {
  filters?: InputMaybe<TaxClassFilters>;
};


export type QuerycountTaxReceiptEventsArgs = {
  filters?: InputMaybe<TaxReceiptEventFilters>;
};


export type QuerycountTransactionReportsArgs = {
  filters?: InputMaybe<TransactionReportFilters>;
};


export type QuerycountTransactionsArgs = {
  filters?: InputMaybe<TransactionFilters>;
};


export type QuerycountUserAddressesArgs = {
  filters?: InputMaybe<UserAddressFilters>;
};


export type QuerycountUserCommunicationPlatformDataArgs = {
  filters?: InputMaybe<UserCommunicationPlatformDataFilters>;
};


export type QuerycountUserDevicesArgs = {
  filters?: InputMaybe<UserDeviceFilters>;
};


export type QuerycountUserDiscountsArgs = {
  filters?: InputMaybe<UserDiscountFilters>;
};


export type QuerycountUserHistoryArgs = {
  filters?: InputMaybe<UserHistoryFilters>;
};


export type QuerycountUserMenusArgs = {
  filters?: InputMaybe<UserMenuFilters>;
};


export type QuerycountUserPartnershipsArgs = {
  filters?: InputMaybe<UserPartnershipFilters>;
};


export type QuerycountUserPreferencesArgs = {
  filters?: InputMaybe<UserPreferenceFilters>;
};


export type QuerycountUserRolesArgs = {
  filters?: InputMaybe<UserRoleFilters>;
};


export type QuerycountUserSubscriptionsArgs = {
  filters?: InputMaybe<UserSubscriptionFilters>;
};


export type QuerycountUsersArgs = {
  filters?: InputMaybe<UserFilters>;
};


export type QuerycountVendorsArgs = {
  filters?: InputMaybe<VendorFilters>;
};


export type QuerycountVineyardsArgs = {
  filters?: InputMaybe<VineyardFilters>;
};


export type QuerycountWarehouseLocationsArgs = {
  filters?: InputMaybe<WarehouseLocationFilters>;
};


export type QuerycountWarehouseProductRangesArgs = {
  filters?: InputMaybe<WarehouseProductRangeFilters>;
};


export type QuerycountWarehousesArgs = {
  filters?: InputMaybe<WarehouseFilters>;
};


export type QuerycountWineCampaignsArgs = {
  filters?: InputMaybe<WineCampaignFilters>;
};


export type QuerycountWineCategoriesArgs = {
  filters?: InputMaybe<WineCategoryFilters>;
};


export type QuerycountWineMakersArgs = {
  filters?: InputMaybe<WineMakerFilters>;
};


export type QuerycountWineTagsArgs = {
  filters?: InputMaybe<WineTagFilters>;
};


export type QuerycountWinesArgs = {
  filters?: InputMaybe<WineFilters>;
};


export type QuerycourierDeliveryItemTotalsArgs = {
  courierDeliveryId: Scalars['ID']['input'];
};


export type QueryfetchZeroCostItemsForMenuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryfindOrderBoxByTrackingNumberArgs = {
  search: Scalars['String']['input'];
};


export type QuerygetAllEventCostHistoryItemsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<EventCostHistoryListOrder>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerygetAvailableSubscriptionDeliveryDaysArgs = {
  userAddressId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerygetCityCourierWarehousesArgs = {
  cityId: Scalars['ID']['input'];
};


export type QuerygetDeliveryDayOptionsForCourierWarehouseCityArgs = {
  courierWarehouseCityId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerygetDetailedInputObjectConfigArgs = {
  name: Scalars['String']['input'];
};


export type QuerygetEnumArgs = {
  enum: Scalars['String']['input'];
};


export type QuerygetEnumsArgs = {
  enums: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QuerygetInputObjectConfigArgs = {
  name: Scalars['String']['input'];
};


export type QuerygetMasterOrderReportArgs = {
  filters?: InputMaybe<MasterOrderReportFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<MasterOrderReportOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerygetMenuRegionBreakdownArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetOrderSubscriptionArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QuerygetPaymentParametersArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetReportFileArgs = {
  type?: InputMaybe<ReportFileStatusTypeEnum>;
};


export type QuerygetReportFileStatusArgs = {
  type?: InputMaybe<ReportFileStatusTypeEnum>;
};


export type QuerygetTableDataArgs = {
  auth: Scalars['String']['input'];
  tableName: Scalars['String']['input'];
};


export type QuerygetTablesArgs = {
  auth: Scalars['String']['input'];
};


export type QuerygetUnavailableProductsArgs = {
  userAddressId: Scalars['String']['input'];
};


export type QuerygetUserIdByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QuerygetUserPointsBalanceArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerygetWineAislesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerylookupLocationArgs = {
  search: Scalars['String']['input'];
};


export type QuerymyShopAisleArgs = {
  input?: InputMaybe<MyShopInputData>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymyShopItemsArgs = {
  input?: InputMaybe<MyShopInputData>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerynextLoyaltyTiersArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAbTestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAbTestBucketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAbTestBucketUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAbTestFilterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAcumaticaDiscountNonStockSkuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAcumaticaProductTypeNonStockSkuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAddressIssueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAllergenArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneAreaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBabyBoxArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneBabyBoxCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBabyBoxTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBabyBoxTubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneBabyBoxTubDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBankBinNumberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBarcodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBoxArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBoxRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBoxSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBranchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCategoryGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneChefArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCommunicationTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCountryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierBoxArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierDeliveryDayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierDeliveryHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierWarehouseCityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCourierWarehouseCityDeliveryDayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneCreditCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDashboardUserMenuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeletedUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeliveryItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeliveryItemHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeliveryItemParcelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeliveryScheduleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDiscountCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDiscountTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneDispatchDayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneExcelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFileCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneForecastArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFriendReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFrozenCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFrozenInstructionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneFrozenMealArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneFrozenMealDishArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
};


export type QueryoneFrozenTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneGiftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneImageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneIngredientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneLeadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneLeadUploadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneLoyaltyTierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneManualEmailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMarketProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
};


export type QueryoneMarketProductBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMarketProductCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneMarketProductSubCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMarketProductTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMealKitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneMealKitCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMealKitDefaultPricingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMealKitDishArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
};


export type QueryoneMealKitInstructionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMealKitPlanArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneMealKitSubscriptionFeeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMealKitTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuDishWineArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuProductCartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuProductOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMenuWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneMinimumOrderValueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderBoxArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderBoxItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderItemRatingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderRatingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxAdditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxProductDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxProductTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxProductTypeDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderTaxReceiptArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneOrderWithPaymentTokenArgs = {
  token?: InputMaybe<Scalars['JWT']['input']>;
};


export type QueryonePackingVolumeDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageBannerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageBannerSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePageCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageCardSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePageDataSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageDetailSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageGridItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageGridSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageMediaSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageModalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePageNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageNotificationGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePageSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageSectionGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePageTabArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTabItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTabSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTextCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTextCardSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTextSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePageTileSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryonePaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePaymentByTokenArgs = {
  token: Scalars['JWT']['input'];
};


export type QueryonePaymentByTransactionArgs = {
  transactionId: Scalars['ID']['input'];
};


export type QueryonePdfArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePermissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePreferenceCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProcessGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProcessGroupCourierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneProductAvailabilityEmailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProductGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneProductIngredientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProductStockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProductWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneProductWarhouseCostHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryonePublicHolidayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneRefundArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneRefundHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneRefundReasonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneRefundReportingReasonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneRegionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneReportByIdentifierArgs = {
  identifier: Scalars['String']['input'];
};


export type QueryoneRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneScheduleCategoryExclusionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneScheduleCategoryInclusionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneServingSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneShipmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryoneShipmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneShipperArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneShipperBoxArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneShipperWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneShippingConsignmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSmsTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSubContainerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSubContainerOrderItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSubContainerRestrictionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSubscriptionPriceHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneSupplierArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneTaxClassArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneTaxReceiptEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneTransactionReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserCommunicationPlatformDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserMenuArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserPartnershipArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserPreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserPreferenceOrDefaultArgs = {
  preferenceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryoneUserRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneVendorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneVineyardArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWarehouseLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWarehouseProductRangeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWineArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
};


export type QueryoneWineCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWineCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWineMakerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryoneWineTagArgs = {
  id: Scalars['ID']['input'];
};


export type QueryplaceDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryproductIslesArgs = {
  filters?: InputMaybe<ProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<FrozenMealDishOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryproductsByCityArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryproductsByDefaultAddressArgs = {
  filters?: InputMaybe<ProductFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  userAddressId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryrafDashboardCountArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerysimulateBoxingArgs = {
  input: SimulateBoxingInput;
};


export type QuerystaticImageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryuserMenuFlagCountArgs = {
  filters?: InputMaybe<UserMenuFilters>;
  order?: InputMaybe<Array<InputMaybe<UserMenuOrder>>>;
};


export type QueryvalidateInviteArgs = {
  token?: InputMaybe<Scalars['JWT']['input']>;
};

export enum QueryTagEnum {
  /** Allergy */
  ALLERGY = 'ALLERGY',
  /** Nutritional Info */
  NUTRITIONAL_INFO = 'NUTRITIONAL_INFO',
  /** Preservatives */
  PRESERVATIVES = 'PRESERVATIVES',
  /** Recycling */
  RECYCLING = 'RECYCLING',
  /** Shelf Life */
  SHELF_LIFE = 'SHELF_LIFE',
  /** Supplier */
  SUPPLIER = 'SUPPLIER'
}

export type RecipeCardPortionSlug = {
  __typename: 'RecipeCardPortionSlug';
  name: Maybe<Scalars['String']['output']>;
  portionSize: Maybe<PortionSizeEnum>;
  slug: Maybe<Scalars['String']['output']>;
};

export enum RecipeCardTagEnum {
  /** Hard to Follow: Too Complex */
  HARD_TO_FOLLOW_TOO_CONPLEX = 'HARD_TO_FOLLOW_TOO_CONPLEX',
  /** Hard to Follow: Too Wordy */
  HARD_TO_FOLLOW_TOO_WORDY = 'HARD_TO_FOLLOW_TOO_WORDY',
  /** Ingredient Reference Error */
  INGREDIENT_REFERENCE_ERROR = 'INGREDIENT_REFERENCE_ERROR',
  /** Missing all recipe cards */
  MISSING_ALL_RECIPE_CARDS = 'MISSING_ALL_RECIPE_CARDS',
  /** Missing one recipe card */
  MISSING_ONE_RECIPE_CARD = 'MISSING_ONE_RECIPE_CARD',
  /** Recipe Method Error */
  RECIPE_METHOD_ERROR = 'RECIPE_METHOD_ERROR',
  /** Spelling and Grammar Error */
  SPELLING_AND_GRAMMAR_ERROR = 'SPELLING_AND_GRAMMAR_ERROR'
}

export type ReferFriendInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type ReferenceHistory = {
  __typename: 'ReferenceHistory';
  additionalData: Maybe<Scalars['JSON']['output']>;
  status: Maybe<UserReferralStatusEnum>;
  timestamp: Maybe<Scalars['Date']['output']>;
};

export enum ReferralRewardTagEnum {
  /** Referral Reward */
  REFERRAL_REWARD = 'REFERRAL_REWARD'
}

export enum RefunOrderTypeEnum {
  /** Refund */
  REFUND = 'REFUND',
  /** User Points */
  USER_POINTS = 'USER_POINTS'
}

/** Represents a Refund in the system */
export type Refund = Transaction & {
  __typename: 'Refund';
  /** The creation date of the Refund */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Credit Card associated to the transaction */
  creditCard: Maybe<CreditCard>;
  /** The description of the transaction */
  description: Scalars['String']['output'];
  /** Due amount */
  dueAmount: Scalars['Float']['output'];
  /** The history of the refund */
  history: Maybe<Array<Maybe<RefundHistory>>>;
  /** The Refund's ID */
  id: Scalars['ID']['output'];
  /** Payment method peach_payments/credit/voucher etc etc */
  method: TransactionMethodEnum;
  /** The Order associated to the transaction */
  order: Maybe<Order>;
  /** Paid amount */
  paidAmount: Scalars['Float']['output'];
  /** The payment being refunded */
  payment: Payment;
  /** The Reasons for refunding */
  reasons: Maybe<Array<Maybe<RefundReason>>>;
  /** The status of the transaction */
  status: TransactionStatusEnum;
  /** The status message */
  statusMessage: Scalars['String']['output'];
  /** Additional Transaction Data */
  transactionData: Maybe<Scalars['JSON']['output']>;
  /** Transaction ID (peach_id most likely) */
  transactionId: Maybe<Scalars['String']['output']>;
  /** The update date of the Refund */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The User associated to the transaction */
  user: User;
};

/** Refund filtering options */
export type RefundFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<Array<TransactionMethodEnum>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<TransactionStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents a RefundHistory in the system */
export type RefundHistory = {
  __typename: 'RefundHistory';
  /** The creation date of the RefundHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  data: Maybe<Scalars['String']['output']>;
  /** The RefundHistory's ID */
  id: Scalars['ID']['output'];
  type: RefundHistoryType;
  /** The update date of the RefundHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** RefundHistory filtering options */
export type RefundHistoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type RefundHistoryList = {
  __typename: 'RefundHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of RefundHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<RefundHistory>>;
  /** Number of RefundHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** RefundHistory ordering options */
export type RefundHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RefundHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum RefundHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum RefundHistoryType {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  STATUS_CHANGE = 'STATUS_CHANGE'
}

/** Properties list object */
export type RefundList = {
  __typename: 'RefundList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Refund records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Refund>>;
  /** Number of Refund records skipped */
  skip: Scalars['Int']['output'];
};

/** Refund ordering options */
export type RefundOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RefundOrderEnum>;
};

/** Fields available to order Properties */
export enum RefundOrderEnum {
  CREATED_AT = 'CREATED_AT',
  METHOD = 'METHOD',
  NAME = 'NAME',
  REFUND_AMOUNT = 'REFUND_AMOUNT',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type RefundOrderInput = {
  method: TransactionMethodEnum;
  refundReportingReasons?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Represents a RefundReason in the system */
export type RefundReason = {
  __typename: 'RefundReason';
  /** The creation date of the RefundReason */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The RefundReason's ID */
  id: Scalars['ID']['output'];
  refund: Refund;
  refundReportingReason: RefundReportingReason;
  /** The update date of the RefundReason */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** RefundReason filtering options */
export type RefundReasonFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type RefundReasonList = {
  __typename: 'RefundReasonList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of RefundReason records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<RefundReason>>;
  /** Number of RefundReason records skipped */
  skip: Scalars['Int']['output'];
};

/** RefundReason ordering options */
export type RefundReasonOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RefundReasonOrderEnum>;
};

/** Fields available to order Properties */
export enum RefundReasonOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a RefundReportingReason in the system */
export type RefundReportingReason = {
  __typename: 'RefundReportingReason';
  /** The creation date of the RefundReportingReason */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultRefundAmount: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The RefundReportingReason's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  refundReasons: Maybe<Array<Maybe<RefundReason>>>;
  /** The update date of the RefundReportingReason */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** RefundReportingReason filtering options */
export type RefundReportingReasonFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type RefundReportingReasonList = {
  __typename: 'RefundReportingReasonList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of RefundReportingReason records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<RefundReportingReason>>;
  /** Number of RefundReportingReason records skipped */
  skip: Scalars['Int']['output'];
};

/** RefundReportingReason ordering options */
export type RefundReportingReasonOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RefundReportingReasonOrderEnum>;
};

/** Fields available to order Properties */
export enum RefundReportingReasonOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Region in the system */
export type Region = {
  __typename: 'Region';
  activeUserAddressCount: Scalars['Int']['output'];
  areas: Maybe<Array<Maybe<Area>>>;
  cities: Maybe<Array<Maybe<City>>>;
  country: Country;
  countryId: Scalars['ID']['output'];
  /** The creation date of the Region */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  defaultWarehouse: Maybe<Warehouse>;
  defaultWarehouseIsLocal: Scalars['Boolean']['output'];
  geometry: Maybe<GeometryData>;
  geometryColor: Scalars['Color']['output'];
  geometryStatus: GeometryStatusEnum;
  /** The Region's ID */
  id: Scalars['ID']['output'];
  locations: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  sameAsChildren: Array<Maybe<Region>>;
  sameAsParent: Maybe<Region>;
  status: GeoStatusEnum;
  timezone: Maybe<Scalars['String']['output']>;
  /** The update date of the Region */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userAddressCount: Scalars['Int']['output'];
};

/** Region filtering options */
export type RegionFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  countries?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  geometryStatus?: InputMaybe<Array<GeometryStatusEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rollupType?: InputMaybe<Array<RegionRollupStatus>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<GeoStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type RegionList = {
  __typename: 'RegionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Region records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Region>>;
  /** Number of Region records skipped */
  skip: Scalars['Int']['output'];
};

/** Region ordering options */
export type RegionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RegionOrderEnum>;
};

/** Fields available to order Properties */
export enum RegionOrderEnum {
  COUNTRIES = 'COUNTRIES',
  CREATED_AT = 'CREATED_AT',
  GEOMETRY_STATUS = 'GEOMETRY_STATUS',
  NAME = 'NAME',
  ROLLUP_TYPE = 'ROLLUP_TYPE',
  STATUS = 'STATUS',
  TIMEZONE = 'TIMEZONE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum RegionRollupStatus {
  /** Children */
  CHILDREN = 'CHILDREN',
  /** Non Children */
  NON_CHILDREN = 'NON_CHILDREN',
  /** Non Prents */
  NON_PARENTS = 'NON_PARENTS',
  /** Parents */
  PARENTS = 'PARENTS'
}

export type RegisterDeviceInput = {
  type: RegisteredDeviceTypeEnum;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export enum RegisteredDeviceTypeEnum {
  /** Android */
  ANDROID = 'ANDROID',
  /** Browser */
  BROWSER = 'BROWSER',
  /** iOS */
  IOS = 'IOS'
}

/** Represents a RegisteredUser in the system */
export type RegisteredUser = User & {
  __typename: 'RegisteredUser';
  activeCart: Cart;
  activeMenu: Maybe<UserMenu>;
  acuRef: Scalars['String']['output'];
  addresses: Maybe<Array<Maybe<UserAddress>>>;
  admin: Maybe<Scalars['Boolean']['output']>;
  cart: Maybe<Cart>;
  checkoutStatus: UserCheckoutStatus;
  communicationConsent: Maybe<Scalars['Boolean']['output']>;
  /** The creation date of the RegisteredUser */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  creditCards: Maybe<Array<Maybe<CreditCard>>>;
  csStatus: CustomerServiceStatusEnum;
  currentDevice: Maybe<Device>;
  email: Scalars['Email']['output'];
  emailIsVerified: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  friendReferences: Maybe<Array<Maybe<FriendReference>>>;
  friendReferrals: Maybe<Array<Maybe<FriendReference>>>;
  googleClientId: Maybe<Scalars['String']['output']>;
  hasAcceptedInvite: Maybe<Scalars['Boolean']['output']>;
  hasDefaultAddress: Maybe<Scalars['Boolean']['output']>;
  /** The RegisteredUser's ID */
  id: Scalars['ID']['output'];
  impersonatedBy: Maybe<RegisteredUser>;
  isInvited: Maybe<Scalars['Boolean']['output']>;
  isOnDemand: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  loyaltyTier: Maybe<LoyaltyTier>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['Phone']['output']>;
  phoneIsVerified: Maybe<Scalars['Boolean']['output']>;
  profileImage: Maybe<Image>;
  referralCode: Maybe<Scalars['String']['output']>;
  referralLink: Maybe<Scalars['String']['output']>;
  referrals: Maybe<Array<Maybe<Lead>>>;
  status: UserStatusEnum;
  subscriptions: Maybe<Array<Maybe<UserSubscription>>>;
  super: Maybe<Scalars['Boolean']['output']>;
  tests: Maybe<Array<Maybe<AbTest>>>;
  title: Maybe<TitleEnum>;
  /** The update date of the RegisteredUser */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userDevices: Maybe<Array<Maybe<UserDevice>>>;
  userHistory: Maybe<Array<Maybe<UserHistory>>>;
  userPartnerships: Maybe<Array<Maybe<UserPartnership>>>;
  userPreferences: Maybe<Array<Maybe<UserPreference>>>;
  userRoles: Maybe<Array<Maybe<UserRole>>>;
};

export type RemoveUserFromAbTestInput = {
  testId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Fields to replace a Location */
export type ReplaceLocationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  coordinates?: InputMaybe<CoordinateInput>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['ID']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Report in the system */
export type Report = {
  __typename: 'Report';
  /** The creation date of the Report */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Report's ID */
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reportArchive: Maybe<Archive>;
  reportStatus: ReportFileStatusEnum;
  reportStatusNotes: Maybe<Scalars['String']['output']>;
  /** The update date of the Report */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a ReportFileStatus in the system */
export type ReportFileStatus = {
  __typename: 'ReportFileStatus';
  archives: Maybe<Archive>;
  /** The creation date of the ReportFileStatus */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ReportFileStatus's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reportStatus: ReportFileStatusEnum;
  type: ReportFileStatusTypeEnum;
  /** The update date of the ReportFileStatus */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum ReportFileStatusEnum {
  /** Available */
  AVAILABLE = 'AVAILABLE',
  /** Error */
  ERROR = 'ERROR',
  /** Generating */
  GENERATING = 'GENERATING',
  /** Not Created */
  NOT_CREATED = 'NOT_CREATED',
  /** Uploading */
  UPLOADING = 'UPLOADING'
}

/** ReportFileStatus filtering options */
export type ReportFileStatusFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ReportFileStatusTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ReportFileStatusList = {
  __typename: 'ReportFileStatusList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ReportFileStatus records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ReportFileStatus>>;
  /** Number of ReportFileStatus records skipped */
  skip: Scalars['Int']['output'];
};

/** ReportFileStatus ordering options */
export type ReportFileStatusOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ReportFileStatusOrderEnum>;
};

/** Fields available to order Properties */
export enum ReportFileStatusOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type ReportFileStatusType = {
  /** Type */
  type?: InputMaybe<ReportFileStatusEnum>;
};

export enum ReportFileStatusTypeEnum {
  /** Active Expired Credit Cards */
  ACTIVE_EXPIRED_CREDIT_CARD_REPORT = 'ACTIVE_EXPIRED_CREDIT_CARD_REPORT',
  /** All Expired Credit Cards */
  ALL_EXPIRED_CREDIT_CARD_REPORT = 'ALL_EXPIRED_CREDIT_CARD_REPORT',
  /** All Stock Levels */
  STOCK_LEVELS_REPORT = 'STOCK_LEVELS_REPORT'
}

/** Report filtering options */
export type ReportFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ReportList = {
  __typename: 'ReportList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Report records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Report>>;
  /** Number of Report records skipped */
  skip: Scalars['Int']['output'];
};

/** Report ordering options */
export type ReportOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ReportOrderEnum>;
};

/** Fields available to order Properties */
export enum ReportOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ReportTypeEnum {
  COURIER_SPLIT_REPORT = 'COURIER_SPLIT_REPORT',
  CREDIT_CARD_ERROR_REPORT = 'CREDIT_CARD_ERROR_REPORT',
  INACTIVE_MEALS_REPORT = 'INACTIVE_MEALS_REPORT',
  INGREDIENT_LIST_REPORT = 'INGREDIENT_LIST_REPORT',
  INGREDIENT_PORTION_CART_QUANTITY_REPORT = 'INGREDIENT_PORTION_CART_QUANTITY_REPORT',
  INGREDIENT_PORTION_ORDER_QUANTITY_REPORT = 'INGREDIENT_PORTION_ORDER_QUANTITY_REPORT',
  INGREDIENT_QUANTITY_CART_REPORT = 'INGREDIENT_QUANTITY_CART_REPORT',
  INGREDIENT_QUANTITY_ORDER_REPORT = 'INGREDIENT_QUANTITY_ORDER_REPORT',
  INVALID_EXPIRED_CARDS_FLAGGED_MENUS = 'INVALID_EXPIRED_CARDS_FLAGGED_MENUS',
  MENU_ADDRESS_CHANGE_REPORT = 'MENU_ADDRESS_CHANGE_REPORT',
  MENU_INGREDIENT_BREAKDOWN_REPORT = 'MENU_INGREDIENT_BREAKDOWN_REPORT',
  MENU_ITEM_CLASS = 'MENU_ITEM_CLASS',
  NO_WAREHOUSE_ASSIGNED_REPORT = 'NO_WAREHOUSE_ASSIGNED_REPORT',
  ORDER_COUNT_AND_BILLING_STATUS_REPORTS = 'ORDER_COUNT_AND_BILLING_STATUS_REPORTS',
  STOCK_REPORT = 'STOCK_REPORT',
  STUCK_IN_PLACED_ORDERS_REPORT = 'STUCK_IN_PLACED_ORDERS_REPORT',
  SUBSCRIPTION_ADDON_CARTS_REPORT = 'SUBSCRIPTION_ADDON_CARTS_REPORT',
  SUBSCRIPTION_ADDON_ORDERS_REPORT = 'SUBSCRIPTION_ADDON_ORDERS_REPORT',
  SUBSCRIPTION_AS_ON_DEMAND_ITEMS_REPORT = 'SUBSCRIPTION_AS_ON_DEMAND_ITEMS_REPORT',
  UNSAVED_MENUS_REPORT = 'UNSAVED_MENUS_REPORT',
  ZERO_ITEM_ORDERS = 'ZERO_ITEM_ORDERS'
}

/** Represents a Role in the system */
export type Role = {
  __typename: 'Role';
  /** The creation date of the Role */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The Role's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Maybe<Array<Maybe<Permission>>>;
  /** The update date of the Role */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  users: Maybe<Array<Maybe<UserRole>>>;
};

/** Role filtering options */
export type RoleFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type RoleList = {
  __typename: 'RoleList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Role records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Role>>;
  /** Number of Role records skipped */
  skip: Scalars['Int']['output'];
};

/** Role ordering options */
export type RoleOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<RoleOrderEnum>;
};

/** Fields available to order Properties */
export enum RoleOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum SMSActivationTypeEnum {
  /** Manual */
  MANUAL = 'MANUAL',
  /** Scheduled */
  SCHEDULED = 'SCHEDULED'
}

export enum SMSStatusEnum {
  /** Waiting */
  WAITING = 'WAITING',
  /** Working */
  WORKING = 'WORKING'
}

export type SaveUserPreferenceByToken = {
  preferences: Scalars['JSONObject']['input'];
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** Represents a ScheduleCategoryExclusion in the system */
export type ScheduleCategoryExclusion = {
  __typename: 'ScheduleCategoryExclusion';
  /** The creation date of the ScheduleCategoryExclusion */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliverySchedule: Maybe<DeliverySchedule>;
  deliveryScheduleId: Scalars['ID']['output'];
  /** The ScheduleCategoryExclusion's ID */
  id: Scalars['ID']['output'];
  productCategoryId: Scalars['ID']['output'];
  /** The update date of the ScheduleCategoryExclusion */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ScheduleCategoryExclusion filtering options */
export type ScheduleCategoryExclusionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ScheduleCategoryExclusionList = {
  __typename: 'ScheduleCategoryExclusionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ScheduleCategoryExclusion records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ScheduleCategoryExclusion>>;
  /** Number of ScheduleCategoryExclusion records skipped */
  skip: Scalars['Int']['output'];
};

/** ScheduleCategoryExclusion ordering options */
export type ScheduleCategoryExclusionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ScheduleCategoryExclusionOrderEnum>;
};

/** Fields available to order Properties */
export enum ScheduleCategoryExclusionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a ScheduleCategoryInclusion in the system */
export type ScheduleCategoryInclusion = {
  __typename: 'ScheduleCategoryInclusion';
  /** The creation date of the ScheduleCategoryInclusion */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliverySchedule: Maybe<DeliverySchedule>;
  deliveryScheduleId: Scalars['ID']['output'];
  /** The ScheduleCategoryInclusion's ID */
  id: Scalars['ID']['output'];
  productCategoryId: Scalars['ID']['output'];
  /** The update date of the ScheduleCategoryInclusion */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ScheduleCategoryInclusion filtering options */
export type ScheduleCategoryInclusionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ScheduleCategoryInclusionList = {
  __typename: 'ScheduleCategoryInclusionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ScheduleCategoryInclusion records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ScheduleCategoryInclusion>>;
  /** Number of ScheduleCategoryInclusion records skipped */
  skip: Scalars['Int']['output'];
};

/** ScheduleCategoryInclusion ordering options */
export type ScheduleCategoryInclusionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ScheduleCategoryInclusionOrderEnum>;
};

/** Fields available to order Properties */
export enum ScheduleCategoryInclusionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum SellableProductRangeEnum {
  /** Baby Box */
  BABY_BOX = 'BABY_BOX',
  /** Craft Kids */
  CRAFT_KIDS = 'CRAFT_KIDS',
  /** Essentials */
  ESSENTIALS = 'ESSENTIALS',
  /** Frozen Meal */
  FROZEN_MEAL = 'FROZEN_MEAL',
  /** Market */
  MARKET_PRODUCT = 'MARKET_PRODUCT',
  /** Meal Kit Plan */
  MEAL_KIT_PLAN = 'MEAL_KIT_PLAN',
  /** Wine */
  WINE = 'WINE'
}

export enum ServerSideEventEnum {
  ADDTOCART = 'ADDTOCART',
  NEWSLETTERSIGNUP = 'NEWSLETTERSIGNUP',
  PURCHASE = 'PURCHASE',
  REMOVEFROMCART = 'REMOVEFROMCART'
}

/** Represents a ServingSuggestion in the system */
export type ServingSuggestion = {
  __typename: 'ServingSuggestion';
  /** The creation date of the ServingSuggestion */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The ServingSuggestion's ID */
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  index: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** The update date of the ServingSuggestion */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ServingSuggestion filtering options */
export type ServingSuggestionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  index?: InputMaybe<Scalars['String']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ServingSuggestionList = {
  __typename: 'ServingSuggestionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ServingSuggestion records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<ServingSuggestion>>;
  /** Number of ServingSuggestion records skipped */
  skip: Scalars['Int']['output'];
};

/** ServingSuggestion ordering options */
export type ServingSuggestionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ServingSuggestionOrderEnum>;
};

/** Fields available to order Properties */
export enum ServingSuggestionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  INDEX = 'INDEX',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Shipment in the system */
export type Shipment = {
  __typename: 'Shipment';
  addressString: Scalars['String']['output'];
  center: Coordinate;
  courierBucketTripNumber: Maybe<Scalars['String']['output']>;
  courierTripSquence: Maybe<Scalars['String']['output']>;
  /** The creation date of the Shipment */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryHistory: Array<ShipmentDeliveryHistory>;
  deliveryStatus: Maybe<ShipmentDeliveryStatusEnum>;
  driverId: Maybe<Scalars['String']['output']>;
  driverName: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  history: Array<ShipmentHistory>;
  /** The Shipment's ID */
  id: Scalars['ID']['output'];
  lotNumber: Maybe<Scalars['String']['output']>;
  order: Maybe<Order>;
  orderAddress: Maybe<OrderAddress>;
  shipmentItems: Array<ShipmentItem>;
  shippingConsignment: ShippingConsignment;
  status: ShipmentStatusEnum;
  trackingNumber: Maybe<Scalars['String']['output']>;
  /** The update date of the Shipment */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ShipmentDeliveryHistory = {
  __typename: 'ShipmentDeliveryHistory';
  data: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  status: ShipmentDeliveryStatusEnum;
  timestmap: Scalars['DateTime']['output'];
};

export enum ShipmentDeliveryStatusEnum {
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Delivered */
  DELIVERED = 'DELIVERED',
  /** Delivery Failed */
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  /** Enroute */
  ENROUTE = 'ENROUTE',
  /** Near Location */
  NEAR_LOCATION = 'NEAR_LOCATION',
  /** Pending Collection */
  PENDING_PICK_UP = 'PENDING_PICK_UP',
  /** Redelivery */
  REDILVERY = 'REDILVERY'
}

/** Shipment filtering options */
export type ShipmentFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  shippingConsignment?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<ShipmentStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ShipmentHistory = {
  __typename: 'ShipmentHistory';
  data: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  status: ShipmentStatusEnum;
  timestmap: Scalars['DateTime']['output'];
};

export enum ShipmentHistoryEventTypeEnum {
  /** Courier Status Change */
  COURIER_STATUS_CHANGE = 'COURIER_STATUS_CHANGE',
  /** Placement Status Change */
  PLACEMENT_STATUS_CHANGE = 'PLACEMENT_STATUS_CHANGE'
}

/** Represents a ShipmentItem in the system */
export type ShipmentItem = {
  __typename: 'ShipmentItem';
  box: Maybe<Box>;
  /** The creation date of the ShipmentItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ShipmentItem's ID */
  id: Scalars['ID']['output'];
  orderBox: OrderBox;
  shipment: Shipment;
  thirdPartyAssignedShipper: Maybe<Shipper>;
  thirdPartyTrackingCode: Maybe<Scalars['String']['output']>;
  trackingNumber: Maybe<Scalars['String']['output']>;
  tripIdentifier: Maybe<Scalars['String']['output']>;
  tripSequence: Maybe<Scalars['String']['output']>;
  /** The update date of the ShipmentItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  waybill: Maybe<Scalars['String']['output']>;
};

/** ShipmentItem filtering options */
export type ShipmentItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orders?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ShipmentItemList = {
  __typename: 'ShipmentItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ShipmentItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<ShipmentItem>;
  /** Number of ShipmentItem records skipped */
  skip: Scalars['Int']['output'];
};

/** ShipmentItem ordering options */
export type ShipmentItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShipmentItemOrderEnum>;
};

/** Fields available to order Properties */
export enum ShipmentItemOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

/** Properties list object */
export type ShipmentList = {
  __typename: 'ShipmentList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Shipment records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Shipment>;
  /** Number of Shipment records skipped */
  skip: Scalars['Int']['output'];
};

/** Shipment ordering options */
export type ShipmentOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShipmentOrderEnum>;
};

/** Fields available to order Properties */
export enum ShipmentOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export type ShipmentStatusCount = {
  __typename: 'ShipmentStatusCount';
  count: Scalars['Int']['output'];
  status: ShipmentStatusEnum;
};

export enum ShipmentStatusEnum {
  /** Awaiting Feedback */
  AWAITING_FEEDBACK = 'AWAITING_FEEDBACK',
  /** Failed */
  FAILED = 'FAILED',
  /** Pending */
  PENDING = 'PENDING',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Reassigned */
  REASSIGNED = 'REASSIGNED',
  /** Retrying */
  RETRYING = 'RETRYING',
  /** Split */
  SPLIT = 'SPLIT',
  /** Successful */
  SUCCESSFUL = 'SUCCESSFUL'
}

/** Represents a Shipper in the system */
export type Shipper = {
  __typename: 'Shipper';
  consignments: ShippingConsignmentList;
  courierType: CourierTypeEnum;
  /** The creation date of the Shipper */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Shipper's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  references: Array<ShipperWarehouseReference>;
  /** The update date of the Shipper */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouses: ShipperWarehouseList;
};


/** Represents a Shipper in the system */
export type ShipperconsignmentsArgs = {
  filters?: InputMaybe<ShippingConsignmentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShippingConsignmentOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


/** Represents a Shipper in the system */
export type ShipperwarehousesArgs = {
  filters?: InputMaybe<ShipperWarehouseFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipperWarehouseOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Represents a ShipperBox in the system */
export type ShipperBox = {
  __typename: 'ShipperBox';
  box: Box;
  /** The creation date of the ShipperBox */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  distanceConverstion: Scalars['Float']['output'];
  /** The ShipperBox's ID */
  id: Scalars['ID']['output'];
  massConverstion: Scalars['Float']['output'];
  shipper: Courier;
  shipperIdentifier: Scalars['String']['output'];
  /** The update date of the ShipperBox */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** ShipperBox filtering options */
export type ShipperBoxFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ShipperBoxList = {
  __typename: 'ShipperBoxList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ShipperBox records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<ShipperBox>;
  /** Number of ShipperBox records skipped */
  skip: Scalars['Int']['output'];
};

/** ShipperBox ordering options */
export type ShipperBoxOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShipperBoxOrderEnum>;
};

/** Fields available to order Properties */
export enum ShipperBoxOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

/** Shipper filtering options */
export type ShipperFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type ShipperList = {
  __typename: 'ShipperList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Shipper records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Shipper>;
  /** Number of Shipper records skipped */
  skip: Scalars['Int']['output'];
};

/** Shipper ordering options */
export type ShipperOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShipperOrderEnum>;
};

/** Fields available to order Properties */
export enum ShipperOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export type ShipperRulePotential = {
  __typename: 'ShipperRulePotential';
  count: Scalars['Int']['output'];
  dispatchDate: Scalars['Date']['output'];
};

/** Represents a ShipperWarehouse in the system */
export type ShipperWarehouse = {
  __typename: 'ShipperWarehouse';
  active: Scalars['Boolean']['output'];
  areas: Array<Area>;
  cities: Array<City>;
  /** The creation date of the ShipperWarehouse */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The ShipperWarehouse's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  onDemandPotential: Array<ShipperRulePotential>;
  onDemandPreference: Scalars['Int']['output'];
  onDemandShippingDays: Array<WeekDayEnum>;
  placeIds: Array<Scalars['String']['output']>;
  regions: Array<Region>;
  shipper: Shipper;
  shipperReference: Scalars['String']['output'];
  subscriptionPotential: Array<ShipperRulePotential>;
  subscriptionPreference: Scalars['Int']['output'];
  subscriptionShippingDays: Array<WeekDayEnum>;
  /** The update date of the ShipperWarehouse */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};

/** ShipperWarehouse filtering options */
export type ShipperWarehouseFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onDemandShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  shippers?: InputMaybe<Array<Scalars['ID']['input']>>;
  subscriptionShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type ShipperWarehouseList = {
  __typename: 'ShipperWarehouseList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ShipperWarehouse records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<ShipperWarehouse>;
  /** Number of ShipperWarehouse records skipped */
  skip: Scalars['Int']['output'];
};

/** ShipperWarehouse ordering options */
export type ShipperWarehouseOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShipperWarehouseOrderEnum>;
};

/** Fields available to order Properties */
export enum ShipperWarehouseOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** On Demand Priority */
  ON_DEMAND_PRIORITY = 'ON_DEMAND_PRIORITY',
  /** Subscription Priority */
  SUBSCRIPTION_PRIORITY = 'SUBSCRIPTION_PRIORITY',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export type ShipperWarehouseReference = {
  __typename: 'ShipperWarehouseReference';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export enum ShippingConsiderationEnum {
  /** Fragile */
  FRAGILE = 'FRAGILE'
}

/** Represents a ShippingConsignment in the system */
export type ShippingConsignment = {
  __typename: 'ShippingConsignment';
  availableOptions: Array<ShippingConsignmentOptions>;
  children: Array<ShippingConsignment>;
  courierBucketReference: Maybe<Scalars['String']['output']>;
  /** The creation date of the ShippingConsignment */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Scalars['Date']['output'];
  error: Maybe<Scalars['String']['output']>;
  estimatedCollectionTime: Scalars['DateTime']['output'];
  history: Array<ShippingConsignmentHistory>;
  /** The ShippingConsignment's ID */
  id: Scalars['ID']['output'];
  inclusiveSummary: ShippingConsignmentSummary;
  name: Scalars['String']['output'];
  orderType: OrderTypeEnum;
  parent: Maybe<ShippingConsignment>;
  shipments: ShipmentList;
  shipper: Shipper;
  status: ShippingConsignmentStatusEnum;
  summary: ShippingConsignmentSummary;
  /** The update date of the ShippingConsignment */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};


/** Represents a ShippingConsignment in the system */
export type ShippingConsignmentshipmentsArgs = {
  filters?: InputMaybe<ShipmentFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ShipmentOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** ShippingConsignment filtering options */
export type ShippingConsignmentFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shipperWarehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
  shippers?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShippingConsignmentHistory = {
  __typename: 'ShippingConsignmentHistory';
  data: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  status: ShippingConsignmentStatusEnum;
  timestmap: Scalars['Date']['output'];
};

/** Properties list object */
export type ShippingConsignmentList = {
  __typename: 'ShippingConsignmentList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of ShippingConsignment records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<ShippingConsignment>;
  /** Number of ShippingConsignment records skipped */
  skip: Scalars['Int']['output'];
};

export enum ShippingConsignmentOptions {
  /** Delete the consignemnt by removing all orders from the shipper and database */
  DELETE = 'DELETE',
  /** Reset the consignment by removing all orders from the shipper and the database and re-adding everything back */
  RESET = 'RESET',
  /** Re-integrate the consignemnt by leaving all the orders in the database as is and removing all orders from the shipper before adding them back */
  RE_INTEGRATE = 'RE_INTEGRATE',
  /** Fetch feedback from the shipper on this consignment */
  SYNC = 'SYNC',
  /** Validate re-try all failed shipments */
  VALIDATE = 'VALIDATE'
}

/** ShippingConsignment ordering options */
export type ShippingConsignmentOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ShippingConsignmentOrderEnum>;
};

/** Fields available to order Properties */
export enum ShippingConsignmentOrderEnum {
  /** Created At */
  CREATED_AT = 'CREATED_AT',
  /** Name */
  NAME = 'NAME',
  /** Updated At */
  UPDATED_AT = 'UPDATED_AT'
}

export enum ShippingConsignmentStatusEnum {
  /** Assigning Orders */
  ASSIGNING_ORDERS = 'ASSIGNING_ORDERS',
  /** Awaiting Courier Feedback */
  AWAITING_FEEDBACK = 'AWAITING_FEEDBACK',
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Created */
  CREATED = 'CREATED',
  /** Deleted */
  DELETED = 'DELETED',
  /** Deleting */
  DELETING = 'DELETING',
  /** Errored */
  ERRORED = 'ERRORED',
  /** Integrating */
  INTEGRATING = 'INTEGRATING',
  /** Orders Assigned */
  ORDERS_ASSIGNED = 'ORDERS_ASSIGNED',
  /** Pending Deletion */
  PENDING_DELETION = 'PENDING_DELETION',
  /** Pending Integration */
  PENDING_INTEGRATION = 'PENDING_INTEGRATION',
  /** Pending Order Assignment */
  PENDING_ORDER_ASSIGNMENT = 'PENDING_ORDER_ASSIGNMENT',
  /** Pending Population by Remote Data */
  PENDING_POPULATION_BY_REMOTE_DATA = 'PENDING_POPULATION_BY_REMOTE_DATA',
  /** Pending Re-integration */
  PENDING_REINTEGRATION = 'PENDING_REINTEGRATION',
  /** Pending Rest */
  PENDING_RESET = 'PENDING_RESET',
  /** Pending Sync */
  PENDING_SYNC = 'PENDING_SYNC',
  /** Pending Validation */
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  /** Populating from Remote Data */
  POPULATING_FROM_REMOTE_DATA = 'POPULATING_FROM_REMOTE_DATA',
  /** Processing Feedback */
  PROCESSING_FEEDBACK = 'PROCESSING_FEEDBACK',
  /** Removing Integration */
  REMOVING_INTEGRATION = 'REMOVING_INTEGRATION',
  /** Resetting */
  RESETTING = 'RESETTING',
  /** Splitting */
  SPLITTING = 'SPLITTING',
  /** Terminal */
  TERMINAL = 'TERMINAL'
}

export type ShippingConsignmentSummary = {
  __typename: 'ShippingConsignmentSummary';
  status: Array<ShipmentStatusCount>;
  totalShipmentItems: Scalars['Int']['output'];
  totalShipments: Scalars['Int']['output'];
};

export type SimulateBoxingBoxInput = {
  acceptableSubContainerIds: Array<Scalars['String']['input']>;
  capacity: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  restrictions: Array<InputMaybe<SimulateBoxingBoxRestrictionInput>>;
};

export type SimulateBoxingBoxRestrictionInput = {
  type?: InputMaybe<BoxRestrictionTypeEnum>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type SimulateBoxingContainerInput = {
  capacity: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  minSpaceUsed: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ranges: Array<ProductRangeEnum>;
  restrictions: Array<SimulateBoxingSubContainerRestrictionInput>;
};

export type SimulateBoxingInput = {
  boxes: Array<SimulateBoxingBoxInput>;
  containers: Array<SimulateBoxingContainerInput>;
  items: Array<SimulateBoxingItemInput>;
};

export type SimulateBoxingItemInput = {
  fragile: Scalars['Boolean']['input'];
  fragility?: InputMaybe<FragilityEnum>;
  id: Scalars['String']['input'];
  packingVolume: Scalars['Float']['input'];
  productType: ProductRangeEnum;
  quantity: Scalars['Int']['input'];
  temperature?: InputMaybe<TemperatureEnum>;
};

export type SimulateBoxingSubContainerRestrictionInput = {
  type?: InputMaybe<SubContainerRestrictionTypeEnum>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type SimulationBoxResponse = {
  __typename: 'SimulationBoxResponse';
  acceptedOrderSubContainerIds: Maybe<Array<Scalars['String']['output']>>;
  capacity: Scalars['Float']['output'];
  containedTypes: Array<Maybe<ProductRangeEnum>>;
  containersNeedLooseItems: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  items: Array<Maybe<SimulationItemResponse>>;
  name: Scalars['String']['output'];
  spaceUsed: Scalars['Float']['output'];
};

export type SimulationItemResponse = {
  __typename: 'SimulationItemResponse';
  id: Scalars['String']['output'];
  packingVolume: Scalars['Float']['output'];
  productType: ProductRangeEnum;
  quantity: Scalars['Int']['output'];
};

export type SimulationResponse = {
  __typename: 'SimulationResponse';
  boxes: Array<Maybe<SimulationBoxResponse>>;
  steps: Array<Maybe<Scalars['String']['output']>>;
};

export type SmsBatchStatus = {
  __typename: 'SmsBatchStatus';
  errors: Scalars['Int']['output'];
  of: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
};

/** Represents a SmsTemplate in the system */
export type SmsTemplate = {
  __typename: 'SmsTemplate';
  activationType: SMSActivationTypeEnum;
  batchStatus: SmsBatchStatus;
  /** The creation date of the SmsTemplate */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  group: UserGroupsEnum;
  /** The SmsTemplate's ID */
  id: Scalars['ID']['output'];
  lastSendDate: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scheduledDay: Maybe<WeekDayEnum>;
  scheduledTime: Maybe<Scalars['Time']['output']>;
  status: SMSStatusEnum;
  /** The update date of the SmsTemplate */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** SmsTemplate filtering options */
export type SmsTemplateFilters = {
  activationTypes?: InputMaybe<Array<SMSActivationTypeEnum>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<UserGroupsEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledDays?: InputMaybe<Array<WeekDayEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<SMSStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SmsTemplateList = {
  __typename: 'SmsTemplateList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of SmsTemplate records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<SmsTemplate>>;
  /** Number of SmsTemplate records skipped */
  skip: Scalars['Int']['output'];
};

/** SmsTemplate ordering options */
export type SmsTemplateOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SmsTemplateOrderEnum>;
};

/** Fields available to order Properties */
export enum SmsTemplateOrderEnum {
  ACTIVATION_TYPE = 'ACTIVATION_TYPE',
  CREATED_AT = 'CREATED_AT',
  GROUP = 'GROUP',
  LAST_SEND_DATE = 'LAST_SEND_DATE',
  NAME = 'NAME',
  SCHEDULED_DAY = 'SCHEDULED_DAY',
  SCHEDULED_TIME = 'SCHEDULED_TIME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type SocialLoginInput = {
  accessToken: Scalars['String']['input'];
  expiry: Scalars['DateTime']['input'];
  platform: SocialPlatformEnum;
  userId: Scalars['String']['input'];
};

/** Social Platforms */
export enum SocialPlatformEnum {
  /** Facebook */
  FACEBOOK = 'FACEBOOK',
  /** Google */
  GOOGLE = 'GOOGLE',
  /** Instagram */
  INSTAGRAM = 'INSTAGRAM'
}

export type SocialSignUpInput = {
  accessToken: Scalars['String']['input'];
  communicationConsent: Scalars['Boolean']['input'];
  expiry: Scalars['DateTime']['input'];
  platform: SocialPlatformEnum;
  userId: Scalars['String']['input'];
};

export enum StockChangeTypeEnum {
  /** Decrease */
  DECREASE = 'DECREASE',
  /** Increase */
  INCREASE = 'INCREASE'
}

export enum StockDecreaseTypeEnum {
  /** Correction */
  CORRECTION = 'CORRECTION',
  /** Damage */
  DAMAGE = 'DAMAGE',
  /** Dispatch */
  DISPATCH = 'DISPATCH',
  /** Non Re-Usable Return */
  NON_REUSABLE_RETURN = 'NON_REUSABLE_RETURN',
  /** Theft */
  THEFT = 'THEFT'
}

export enum StockIncreaseTypeEnum {
  /** Correction */
  CORRECTION = 'CORRECTION',
  /** Purchase */
  PURCHASE = 'PURCHASE',
  /** Return */
  RETURN = 'RETURN'
}

export type StockReportFile = {
  __typename: 'StockReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  status: ReportFileStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type StockSystemChange = SystemChange & {
  __typename: 'StockSystemChange';
  id: Scalars['ID']['output'];
  product: Product;
  status: ProductStockStatusEnum;
  warehouse: Warehouse;
};

export type StuckOrdersReportFile = {
  __typename: 'StuckOrdersReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Represents a SubContainer in the system */
export type SubContainer = {
  __typename: 'SubContainer';
  acceptingBoxes: Maybe<Array<BoxSubContainer>>;
  /** The creation date of the SubContainer */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The SubContainer's ID */
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  maxVolume: Scalars['Float']['output'];
  minVolume: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  orderSubContainers: Maybe<Array<OrderSubContainer>>;
  productTypes: Scalars['JSON']['output'];
  restrictions: Maybe<Array<SubContainerRestriction>>;
  sku: Maybe<Scalars['String']['output']>;
  storageArea: SubContainerStorageAreaStatusEnum;
  /** The update date of the SubContainer */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** SubContainer filtering options */
export type SubContainerFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SubContainerList = {
  __typename: 'SubContainerList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of SubContainer records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<SubContainer>>;
  /** Number of SubContainer records skipped */
  skip: Scalars['Int']['output'];
};

/** SubContainer ordering options */
export type SubContainerOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SubContainerOrderEnum>;
};

/** Fields available to order Properties */
export enum SubContainerOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a SubContainerOrderItem in the system */
export type SubContainerOrderItem = {
  __typename: 'SubContainerOrderItem';
  /** The creation date of the SubContainerOrderItem */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The SubContainerOrderItem's ID */
  id: Scalars['ID']['output'];
  orderItem: Maybe<OrderItem>;
  orderSubContainer: Maybe<OrderSubContainer>;
  quantity: Scalars['Float']['output'];
  /** The update date of the SubContainerOrderItem */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** SubContainerOrderItem filtering options */
export type SubContainerOrderItemFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SubContainerOrderItemList = {
  __typename: 'SubContainerOrderItemList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of SubContainerOrderItem records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<SubContainerOrderItem>>;
  /** Number of SubContainerOrderItem records skipped */
  skip: Scalars['Int']['output'];
};

/** SubContainerOrderItem ordering options */
export type SubContainerOrderItemOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SubContainerOrderItemOrderEnum>;
};

/** Fields available to order Properties */
export enum SubContainerOrderItemOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a SubContainerRestriction in the system */
export type SubContainerRestriction = {
  __typename: 'SubContainerRestriction';
  /** The creation date of the SubContainerRestriction */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The SubContainerRestriction's ID */
  id: Scalars['ID']['output'];
  subContainer: SubContainer;
  type: SubContainerRestrictionTypeEnum;
  /** The update date of the SubContainerRestriction */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['JSON']['output'];
};

/** SubContainerRestriction filtering options */
export type SubContainerRestrictionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subContainerId?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SubContainerRestrictionList = {
  __typename: 'SubContainerRestrictionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of SubContainerRestriction records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<SubContainerRestriction>>;
  /** Number of SubContainerRestriction records skipped */
  skip: Scalars['Int']['output'];
};

/** SubContainerRestriction ordering options */
export type SubContainerRestrictionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SubContainerRestrictionOrderEnum>;
};

/** Fields available to order Properties */
export enum SubContainerRestrictionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum SubContainerRestrictionTypeEnum {
  /** Shipping Consideration */
  SHIPPING_CONSIDERATIONS = 'SHIPPING_CONSIDERATIONS',
  /** Temperature */
  TEMP = 'TEMP'
}

export enum SubContainerStorageAreaStatusEnum {
  /** Ambient */
  AMBIENT = 'AMBIENT',
  /** Chilled */
  CHILLED = 'CHILLED',
  /** Frozen */
  FROZEN = 'FROZEN',
  /** Other */
  OTHER = 'OTHER',
  /** Wine */
  WINE = 'WINE'
}

export type SubNavItem = {
  __typename: 'SubNavItem';
  active: Scalars['Boolean']['output'];
  exactPath: Scalars['Boolean']['output'];
  href: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Subscription = {
  __typename: 'Subscription';
  backgroundTask: BackgroundTask;
  /** Subscribe to menu changes */
  menuChange: Menu;
  /** Subscribe to menu recipe card changes */
  menuRecipeCardChange: Menu;
  paymentChanges: PayOrderOutput;
  /** Subscribe to menu recipe card changes */
  recipeCardChange: MealKit;
  /** Subscribe to system changes */
  systemChanges: SystemChange;
  testSockets: Scalars['String']['output'];
};


export type SubscriptionbackgroundTaskArgs = {
  types: Array<BackgroundTaskInput>;
};


export type SubscriptionmenuChangeArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionmenuRecipeCardChangeArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionpaymentChangesArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionrecipeCardChangeArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionsystemChangesArgs = {
  types: Array<SystemChangeTypeEnum>;
};


export type SubscriptiontestSocketsArgs = {
  id: Scalars['String']['input'];
};

export type SubscriptionAddonCartsReportFile = {
  __typename: 'SubscriptionAddonCartsReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type SubscriptionAddonOrdersReportFile = {
  __typename: 'SubscriptionAddonOrdersReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type SubscriptionAsOnDemandReportFile = {
  __typename: 'SubscriptionAsOnDemandReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Represents a SubscriptionPriceHistory in the system */
export type SubscriptionPriceHistory = {
  __typename: 'SubscriptionPriceHistory';
  /** The creation date of the SubscriptionPriceHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The SubscriptionPriceHistory's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: NumberOfPortionsEnum;
  parentProduct: MealKitPlan;
  price: Scalars['Float']['output'];
  taxReceiptEvent: TaxReceiptEvent;
  /** The update date of the SubscriptionPriceHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** SubscriptionPriceHistory filtering options */
export type SubscriptionPriceHistoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  meals?: InputMaybe<Array<Scalars['Int']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portions?: InputMaybe<Array<NumberOfPortionsEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SubscriptionPriceHistoryList = {
  __typename: 'SubscriptionPriceHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of SubscriptionPriceHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<SubscriptionPriceHistory>>;
  /** Number of SubscriptionPriceHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** SubscriptionPriceHistory ordering options */
export type SubscriptionPriceHistoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SubscriptionPriceHistoryOrderEnum>;
};

/** Fields available to order Properties */
export enum SubscriptionPriceHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  NUMBER_OF_MEALS = 'NUMBER_OF_MEALS',
  NUMBER_OF_PORTIONS = 'NUMBER_OF_PORTIONS',
  PRICE = 'PRICE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a Supplier in the system */
export type Supplier = {
  __typename: 'Supplier';
  bannerImage: Maybe<Image>;
  /** The creation date of the Supplier */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  csrFeatured: Scalars['Boolean']['output'];
  description: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  gallery: Maybe<Array<Maybe<Image>>>;
  /** The Supplier's ID */
  id: Scalars['ID']['output'];
  location: Maybe<Scalars['String']['output']>;
  logo: Maybe<Image>;
  mobileNameColor: Maybe<Scalars['String']['output']>;
  mobileShortDescriptionColor: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nameColor: Maybe<Scalars['String']['output']>;
  productOfferings: Maybe<ProductOfferingsEnum>;
  shortDescription: Maybe<Scalars['String']['output']>;
  shortDescriptionColor: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  supplierSeriesFeatured: Scalars['Boolean']['output'];
  /** The update date of the Supplier */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Supplier filtering options */
export type SupplierFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  csrFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productOfferings?: InputMaybe<Array<InputMaybe<ProductOfferingsEnum>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  supplierSeriesFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type SupplierList = {
  __typename: 'SupplierList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Supplier records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Supplier>>;
  /** Number of Supplier records skipped */
  skip: Scalars['Int']['output'];
};

/** Supplier ordering options */
export type SupplierOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<SupplierOrderEnum>;
};

/** Fields available to order Properties */
export enum SupplierOrderEnum {
  CREATED_AT = 'CREATED_AT',
  CSR_FEATURED = 'CSR_FEATURED',
  LOCATION = 'LOCATION',
  NAME = 'NAME',
  PRODUCT_OFFERINGS = 'PRODUCT_OFFERINGS',
  SUPPLIER_SERIES_FEATURED = 'SUPPLIER_SERIES_FEATURED',
  UPDATED_AT = 'UPDATED_AT'
}

export type SystemChange = {
  id: Scalars['ID']['output'];
};

export enum SystemChangeTypeEnum {
  /** Menu */
  MENU = 'MENU',
  /** Stock */
  STOCK = 'STOCK'
}

export enum TaxCategoryTypeEnum {
  /** Exempt */
  EXEMPT = 'EXEMPT',
  /** Taxable */
  TAXABLE = 'TAXABLE'
}

/** Represents a TaxClass in the system */
export type TaxClass = {
  __typename: 'TaxClass';
  acumaticaReference: Scalars['String']['output'];
  /** The creation date of the TaxClass */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The TaxClass's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  /** The update date of the TaxClass */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** TaxClass filtering options */
export type TaxClassFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type TaxClassList = {
  __typename: 'TaxClassList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of TaxClass records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<TaxClass>>;
  /** Number of TaxClass records skipped */
  skip: Scalars['Int']['output'];
};

/** TaxClass ordering options */
export type TaxClassOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<TaxClassOrderEnum>;
};

/** Fields available to order Properties */
export enum TaxClassOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a TaxReceiptEvent in the system */
export type TaxReceiptEvent = {
  __typename: 'TaxReceiptEvent';
  /** The creation date of the TaxReceiptEvent */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The TaxReceiptEvent's ID */
  id: Scalars['ID']['output'];
  ingredientCostFile: Maybe<Archive>;
  ingredientCostStatus: FileGenerationStatus;
  isActive: Maybe<Scalars['Boolean']['output']>;
  menu: Maybe<Menu>;
  name: Maybe<Scalars['String']['output']>;
  note: Maybe<Scalars['String']['output']>;
  orderReceipts: Maybe<Array<OrderTaxReceipt>>;
  productSnapshots: Maybe<Array<ProductWarhouseCostHistory>>;
  receiptsFile: Maybe<Archive>;
  receiptsStatus: FileGenerationStatus;
  status: TaxReceiptEventStatusEnum;
  subscriptionSnapshots: Maybe<Array<SubscriptionPriceHistory>>;
  summaryFile: Maybe<Archive>;
  summaryStatus: FileGenerationStatus;
  taxCategoryFile: Maybe<Archive>;
  taxCategoryStatus: FileGenerationStatus;
  trigger: TaxReceiptTiggerEnum;
  triggeredBy: Maybe<User>;
  /** The update date of the TaxReceiptEvent */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** TaxReceiptEvent filtering options */
export type TaxReceiptEventFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<TaxReceiptEventStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type TaxReceiptEventList = {
  __typename: 'TaxReceiptEventList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of TaxReceiptEvent records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<TaxReceiptEvent>>;
  /** Number of TaxReceiptEvent records skipped */
  skip: Scalars['Int']['output'];
};

/** TaxReceiptEvent ordering options */
export type TaxReceiptEventOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<TaxReceiptEventOrderEnum>;
};

/** Fields available to order Properties */
export enum TaxReceiptEventOrderEnum {
  BILLING_DATE = 'BILLING_DATE',
  CREATED_AT = 'CREATED_AT',
  IS_ACTIVE = 'IS_ACTIVE',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Tax Receipt Event Status */
export enum TaxReceiptEventStatusEnum {
  /** Archived */
  ARCHIVED = 'ARCHIVED',
  /** Confirmed */
  CONFIRMED = 'CONFIRMED',
  /** Generated */
  GENERATED = 'GENERATED',
  /** Loading Child Products */
  LOADING_CHILD_PRODUCTS = 'LOADING_CHILD_PRODUCTS',
  /** Loading Orders */
  LOADING_ORDERS = 'LOADING_ORDERS',
  /** Loading Parent Products */
  LOADING_PARENT_PRODUCTS = 'LOADING_PARENT_PRODUCTS',
  /** Loading Product Types */
  LOADING_PRODUCT_TYPES = 'LOADING_PRODUCT_TYPES',
  /** New */
  NEW = 'NEW',
  /** Pending Order Reciept Generation */
  PENDING_ORDER_RECIEPT_GENERATION = 'PENDING_ORDER_RECIEPT_GENERATION',
  /** Taking Product Snapshot */
  TAKING_PRODUCT_SNAPSHOT = 'TAKING_PRODUCT_SNAPSHOT',
  /** Taking Subscription Snapshot */
  TAKING_SUBSCRIPTION_SNAPSHOT = 'TAKING_SUBSCRIPTION_SNAPSHOT'
}

/** Depictes the type of entity that started the event */
export enum TaxReceiptTiggerEnum {
  /** Admin */
  ADMIN = 'ADMIN',
  /** System */
  SYSTEM = 'SYSTEM'
}

export enum TechIssuesTagEnum {
  /** Address  (Issues) */
  ADDRESS_ISSUES = 'ADDRESS_ISSUES',
  /** Billing (Issues) */
  BILLING_ISSUES = 'BILLING_ISSUES',
  /** Checkout (Issues) */
  CHECKOUT_ISSUES = 'CHECKOUT_ISSUES',
  /** Login (Issues) */
  LOGIN_ISSUES = 'LOGIN_ISSUES',
  /** Order (Issues) */
  ORDER_ISSUES = 'ORDER_ISSUES',
  /** Payment (Issues) */
  PAYMENT_ISSUES = 'PAYMENT_ISSUES',
  /** Placed Status (Issues) */
  PLACED_STATUS_ISSUES = 'PLACED_STATUS_ISSUES',
  /** Sign up (Issues) */
  SIGN_UP_ISSUES = 'SIGN_UP_ISSUES',
  /** Website (Issues) */
  WEBSITE_ISSUES = 'WEBSITE_ISSUES'
}

/** Represents Storage Temperature */
export enum TemperatureEnum {
  /** Ambient */
  AMBIENT = 'AMBIENT',
  /** Cold */
  COLD = 'COLD',
  /** Frozen */
  FROZEN = 'FROZEN',
  /** Occasion */
  OCCASION = 'OCCASION'
}

export enum TestDurationEnum {
  FIVE_MINUTES = 'FIVE_MINUTES',
  HALF_HOUR = 'HALF_HOUR',
  HOUR = 'HOUR',
  IMMEDIATE = 'IMMEDIATE',
  QUARTER_HOUR = 'QUARTER_HOUR'
}

export enum TextAlignEnum {
  /** Center */
  CENTER = 'CENTER',
  /** Left */
  LEFT = 'LEFT',
  /** Right */
  RIGHT = 'RIGHT'
}

/** A Users Title */
export enum TitleEnum {
  /** Mr */
  MR = 'MR',
  /** Mrs */
  MRS = 'MRS',
  /** Ms */
  MS = 'MS'
}

export type TrackingData = {
  admarulaUTMTracker: Maybe<Scalars['String']['output']>;
  facebookBrowserId: Maybe<Scalars['String']['output']>;
  facebookClickId: Maybe<Scalars['String']['output']>;
  googleClickId: Maybe<Scalars['String']['output']>;
  googleSessionId: Maybe<Scalars['Int']['output']>;
  instagramClickId: Maybe<Scalars['String']['output']>;
  referrerUrl: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
  tikTokClickId: Maybe<Scalars['String']['output']>;
};

/** Represents a Transaction in the system */
export type Transaction = {
  /** The creation date of the Model */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The Credit Card associated to the transaction */
  creditCard: Maybe<CreditCard>;
  /** The description of the transaction */
  description: Scalars['String']['output'];
  /** Due amount */
  dueAmount: Scalars['Float']['output'];
  /** The Transaction's ID */
  id: Scalars['ID']['output'];
  /** Payment method peach_payments/credit/voucher etc etc */
  method: TransactionMethodEnum;
  /** The Order associated to the transaction */
  order: Maybe<Order>;
  /** Paid amount */
  paidAmount: Scalars['Float']['output'];
  /** The status of the transaction */
  status: TransactionStatusEnum;
  /** The status message */
  statusMessage: Scalars['String']['output'];
  /** Additional Transaction Data */
  transactionData: Maybe<Scalars['JSON']['output']>;
  /** Transaction ID (peach_id most likely) */
  transactionId: Maybe<Scalars['String']['output']>;
  /** The update date of the Model */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** The User associated to the transaction */
  user: User;
};

/** Transaction filtering options */
export type TransactionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
  transactionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type TransactionList = {
  __typename: 'TransactionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Transaction records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Transaction>>;
  /** Number of Transaction records skipped */
  skip: Scalars['Int']['output'];
};

export enum TransactionMethodEnum {
  /** Credit Card */
  CREDIT_CARD = 'CREDIT_CARD',
  /** Electronic Funds Transfer */
  EFT = 'EFT',
  /** User Points */
  USER_POINTS = 'USER_POINTS'
}

/** Transaction ordering options */
export type TransactionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<TransactionOrderEnum>;
};

/** Fields available to order Properties */
export enum TransactionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a TransactionReport in the system */
export type TransactionReport = {
  __typename: 'TransactionReport';
  /** The creation date of the TransactionReport */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['Date']['output'];
  /** The TransactionReport's ID */
  id: Scalars['ID']['output'];
  startDate: Scalars['Date']['output'];
  summaryFile: Maybe<Archive>;
  summaryStatus: FileGenerationStatus;
  /** The update date of the TransactionReport */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** TransactionReport filtering options */
export type TransactionReportFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type TransactionReportList = {
  __typename: 'TransactionReportList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of TransactionReport records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<TransactionReport>>;
  /** Number of TransactionReport records skipped */
  skip: Scalars['Int']['output'];
};

/** TransactionReport ordering options */
export type TransactionReportOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<TransactionReportOrderEnum>;
};

/** Fields available to order Properties */
export enum TransactionReportOrderEnum {
  CREATED_AT = 'CREATED_AT',
  END_DATE = 'END_DATE',
  START_DATE = 'START_DATE',
  SUMMARY_STATUS = 'SUMMARY_STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum TransactionStatusEnum {
  /** Created */
  CREATED = 'CREATED',
  /** Failed */
  FAILED = 'FAILED',
  /** Internal Failure */
  INTERNAL_FAILURE = 'INTERNAL_FAILURE',
  /** Card Blacklisted */
  INVALIDATE_CARD = 'INVALIDATE_CARD',
  /** Pending */
  PENDING = 'PENDING',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Rejected */
  REJECTED = 'REJECTED',
  /** Requires Approval */
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  /** Success */
  SUCCESS = 'SUCCESS',
  /** Too Many Requests */
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS'
}

export enum UnitTypeEnum {
  /** Drained Gram */
  DG = 'DG',
  /** Gram */
  G = 'G',
  /** Kilogram */
  KG = 'KG',
  /** Litre */
  L = 'L',
  /** Milligram */
  MG = 'MG',
  /** Milliliter */
  ML = 'ML',
  /** Piece */
  PIECE = 'PIECE',
  /** Pinch */
  PINCH = 'PINCH',
  /** Portion */
  PORTION = 'PORTION',
  /** Table Spoon */
  TBSP = 'TBSP',
  /** Tea Spoon */
  TSP = 'TSP',
  /** Unit */
  UNIT = 'UNIT'
}

export type UnsavedMenusReportFile = {
  __typename: 'UnsavedMenusReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

/** Fields to update an existing AbTestBucket */
export type UpdateAbTestBucketInput = {
  abTest?: InputMaybe<Scalars['ID']['input']>;
  abTestFilter?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing AbTestBucketUser */
export type UpdateAbTestBucketUserInput = {
  abTestBucket?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing AbTestFilter */
export type UpdateAbTestFilterInput = {
  andOr?: InputMaybe<AbTestAndOrEnum>;
  clause?: InputMaybe<AbTestClauseEnum>;
  data?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterType?: InputMaybe<AbTestFilterTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing AbTest */
export type UpdateAbTestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<AbTestStatusEnum>;
};

/** Fields to update an existing AcumaticaDiscountNonStockSku */
export type UpdateAcumaticaDiscountNonStockSkuInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing AcumaticaProductTypeNonStockSku */
export type UpdateAcumaticaProductTypeNonStockSkuInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressIssueInput = {
  stub?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing Allergen */
export type UpdateAllergenInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Archive */
export type UpdateArchiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing BabyBoxCategory */
export type UpdateBabyBoxCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BabyBoxCategoryStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Frozen */
export type UpdateBabyBoxInput = {
  allergens?: InputMaybe<Array<InputMaybe<BabyBoxAllergenEnum>>>;
  babyBoxCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  babyBoxTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize?: InputMaybe<BabyBoxPortionSizeEnum>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productImages?: InputMaybe<Array<AddProductImageInput>>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shouldDisplay?: InputMaybe<BabyBoxTubShouldDisplayEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing BabyBoxTag */
export type UpdateBabyBoxTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  tagType: BabyBoxTagTypeEnum;
  title: Scalars['String']['input'];
};

/** Fields to update an existing BabyBoxTubDetail */
export type UpdateBabyBoxTubDetailInput = {
  allergens?: InputMaybe<Scalars['String']['input']>;
  babyBox?: InputMaybe<Scalars['ID']['input']>;
  carbs?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  energyInKilojoules?: InputMaybe<Scalars['String']['input']>;
  fat?: InputMaybe<Scalars['String']['input']>;
  fibre?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  ingredientList?: InputMaybe<Scalars['String']['input']>;
  protein?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  saturatedFat?: InputMaybe<Scalars['String']['input']>;
  sensoryPersonality?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sodium?: InputMaybe<Scalars['String']['input']>;
  sugars?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBabyBoxTubInput = {
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  attributes?: InputMaybe<Scalars['String']['input']>;
  babyBoxCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  babyBoxTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryDescription?: InputMaybe<Scalars['String']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  coverImageId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packageDetails?: InputMaybe<Scalars['String']['input']>;
  packageGoals?: InputMaybe<Scalars['String']['input']>;
  packageSize?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  recommendedServings?: InputMaybe<Scalars['String']['input']>;
  servingSuggestions?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageInstructions?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing BankBinNumber */
export type UpdateBankBinNumberInput = {
  bank?: InputMaybe<BankEnum>;
  binNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Barcode */
export type UpdateBarcodeInput = {
  baseUnit?: InputMaybe<UnitTypeEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  product?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
  type?: InputMaybe<BarcodeTypeEnum>;
};

/** Fields to update an existing Box */
export type UpdateBoxInput = {
  SKU?: InputMaybe<Scalars['String']['input']>;
  boxType?: InputMaybe<BoxTypeEnum>;
  capacity?: InputMaybe<Scalars['Float']['input']>;
  containersNeedLooseItems?: InputMaybe<Scalars['Boolean']['input']>;
  depthInMillimeters?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimatedFullMassInGrams?: InputMaybe<Scalars['Float']['input']>;
  hiegthInMillimeters?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  widthInMillimeters?: InputMaybe<Scalars['Float']['input']>;
};

/** Fields to update an existing BoxRestriction */
export type UpdateBoxRestrictionInput = {
  type?: InputMaybe<BoxRestrictionTypeEnum>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

/** Fields to update an existing BoxSubContainer */
export type UpdateBoxSubContainerInput = {
  box?: InputMaybe<Scalars['ID']['input']>;
  subContainer?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing Branch */
export type UpdateBranchInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Brand */
export type UpdateBrandInput = {
  about: Scalars['String']['input'];
  banner?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/** Fields to update an existing CategoryGroup */
export type UpdateCategoryGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Category */
export type UpdateCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Chef */
export type UpdateChefInput = {
  biography?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing City */
export type UpdateCityInput = {
  country?: InputMaybe<Scalars['ID']['input']>;
  deliveryDays?: InputMaybe<Array<InputMaybe<CityDeliveryDaysEnum>>>;
  deliveryType?: InputMaybe<CityDeliveryTypeEnum>;
  dontAssignChildren?: InputMaybe<Scalars['Boolean']['input']>;
  dontAssignParent?: InputMaybe<Scalars['Boolean']['input']>;
  geometry?: InputMaybe<GeometryInput>;
  geometryColor?: InputMaybe<Scalars['Color']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productRanges?: InputMaybe<Array<InputMaybe<ProductRangeEnum>>>;
  region?: InputMaybe<Scalars['ID']['input']>;
  sameAsParent?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GeoStatusEnum>;
  warehouse?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing CommunicationTemplate */
export type UpdateCommunicationTemplateInput = {
  activationType?: InputMaybe<CommunicationActivationTypeEnum>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<UserGroupsEnum>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledDay?: InputMaybe<WeekDayEnum>;
  scheduledTime?: InputMaybe<Scalars['Time']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing Country */
export type UpdateCountryInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<GeometryInput>;
  geometryColor?: InputMaybe<Scalars['Color']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GeoStatusEnum>;
};

/** Fields to update an existing CourierBox */
export type UpdateCourierBoxInput = {
  box?: InputMaybe<Scalars['ID']['input']>;
  courier?: InputMaybe<Scalars['ID']['input']>;
  courierIdentifier?: InputMaybe<Scalars['String']['input']>;
  distanceConverstion?: InputMaybe<Scalars['Float']['input']>;
  massConverstion?: InputMaybe<Scalars['Float']['input']>;
};

/** Fields to update an existing CourierDeliveryDay */
export type UpdateCourierDeliveryDayInput = {
  courier?: InputMaybe<Scalars['ID']['input']>;
  dayOfWeek?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing CourierDelivery */
export type UpdateCourierDeliveryInput = {
  courier?: InputMaybe<Scalars['ID']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedCollectionTime?: InputMaybe<Scalars['DateTime']['input']>;
  warehouse?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing Courier */
export type UpdateCourierInput = {
  appendParcelIndexToBarcode?: InputMaybe<Scalars['Boolean']['input']>;
  barcodeType: BarcodeType;
  batchSize?: InputMaybe<Scalars['Int']['input']>;
  delayedIntegrationFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  identity?: InputMaybe<CourierIdentityEnum>;
  maxVolume?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  volumeType?: InputMaybe<CourierVolumeType>;
};

/** Fields to update an existing CourierWarehouseCityDeliveryDay */
export type UpdateCourierWarehouseCityDeliveryDayInput = {
  courierDeliveryDay?: InputMaybe<Scalars['ID']['input']>;
  courierWarehouseCity?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing CourierWarehouseCity */
export type UpdateCourierWarehouseCityInput = {
  city?: InputMaybe<Scalars['ID']['input']>;
  courierWarehouse?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing CourierWarehouse */
export type UpdateCourierWarehouseInput = {
  courier?: InputMaybe<Scalars['ID']['input']>;
  courierReference?: InputMaybe<Scalars['String']['input']>;
  maxVolume?: InputMaybe<Scalars['Int']['input']>;
  minVolume?: InputMaybe<Scalars['Int']['input']>;
  volumeType?: InputMaybe<CourierVolumeType>;
  warehouse?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing CreditCard */
export type UpdateCreditCardInput = {
  cardNickname?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing DeletedUser */
export type UpdateDeletedUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing DeliveryItem */
export type UpdateDeliveryItemInput = {
  addressString?: InputMaybe<Scalars['String']['input']>;
  cart?: InputMaybe<Scalars['ID']['input']>;
  center?: InputMaybe<CoordinateInput>;
  courierDelivery?: InputMaybe<Scalars['ID']['input']>;
  courierStatus?: InputMaybe<CourierItemStatusEnum>;
  location?: InputMaybe<Scalars['String']['input']>;
  placementStatus?: InputMaybe<PlacementStatusEnum>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing DeliveryItemParcel */
export type UpdateDeliveryItemParcelInput = {
  box?: InputMaybe<Scalars['ID']['input']>;
  deliveryItem?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing DeliverySchedule */
export type UpdateDeliveryScheduleInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduleCron?: InputMaybe<Scalars['String']['input']>;
  targetDeliveryWeekDay?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing Device */
export type UpdateDeviceInput = {
  type?: InputMaybe<RegisteredDeviceTypeEnum>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing DiscountCampaign */
export type UpdateDiscountCampaignInput = {
  codePrefix?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['ID']['input']>;
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfCodes?: InputMaybe<Scalars['Int']['input']>;
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueToUser?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing DiscountCode */
export type UpdateDiscountCodeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing Discount */
export type UpdateDiscountInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  acuDiscountType?: InputMaybe<AcuDiscountTypeEnum>;
  applicationEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  banner?: InputMaybe<DiscountBannerInput>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  canStack?: InputMaybe<Scalars['Boolean']['input']>;
  canStackWith?: InputMaybe<Array<InputMaybe<CanStackWithInput>>>;
  cannotStackWith?: InputMaybe<Array<InputMaybe<CannotStackWithInput>>>;
  deliveryDays?: InputMaybe<Array<InputMaybe<WeekDayEnum>>>;
  discountType?: InputMaybe<DiscountTypeEnum>;
  dropOffDays?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<DiscountFrequencyEnum>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  isAppOnly?: InputMaybe<Scalars['Boolean']['input']>;
  maxFrequencyAmount?: InputMaybe<Scalars['Int']['input']>;
  maxOrderCount?: InputMaybe<Scalars['Int']['input']>;
  maximumAccountUsage?: InputMaybe<Scalars['Int']['input']>;
  maximumSystemUsage?: InputMaybe<Scalars['Int']['input']>;
  mealKitPortionSizes?: InputMaybe<Array<InputMaybe<PortionSizeEnum>>>;
  minOrderCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsDiscoveryHealthMeal?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfReapplications?: InputMaybe<Scalars['Int']['input']>;
  orderType?: InputMaybe<OrderTypeEnum>;
  productRanges?: InputMaybe<Array<InputMaybe<ProductRangeEnum>>>;
  redemptionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shouldAutomaticallyReApply?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subvalidationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  termsAndConditions?: InputMaybe<DiscountTermsAndConditionsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  validationApplication?: InputMaybe<DiscountApplicationTypeEnum>;
  validationId?: InputMaybe<Scalars['String']['input']>;
  validationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  validationType?: InputMaybe<DiscountValidationTypeEnum>;
  validationValue?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<DiscountValueInput>>;
};

/** Fields to update an existing DiscountTag */
export type UpdateDiscountTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagLevel?: InputMaybe<DiscountTagLevelEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Excel */
export type UpdateExcelInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<UploadFileInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing FileCategory */
export type UpdateFileCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFriendReferenceInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing FrozenCategory */
export type UpdateFrozenCategoryInput = {
  background?: InputMaybe<CategoryBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<FrozenCategoryStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing FrozenInstruction */
export type UpdateFrozenInstructionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFrozenMealDishInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  frozenTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  ingredients?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionalInfoInput>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  servingSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  status?: InputMaybe<ProductStatusEnum>;
  story?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Frozen */
export type UpdateFrozenMealInput = {
  allergens?: InputMaybe<Array<InputMaybe<FrozenMealAllergenEnum>>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionalInfoInput>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize?: InputMaybe<FrozenPortionSizeEnum>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing FrozenTag */
export type UpdateFrozenTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<FrozenTagTypeEnum>;
  title: Scalars['String']['input'];
};

/** Fields to update an existing Gift */
export type UpdateGiftInput = {
  alcoholic?: InputMaybe<GiftAlcoholStatusEnum>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  packingVolume: Scalars['Float']['input'];
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
};

/** Fields to update an existing GuestUser */
export type UpdateGuestUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Image */
export type UpdateImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  hasTransparency?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  staticId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Ingredient */
export type UpdateIngredientInput = {
  baseUnitType?: InputMaybe<UnitTypeEnum>;
  conversionFactor?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  coverImageId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTitle?: InputMaybe<Scalars['String']['input']>;
  ingredientType?: InputMaybe<IngredientTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  productionUnitType?: InputMaybe<UnitTypeEnum>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  taxCategory?: InputMaybe<TaxCategoryTypeEnum>;
};

/** Fields to update an existing Lead */
export type UpdateLeadInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  referredBy?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LeadStatusEnum>;
  unsubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing LeadUpload */
export type UpdateLeadUploadInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  duplicate?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  invalid?: InputMaybe<Scalars['Int']['input']>;
  leadUploadFile?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  successful?: InputMaybe<Scalars['Int']['input']>;
  triggered?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing Location */
export type UpdateLocationInput = {
  area?: InputMaybe<Scalars['ID']['input']>;
  areaId?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  city?: InputMaybe<Scalars['ID']['input']>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  hasBeenModified?: InputMaybe<Scalars['Boolean']['input']>;
  isIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['ID']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing LoyaltyTier */
export type UpdateLoyaltyTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderCount?: InputMaybe<Scalars['Int']['input']>;
  referrerPoints?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing ManualEmail */
export type UpdateManualEmailInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  emailType: ManualEmailEnum;
};

/** Fields to update an existing MarketProductBrand */
export type UpdateMarketProductBrandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MarketProductCategory */
export type UpdateMarketProductCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<MarketProductCategoryStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MarketProduct */
export type UpdateMarketProductInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  brand?: InputMaybe<Scalars['ID']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  isOccasionBox?: InputMaybe<Scalars['Boolean']['input']>;
  isWeekendBox?: InputMaybe<Scalars['Boolean']['input']>;
  marketProductCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketProductTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  packageIngredients?: InputMaybe<Array<Scalars['String']['input']>>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageInstructions?: InputMaybe<Scalars['String']['input']>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subCategory?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
  warning?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MarketProductSubCategory */
export type UpdateMarketProductSubCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketProductSubCategoryStatusEnum>;
  title: Scalars['String']['input'];
};

/** Fields to update an existing MarketProductTag */
export type UpdateMarketProductTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<MarketProductTagTypeEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MealKitCategory */
export type UpdateMealKitCategoryInput = {
  background?: InputMaybe<CategoryBackgroundEnum>;
  canSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  costClass?: InputMaybe<MealKitCostClass>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  maxMealCount?: InputMaybe<Scalars['Int']['input']>;
  minMealCount?: InputMaybe<Scalars['Int']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<MealKitCategoryStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MealKitDefaultPricing */
export type UpdateMealKitDefaultPricingInput = {
  price: Scalars['Float']['input'];
};

/** Fields to update an existing MealKitDish */
export type UpdateMealKitDishInput = {
  allergens?: InputMaybe<Array<Scalars['ID']['input']>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  chefsTips?: InputMaybe<Array<Scalars['String']['input']>>;
  cookWithin?: InputMaybe<Scalars['Int']['input']>;
  costClass?: InputMaybe<MealKitCostClass>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveryHealthyDiningItem?: InputMaybe<Scalars['Boolean']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromYourKitchen?: InputMaybe<Array<Scalars['String']['input']>>;
  isVegitarian?: InputMaybe<Scalars['Boolean']['input']>;
  mealKitCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionalInfoInput>;
  nutritionPerServing?: InputMaybe<NutritionalInfoInput>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  prepStation?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<ProductStatusEnum>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  upSellFrozenMealDishes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  upSellMarketProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
  upSellWines?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to update an existing MealKit */
export type UpdateMealKitInput = {
  allergens?: InputMaybe<Array<InputMaybe<AllergenEnum>>>;
  chef?: InputMaybe<Scalars['ID']['input']>;
  chefsTips?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cookWithin?: InputMaybe<Scalars['Int']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featuredSuppliers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromYourKitchen?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  handsOnTime?: InputMaybe<Scalars['Int']['input']>;
  ingredients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nutrition?: InputMaybe<NutritionalInfoInput>;
  overallTime?: InputMaybe<Scalars['Int']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  portionSize?: InputMaybe<PortionSizeEnum>;
  prepStation?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to update an existing MealKitInstruction */
export type UpdateMealKitInstructionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MealKitPlan */
export type UpdateMealKitPlanInput = {
  nothing?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing MealKitSubscriptionFee */
export type UpdateMealKitSubscriptionFeeInput = {
  price: Scalars['Int']['input'];
};

/** Fields to update an existing MealKitTag */
export type UpdateMealKitTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<MealKitTagTypeEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MenuCategory */
export type UpdateMenuCategoryInput = {
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  preference?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing MenuDishWine */
export type UpdateMenuDishWineInput = {
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Menu */
export type UpdateMenuInput = {
  billingDate: Scalars['DateTime']['input'];
  checkingDate: Scalars['DateTime']['input'];
  deliveryDates?: InputMaybe<Array<InputMaybe<DeliveryDateInput>>>;
  openingDate: Scalars['DateTime']['input'];
  published: Scalars['Boolean']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  weekIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing MenuWarehouse */
export type UpdateMenuWarehouseInput = {
  menu?: InputMaybe<Scalars['ID']['input']>;
  newSignUpOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  reactivationOrderLimit?: InputMaybe<Scalars['Int']['input']>;
  warehouse?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing MinimumOrderValue */
export type UpdateMinimumOrderValueInput = {
  areaType: CityDeliveryTypeEnum;
  deliveryFee?: InputMaybe<Scalars['String']['input']>;
  mov?: InputMaybe<Scalars['String']['input']>;
  orderType: OrderTypeEnum;
  productRange: ProductRangeEnum;
};

/** Fields to update an existing OrderAddress */
export type UpdateOrderAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  coordinates?: InputMaybe<CoordinateInput>;
  placeId?: InputMaybe<Scalars['ID']['input']>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderAddressTextInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  fullAddress: Scalars['String']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  mdsAddressId?: InputMaybe<Scalars['Int']['input']>;
  mdsContactId?: InputMaybe<Scalars['Int']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderBox */
export type UpdateOrderBoxInput = {
  box?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing OrderBoxItem */
export type UpdateOrderBoxItemInput = {
  orderBox?: InputMaybe<Scalars['ID']['input']>;
  orderItem?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

/** Fields to update an existing OrderHistory */
export type UpdateOrderHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Order */
export type UpdateOrderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderItem (we exclude some things on purpose) */
export type UpdateOrderItemInput = {
  /** Discount amount at purchase */
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Discount percent at purchase (this is for reporting) */
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  /** Price amount at purchase */
  priceAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity refunded */
  qtyRefunded?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity shipped */
  qtyShipped?: InputMaybe<Scalars['Float']['input']>;
  /** Tax amount at purchase */
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
};

/** Fields to update an existing OrderItemRating */
export type UpdateOrderItemRatingInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  orderItem?: InputMaybe<Scalars['ID']['input']>;
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOrderRatingInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['ID']['input']>;
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing OrderSubContainer */
export type UpdateOrderSubContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderTaxDiscount */
export type UpdateOrderTaxDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderTaxProduct */
export type UpdateOrderTaxProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderTaxProductTypeDiscount */
export type UpdateOrderTaxProductTypeDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderTaxProductType */
export type UpdateOrderTaxProductTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing OrderTaxReceipt */
export type UpdateOrderTaxReceiptInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PackingVolumeDefault */
export type UpdatePackingVolumeDefaultInput = {
  defaultPackingVolume: Scalars['Float']['input'];
  productRange: ProductRangeEnum;
  variant?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageBanner */
export type UpdatePageBannerInput = {
  backgroundColor?: InputMaybe<PageBannerBackgroundColorEnum>;
  bannerTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionColor?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  mobileDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  mobileImageId?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  mobileSubTitleColor?: InputMaybe<Scalars['String']['input']>;
  mobileTitleColor?: InputMaybe<Scalars['String']['input']>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageBannerSection?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleColor?: InputMaybe<Scalars['String']['input']>;
  titleColor?: InputMaybe<Scalars['String']['input']>;
  variation?: InputMaybe<PageBannerVariationEnum>;
};

/** Fields to update an existing PageBannerSection */
export type UpdatePageBannerSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  bannerHeight?: InputMaybe<Scalars['String']['input']>;
  bannerSectionHeight?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileBannerHeight?: InputMaybe<Scalars['String']['input']>;
  mobileBannerSectionHeight?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageCard */
export type UpdatePageCardInput = {
  cardTitle: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
};

/** Fields to update an existing PageCardSection */
export type UpdatePageCardSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  cardHeight?: InputMaybe<Scalars['String']['input']>;
  cardSectionHeight?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  mobileCardHeight?: InputMaybe<Scalars['String']['input']>;
  mobileCardSectionHeight?: InputMaybe<Scalars['String']['input']>;
  mobileShouldStack?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageDataSection */
export type UpdatePageDataSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  dataSectionFilterVariation?: InputMaybe<PageSectionDataFilterVariationEnum>;
  dataSectionVariation?: InputMaybe<PageSectionDataVariationEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  embeddedFormHeight?: InputMaybe<PageSectionFormFactorInput>;
  embeddedFormId?: InputMaybe<Scalars['String']['input']>;
  frozenCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  frozenTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketProductTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mealKitTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  viewAllUrl?: InputMaybe<Scalars['String']['input']>;
  wineCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  wineTags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Fields to update an existing PageDetail */
export type UpdatePageDetailInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageDetailSection: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Fields to update an existing PageDetailSection */
export type UpdatePageDetailSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageGridItem */
export type UpdatePageGridItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gridItemTitle: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  imageHeight?: InputMaybe<Scalars['String']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageWidth?: InputMaybe<Scalars['String']['input']>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  objectFit?: InputMaybe<ObjectFitEnum>;
  pageGridSection: Scalars['ID']['input'];
  titleColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageGridSection */
export type UpdatePageGridSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  columns: PageSectionFormFactorInput;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  gutterWidth?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  spacingType: PageGridSpacingEnum;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageMediaSection */
export type UpdatePageMediaSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageShadow?: InputMaybe<Scalars['Boolean']['input']>;
  imageSize?: InputMaybe<ImageSizeEnum>;
  imageVariant?: InputMaybe<ImageVariantEnum>;
  links?: InputMaybe<PageSectionLinksInput>;
  mobileObjectFit?: InputMaybe<ObjectFitEnum>;
  name: Scalars['String']['input'];
  objectFit?: InputMaybe<ObjectFitEnum>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  variation?: InputMaybe<PageSectionMediaVariationEnum>;
  variationBackground?: InputMaybe<PageSectionBackgroundEnum>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageModal */
export type UpdatePageModalInput = {
  actionLink?: InputMaybe<Scalars['String']['input']>;
  actionTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionColor?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  locationLink?: InputMaybe<Scalars['String']['input']>;
  mobileDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  mobileImageId?: InputMaybe<Scalars['String']['input']>;
  mobileSubTitleColor?: InputMaybe<Scalars['String']['input']>;
  mobileTitleColor?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleColor?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageNotificationGroup */
export type UpdatePageNotificationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageNotification */
export type UpdatePageNotificationInput = {
  additionalText?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  canClose?: InputMaybe<Scalars['Boolean']['input']>;
  countDownBackground?: InputMaybe<Scalars['String']['input']>;
  ctaBackground?: InputMaybe<ButtonColorEnum>;
  ctaText?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  isPopup?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  pageNotificationGroup?: InputMaybe<Scalars['ID']['input']>;
  shouldCountDownDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  shouldIconDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  textColor?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageSectionGroup */
export type UpdatePageSectionGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTab */
export type UpdatePageTabInput = {
  header?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTabItem */
export type UpdatePageTabItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tabItemTitle: Scalars['String']['input'];
};

/** Fields to update an existing PageTabSection */
export type UpdatePageTabSectionInput = {
  activeTab?: InputMaybe<Scalars['String']['input']>;
  background?: InputMaybe<PageSectionBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTextCard */
export type UpdatePageTextCardInput = {
  contentColumns?: InputMaybe<PageSectionFormFactorInput>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hideContentOverflow?: InputMaybe<Scalars['Boolean']['input']>;
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTextCardSection */
export type UpdatePageTextCardSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTextSection */
export type UpdatePageTextSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  contentColumns?: InputMaybe<PageSectionFormFactorInput>;
  contents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  hideContentOverflow?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<PageSectionLinksInput>;
  name: Scalars['String']['input'];
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  textAlign?: InputMaybe<TextAlignEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PageTileSection */
export type UpdatePageTileSectionInput = {
  background?: InputMaybe<PageSectionBackgroundEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<PageSectionLinkInput>;
  name: Scalars['String']['input'];
  selectorId?: InputMaybe<Scalars['String']['input']>;
  tileVariation?: InputMaybe<PageTileVariationEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Pdf */
export type UpdatePdfInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<UploadFileInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PreferenceCategory */
export type UpdatePreferenceCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Preference */
export type UpdatePreferenceInput = {
  dataType?: InputMaybe<DataTypeEnum>;
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing ProcessGroupCourier */
export type UpdateProcessGroupCourierInput = {
  courier?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  processGroup?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing ProcessGroup */
export type UpdateProcessGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderType?: InputMaybe<OrderTypeEnum>;
  squencePosition?: InputMaybe<Scalars['Int']['input']>;
};

/** Fields to update an existing ProductAvailabilityEmail */
export type UpdateProductAvailabilityEmailInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing ProductImage */
export type UpdateProductImageInput = {
  image?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['ID']['input']>;
  productGroup?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing ProductIngredient */
export type UpdateProductIngredientInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  displayTitle?: InputMaybe<Scalars['String']['input']>;
  displayUnit?: InputMaybe<Scalars['String']['input']>;
  ingredient?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  unitType?: InputMaybe<UnitTypeEnum>;
};

/** Fields to update an existing ProductWarehouse */
export type UpdateProductWarehouseInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  lowStockAlertThreshold?: InputMaybe<Scalars['Float']['input']>;
  orderType?: InputMaybe<ProductOrderTypeEnum>;
  procurementType?: InputMaybe<ProcurementTypeEnum>;
  stockType?: InputMaybe<ProductStockTypeEnum>;
  vendors?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Fields to update an existing ProductWarhouseCostHistory */
export type UpdateProductWarhouseCostHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing PublicHoliday */
export type UpdatePublicHolidayInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing RefundReason */
export type UpdateRefundReasonInput = {
  refund?: InputMaybe<Scalars['ID']['input']>;
  refundReportingReason?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing RefundReportingReason */
export type UpdateRefundReportingReasonInput = {
  defaultRefundAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Region */
export type UpdateRegionInput = {
  defaultWarehouse?: InputMaybe<Scalars['ID']['input']>;
  defaultWarehouseIsLocal?: InputMaybe<Scalars['Boolean']['input']>;
  geometry?: InputMaybe<GeometryInput>;
  geometryColor?: InputMaybe<Scalars['Color']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sameAsParent?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GeoStatusEnum>;
};

/** Fields to update an existing RegisteredUser */
export type UpdateRegisteredUserInput = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  communicationConsent?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<TitleEnum>;
};

/** Fields to update an existing Report */
export type UpdateReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Role */
export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Fields to update an existing ScheduleCategoryExclusion */
export type UpdateScheduleCategoryExclusionInput = {
  deliveryScheduleId: Scalars['ID']['input'];
  productCategoryId: Scalars['ID']['input'];
};

/** Fields to update an existing ScheduleCategoryInclusion */
export type UpdateScheduleCategoryInclusionInput = {
  deliveryScheduleId?: InputMaybe<Scalars['ID']['input']>;
  productCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing ServingSuggestion */
export type UpdateServingSuggestionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Shipment */
export type UpdateShipmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing ShipmentItem */
export type UpdateShipmentItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing ShipperBox */
export type UpdateShipperBoxInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Shipper */
export type UpdateShipperInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing ShipperWarehouse */
export type UpdateShipperWarehouseInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  areaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  onDemandPreference?: InputMaybe<Scalars['Int']['input']>;
  onDemandShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  placeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shipperId?: InputMaybe<Scalars['ID']['input']>;
  shipperReference?: InputMaybe<Scalars['String']['input']>;
  subscriptionPreference?: InputMaybe<Scalars['Int']['input']>;
  subscriptionShippingDays?: InputMaybe<Array<WeekDayEnum>>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing ShippingConsignment */
export type UpdateShippingConsignmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing SmsTemplate */
export type UpdateSmsTemplateInput = {
  activationType?: InputMaybe<SMSActivationTypeEnum>;
  group?: InputMaybe<UserGroupsEnum>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledDay?: InputMaybe<WeekDayEnum>;
  scheduledTime?: InputMaybe<Scalars['Time']['input']>;
};

/** Fields to update an existing SubContainer */
export type UpdateSubContainerInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxVolume?: InputMaybe<Scalars['Float']['input']>;
  minVolume?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productTypes?: InputMaybe<Scalars['JSON']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  storageArea?: InputMaybe<SubContainerStorageAreaStatusEnum>;
};

/** Fields to update an existing SubContainerOrderItem */
export type UpdateSubContainerOrderItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing SubContainerRestriction */
export type UpdateSubContainerRestrictionInput = {
  type?: InputMaybe<SubContainerRestrictionTypeEnum>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

/** Fields to update an existing SubscriptionPriceHistory */
export type UpdateSubscriptionPriceHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Supplier */
export type UpdateSupplierInput = {
  bannerImage?: InputMaybe<Scalars['ID']['input']>;
  bannerImageId?: InputMaybe<Scalars['String']['input']>;
  csrFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gallery?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  galleryIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  mobileNameColor?: InputMaybe<Scalars['String']['input']>;
  mobileShortDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameColor?: InputMaybe<Scalars['String']['input']>;
  productOfferings?: InputMaybe<ProductOfferingsEnum>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  shortDescriptionColor?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  supplierSeriesFeatured?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing TaxClass */
export type UpdateTaxClassInput = {
  acumaticaReference: Scalars['String']['input'];
  name: Scalars['String']['input'];
  percentage: Scalars['Float']['input'];
};

/** Fields to update an existing TaxReceiptEvent */
export type UpdateTaxReceiptEventInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing TransactionReport */
export type UpdateTransactionReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing UserAddress */
export type UpdateUserAddressInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  isSupported?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing UserCommunicationPlatformData */
export type UpdateUserCommunicationPlatformDataInput = {
  nothing: Scalars['ID']['input'];
};

/** Fields to update an existing UserDevice */
export type UpdateUserDeviceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing UserDiscount */
export type UpdateUserDiscountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing UserHistory */
export type UpdateUserHistoryInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']['input']>;
  admin?: InputMaybe<Scalars['ID']['input']>;
  event?: InputMaybe<UserHistoryEventEnum>;
  type?: InputMaybe<UserHistoryTypeEnum>;
};

/** Fields to update an existing UserMenu */
export type UpdateUserMenuInput = {
  creditCard?: InputMaybe<Scalars['ID']['input']>;
  deliveryAddress?: InputMaybe<Scalars['ID']['input']>;
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  deliveryOption?: InputMaybe<WeekDayEnum>;
  numberOfMeals?: InputMaybe<Scalars['Int']['input']>;
  numberOfPortions?: InputMaybe<NumberOfPortionsEnum>;
};

/** Fields to update an existing UserPartnership */
export type UpdateUserPartnershipInput = {
  memberId?: InputMaybe<Scalars['String']['input']>;
  memberToken?: InputMaybe<Scalars['String']['input']>;
  memberVerified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to add a all UserPreferencea in bulk */
export type UpdateUserPreferenceCategoryInput = {
  preferenceCategoryId: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
  userValue?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing UserPreference */
export type UpdateUserPreferenceInput = {
  preference: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
  userValue?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserRoleInput = {
  role: Scalars['ID']['input'];
  user: Scalars['ID']['input'];
};

/** Fields to update an existing UserSubscription */
export type UpdateUserSubscriptionInput = {
  alcoholicGifts?: InputMaybe<GiftAlcoholStatusEnum>;
  category?: InputMaybe<Scalars['ID']['input']>;
  deliveryOption?: InputMaybe<WeekDayEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfMeals?: InputMaybe<Scalars['Int']['input']>;
  numberOfPortions?: InputMaybe<NumberOfPortionsEnum>;
  pauseReasons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updateMenu?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing Vendor */
export type UpdateVendorInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Vineyard */
export type UpdateVineyardInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  banner?: InputMaybe<Scalars['ID']['input']>;
  bannerId?: InputMaybe<Scalars['String']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['ID']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  selectorId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing Warehouse */
export type UpdateWarehouseInput = {
  branch?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Fields to update an existing WarehouseLocation */
export type UpdateWarehouseLocationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing WarehouseProductRange */
export type UpdateWarehouseProductRangeInput = {
  leadDays?: InputMaybe<Scalars['Int']['input']>;
  onDemandDispatchDays?: InputMaybe<Array<WeekDayEnum>>;
  productStatus?: InputMaybe<WarehouseProductStatusEnum>;
  subscriptionDispatchDays?: InputMaybe<Array<WeekDayEnum>>;
};

/** Fields to update an existing WineCampaign */
export type UpdateWineCampaignInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vineyards?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Fields to update an existing WineCategory */
export type UpdateWineCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<WineCategoryStatusEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
  variety?: InputMaybe<WineVarietyEnum>;
};

/** Fields to update an existing Wine */
export type UpdateWineInput = {
  alcoholContent?: InputMaybe<Scalars['Float']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  coverImage?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Array<Scalars['String']['input']>>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  maker?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packingCode?: InputMaybe<Scalars['String']['input']>;
  packingVolume?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  shippingConsiderations?: InputMaybe<Array<ShippingConsiderationEnum>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProductStatusEnum>;
  storageTemp?: InputMaybe<ProductStorageTempEnum>;
  sugar?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  upSellText?: InputMaybe<Scalars['String']['input']>;
  vineyard?: InputMaybe<Scalars['ID']['input']>;
  vintage?: InputMaybe<Scalars['Int']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  wineCategory?: InputMaybe<Scalars['ID']['input']>;
};

/** Fields to update an existing WineMaker */
export type UpdateWineMakerInput = {
  biography?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['ID']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
};

/** Fields to update an existing WineTag */
export type UpdateWineTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayIndex?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  pageDataSection?: InputMaybe<Scalars['ID']['input']>;
  tagType?: InputMaybe<WineTagTypeEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFile = {
  __typename: 'UploadFile';
  destination: Scalars['String']['output'];
  encoding: Scalars['String']['output'];
  fieldname: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalname: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type UploadFileInput = {
  destination: Scalars['String']['input'];
  encoding: Scalars['String']['input'];
  fieldname: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
  originalname: Scalars['String']['input'];
  path: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

/** Represents a User in the system */
export type User = {
  activeCart: Cart;
  activeMenu: Maybe<UserMenu>;
  acuRef: Scalars['String']['output'];
  cart: Maybe<Cart>;
  checkoutStatus: UserCheckoutStatus;
  /** The creation date of the User */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  csStatus: CustomerServiceStatusEnum;
  currentDevice: Maybe<Device>;
  email: Scalars['Email']['output'];
  emailIsVerified: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  hasDefaultAddress: Maybe<Scalars['Boolean']['output']>;
  /** The User's ID */
  id: Scalars['ID']['output'];
  isOnDemand: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['Phone']['output']>;
  phoneIsVerified: Maybe<Scalars['Boolean']['output']>;
  profileImage: Maybe<Image>;
  status: UserStatusEnum;
  subscriptions: Maybe<Array<Maybe<UserSubscription>>>;
  tests: Maybe<Array<Maybe<AbTest>>>;
  title: Maybe<TitleEnum>;
  /** The update date of the User */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  userDevices: Maybe<Array<Maybe<UserDevice>>>;
  userRoles: Maybe<Array<Maybe<UserRole>>>;
};

/** Represents a UserAddress in the system */
export type UserAddress = {
  __typename: 'UserAddress';
  /** Is the address supported by our delivery areas */
  addressUnsupported: Maybe<Scalars['Boolean']['output']>;
  /** The reason for the address to not be supported */
  addressUnsupportedReason: AddressUnsupportedReasonEnum;
  /** Address Alias */
  alias: Scalars['String']['output'];
  /** Company Name */
  company: Maybe<Scalars['String']['output']>;
  /** The creation date of the UserAddress */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The UserAddress's ID */
  id: Scalars['ID']['output'];
  /** Instructions given by user */
  instructions: Maybe<Scalars['String']['output']>;
  /** Is the User's Default Address */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** If the user has an incomplete address */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Location link */
  location: Location;
  /** Postal Code */
  postalCode: Maybe<Scalars['String']['output']>;
  /** The update date of the UserAddress */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** User link */
  user: User;
};

export type UserAddressFilter = {
  id: Scalars['ID']['input'];
};

/** UserAddress filtering options */
export type UserAddressFilters = {
  activeSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  addressUnsupported?: InputMaybe<Scalars['Boolean']['input']>;
  addressUnsupportedReason?: InputMaybe<Array<AddressUnsupportedReasonEnum>>;
  areas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  buildingTypeEnum?: InputMaybe<BuildingTypeEnum>;
  cities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  countries?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  needsAttention?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  parentCities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentRegions?: InputMaybe<Array<Scalars['ID']['input']>>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<LocationStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  withoutCities?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Properties list object */
export type UserAddressList = {
  __typename: 'UserAddressList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserAddress records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserAddress>>;
  /** Number of UserAddress records skipped */
  skip: Scalars['Int']['output'];
};

/** UserAddress ordering options */
export type UserAddressOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserAddressOrderEnum>;
};

/** Fields available to order Properties */
export enum UserAddressOrderEnum {
  ADDRESS = 'ADDRESS',
  ADDRESS_UNSUPPORTED_REASON = 'ADDRESS_UNSUPPORTED_REASON',
  ALIAS = 'ALIAS',
  BUILDING_NAME = 'BUILDING_NAME',
  BUILDING_TYPE_ENUM = 'BUILDING_TYPE_ENUM',
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PARENT_CITIES = 'PARENT_CITIES',
  PARENT_REGIONS = 'PARENT_REGIONS',
  POSTAL_CODE = 'POSTAL_CODE',
  REGIONS = 'REGIONS',
  UNIT_NUMBER = 'UNIT_NUMBER',
  UPDATED_AT = 'UPDATED_AT'
}

export type UserCheckoutStatus = {
  __typename: 'UserCheckoutStatus';
  hasAcceptedCookWithInDays: Scalars['Boolean']['output'];
  hasAcceptedPriceDifference: Scalars['Boolean']['output'];
  hasAccount: Scalars['Boolean']['output'];
  hasActiveSubscription: Scalars['Boolean']['output'];
  hasConfiguredBox: Scalars['Boolean']['output'];
  hasOnDemandProducts: Scalars['Boolean']['output'];
  hasPausedSubscription: Scalars['Boolean']['output'];
  hasSelectedDeliveryDay: Scalars['Boolean']['output'];
  hasSelectedPlan: Scalars['Boolean']['output'];
  hasSelectedPortionSize: Scalars['Boolean']['output'];
  hasSetDeliveryAddress: Scalars['Boolean']['output'];
  hasSetDeliveryAddressDetails: Scalars['Boolean']['output'];
  hasSetPaymentMethod: Scalars['Boolean']['output'];
  hasValidSubscription: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  subscriptionIsSaved: Scalars['Boolean']['output'];
};

/** Represents a UserCommunicationPlatformData in the system */
export type UserCommunicationPlatformData = {
  __typename: 'UserCommunicationPlatformData';
  /** The creation date of the UserCommunicationPlatformData */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  device: Device;
  /** The UserCommunicationPlatformData's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  platformId: Maybe<Scalars['String']['output']>;
  platformType: Maybe<UserCommunicationPlatformEnum>;
  /** The update date of the UserCommunicationPlatformData */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

/** UserCommunicationPlatformData filtering options */
export type UserCommunicationPlatformDataFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type UserCommunicationPlatformDataList = {
  __typename: 'UserCommunicationPlatformDataList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserCommunicationPlatformData records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserCommunicationPlatformData>>;
  /** Number of UserCommunicationPlatformData records skipped */
  skip: Scalars['Int']['output'];
};

/** UserCommunicationPlatformData ordering options */
export type UserCommunicationPlatformDataOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserCommunicationPlatformDataOrderEnum>;
};

/** Fields available to order Properties */
export enum UserCommunicationPlatformDataOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** PlatformEnums */
export enum UserCommunicationPlatformEnum {
  /** Netcore */
  NETCORE = 'NETCORE',
  /** OneSignal */
  ONE_SIGNAL = 'ONE_SIGNAL'
}

export enum UserCustomerServiceEventEnum {
  /** Delivery */
  DELIVERY = 'DELIVERY',
  /** Expired Goods */
  EXPIRED_GOODS = 'EXPIRED_GOODS',
  /** Ingredient */
  INGREDIENT = 'INGREDIENT',
  /** Meal */
  MEAL = 'MEAL',
  /** Other */
  OTHER = 'OTHER',
  /** Packaging */
  PACKAGING = 'PACKAGING',
  /** Poor Quality Ingredient  */
  POOR_QUALITY_INGREDIENT = 'POOR_QUALITY_INGREDIENT',
  /** Portion Size */
  PORTION_SIZE = 'PORTION_SIZE',
  /** Query */
  QUERY = 'QUERY',
  /** Recipe Card */
  RECIPE_CARD = 'RECIPE_CARD',
  /** Referral Reward */
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  /** Tech Issues */
  TECH_ISSUES = 'TECH_ISSUES',
  /** Voucher */
  VOUCHER = 'VOUCHER'
}

export enum UserCustomerServiceTagEnum {
  /** Address  (Issues) */
  ADDRESS_ISSUES = 'ADDRESS_ISSUES',
  /** Allergy */
  ALLERGY = 'ALLERGY',
  /** Billing (Issues) */
  BILLING_ISSUES = 'BILLING_ISSUES',
  /** Broken Seals */
  BROKEN_SEALS = 'BROKEN_SEALS',
  /** Checkout (Issues) */
  CHECKOUT_ISSUES = 'CHECKOUT_ISSUES',
  /** Chicken */
  CHICKEN = 'CHICKEN',
  /** Competition */
  COMPETITION = 'COMPETITION',
  /** Damaged Ingredient */
  DAMAGED_INGREDIENT = 'DAMAGED_INGREDIENT',
  /** Dry Goods */
  DRY_GOODS = 'DRY_GOODS',
  /** Expired chicken */
  EXPIRED_CHICKEN = 'EXPIRED_CHICKEN',
  /** Expired Dairy */
  EXPIRED_DAIRY = 'EXPIRED_DAIRY',
  /** Expired dry goods */
  EXPIRED_DRY_GOODS = 'EXPIRED_DRY_GOODS',
  /** Expired Fish */
  EXPIRED_FISH = 'EXPIRED_FISH',
  /** Expired Pasta */
  EXPIRED_PASTA = 'EXPIRED_PASTA',
  /** Expired pork */
  EXPIRED_PORK = 'EXPIRED_PORK',
  /** Expired red meat */
  EXPIRED_RED_MEAT = 'EXPIRED_RED_MEAT',
  /** Expired Sauces */
  EXPIRED_SAUCES = 'EXPIRED_SAUCES',
  /** Expired veg */
  EXPIRED_VEG = 'EXPIRED_VEG',
  /** Fish */
  FISH = 'FISH',
  /** Foreign object- natural */
  FOREIGN_OBJECT_NATURAL = 'FOREIGN_OBJECT_NATURAL',
  /** Foreign object- unnatural */
  FOREIGN_OBJECT_UNNATURAL = 'FOREIGN_OBJECT_UNNATURAL',
  /** Hard to Follow: Too Complex */
  HARD_TO_FOLLOW_TOO_CONPLEX = 'HARD_TO_FOLLOW_TOO_CONPLEX',
  /** Hard to Follow: Too Wordy */
  HARD_TO_FOLLOW_TOO_WORDY = 'HARD_TO_FOLLOW_TOO_WORDY',
  /** Hard Veg */
  HARD_VEG = 'HARD_VEG',
  /** Incorrect Delivery */
  INCORRECT_DELIVERY = 'INCORRECT_DELIVERY',
  /** Incorrect Ingredient */
  INCORRECT_INGREDIENT = 'INCORRECT_INGREDIENT',
  /** Incorrect label */
  INCORRECT_LABEL = 'INCORRECT_LABEL',
  /** Incorrect Meal */
  INCORRECT_MEAL = 'INCORRECT_MEAL',
  /** Incorrect Portion */
  INCORRECT_PORTION = 'INCORRECT_PORTION',
  /** Ingredient Reference Error */
  INGREDIENT_REFERENCE_ERROR = 'INGREDIENT_REFERENCE_ERROR',
  /** Leaves */
  LEAVES = 'LEAVES',
  /** Login (Issues) */
  LOGIN_ISSUES = 'LOGIN_ISSUES',
  /** Lost in the system */
  LOST_IN_THE_SYSTEM = 'LOST_IN_THE_SYSTEM',
  /** Missing all recipe cards */
  MISSING_ALL_RECIPE_CARDS = 'MISSING_ALL_RECIPE_CARDS',
  /** Missing Ingredient */
  MISSING_INGREDIENT = 'MISSING_INGREDIENT',
  /** Missing one recipe card */
  MISSING_ONE_RECIPE_CARD = 'MISSING_ONE_RECIPE_CARD',
  /** Nutritional Info */
  NUTRITIONAL_INFO = 'NUTRITIONAL_INFO',
  /** Order (Issues) */
  ORDER_ISSUES = 'ORDER_ISSUES',
  /** Other */
  OTHER = 'OTHER',
  /** Payment (Issues) */
  PAYMENT_ISSUES = 'PAYMENT_ISSUES',
  /** Placed Status (Issues) */
  PLACED_STATUS_ISSUES = 'PLACED_STATUS_ISSUES',
  /** Poor Quality */
  POOR_QUALITY = 'POOR_QUALITY',
  /** Poor quality bread */
  POOR_QUALITY_BREAD = 'POOR_QUALITY_BREAD',
  /** Poor quality chicken */
  POOR_QUALITY_CHICKEN = 'POOR_QUALITY_CHICKEN',
  /** Poor quality Dry goods item */
  POOR_QUALITY_DRY_GOODS_ITEM = 'POOR_QUALITY_DRY_GOODS_ITEM',
  /** Poor quality Fish */
  POOR_QUALITY_FISH = 'POOR_QUALITY_FISH',
  /** Poor quality Hard Veg */
  POOR_QUALITY_HARD_VEG = 'POOR_QUALITY_HARD_VEG',
  /** Poor quality Leaves */
  POOR_QUALITY_LEAVES = 'POOR_QUALITY_LEAVES',
  /** Poor quality red meat */
  POOR_QUALITY_RED_MEAT = 'POOR_QUALITY_RED_MEAT',
  /** Poor quality Veg */
  POOR_QUALITY_VEG = 'POOR_QUALITY_VEG',
  /** Preservatives */
  PRESERVATIVES = 'PRESERVATIVES',
  /** Recipe Method Error */
  RECIPE_METHOD_ERROR = 'RECIPE_METHOD_ERROR',
  /** Recycling */
  RECYCLING = 'RECYCLING',
  /** Red Meat */
  RED_MEAT = 'RED_MEAT',
  /** Referral Reward */
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  /** Shelf Life */
  SHELF_LIFE = 'SHELF_LIFE',
  /** Sign up (Issues) */
  SIGN_UP_ISSUES = 'SIGN_UP_ISSUES',
  /** Spelling and Grammar Error */
  SPELLING_AND_GRAMMAR_ERROR = 'SPELLING_AND_GRAMMAR_ERROR',
  /** Stickering Issue */
  STICKERING_ISSUE = 'STICKERING_ISSUE',
  /** Supplier */
  SUPPLIER = 'SUPPLIER',
  /** Too much plastic */
  TOO_MUCH_PLASTIC = 'TOO_MUCH_PLASTIC',
  /** Website (Issues) */
  WEBSITE_ISSUES = 'WEBSITE_ISSUES',
  /** Whole Meal */
  WHOLE_MEAL = 'WHOLE_MEAL'
}

export enum UserDetailsEventEnum {
  /** Address */
  ADDRESS = 'ADDRESS',
  /** Default Address */
  DEFAULT_ADDRESS = 'DEFAULT_ADDRESS',
  /** Default Card */
  DEFAULT_CARD = 'DEFAULT_CARD',
  /** Forgot Password */
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  /** General */
  GENERAL = 'GENERAL',
  /** Password */
  PASSWORD = 'PASSWORD',
  /** Payment */
  PAYMENT = 'PAYMENT',
  /** Verification */
  VERIFICATION = 'VERIFICATION'
}

/** Represents a UserDevice in the system */
export type UserDevice = {
  __typename: 'UserDevice';
  active: Scalars['Boolean']['output'];
  /** The creation date of the UserDevice */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  device: Device;
  deviceId: Scalars['ID']['output'];
  /** The UserDevice's ID */
  id: Scalars['ID']['output'];
  /** The update date of the UserDevice */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  userId: Scalars['ID']['output'];
};

/** UserDevice filtering options */
export type UserDeviceFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  deviceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Properties list object */
export type UserDeviceList = {
  __typename: 'UserDeviceList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserDevice records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserDevice>>;
  /** Number of UserDevice records skipped */
  skip: Scalars['Int']['output'];
};

/** UserDevice ordering options */
export type UserDeviceOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserDeviceOrderEnum>;
};

/** Fields available to order Properties */
export enum UserDeviceOrderEnum {
  ACTIVE = 'ACTIVE',
  CREATED_AT = 'CREATED_AT',
  DEVICE_ID = 'DEVICE_ID',
  ID = 'ID',
  UPDATED_AT = 'UPDATED_AT',
  USER_ID = 'USER_ID'
}

/** Represents a UserDiscount in the system */
export type UserDiscount = {
  __typename: 'UserDiscount';
  /** The creation date of the UserDiscount */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The UserDiscount's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: UserDiscountStatus;
  /** The update date of the UserDiscount */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum UserDiscountEventEnum {
  /** Discount Redeemed */
  DISCOUNT_REDEEMED = 'DISCOUNT_REDEEMED',
  /** Discount Removed */
  DISCOUNT_REMOVED = 'DISCOUNT_REMOVED'
}

/** UserDiscount filtering options */
export type UserDiscountFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type UserDiscountList = {
  __typename: 'UserDiscountList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserDiscount records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserDiscount>>;
  /** Number of UserDiscount records skipped */
  skip: Scalars['Int']['output'];
};

/** UserDiscount ordering options */
export type UserDiscountOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserDiscountOrderEnum>;
};

/** Fields available to order Properties */
export enum UserDiscountOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UserDiscountStatus {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Invalidated */
  INVALIDATED = 'INVALIDATED',
  /** Removed */
  REMOVED = 'REMOVED',
  /** Used */
  USED = 'USED'
}

/** User filtering options */
export type UserFilters = {
  adminsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  csStatus?: InputMaybe<Array<CustomerServiceStatusEnum>>;
  hasAcceptedInvite?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isInvited?: InputMaybe<Scalars['Boolean']['input']>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<UserStatusEnum>>;
  type?: InputMaybe<Array<UserTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum UserGroupsEnum {
  /** All Subscribers */
  ALL_SUBSCRIBERS = 'ALL_SUBSCRIBERS',
  /** All Users */
  ALL_USERS = 'ALL_USERS',
  /** Current Menu Users */
  CURRENT_MENU_USERS = 'CURRENT_MENU_USERS',
  /** Last Menu Users With Monday Delivery */
  LAST_MENU_MONDAY_USERS = 'LAST_MENU_MONDAY_USERS',
  /** Last Menu Users With Sunday Delivery */
  LAST_MENU_SUNDAY_USERS = 'LAST_MENU_SUNDAY_USERS',
  /** Last Menu Users With Thursday Delivery */
  LAST_MENU_THURSDAY_USERS = 'LAST_MENU_THURSDAY_USERS',
  /** Last Menu Users */
  LAST_MENU_USERS = 'LAST_MENU_USERS',
  /** Non Subscribers */
  NON_SUBSCRIBERS = 'NON_SUBSCRIBERS',
  /** Paused Subscribers */
  PAUSED_SUBSCRIBERS = 'PAUSED_SUBSCRIBERS',
  /** Un-Paused Subscribers */
  UN_PAUSED_SUBSCRIBERS = 'UN_PAUSED_SUBSCRIBERS'
}

/** Represents a UserHistory in the system */
export type UserHistory = {
  __typename: 'UserHistory';
  additionalInfo: Maybe<Scalars['JSON']['output']>;
  /** The ID of the user who made the event, null if created by the User */
  admin: Maybe<User>;
  attendedBy: Maybe<User>;
  /** The creation date of the UserHistory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  event: Maybe<UserHistoryEventEnum>;
  /** The UserHistory's ID */
  id: Scalars['ID']['output'];
  requiresAttention: Scalars['Boolean']['output'];
  type: UserHistoryTypeEnum;
  /** The update date of the UserHistory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export enum UserHistoryEventEnum {
  /** Activated */
  ACTIVATED = 'ACTIVATED',
  /** Address */
  ADDRESS = 'ADDRESS',
  /** AppPasswordAdded */
  APP_PASSWORD_RESET = 'APP_PASSWORD_RESET',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Changed */
  CHANGED = 'CHANGED',
  /** Credit */
  CREDITED = 'CREDITED',
  /** Debit */
  DEBIT = 'DEBIT',
  /** Default Address */
  DEFAULT_ADDRESS = 'DEFAULT_ADDRESS',
  /** Default Card */
  DEFAULT_CARD = 'DEFAULT_CARD',
  /** Delivery */
  DELIVERY = 'DELIVERY',
  /** Delivery Slot Changed */
  DELIVERY_SLOT_CHANGED = 'DELIVERY_SLOT_CHANGED',
  /** Discount Redeemed */
  DISCOUNT_REDEEMED = 'DISCOUNT_REDEEMED',
  /** Discount Removed */
  DISCOUNT_REMOVED = 'DISCOUNT_REMOVED',
  /** Dispatched */
  DISPATCHED = 'DISPATCHED',
  /** Expired Goods */
  EXPIRED_GOODS = 'EXPIRED_GOODS',
  /** Fail */
  FAIL = 'FAIL',
  /** Forgot Password */
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  /** General */
  GENERAL = 'GENERAL',
  /** Ingredient */
  INGREDIENT = 'INGREDIENT',
  /** Manually Created Order */
  MANUALLY_CREATED_ORDER = 'MANUALLY_CREATED_ORDER',
  /** Meal */
  MEAL = 'MEAL',
  /** Meal Count Changed */
  MEAL_COUNT_CHANGED = 'MEAL_COUNT_CHANGED',
  /** Menu Changed */
  MENU_CHANGED = 'MENU_CHANGED',
  /** Other */
  OTHER = 'OTHER',
  /** Packaging */
  PACKAGING = 'PACKAGING',
  /** Password */
  PASSWORD = 'PASSWORD',
  /** Paused */
  PAUSED = 'PAUSED',
  /** Pause Reasons */
  PAUSE_REASONS = 'PAUSE_REASONS',
  /** Payment */
  PAYMENT = 'PAYMENT',
  /** Placed */
  PLACED = 'PLACED',
  /** Poor Quality Ingredient  */
  POOR_QUALITY_INGREDIENT = 'POOR_QUALITY_INGREDIENT',
  /** Portions Changed */
  PORTIONS_CHANGED = 'PORTIONS_CHANGED',
  /** Portion Size */
  PORTION_SIZE = 'PORTION_SIZE',
  /** Query */
  QUERY = 'QUERY',
  /** Rebill */
  REBILL = 'REBILL',
  /** Recipe Card */
  RECIPE_CARD = 'RECIPE_CARD',
  /** Referral Reward */
  REFERRAL_REWARD_EVENT = 'REFERRAL_REWARD_EVENT',
  /** Refunded */
  REFUNDED = 'REFUNDED',
  /** Returned */
  RETURNED = 'RETURNED',
  /** Saved Cart */
  SAVED_CART = 'SAVED_CART',
  /** Started */
  STARTED = 'STARTED',
  /** Success */
  Success = 'Success',
  /** Tech Issues */
  TECH_ISSUES = 'TECH_ISSUES',
  /** Unsupported Address Event */
  UNSUPPORTED_ADDRESS_EVENT = 'UNSUPPORTED_ADDRESS_EVENT',
  /** Customer Service Event */
  USER_CUSTOMER_SERVICE_EVENT = 'USER_CUSTOMER_SERVICE_EVENT',
  /** Details Event */
  USER_DETAILS_EVENT = 'USER_DETAILS_EVENT',
  /** User Discount Event */
  USER_DISCOUNT_EVENT = 'USER_DISCOUNT_EVENT',
  /** Order Event */
  USER_ORDER_EVENT = 'USER_ORDER_EVENT',
  /** Payment Event */
  USER_PAYMENT_EVENT = 'USER_PAYMENT_EVENT',
  /** Preferences Event */
  USER_PREFERENCES_EVENT = 'USER_PREFERENCES_EVENT',
  /** Sign Up Event */
  USER_SIGN_UP_EVENT = 'USER_SIGN_UP_EVENT',
  /** Subscription Event */
  USER_SUBSCRIPTION_EVENT = 'USER_SUBSCRIPTION_EVENT',
  /** User Subscription Updated */
  USER_SUBSCRIPTION_UPDATED = 'USER_SUBSCRIPTION_UPDATED',
  /** Verification */
  VERIFICATION = 'VERIFICATION',
  /** Voucher */
  VOUCHER = 'VOUCHER'
}

/** UserHistory filtering options */
export type UserHistoryFilters = {
  adminId?: InputMaybe<Scalars['ID']['input']>;
  admins?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  events?: InputMaybe<Array<InputMaybe<UserHistoryEventEnum>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  requiresAttention?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<InputMaybe<UserHistoryTypeEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Properties list object */
export type UserHistoryList = {
  __typename: 'UserHistoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserHistory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserHistory>>;
  /** Number of UserHistory records skipped */
  skip: Scalars['Int']['output'];
};

/** UserHistory ordering options */
export type UserHistoryOrder = {
  /** Order direction */
  direction: OrderDirectionEnum;
  /** Order field */
  field: UserHistoryOrderEnum;
};

/** Fields available to order Properties */
export enum UserHistoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  EVENT = 'EVENT',
  TYPE = 'TYPE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UserHistoryTypeEnum {
  /** Referral Reward */
  REFERRAL_REWARD_EVENT = 'REFERRAL_REWARD_EVENT',
  /** Unsupported Address Event */
  UNSUPPORTED_ADDRESS_EVENT = 'UNSUPPORTED_ADDRESS_EVENT',
  /** Customer Service Event */
  USER_CUSTOMER_SERVICE_EVENT = 'USER_CUSTOMER_SERVICE_EVENT',
  /** Details Event */
  USER_DETAILS_EVENT = 'USER_DETAILS_EVENT',
  /** User Discount Event */
  USER_DISCOUNT_EVENT = 'USER_DISCOUNT_EVENT',
  /** Order Event */
  USER_ORDER_EVENT = 'USER_ORDER_EVENT',
  /** Payment Event */
  USER_PAYMENT_EVENT = 'USER_PAYMENT_EVENT',
  /** User Points */
  USER_POINTS_EVENT = 'USER_POINTS_EVENT',
  /** Preferences Event */
  USER_PREFERENCES_EVENT = 'USER_PREFERENCES_EVENT',
  /** Sign Up Event */
  USER_SIGN_UP_EVENT = 'USER_SIGN_UP_EVENT',
  /** Subscription Event */
  USER_SUBSCRIPTION_EVENT = 'USER_SUBSCRIPTION_EVENT'
}

export type UserIdByToken = {
  __typename: 'UserIdByToken';
  token: Scalars['String']['output'];
};

/** Properties list object */
export type UserList = {
  __typename: 'UserList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of User records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<User>>;
  /** Number of User records skipped */
  skip: Scalars['Int']['output'];
};

/** Represents a UserMenu in the system */
export type UserMenu = {
  __typename: 'UserMenu';
  billingDate: Scalars['DateTime']['output'];
  checkingDate: Scalars['DateTime']['output'];
  /** The creation date of the UserMenu */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  creditCard: Maybe<CreditCard>;
  deliveryAddress: Maybe<UserAddress>;
  deliveryDate: Scalars['DateTime']['output'];
  deliveryOption: WeekDayEnum;
  dishes: Array<UserMenuDish>;
  dispatchDate: Scalars['DateTime']['output'];
  flagReasons: Maybe<Array<UserMenuCheckEnum>>;
  hasDifferentCostFromSubscription: Scalars['Boolean']['output'];
  hasLastSavedConfig: Scalars['Boolean']['output'];
  /** The UserMenu's ID */
  id: Scalars['ID']['output'];
  invalidMessages: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isFlagged: Scalars['Boolean']['output'];
  menu: Menu;
  menuIsSaved: Scalars['Boolean']['output'];
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: NumberOfPortionsEnum;
  openingDate: Scalars['DateTime']['output'];
  order: Maybe<Order>;
  status: UserMenuStatusEnum;
  subscription: UserSubscription;
  subscriptionDifference: UserMenuSubscriptionDifference;
  /** The update date of the UserMenu */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  valid: Maybe<Scalars['Boolean']['output']>;
};

export type UserMenuCartResponse = {
  __typename: 'UserMenuCartResponse';
  cart: Cart;
  userMenu: UserMenu;
};

export enum UserMenuCheckActionEnum {
  /** Error */
  ERROR = 'ERROR',
  /** Pause Subscription */
  PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION',
  /** Warning */
  WARNING = 'WARNING'
}

export enum UserMenuCheckEnum {
  /** Expired Credit Card */
  EXPIRED_CREDIT_CARD = 'EXPIRED_CREDIT_CARD',
  /** Invalid Address */
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  /** Invalid Credit Card */
  INVALID_CREDIT_CARD = 'INVALID_CREDIT_CARD',
  /** Invalid Delivery Day */
  INVALID_DELIVERY_DAY = 'INVALID_DELIVERY_DAY',
  /** Menu not saved (does not match Cart) */
  MENU_NOT_SAVED = 'MENU_NOT_SAVED',
  /** No Address */
  NO_ADDRESS = 'NO_ADDRESS',
  /** No Credit Card */
  NO_CREDIT_CARD = 'NO_CREDIT_CARD',
  /** No Warehouse for City */
  NO_WAREHOUSE_FOR_CITY = 'NO_WAREHOUSE_FOR_CITY',
  /** Previous Order Unpaid */
  PREVIOUS_ORDER_UNPAID = 'PREVIOUS_ORDER_UNPAID',
  /** Unsupported Address */
  UNSUPPORTED_ADDRESS = 'UNSUPPORTED_ADDRESS'
}

/** Represents a UserMenuDish in the system */
export type UserMenuDish = {
  __typename: 'UserMenuDish';
  /** The creation date of the UserMenuDish */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  dish: MealKitDish;
  /** The UserMenuDish's ID */
  id: Scalars['ID']['output'];
  portionSize: Maybe<NumberOfPortionsEnum>;
  /** The update date of the UserMenuDish */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type UserMenuError = {
  __typename: 'UserMenuError';
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
  userMenus: Array<UserMenu>;
};

/** UserMenu filtering options */
export type UserMenuFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  flagReasons?: InputMaybe<Array<UserMenuCheckEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isFlagged?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  menus?: InputMaybe<Array<Scalars['ID']['input']>>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfMeals?: InputMaybe<Array<Scalars['Int']['input']>>;
  numberOfPortions?: InputMaybe<Array<NumberOfPortionsEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<UserMenuStatusEnum>>>;
  subscriptionStatus?: InputMaybe<Array<UserSubscriptionStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userTypes?: InputMaybe<Array<UserTypeEnum>>;
};

export type UserMenuFlagCount = {
  __typename: 'UserMenuFlagCount';
  count: Scalars['Int']['output'];
  flagReasons: Maybe<Array<UserMenuCheckEnum>>;
};

export type UserMenuFlagCountList = {
  __typename: 'UserMenuFlagCountList';
  list: Array<Maybe<UserMenuFlagCount>>;
};

/** Properties list object */
export type UserMenuList = {
  __typename: 'UserMenuList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserMenu records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserMenu>>;
  /** Number of UserMenu records skipped */
  skip: Scalars['Int']['output'];
};

/** UserMenu ordering options */
export type UserMenuOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserMenuOrderEnum>;
};

/** Fields available to order Properties */
export enum UserMenuOrderEnum {
  CREATED_AT = 'CREATED_AT',
  IS_FLAGGED = 'IS_FLAGGED',
  NAME = 'NAME',
  NUMBER_OF_MEALS = 'NUMBER_OF_MEALS',
  NUMBER_OF_PORTIONS = 'NUMBER_OF_PORTIONS',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type UserMenuSave = {
  __typename: 'UserMenuSave';
  cart: Cart;
  errors: Array<Maybe<CartError>>;
  notification: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userMenu: Maybe<UserMenu>;
};

export enum UserMenuStatusEnum {
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Converted to Order */
  CONVERTED_TO_ORDER = 'CONVERTED_TO_ORDER',
  /** Failed */
  FAILED = 'FAILED',
  /** Guest */
  GUEST = 'GUEST',
  /** Open */
  OPEN = 'OPEN',
  /** Processing */
  PROCESSING = 'PROCESSING'
}

export type UserMenuSubscriptionDifference = {
  __typename: 'UserMenuSubscriptionDifference';
  currentCost: Scalars['Float']['output'];
  currentNumberOfMeals: Scalars['Int']['output'];
  currentNumberOfPortions: NumberOfPortionsEnum;
  subscriptionCost: Scalars['Float']['output'];
  subscriptionNumberOfMeals: Scalars['Int']['output'];
  subscriptionNumberOfPortions: NumberOfPortionsEnum;
};

/** User ordering options */
export type UserOrder = {
  /** Order direction */
  direction: OrderDirectionEnum;
  /** Order field */
  field: UserOrderEnum;
};

/** Fields available to order Properties */
export enum UserOrderEnum {
  CREATED_AT = 'CREATED_AT',
  CS_STATUS = 'CS_STATUS',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UserOrderEvent {
  /** Call Status Updated */
  CALL_STATUS_UPDATED = 'CALL_STATUS_UPDATED',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Dispatched */
  DISPATCHED = 'DISPATCHED',
  /** Manually Created Order */
  MANUALLY_CREATED_ORDER = 'MANUALLY_CREATED_ORDER',
  /** Placed */
  PLACED = 'PLACED',
  /** Returned */
  RETURNED = 'RETURNED'
}

/** Represents a UserPartnership in the system */
export type UserPartnership = {
  __typename: 'UserPartnership';
  /** The creation date of the UserPartnership */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The UserPartnership's ID */
  id: Scalars['ID']['output'];
  memberToken: Scalars['String']['output'];
  memberVerified: Scalars['Boolean']['output'];
  partnershipPlatform: PartnershipPlatformEnum;
  /** The update date of the UserPartnership */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

/** UserPartnership filtering options */
export type UserPartnershipFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  memberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  partnershipPlatform?: InputMaybe<Array<InputMaybe<PartnershipPlatformEnum>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Properties list object */
export type UserPartnershipList = {
  __typename: 'UserPartnershipList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserPartnership records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserPartnership>>;
  /** Number of UserPartnership records skipped */
  skip: Scalars['Int']['output'];
};

/** UserPartnership ordering options */
export type UserPartnershipOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserPartnershipOrderEnum>;
};

/** Fields available to order Properties */
export enum UserPartnershipOrderEnum {
  CREATED_AT = 'CREATED_AT',
  MEMBER_VERIFIED = 'MEMBER_VERIFIED',
  PARTNERSHIP_PLATFORM = 'PARTNERSHIP_PLATFORM',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UserPaymentEventEnum {
  /** Fail */
  FAIL = 'FAIL',
  /** Rebill */
  REBILL = 'REBILL',
  /** Refunded */
  REFUNDED = 'REFUNDED',
  /** Success */
  SUCCESS = 'SUCCESS'
}

export enum UserPointEventEnum {
  /** Credit */
  CREDITED = 'CREDITED',
  /** Debit */
  DEBIT = 'DEBIT'
}

export enum UserPointsReporting {
  /** Adjustment */
  ADJUSTMENT = 'ADJUSTMENT',
  /** History Carry Over */
  HISTORY_CARRY_OVER = 'HISTORY_CARRY_OVER',
  /** Used */
  USED = 'USED'
}

/** Represents a UserPreference in the system */
export type UserPreference = {
  __typename: 'UserPreference';
  /** The creation date of the UserPreference */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The UserPreference's ID */
  id: Scalars['ID']['output'];
  preference: Preference;
  /** The update date of the UserPreference */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
  userValue: Scalars['String']['output'];
};

/** Object for use in Add All method */
export type UserPreferenceBulkObject = {
  preference: Scalars['ID']['input'];
  userValue?: InputMaybe<Scalars['String']['input']>;
};

export type UserPreferenceByToken = {
  __typename: 'UserPreferenceByToken';
  preferences: Scalars['JSONObject']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** UserPreference filtering options */
export type UserPreferenceFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  preferenceId?: InputMaybe<Scalars['ID']['input']>;
  preferenceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type UserPreferenceList = {
  __typename: 'UserPreferenceList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserPreference records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserPreference>>;
  /** Number of UserPreference records skipped */
  skip: Scalars['Int']['output'];
};

/** UserPreference ordering options */
export type UserPreferenceOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserPreferenceOrderEnum>;
};

/** Fields available to order Properties */
export enum UserPreferenceOrderEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UserReferralStatusEnum {
  /** Activated Subscription */
  ACTIVATED_SUBSCRIPTION = 'ACTIVATED_SUBSCRIPTION',
  /** Already Referred */
  ALREADY_REFERRED = 'ALREADY_REFERRED',
  /** Already Referred By User */
  ALREADY_REFERRED_BY_USER = 'ALREADY_REFERRED_BY_USER',
  /** Already Subscription User */
  ALREADY_SUBSCRIPTION_USER = 'ALREADY_SUBSCRIPTION_USER',
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Created Account */
  CREATED_ACCOUNT = 'CREATED_ACCOUNT',
  /** Emailed */
  EMAILED = 'EMAILED',
  /** Expired */
  EXPIRED = 'EXPIRED',
  /** Ordered */
  ORDERED = 'ORDERED',
  /** Reference Created */
  REFERENCE_CREATED = 'REFERENCE_CREATED',
  /** Resent Email */
  RESENT_EMAIL = 'RESENT_EMAIL',
  /** Used Organic Link */
  USED_ORGANIC_LINK = 'USED_ORGANIC_LINK',
  /** User Reference Link */
  USED_REFERENCE_LINK = 'USED_REFERENCE_LINK',
  /** User Separate Referral Link */
  USED_SEPARATE_REFERRAL_LINK = 'USED_SEPARATE_REFERRAL_LINK'
}

/** Represents a UserRole in the system */
export type UserRole = {
  __typename: 'UserRole';
  /** The creation date of the UserRole */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The UserRole's ID */
  id: Scalars['ID']['output'];
  role: Role;
  /** The update date of the UserRole */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

/** UserRole filtering options */
export type UserRoleFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Properties list object */
export type UserRoleList = {
  __typename: 'UserRoleList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserRole records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserRole>>;
  /** Number of UserRole records skipped */
  skip: Scalars['Int']['output'];
};

/** UserRole ordering options */
export type UserRoleOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserRoleOrderEnum>;
};

/** Fields available to order Properties */
export enum UserRoleOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Fields to sign up an existing User */
export type UserSignUpInput = {
  communicationConsent: Scalars['Boolean']['input'];
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['Phone']['input'];
  title?: InputMaybe<TitleEnum>;
};

/** User Status */
export enum UserStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Guest */
  GUEST = 'GUEST',
  /** Inactive */
  INACTIVE = 'INACTIVE',
  /** Suspended */
  SUSPENDED = 'SUSPENDED'
}

/** Represents a UserSubscription in the system */
export type UserSubscription = {
  __typename: 'UserSubscription';
  alcoholicGifts: GiftAlcoholStatusEnum;
  category: MealKitCategory;
  /** The creation date of the UserSubscription */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  deliveryOption: WeekDayEnum;
  /** The UserSubscription's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfMeals: Scalars['Int']['output'];
  numberOfPortions: NumberOfPortionsEnum;
  pauseReasoning: Maybe<Scalars['String']['output']>;
  pauseReasons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resumeDate: Maybe<Scalars['DateTime']['output']>;
  status: UserSubscriptionStatusEnum;
  subscription: OrderSubscription;
  upcomingMenus: Array<Menu>;
  /** The update date of the UserSubscription */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: User;
};


/** Represents a UserSubscription in the system */
export type UserSubscriptionupcomingMenusArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserSubscriptionEventEnum {
  /** Activated */
  ACTIVATED = 'ACTIVATED',
  /** Changed */
  CHANGED = 'CHANGED',
  /** Delivery Slot Changed */
  DELIVERY_SLOT_CHANGED = 'DELIVERY_SLOT_CHANGED',
  /** Meal Count Changed */
  MEAL_COUNT_CHANGED = 'MEAL_COUNT_CHANGED',
  /** Menu Changed */
  MENU_CHANGED = 'MENU_CHANGED',
  /** Paused */
  PAUSED = 'PAUSED',
  /** Pause Reasons */
  PAUSE_REASONS = 'PAUSE_REASONS',
  /** Portions Changed */
  PORTIONS_CHANGED = 'PORTIONS_CHANGED',
  /** Saved Cart */
  SAVED_CART = 'SAVED_CART',
  /** Started */
  STARTED = 'STARTED',
  /** User Subscription Updated */
  USER_SUBSCRIPTION_UPDATED = 'USER_SUBSCRIPTION_UPDATED'
}

/** UserSubscription filtering options */
export type UserSubscriptionFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryOptions?: InputMaybe<Array<WeekDayEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  numberOfMeals?: InputMaybe<Array<Scalars['Int']['input']>>;
  numberOfPortions?: InputMaybe<Array<NumberOfPortionsEnum>>;
  resumeDate?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<UserSubscriptionStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type UserSubscriptionList = {
  __typename: 'UserSubscriptionList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserSubscription records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<UserSubscription>>;
  /** Number of UserSubscription records skipped */
  skip: Scalars['Int']['output'];
};

/** UserSubscription ordering options */
export type UserSubscriptionOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<UserSubscriptionOrderEnum>;
};

/** Fields available to order Properties */
export enum UserSubscriptionOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DELIVERY_OPTION = 'DELIVERY_OPTION',
  NAME = 'NAME',
  NUMBER_OF_MEALS = 'NUMBER_OF_MEALS',
  NUMBER_OF_PORTIONS = 'NUMBER_OF_PORTIONS',
  RESUME_DATE = 'RESUME_DATE',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT',
  USER = 'USER'
}

export enum UserSubscriptionStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Cancelled */
  CANCELLED = 'CANCELLED',
  /** Paused */
  PAUSED = 'PAUSED',
  /** Pending */
  PENDING = 'PENDING'
}

export enum UserTypeEnum {
  /** Guest */
  GUEST_USER = 'GUEST_USER',
  /** Registered */
  REGISTERED_USER = 'REGISTERED_USER'
}

/** List for Get User Preferences */
export type UsersPreferencesOrDefaultsList = {
  __typename: 'UsersPreferencesOrDefaultsList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of UserPreference records returned */
  limit: Scalars['Int']['output'];
  list: Array<Maybe<UsersPreferencesOrDefaultsObject>>;
  /** Number of UserPreference records skipped */
  skip: Scalars['Int']['output'];
};

/** Get Users Preferences Return Object */
export type UsersPreferencesOrDefaultsObject = {
  __typename: 'UsersPreferencesOrDefaultsObject';
  dataType: Maybe<ApiScalarDataTypeEnum>;
  defaultValue: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  options: Maybe<Scalars['String']['output']>;
  preferenceId: Scalars['ID']['output'];
  userPreferenceId: Maybe<Scalars['ID']['output']>;
  userValue: Maybe<Scalars['String']['output']>;
};

export type ValidateResponse = {
  __typename: 'ValidateResponse';
  friendReference: Maybe<FriendReference>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Represents a Vendor in the system */
export type Vendor = {
  __typename: 'Vendor';
  /** The creation date of the Vendor */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The Vendor's ID */
  id: Scalars['ID']['output'];
  /** The update date of the Vendor */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vendorId: Scalars['String']['output'];
  vendorName: Scalars['String']['output'];
};

/** Vendor filtering options */
export type VendorFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

/** Properties list object */
export type VendorList = {
  __typename: 'VendorList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Vendor records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Vendor>>;
  /** Number of Vendor records skipped */
  skip: Scalars['Int']['output'];
};

/** Vendor ordering options */
export type VendorOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<VendorOrderEnum>;
};

/** Fields available to order Properties */
export enum VendorOrderEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR_ID = 'VENDOR_ID',
  VENDOR_NAME = 'VENDOR_NAME'
}

/** Represents a Vineyard in the system */
export type Vineyard = {
  __typename: 'Vineyard';
  about: Scalars['String']['output'];
  banner: Image;
  /** The creation date of the Vineyard */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  discountCode: Maybe<Scalars['String']['output']>;
  /** The Vineyard's ID */
  id: Scalars['ID']['output'];
  logo: Image;
  makers: Array<WineMaker>;
  name: Scalars['String']['output'];
  selectorId: Maybe<Scalars['String']['output']>;
  /** The update date of the Vineyard */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Vineyard filtering options */
export type VineyardFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  selectorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type VineyardList = {
  __typename: 'VineyardList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Vineyard records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Vineyard>>;
  /** Number of Vineyard records skipped */
  skip: Scalars['Int']['output'];
};

/** Vineyard ordering options */
export type VineyardOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<VineyardOrderEnum>;
};

/** Fields available to order Properties */
export enum VineyardOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum VoucherTagEnum {
  /** Competition */
  COMPETITION = 'COMPETITION'
}

/** Represents a Warehouse in the system */
export type Warehouse = {
  __typename: 'Warehouse';
  branch: Branch;
  branchId: Scalars['ID']['output'];
  courierDeliveries: Maybe<Array<Maybe<CourierDelivery>>>;
  /** The creation date of the Warehouse */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The Warehouse's ID */
  id: Scalars['ID']['output'];
  location: Location;
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  products: Array<ProductWarehouse>;
  shippers: Array<ShipperWarehouse>;
  /** The update date of the Warehouse */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouseCouriers: Maybe<Array<Maybe<CourierWarehouse>>>;
  warehouseLocations: Maybe<Array<Maybe<WarehouseLocation>>>;
};

/** Warehouse filtering options */
export type WarehouseFilters = {
  brachIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  branchId?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type WarehouseList = {
  __typename: 'WarehouseList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Warehouse records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Warehouse>>;
  /** Number of Warehouse records skipped */
  skip: Scalars['Int']['output'];
};

/** Represents a WarehouseLocation in the system */
export type WarehouseLocation = {
  __typename: 'WarehouseLocation';
  /** The creation date of the WarehouseLocation */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The WarehouseLocation's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The update date of the WarehouseLocation */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

/** WarehouseLocation filtering options */
export type WarehouseLocationFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  warehouseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Properties list object */
export type WarehouseLocationList = {
  __typename: 'WarehouseLocationList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WarehouseLocation records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WarehouseLocation>>;
  /** Number of WarehouseLocation records skipped */
  skip: Scalars['Int']['output'];
};

/** WarehouseLocation ordering options */
export type WarehouseLocationOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WarehouseLocationOrderEnum>;
};

/** Fields available to order Properties */
export enum WarehouseLocationOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  WAREHOUSE_ID = 'WAREHOUSE_ID'
}

/** Warehouse ordering options */
export type WarehouseOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WarehouseOrderEnum>;
};

/** Fields available to order Properties */
export enum WarehouseOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a WarehouseProductRange in the system */
export type WarehouseProductRange = {
  __typename: 'WarehouseProductRange';
  /** The creation date of the WarehouseProductRange */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** The WarehouseProductRange's ID */
  id: Scalars['ID']['output'];
  leadDays: Scalars['Int']['output'];
  onDemandDispatchDays: Array<WeekDayEnum>;
  productRange: ProductRangeEnum;
  productStatus: WarehouseProductStatusEnum;
  subscriptionDispatchDays: Array<WeekDayEnum>;
  /** The update date of the WarehouseProductRange */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warehouse: Warehouse;
};

/** WarehouseProductRange filtering options */
export type WarehouseProductRangeFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productRanges?: InputMaybe<Array<ProductRangeEnum>>;
  productStatus?: InputMaybe<Array<WarehouseProductStatusEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  warehouses?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Properties list object */
export type WarehouseProductRangeList = {
  __typename: 'WarehouseProductRangeList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WarehouseProductRange records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WarehouseProductRange>>;
  /** Number of WarehouseProductRange records skipped */
  skip: Scalars['Int']['output'];
};

/** WarehouseProductRange ordering options */
export type WarehouseProductRangeOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WarehouseProductRangeOrderEnum>;
};

/** Fields available to order Properties */
export enum WarehouseProductRangeOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum WarehouseProductStatusEnum {
  /** Always Available */
  ALWAYS_AVAILABLE = 'ALWAYS_AVAILABLE',
  /** Never Available */
  NEVER_AVAILABLE = 'NEVER_AVAILABLE',
  /** Sometimes Available */
  SOMETIMES_AVAILABLE = 'SOMETIMES_AVAILABLE'
}

export type WarehouseStock = {
  __typename: 'WarehouseStock';
  id: Scalars['ID']['output'];
  orderType: ProductOrderTypeEnum;
  physicalStockCount: Scalars['Float']['output'];
  status: ProductStockStatusEnum;
  stockCount: Scalars['Float']['output'];
  stockType: ProductStockTypeEnum;
  warehouse: Warehouse;
};

/** Week Days */
export enum WeekDayEnum {
  /** Friday */
  FRIDAY = 'FRIDAY',
  /** Monday */
  MONDAY = 'MONDAY',
  /** Saturday */
  SATURDAY = 'SATURDAY',
  /** Sunday */
  SUNDAY = 'SUNDAY',
  /** Thursday */
  THURSDAY = 'THURSDAY',
  /** Tuesday */
  TUESDAY = 'TUESDAY',
  /** Wednesday */
  WEDNESDAY = 'WEDNESDAY'
}

/** Represents a Wine in the system */
export type Wine = Product & {
  __typename: 'Wine';
  activeParings: Maybe<Array<MenuDishWine>>;
  alcoholContent: Scalars['Float']['output'];
  availability: ProductAvailabilityEnum;
  baseUnitType: Maybe<UnitTypeEnum>;
  cartItemId: Maybe<Scalars['String']['output']>;
  categories: Array<Category>;
  conversionFactor: Maybe<Scalars['Float']['output']>;
  coverImage: Image;
  /** The creation date of the Wine */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  details: Array<Scalars['String']['output']>;
  displayIndex: Maybe<Scalars['Int']['output']>;
  group: Maybe<ProductGroup>;
  /** The Wine's ID */
  id: Scalars['ID']['output'];
  ingredients: Maybe<Array<ProductIngredient>>;
  maker: Maybe<WineMaker>;
  name: Scalars['String']['output'];
  packingCode: Maybe<Scalars['String']['output']>;
  packingVolume: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  productImages: Maybe<Array<Maybe<ProductImage>>>;
  productRange: ProductRangeEnum;
  productType: ProductTypeEnum;
  productionUnitType: Maybe<UnitTypeEnum>;
  quantityInCart: Scalars['Int']['output'];
  related: Array<Wine>;
  revisionId: Scalars['String']['output'];
  shippingConsiderations: Array<ShippingConsiderationEnum>;
  shortDescription: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: ProductStatusEnum;
  stock: Array<WarehouseStock>;
  stockCount: Scalars['Int']['output'];
  stockStatus: ProductStockStatusEnum;
  stockType: ProductStockTypeEnum;
  storageTemp: ProductStorageTempEnum;
  sugar: Scalars['Float']['output'];
  syncStatus: ProductSyncStatusEnum;
  tags: Array<WineTag>;
  taxCategory: TaxCategoryTypeEnum;
  upSellText: Maybe<Scalars['String']['output']>;
  /** The update date of the Wine */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vineyard: Maybe<Vineyard>;
  vintage: Scalars['Int']['output'];
  volume: Scalars['Float']['output'];
  warehouses: Array<ProductWarehouse>;
  wineCategory: WineCategory;
};

/** Represents a WineCampaign in the system */
export type WineCampaign = Campaign & {
  __typename: 'WineCampaign';
  active: Maybe<Scalars['Boolean']['output']>;
  campaignCategories: Array<Maybe<WineCampaignCategory>>;
  campaignVarieties: Array<Maybe<WineCampaignVariety>>;
  campaignWines: WineList;
  /** The creation date of the WineCampaign */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  /** The WineCampaign's ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageSections: Array<PageSection>;
  published: Scalars['Boolean']['output'];
  sections: Maybe<Array<Maybe<PageSection>>>;
  startDate: Scalars['DateTime']['output'];
  status: CampaignStatusEnum;
  title: Maybe<Scalars['String']['output']>;
  /** The update date of the WineCampaign */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vineyards: Maybe<Array<Vineyard>>;
};


/** Represents a WineCampaign in the system */
export type WineCampaigncampaignCategoriesArgs = {
  filters?: InputMaybe<WineCampaignWineFilters>;
};


/** Represents a WineCampaign in the system */
export type WineCampaigncampaignVarietiesArgs = {
  filters?: InputMaybe<WineCampaignWineFilters>;
};


/** Represents a WineCampaign in the system */
export type WineCampaigncampaignWinesArgs = {
  filters?: InputMaybe<WineCampaignWineFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<WineCampaignWineOrder>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type WineCampaignCategory = {
  __typename: 'WineCampaignCategory';
  active: Maybe<Scalars['Boolean']['output']>;
  available: Maybe<Scalars['Boolean']['output']>;
  icon: Maybe<Image>;
  title: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

/** WineCampaign filtering options */
export type WineCampaignFilters = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type WineCampaignList = {
  __typename: 'WineCampaignList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WineCampaign records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WineCampaign>>;
  /** Number of WineCampaign records skipped */
  skip: Scalars['Int']['output'];
};

/** WineCampaign ordering options */
export type WineCampaignOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineCampaignOrderEnum>;
};

/** Fields available to order Properties */
export enum WineCampaignOrderEnum {
  NAME = 'NAME',
  START_DATE = 'START_DATE',
  UPDATED_AT = 'UPDATED_AT'
}

export type WineCampaignVariety = {
  __typename: 'WineCampaignVariety';
  active: Maybe<Scalars['Boolean']['output']>;
  available: Maybe<Scalars['Boolean']['output']>;
  icon: Maybe<Image>;
  title: Scalars['String']['output'];
  value: WineVarietyEnum;
};

export type WineCampaignWineFilters = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  varieties?: InputMaybe<Array<WineVarietyEnum>>;
};

export type WineCampaignWineOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineCampaignWineOrderEnum>;
};

/** Fields available to order Properties */
export enum WineCampaignWineOrderEnum {
  AVAILABILITY = 'AVAILABILITY',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  PRICE = 'PRICE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a WineCategory in the system */
export type WineCategory = {
  __typename: 'WineCategory';
  /** The creation date of the WineCategory */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The WineCategory's ID */
  id: Scalars['ID']['output'];
  pageDataSection: Maybe<Scalars['ID']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  status: WineCategoryStatusEnum;
  title: Scalars['String']['output'];
  /** The update date of the WineCategory */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  variety: WineVarietyEnum;
};

/** WineCategory filtering options */
export type WineCategoryFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<WineCategoryStatusEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type WineCategoryList = {
  __typename: 'WineCategoryList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WineCategory records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WineCategory>>;
  /** Number of WineCategory records skipped */
  skip: Scalars['Int']['output'];
};

/** WineCategory ordering options */
export type WineCategoryOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineCategoryOrderEnum>;
};

/** Fields available to order Properties */
export enum WineCategoryOrderEnum {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum WineCategoryStatusEnum {
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Inactive */
  INACTIVE = 'INACTIVE'
}

/** Wine filtering options */
export type WineFilters = {
  campaignOnly?: InputMaybe<Scalars['Boolean']['input']>;
  campaigns?: InputMaybe<Array<Scalars['ID']['input']>>;
  cities?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  makers?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  priceRange?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ProductStatusEnum>>;
  tags?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  varieties?: InputMaybe<Array<WineVarietyEnum>>;
  vineyards?: InputMaybe<Array<Scalars['ID']['input']>>;
  wineCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  wineCategorySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Properties list object */
export type WineList = {
  __typename: 'WineList';
  aggregation: Maybe<ProductAggregation>;
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of Wine records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<Wine>>;
  /** Number of Wine records skipped */
  skip: Scalars['Int']['output'];
};

/** Represents a WineMaker in the system */
export type WineMaker = {
  __typename: 'WineMaker';
  biography: Maybe<Scalars['String']['output']>;
  /** The creation date of the WineMaker */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  /** The WineMaker's ID */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profileImage: Maybe<Image>;
  /** The update date of the WineMaker */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** WineMaker filtering options */
export type WineMakerFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type WineMakerList = {
  __typename: 'WineMakerList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WineMaker records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WineMaker>>;
  /** Number of WineMaker records skipped */
  skip: Scalars['Int']['output'];
};

/** WineMaker ordering options */
export type WineMakerOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineMakerOrderEnum>;
};

/** Fields available to order Properties */
export enum WineMakerOrderEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

/** Wine ordering options */
export type WineOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineOrderEnum>;
};

/** Fields available to order Properties */
export enum WineOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  MAKER = 'MAKER',
  NAME = 'NAME',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT',
  VINEYARD = 'VINEYARD'
}

/** Represents a WineTag in the system */
export type WineTag = {
  __typename: 'WineTag';
  /** The creation date of the WineTag */
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayIndex: Scalars['Int']['output'];
  icon: Maybe<Image>;
  /** The WineTag's ID */
  id: Scalars['ID']['output'];
  pageDataSection: Maybe<Scalars['ID']['output']>;
  tagType: WineTagTypeEnum;
  title: Scalars['String']['output'];
  /** The update date of the WineTag */
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** WineTag filtering options */
export type WineTagFilters = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<Array<WineTagTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Properties list object */
export type WineTagList = {
  __typename: 'WineTagList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  /** Number of WineTag records returned */
  limit: Scalars['Int']['output'];
  /** A list of Properties */
  list: Array<Maybe<WineTag>>;
  /** Number of WineTag records skipped */
  skip: Scalars['Int']['output'];
};

/** WineTag ordering options */
export type WineTagOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<WineTagOrderEnum>;
};

/** Fields available to order Properties */
export enum WineTagOrderEnum {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_INDEX = 'DISPLAY_INDEX',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Wine Tag Type Enum */
export enum WineTagTypeEnum {
  /** Preferences */
  PREFERENCE = 'PREFERENCE'
}

export enum WineVarietyEnum {
  /** Red */
  RED = 'RED',
  /** Rosé */
  ROSE = 'ROSE',
  /** White */
  WHITE = 'WHITE'
}

/** YearMonths */
export enum YearMonthsEnum {
  /** April */
  APRIL = 'APRIL',
  /** August */
  AUGUST = 'AUGUST',
  /** December */
  DECEMBER = 'DECEMBER',
  /** February */
  FEBRUARY = 'FEBRUARY',
  /** January */
  JANUARY = 'JANUARY',
  /** July */
  JULY = 'JULY',
  /** June */
  JUNE = 'JUNE',
  /** March */
  MARCH = 'MARCH',
  /** May */
  MAY = 'MAY',
  /** November */
  NOVEMBER = 'NOVEMBER',
  /** October */
  OCTOBER = 'OCTOBER',
  /** September */
  SEPTEMBER = 'SEPTEMBER'
}

export type ZeroItemOrderReportFile = {
  __typename: 'ZeroItemOrderReportFile';
  archives: Maybe<Archive>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  menuId: Scalars['String']['output'];
  status: BackgroundTaskStatusEnum;
  updatedAt: Scalars['Date']['output'];
};

export type openingDateRangeFilter = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};
