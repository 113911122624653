import React, { MutableRefObject, useEffect } from 'react'

import { useLocation } from 'react-router'
import styled, { useTheme } from 'styled-components'

import {
  PageDataSection, PageCardSection, PageTabSection, PageTextCardSection, scrollToSelector, PageTileSection,
  ButtonColor, ButtonVariant, getButtonColors, CopyToClipboard, PageBannerSection, PageDetailSection, PageGridSection,
  PageMediaSection, PageTextSection, SectionLoading,
} from '@client/components'
import { getPageSectionBackground, ResponsivePXValue, NotFound } from '@client/components/Theme'
import {
  useGetOnePageSectionGroupQuery, PageSectionFragment, PageDataSectionFragment, PageBannerSectionFragment, PageGridSectionFragment,
  PageMediaSectionFragment, PageTextSectionFragment, PageCardSectionFragment, PageTabSectionFragment, PageDetailSectionFragment,
  PageTextCardSectionFragment, PageTileSectionFragment,
} from '@hooks/api'
import { PageSectionDataVariationEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  flex: 1;
  
  ${ResponsivePXValue('max-width', { mobile: '100vw', tablet: '100vw', desktop: '100%' })}

  .page-section-container:last-child {
    .legal-doc {
      ${ResponsivePXValue('padding-bottom', '28px')}
    }
  }

  .PageDataSection {
    justify-content: center;
  }
`

const PageSectionContainer = styled.div<{ $color: string }>`
  position: relative;
  display: grid;
`

const NotFoundMessage = styled.div`
  ${NotFound}
`
export interface PageSectionRef {
  ref: MutableRefObject<undefined>
  id: string
}
export interface PageSectionGroupProps {
  identifier: string
  refs?: boolean
  isLegalDocumentation?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void

  className?: string
}

export const getPageSectionButtonColor = (color: string): ButtonColor => {
  return color?.toLowerCase() as ButtonColor || 'black'
}

export const getLinkColorFromButtonColor = (variant: ButtonVariant, color: string): string => getButtonColors(variant, getPageSectionButtonColor(color)).color

export function PageSectionGroup({ identifier, isLegalDocumentation, refs = false, addPageSectionRef, className }: PageSectionGroupProps): JSX.Element {

  const { data, loading } = useGetOnePageSectionGroupQuery({ variables: { identifier } })

  const theme = useTheme()
  const location = useLocation()

  const pageSections = data?.pageSectionGroup?.pageSections || []
  let pageSection: PageSectionFragment

  const dontRenderMobile = (pageSection: PageSectionFragment): boolean => {
    const noMobileRender = theme.isMobile() && pageSection.__typename === 'PageDataSection' && pageSection.dataSectionVariation === PageSectionDataVariationEnum.ANCHORED_MEAL_KIT_MENU_THIS_WEEK
    if (noMobileRender) {
      // console.log('Not rendering mobile version for PageDataSection with title => ', pageSection.title)
    }
    return noMobileRender
  }

  const getPageSectionId = (pageSection: PageSectionFragment): string => pageSection?.selectorId?.length > 0 ? pageSection?.selectorId : pageSection?.id
  const getPageColor = (pageSection: PageSectionFragment): string => pageSection?.background ? getPageSectionBackground(pageSection?.background) : theme.colors.misc.transparent

  useEffect(() => {
    if (!loading && location?.hash?.length > 0) {
      setTimeout(() => {
        scrollToSelector(location.hash)
      }, 500)
    }
  }, [loading])

  return (
    <Container className={className}>
      <Choose>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <When condition={!data}>
          <NotFoundMessage>
            No PageSectionGroup Found
          </NotFoundMessage>
        </When>
        <Otherwise>
          <For each='pageSection' of={pageSections}>
            <PageSectionContainer className={`page-section-container ${pageSection.__typename}`} id={getPageSectionId(pageSection)} $color={getPageColor(pageSection)} key={pageSection.id}>
              <If condition={!!pageSection?.discountCode}>
                <CopyToClipboard contentToCopy={pageSection?.discountCode} />
              </If>
              <Choose>
                <When condition={dontRenderMobile(pageSection)}>
                  <></>
                </When>
                <When condition={pageSection.__typename === 'PageDataSection'}>
                  <PageDataSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageDataSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageBannerSection'}>
                  <PageBannerSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageBannerSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageGridSection'}>
                  <PageGridSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageGridSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageMediaSection'}>
                  <PageMediaSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageMediaSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageTextSection'}>
                  <PageTextSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageTextSectionFragment} isLegalDocumentation={isLegalDocumentation} />
                </When>
                <When condition={pageSection.__typename === 'PageCardSection'}>
                  <PageCardSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageCardSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageTabSection'}>
                  <PageTabSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageTabSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageDetailSection'}>
                  <PageDetailSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageDetailSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageTextCardSection'}>
                  <PageTextCardSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageTextCardSectionFragment} />
                </When>
                <When condition={pageSection.__typename === 'PageTileSection'}>
                  <PageTileSection pageSectionRef={refs} addPageSectionRef={addPageSectionRef} pageSection={pageSection as unknown as PageTileSectionFragment} />
                </When>
              </Choose>
            </PageSectionContainer>
          </For>
        </Otherwise>
      </Choose>
    </Container>
  )

}
